import axios from '@/axios/config'

export default {
  namespaced: true,
  state: {
    fetchAllCalled: false,
    allAccounts: [],
  },
  mutations: {
    setFetchAllCalled(state, value) {
      state.fetchAllCalled = value
    },
    setAllAccounts(state, value) {
      state.allAccounts = value
    },
  },
  actions: {
    async fetchAllBankAccounts({ commit }) {
      commit('setFetchAllCalled', true)
      return axios
        .get(`/api/bank-accounts`, {
          params: {
            page: 1,
            per_page: -1,
          },
        })
        .then(res => {
          commit('setAllAccounts', res.data.items)
          return res.data
        })
    },
    async searchBankAccounts({ commit }, params) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .get(`/api/bank-accounts`, { params })
        .then(res => res.data)
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async updateBankAccount({ commit }, account) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .put(`/api/bank-accounts/${account.id}`, account)
        .then(res => res.data)
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async createNewBankAccount({ commit }, data) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .post(`/api/bank-accounts`, data)
        .then(res => res.data)
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async csvImportTransactions({ commit }, { file }) {
      commit('updateHeaderProgressBar', true, { root: true })
      const formData = new FormData()
      formData.append('file', file)
      const res = await axios
        .post(`/api/bank-transactions/import_from_csv`, formData)
        .then(res => res.data)
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
      return res
    },
  },
}
