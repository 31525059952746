<template>
  <div>
    <expense-modal
      :edit="true"
      :edit-expense="editExpense"
      @on-submit="handleOnExpenseUpdate"
    />
    <v-card class="overflow-y-auto">
      <v-card-title>
        <p v-if="isAdmin && isDesignedVr" class="small-text">
          {{ expense.id }}
        </p>
        <expense-card-header
          :expense-id="expense.id"
          :expense="expense"
          :listing-name="expense.listing && expense.listing.nickname"
          title="Expenses Details"
          :sub-title="expense.sub_topic"
          :hide-actions="isInExpensePage || isMobile"
          :delete-enabled="deleteEnabled"
          :edit-enabled="editEnabled"
          @delete-expense="handleDeleteExpense"
        />
      </v-card-title>
      <v-divider />
      <div class="pa-6">
        <span v-if="expense.type === 'BusinessExpense'">
          * Company expense *</span
        >
        <div v-if="expense.unit_number">
          <h5 class="cyan-icon">{{ expense.unit_number }}</h5>
        </div>

        <expense-card-title
          :title="$t(expenseType)"
          :show-link="
            !isInvestor &&
            (expense.listing_task_id ||
              expense.material_task_id ||
              expense.work_info.req_id ||
              expense.work_info.lt_id)
          "
          :lt-id="
            expense.listing_task_id ||
            expense.material_task_id ||
            expense.work_info.lt_id
          "
          :req-id="expense.work_info.req_id"
        />
        <div v-if="description">
          <div class="text-caption">Description</div>
          <div>{{ description }}</div>
        </div>
        <div v-if="managementRoles && listingTask.approval_comments">
          <h5 class="cyan-icon">approval comments</h5>
          <span class="grey--text">{{ listingTask.approval_comments }}</span>
        </div>
      </div>
      <v-divider />
      <div class="pa-6">
        <expense-card-info
          :created-by-name="isInvestor ? null : expense.created_by_name"
          :paid-at="parseDate(paidAt)"
          :done-at="parseDate(doneAt)"
          :material-cost="materialsSum"
          :management-fee="listingTask.management_fee"
          :labor-cost="expense.amount"
        />
      </div>
      <v-divider />
      <div v-if="descriptionImages.length" class="pa-6">
        <div class="font-weight-medium">Media</div>
        <gallery
          :thumbnails="true"
          :cols="4"
          :images="descriptionImages"
          :readonly="true"
        />
      </div>
      <v-divider v-show="listingTask.desc_images" />
      <div v-if="!imageSrc && expense.attached_image" class="pa-6">
        Images are uploading
        <v-progress-circular
          :size="20"
          class="ml-2"
          indeterminate
          color="primary"
        />
      </div>
      <div v-if="!isEmpty(expense.work_info.items)">
        <expense-card-storage-items :list="expense.work_info.items" />
      </div>
      <div
        v-if="!isEmpty(listingTask.sub_tasks) || !isEmpty(subTasks)"
        class="pa-6"
      >
        <div class="font-weight-medium pb-4">Subtasks:</div>
        <expense-card-subtasks :list="listingTask.sub_tasks" />
      </div>
      <v-row v-else class="mx-0 pa-6">
        <v-col cols="12" sm="6" class="d-flex">
          <div
            v-if="
              !isEmpty(listingTask.before_images) || listingTask.before_comments
            "
            class="flex"
          >
            <p class="font-weight-medium">Before job:</p>
            <p>{{ listingTask.before_comments }}</p>
            <gallery
              :thumbnails="true"
              :images="listingTask.before_images"
              :readonly="true"
            />
          </div>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex">
          <div
            v-if="
              !isEmpty(listingTask.after_images) || listingTask.after_comments
            "
            class="flex"
          >
            <p class="font-weight-medium">After job:</p>
            <p>{{ listingTask.after_comments }}</p>
            <gallery
              :thumbnails="true"
              :images="listingTask.after_images"
              :readonly="true"
            />
          </div>
        </v-col>
      </v-row>
      <v-divider />
      <div v-if="!isEmpty(listingTask.receipt_images)" class="py-6 px-8">
        <p class="font-weight-medium">Receipt images:</p>
        <gallery
          :thumbnails="true"
          :images="listingTask.receipt_images"
          :cols="4"
          :readonly="true"
        />
      </div>
      <v-card-actions
        v-if="hasAbility('hide-expense') && isDesignedVr"
        class="d-flex justify-space-between"
      >
        <expense-actions :expense="expense" @action="updateExpense" />
        <div>
          <inquiry-expense-modal
            v-if="isDesignedVr"
            :expense-id="expense.id"
            :disabled="
              canDeleteFinancialData(expense.paid_at, currentUser) ||
              !expense.user_id
            "
            @on-inquiry="fetchData"
          />
          <v-btn
            v-if="isAdmin || isFieldPm"
            depressed
            :disabled="expense.validated_by_id"
            small
            color="green lighten-5"
            class="green--text font-weight-bold ml-2"
            @click.stop="validateExpenseHandler"
          >
            <v-icon class="mr-2" small>mdi-check-circle-outline</v-icon>
            Validate</v-btn
          >
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { get, sumBy, compact } from 'lodash/fp'
import CommonFunctions from 'components/mixins/common_functions'
import AccountingMixin from 'components/mixins/accounting-mixin'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ExpenseCardHeader from 'components/expenses/expense-card-header'
import ExpenseCardTitle from 'components/expenses/expense-card-title'
import ExpenseCardInfo from 'components/expenses/expense-card-info'
import ExpenseCardStorageItems from 'components/expenses/expense-card-storage-items'
import ExpenseCardSubtasks from 'components/expenses/expense-card-subtasks'
import ExpenseModal from 'components/expenses/expense-modal'
import Gallery from 'components/common/gallery'
import { mapGetters, mapState, mapActions } from 'vuex'
import InquiryExpenseModal from 'components/expenses/inquiry-expense-modal'
import ExpenseActions from 'components/expenses/expense-actions'
import DeviceMixin from 'components/mixins/device-mixin'

export default {
  components: {
    ExpenseActions,
    InquiryExpenseModal,
    ExpenseCardHeader,
    ExpenseCardTitle,
    ExpenseCardInfo,
    ExpenseCardStorageItems,
    ExpenseCardSubtasks,
    ExpenseModal,
    Gallery,
  },
  mixins: [CommonFunctions, PermissionsMixin, AccountingMixin, DeviceMixin],
  props: [
    'expense',
    'subTasks',
    'task',
    'fetchExpenses',
    'deleteEnabled',
    'editEnabled',
    'onDelete',
    'onUpdate',
  ],
  data() {
    return {
      listingTask: this.task || { receipt_images: [] },
    }
  },
  async mounted() {
    if (
      !this.listingTask.id &&
      (this.expense.listing_task_id || this.expense.work_info.lt_id)
    ) {
      const listingTaskId =
        this.expense.listing_task_id || this.expense.work_info.lt_id
      this.listingTask = await this.$store.dispatch(
        'getListingTaskBg',
        listingTaskId
      )
    }
  },
  computed: {
    // ...mapState('taskCalendar', ['miniCalendarTitle']),
    ...mapState(['taskCalendar.miniCalendarTitle', 'editExpense']),
    ...mapGetters(['currentUser']),
    isInExpensePage() {
      return Boolean(this.$route.name === 'Expense')
    },
    doneAt() {
      if (this.expense) {
        return this.parseDate(this.parseDate(this.listingTask.done_at))
      }
      return null
    },
    materialsSum() {
      return sumBy('amount', this.listingTask.material_expenses)
    },
    paidAt() {
      return get('paid_at', this.expense)
    },
    amount() {
      return (get('amount', this.expense) || '').toLocaleString('en')
    },
    description() {
      return get('description', this.expense)
    },
    videoSrc() {
      return this.expense && this.expense.video_url
    },
    expenseType() {
      return get('short_reason', this.expense)
    },
    hasImage() {
      return get('images[0]', this.expense)
    },
    imageSrc() {
      if (this.expense) {
        let src = get('images[0]', this.expense)
        if (!src) {
          if (this.expense.short_reason === 'Management Service') {
            src = '/management.png'
          } else if (this.expense.short_reason === 'Cleaning Service') {
            src = '/cleaning.png'
          } else if (this.expense.short_reason === 'Host Channels Fee') {
            src = '/fee.png'
          } else if (this.expense.short_reason === 'Taxes') {
            src = '/taxes.png'
          }
        }
        return src
      }
      return null
    },
    descriptionImages() {
      const media = (this.listingTask.desc_images || []).concat(
        this.expense.images
      )
      if (this.expense.video_url) {
        media.push(this.expense.video_url)
      }
      return compact(media)
    },
    currYear() {
      return new Date().getFullYear()
    },
    currMonth() {
      return new Date().getMonth()
    },
  },
  methods: {
    ...mapActions('expenses', ['validateExpense', 'deleteExpense']),
    async fetchData() {
      const month = this.$moment(this.expense.paid_at).format('MMMM')
      const year = this.$moment(this.expense.paid_at).format('YYYY')
      await this.$store.dispatch('getInitialStats', {
        year,
        is_ytd: year === this.currYear,
        id: this.expense.listing_id,
      })
      await this.$store.dispatch('getInitialStats', {
        year,
        month,
        id: this.expense.listing_id,
        is_ytd: year === this.currYear && month === this.currMonth,
      })
      this.$store.commit('hideModal')
    },
    async updateExpense(data) {
      await this.$store.dispatch('expenses/updateExpensesBatch', {
        ids: [this.expense.id],
        data,
      })
      if (!this.isInExpensePage) {
        await this.fetchData()
      }
      if (this.onUpdate) {
        this.onUpdate()
      }
    },
    async handleOnExpenseUpdate() {
      if (!this.isInExpensePage) {
        await this.fetchData()
      }
      if (this.onUpdate) {
        this.onUpdate()
      }
    },
    async handleDeleteExpense() {
      const expenseDeleted = await this.deleteExpense(this.expense.id)
      if (!expenseDeleted) {
        return
      }
      if (!this.isInExpensePage) {
        await this.fetchData()
      }
      if (this.onDelete) {
        this.onDelete()
      }
    },
    async validateExpenseHandler() {
      await this.validateExpense(this.expense.id)
      if (this.fetchExpenses) {
        this.$store.commit('hideModal')
        this.fetchExpenses()
      } else {
        this.fetchData()
      }
    },
  },
}
</script>
