<template>
  <v-container>
    <channel-management-filters
      :channels="channels"
      :search-term.sync="searchTerm"
      :filtered-channels.sync="filteredChannels"
      @fetch-listings="fetchListings"
      @toggle-listings="toggleListings"
      @reset-filters="resetFilters"
    />
    <data-table
      :headers="headers"
      :items="tableItems"
      @onRowClick="openModal"
    />
    <v-pagination
      v-if="
        pagination &&
        pagination.properties_count > 29 &&
        paramsTab === 'channels'
      "
      v-model="page"
      :length="Math.ceil(pagination.properties_count / pagination.per_page)"
      prev-icon="chevron_left"
      next-icon="chevron_right"
      class="mt-3 mb-3"
      @input="updatePage"
    />
  </v-container>
</template>

<script>
import DataTable from 'components/common/data-table/data-table.vue'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import {
  CHANNELS,
  MARKETING_HEADERS,
} from 'components/channel-management/config'
import { mapActions } from 'vuex'
import ChannelManagementFilters from './channel-management-filters.vue'

export default {
  mixins: [CommonFunctions, PermissionsMixin],
  name: 'ChannelManagement',
  components: {
    DataTable,
    ChannelManagementFilters,
  },
  methods: {
    ...mapActions('listings', ['getChannelListings']),
    updatePage(val) {
      this.$router.push({
        query: { ...this.$route.query, page: val },
      })
    },
    async fetchListings() {
      const { data } = await this.getChannelListings({
        page: this.page,
        listed: this.listed,
        search_term: this.searchTerm,
        channels: this.filteredChannels,
      })
      this.pagination = data.pagination
      this.tableItems = (data.listings || []).map(l => {
        const keys = Object.keys(l.integrations)
        const integrations = keys.reduce((acc, curr) => {
          if (l.integrations[curr].links) {
            acc[curr] = l.integrations[curr].links[0]
          }
          return acc
        }, {})
        return { ...l, ...integrations }
      })
    },
    resetFilterValues() {
      this.searchTerm = ''
      this.filteredChannels = []
      this.listed = true
      this.page = 1
    },
    resetFilters() {
      this.resetFilterValues()
      this.fetchListings()
    },
    toggleListings(val) {
      this.resetFilterValues()
      this.listed = val
      this.fetchListings()
    },
    openModal(item) {
      this.$store.commit('showModal', {
        name: 'UpdateFieldsModal',
        props: {
          onConfirm: fields => this.updateFields(item.id, fields),
          item,
          fields: this.headers.filter(h => !h.readonly),
        },
      })
    },
    updateFields(id, fields) {
      const listing = this.tableItems.find(l => l.id === id)
      Object.keys(fields).forEach(key => {
        listing.integrations[key] = {
          ...listing.integrations[key],
          links: fields[key] ? [fields[key]] : [],
        }
      })
      this.$store
        .dispatch('updateIntegration', {
          listing,
        })
        .then(() => this.fetchListings())
    },
  },
  mounted() {
    const query = {}
    if (!this.$route.query.page) {
      query.page = 1
    }
    this.$router.replace({ query })
  },

  watch: {
    '$route.query': {
      handler({ page }) {
        this.page = +page || 1
        this.fetchListings()
      },
    },
  },
  data() {
    return {
      page: +this.$route.query.page || 1,
      headers: MARKETING_HEADERS,
      pagination: {},
      channels: CHANNELS,
      paramsTab2: null,
      searchTerm: '',
      tableItems: [],
      filteredChannels: [],
      listed: true,
      tab: null,
    }
  },
}
</script>
