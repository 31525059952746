<template>
  <div>
    <menu-hot-key />
    <app-version v-if="showVersionAlert" />
    <v-app-bar
      id="site-header"
      :app="!showVersionAlert"
      :fixed="!showVersionAlert"
      class="dash-app-bar"
      color="white"
    >
      <v-icon v-if="isMobileOrIPad && showBackButton" @click="handleBack">
        mdi-arrow-left
      </v-icon>

      <v-app-bar-nav-icon @click.stop="$emit('update:drawer', !drawer)" />
      <v-toolbar-title v-if="!isCleaner && !isMobile">
        <router-link :to="defaultRouteByRole" @click="setDisplay('listings')">
          <img class="mt-2 mr-2" :src="logo" height="40" />
        </router-link>
      </v-toolbar-title>

      <v-spacer />
      <property-picker
        v-if="showPropertyPicker && !onHotelScreens"
        :show-dups="true"
        :value="+$route.params.id"
      />
      <reservation-search
        v-else-if="onHotelScreens"
        @get-reservation="getReservation"
      />

      <chat-kpi v-if="showChatKPI" />
      <v-spacer />
      <div v-if="user" class="d-flex align-center">
        <v-menu
          v-if="!isInvestor"
          v-model="menu"
          transition="slide-y-transition"
          offset-y
          left
        >
          <template #activator="{ on }">
            <v-btn elevation="0" class="mr-2 ml-2 primary" fab x-small v-on="on"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </template>
          <v-list>
            <listing-task-module
              v-if="showModule('ops')"
              :quick-task="true"
              :pre-fields="{
                listings_ids: listingIds,
                ...quickFieldCatalog,
              }"
              @after-create="incrementQuickTasks"
            >
              <template #customBtn="{ on }">
                <v-list-item link v-on="on">
                  <div @click="menu = false">
                    <v-list-item-title class="text-body-2"
                      ><v-icon class="mr-2">$maintenance</v-icon>
                      {{ $t('New Field Task') }}</v-list-item-title
                    >
                  </div>
                </v-list-item>
              </template>
            </listing-task-module>
            <expense-modal
              v-if="
                !isInvestor &&
                ((onPayrole && isOperationsPersonal) ||
                  isAdmin ||
                  isOperationsPersonal ||
                  isCommunicationPerson)
              "
              :quick-mode="true"
              :central-bank-view="true"
              @open="menu = false"
            />
            <reservation-create-modal
              v-if="hasAbility(['create-reservation']) || isHotelReception"
              class="ml-auto"
              @oncreate="createReservation"
            >
              <template #customBtn="{ on }">
                <v-list-item link v-on="on">
                  <div @click="menu = false">
                    <v-list-item-title class="text-body-2"
                      ><v-icon class="mr-2">$calendar</v-icon>
                      {{ $t('Reservation') }}</v-list-item-title
                    >
                  </div>
                </v-list-item>
              </template>
            </reservation-create-modal>
            <v-list-item v-if="isDemoTenant || isSystemAdmin" link>
              <div @click="showBoomListingDialog">
                <v-list-item-title class="text-body-2">
                  <v-icon class="mr-2">$calendar</v-icon>
                  {{ $t('New Boom Listing') }}
                </v-list-item-title>
              </div>
            </v-list-item>
            <setup-listing-modal
              v-if="showModule('ops') && isAdmin"
              quick-mode="true"
            />
            <requests
              v-if="showModule('projects') && !loading"
              :only-take="true"
            >
              <template #customBtn="{ on }">
                <v-list-item link v-on="on">
                  <div @click="menu = false">
                    <v-list-item-title class="text-body-2"
                      ><v-icon class="mr-2">$inventory</v-icon>
                      {{ $t('Supply request') }}</v-list-item-title
                    >
                  </div>
                </v-list-item>
              </template>
            </requests>
          </v-list>
        </v-menu>
        <div v-if="showClockSwitch">
          <v-switch
            v-model="user.clocked_in"
            class="pa-1 pb-0 rounded small-text"
            hide-details
            @change="clockChange"
          />
          <div class="text-caption small-text">
            {{ user.clocked_in ? 'On-duty' : 'Off-duty' }}
          </div>
        </div>
      </div>
      <notifications-tip v-if="showNotificationsTip" />

      <v-progress-linear
        v-if="listingTaskLoading || headerProgressBar"
        indeterminate
        color="primary"
        height="10"
        class="dash-app-bar-progress"
      />
    </v-app-bar>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import axios from 'axios'
import { mapActions, mapGetters, mapState } from 'vuex'
import PropertyPicker from 'components/property-picker'
import NotificationsTip from 'components/notifications-tip'
import ChatKpi from 'components/kpis/chat-kpi'
import MenuHotKey from 'components/menu/menu-hot-key'
import ReservationSearch from 'components/reservation/reservation-search'
import ListingTaskModule from 'components/listing-task-module'
import ExpenseModal from '../expenses/expense-modal'
import ltMixin from 'components/mixins/lt-mixin'
import ReservationCreateModal from 'components/reservation/reservation-create-modal'
import AppVersion from 'components/common/app-version.vue'
import SetupListingModal from 'components/setup-listing-modal.vue'
import Requests from 'components/inventory/requests/requests.vue'

export default {
  name: 'AppHeader',
  components: {
    Requests,
    SetupListingModal,
    AppVersion,
    ReservationCreateModal,
    ExpenseModal,
    ReservationSearch,
    MenuHotKey,
    ChatKpi,
    NotificationsTip,
    PropertyPicker,
    ListingTaskModule,
  },
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin, ltMixin],
  props: ['drawer', 'showBackButton'],
  data() {
    return {
      fetchingData: false,
      menu: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserId', 'showVersionAlert', 'currentListingTask']),
    ...mapState([
      'user',
      'listingTaskLoading',
      'headerProgressBar',
      'configuration',
      'tasksCatalog',
    ]),
    quickFieldCatalog() {
      const catalog =
        this.tasksCatalog.catalogItemSelect.find(
          c => c.system_key === 'quick_field_task'
        ) || {}
      return {
        task_type: catalog.department,
        sub_topic: catalog.sub_topic,
        listing_task_catalog_item_name: catalog.description,
        listing_task_catalog_item_id: catalog.id,
      }
    },
    currentListing() {
      return this.$store.state.currentListing
    },
    showPropertyPicker() {
      return (
        (this.managementRoles || this.isCommunicationAgent) &&
        !this.loading &&
        !this.isDemoUser &&
        !this.showChatKPI &&
        ['dashboard/taskim'].every(route => !this.$route.path.includes(route))
      )
    },
    onHotelScreens() {
      return (
        (this.managementRoles || this.isCommunicationAgent) &&
        !this.loading &&
        (this.$route.path.includes('hotel-dashboard') ||
          this.$route.path.includes('multi-calendar') ||
          this.$route.path.includes('/reservation/'))
      )
    },
    logo() {
      return this.$store.state.app.configuration.logo_url
    },
    showClockSwitch() {
      return (
        (this.managementRoles || this.userRelatedToComm || this.onPayrole) &&
        (this.isDesignedVr || this.isNox)
      )
    },
    showNotificationsTip() {
      return !this.loading && !this.isInvestor
    },
    showChatKPI() {
      return this.$route.path.includes('dashboard/sales-chat')
    },
    listingIds() {
      const { name } = this.$route
      if (['view-property', 'area-report'].includes(name)) {
        return [+this.$route.params.id]
      } else if (this.currentListingTask)
        return [+this.currentListingTask.listing_id]
      else return []
    },
  },
  methods: {
    ...mapActions('reservation', ['getReservation']),
    ...mapActions(['incrementQuickTasks']),
    setDisplay(display) {
      this.$store.commit('updateDisplay', display)
    },
    createReservation(resId) {
      window.open(`/dashboard/reservation/${resId}`, '_blank')
    },
    clockChange(newVal) {
      if (!newVal) {
        this.$store.dispatch('unsubscribeFromInquiryChannel')
      }
      axios
        .post(`/api/users/${this.currentUserId}/clock-${newVal ? 'in' : 'out'}`)
        .then(response => {
          this.$store.commit('updateUser', response.data)
        })
        .catch(alert)
    },
    showBoomListingDialog() {
      this.$store.commit('showModal', {
        name: 'ListingCreateModal',
        props: {},
        isPersistent: false,
      })
    },
    handleBack() {
      this.$router.back()
    },
  },
}
</script>

<style lang="scss">
.dash-app-bar {
  z-index: 100 !important;
  box-shadow: 0 -3px 2px 2px black !important;
}

.dash-app-bar-progress {
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
}
</style>
