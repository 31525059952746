<template>
  <v-layout wrap>
    <v-dialog v-if="showForm" v-model="showForm" max-width="950px">
      <v-card>
        <v-card-title class="text-h5 align-center bb-1">
          <v-icon class="pr-2" color="secondary">mdi-calendar-star</v-icon>
          Add {{ startCase(formType) }}
          <v-spacer />
          <v-btn icon @click="closeForm">
            <v-icon color="primary">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-3 text-body-1">
          <v-row align="baseline">
            <v-col cols="3">
              <date-picker
                v-model="newEvent.date_range"
                :range="true"
                readonly
                :label="errorDateRange ? 'Invalid Date Range' : 'Date Range'"
                :min="$moment().add(1, 'days').toISOString()"
                @input="validateDate"
              />
            </v-col>
            <v-col cols="5">
              <v-text-field v-model="newEvent.event_name" label="Event Name" />
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="newEvent.percent" label="Percent" />
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="newEvent.min_days" label="Min Days" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions class="justify-space-around">
          <v-btn depressed @click="closeForm">Cancel</v-btn>
          <v-spacer />
          <v-btn v-if="!errorDateRange" color="primary" @click="saveEvent"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col cols="10" offset="1" class="d-flex" style="height: 50px">
      <v-spacer />
      <v-btn v-if="enableSave" class="cyan-icon" @click="save()">Save</v-btn>
    </v-col>

    <v-flex v-if="false" xs8 offset-xs2>
      <v-layout wrap mt-2>
        <v-flex xs12>
          <h4 class="grey-text">Month seasonailty</h4>
          <br />
        </v-flex>
        <v-flex xs1>
          <v-text-field
            v-model="configObject.month_season_factor.January"
            label="Jan"
            required
          />
        </v-flex>
        <v-flex xs1>
          <v-text-field
            v-model="configObject.month_season_factor.February"
            label="Feb"
            required
          />
        </v-flex>
        <v-flex xs1>
          <v-text-field
            v-model="configObject.month_season_factor.March"
            label="Mar"
            required
          />
        </v-flex>
        <v-flex xs1>
          <v-text-field
            v-model="configObject.month_season_factor.April"
            label="Apr"
            required
          />
        </v-flex>
        <v-flex xs1>
          <v-text-field
            v-model="configObject.month_season_factor.May"
            label="May"
            required
          />
        </v-flex>
        <v-flex xs1>
          <v-text-field
            v-model="configObject.month_season_factor.June"
            label="Jun"
            required
          />
        </v-flex>
        <v-flex xs1>
          <v-text-field
            v-model="configObject.month_season_factor.July"
            label="Jul"
            required
          />
        </v-flex>
        <v-flex xs1>
          <v-text-field
            v-model="configObject.month_season_factor.August"
            label="Aug"
            required
          />
        </v-flex>
        <v-flex xs1>
          <v-text-field
            v-model="configObject.month_season_factor.September"
            label="Sep"
            required
          />
        </v-flex>
        <v-flex xs1>
          <v-text-field
            v-model="configObject.month_season_factor.October"
            label="Oct"
            required
          />
        </v-flex>
        <v-flex xs1>
          <v-text-field
            v-model="configObject.month_season_factor.November"
            label="Nov"
            required
          />
        </v-flex>
        <v-flex xs1>
          <v-text-field
            v-model="configObject.month_season_factor.December"
            label="Dec"
            required
          />
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex v-if="false" xs8 offset-xs2>
      <v-layout wrap mt-2>
        <v-flex xs12>
          <h4 class="grey-text">Day of the week</h4>
          <br />
        </v-flex>
        <v-flex xs1>
          <v-text-field
            v-model="configObject.day_of_week_factor.Sunday"
            label="Sun"
            required
          />
        </v-flex>
        <v-flex xs1>
          <v-text-field
            v-model="configObject.day_of_week_factor.Monday"
            label="Mon"
            required
          />
        </v-flex>
        <v-flex xs1>
          <v-text-field
            v-model="configObject.day_of_week_factor.Tuesday"
            label="Tue"
            required
          />
        </v-flex>
        <v-flex xs1>
          <v-text-field
            v-model="configObject.day_of_week_factor.Wednesday"
            label="Wed"
            required
          />
        </v-flex>
        <v-flex xs1>
          <v-text-field
            v-model="configObject.day_of_week_factor.Thursday"
            label="Thu"
            required
          />
        </v-flex>
        <v-flex xs1>
          <v-text-field
            v-model="configObject.day_of_week_factor.Friday"
            label="Fri"
            required
          />
        </v-flex>
        <v-flex xs1>
          <v-text-field
            v-model="configObject.day_of_week_factor.Saturday"
            label="Sat"
            required
          />
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex v-if="false" xs8 offset-xs2>
      <v-layout wrap>
        <v-flex xs12>
          <h4 class="grey-text">Month Occupancy</h4>
          <br />
        </v-flex>
        <v-flex xs2>
          <v-text-field
            v-model="configObject.occupancy_factor['0-0.2']"
            label="0%-20%"
            required
          />
        </v-flex>
        <v-flex xs2>
          <v-text-field
            v-model="configObject.occupancy_factor['0.2-0.4']"
            label="20%-40%"
            required
          />
        </v-flex>
        <v-flex xs2>
          <v-text-field
            v-model="configObject.occupancy_factor['0.4-0.6']"
            label="40%-60%"
            required
          />
        </v-flex>
        <v-flex xs2>
          <v-text-field
            v-model="configObject.occupancy_factor['0.6-0.8']"
            label="60%-80%"
            required
          />
        </v-flex>
        <v-flex xs2>
          <v-text-field
            v-model="configObject.occupancy_factor['0.8-1']"
            label="80%-100%"
            required
          />
        </v-flex>
      </v-layout>
    </v-flex>

    <v-col cols="10" offset="1">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel
          v-for="(configKey, index) in ['holidays', 'public_events']"
          :key="index"
        >
          <v-expansion-panel-header>
            <div class="d-flex text-subtitle-2 bolder">
              {{ startCase(configKey) }}
              <v-spacer />
              <v-btn
                class="cyan-icon mr-5"
                small
                @click.stop="onShowForm(configKey)"
              >
                Add New
              </v-btn>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list v-if="panel === index" dense>
              <div v-for="(val, key) in configObject[configKey]" :key="key">
                <div v-if="allowedEvent(key)">
                  <div class="d-flex align-center">
                    <div
                      class="text-subtitle-2"
                      style="min-width: 95px; width: 95px"
                    >
                      {{ key }}
                    </div>
                    <div class="ml-5 text-wrap text-title-1">
                      {{ val['event_name'] }}
                    </div>
                    <v-spacer />
                    <div>
                      <div class="d-flex">
                        <div
                          class="mt-5 pl-5"
                          style="min-width: 100px; width: 100px"
                        >
                          <v-text-field
                            v-model="configObject[configKey][key]['percent']"
                            label="Percent"
                            dense
                            hide-details
                          />
                        </div>
                        <div
                          class="mt-5 pl-5"
                          style="min-width: 120px; width: 120px"
                        >
                          <v-text-field
                            v-model="configObject[configKey][key]['min_days']"
                            label="Min Days"
                            dense
                            hide-details
                          />
                        </div>
                      </div>
                      <div class="d-flex">
                        <div
                          class="mb-1 pl-5"
                          style="min-width: 173px; width: 173px"
                        >
                          <v-checkbox
                            v-model="
                              configObject[configKey][key]['apply_on_units']
                            "
                            label="Apply On Units"
                            class="d-inline-block mt-1"
                            dense
                            hide-details
                            @change="applyOnChanged(configKey, key)"
                          />
                        </div>
                        <div
                          class="mb-1 pl-5"
                          style="min-width: 190px; width: 190px"
                        >
                          <v-checkbox
                            v-model="
                              configObject[configKey][key]['apply_on_houses']
                            "
                            label="Apply On Houses"
                            class="d-inline-block mt-1"
                            dense
                            hide-details
                            @change="applyOnChanged(configKey, key)"
                          />
                        </div>
                        <div
                          class="mb-1 pl-5"
                          style="min-width: 170px; width: 170px"
                        >
                          <v-checkbox
                            v-model="
                              configObject[configKey][key]['select_listings']
                            "
                            :label="$t('Select Listings')"
                            class="d-inline-block mt-1"
                            dense
                            hide-details
                            @change="selectListingsChanged(configKey, key)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="configObject[configKey][key]['select_listings']">
                    <multi-property-picker
                      :hide-btns="true"
                      :value="
                        configObject[configKey][key]['selected_listings_ids'] ||
                        []
                      "
                      :filter-change="
                        ids => setListingsIds(configKey, key, ids)
                      "
                      :filter-listing-types="['unit']"
                    />
                  </div>
                  <v-divider />
                </div>
              </div>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-layout>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { startCase } from 'lodash'
import MultiPropertyPicker from './multi-property-picker'
import DatePicker from 'components/form-fields/date-picker.vue'

export default {
  components: { MultiPropertyPicker, DatePicker },
  mixins: [CommonFunctions, PermissionsMixin],
  data: function () {
    return {
      enableSave: false,
      showForm: false,
      formType: null,
      newEvent: null,
      dateMenu: false,
      menu: false,
      panel: null,
      errorDateRange: false,
    }
  },
  computed: {
    configObject() {
      return this.$store.state.pricingSetings
    },
  },
  watch: {
    configObject: {
      handler: function () {
        this.enableSave = true
      },
      deep: true,
    },
  },
  methods: {
    validateDate() {
      this.errorDateRange =
        !this?.newEvent?.date_range ||
        this.$moment(this.newEvent.date_range[1]).diff(
          this.$moment(this.newEvent.date_range[0]),
          'days'
        ) > 31
    },
    onShowForm(configKey) {
      this.formType = configKey === 'holidays' ? 'holiday' : 'publicEvent'
      this.newEvent = {
        date_range: [
          this.$moment().add(1, 'days').format('YYYY-MM-DD'),
          this.$moment().add(1, 'days').format('YYYY-MM-DD'),
        ],
        event_name: '',
        percent: this.formType === 'holiday' ? '0.1' : '0.03',
        min_days: '2',
      }
      this.showForm = true
    },
    closeForm() {
      this.showForm = false
    },
    selectListingsChanged(configKey, key) {
      const val = !this.configObject[configKey][key]['select_listings']
      this.configObject[configKey][key]['apply_on_units'] = val
      this.configObject[configKey][key]['apply_on_houses'] = val
      if (val && !this.configObject[configKey][key]['selected_listings_ids']) {
        this.configObject[configKey][key]['selected_listings_ids'] = []
      }
    },
    applyOnChanged(configKey, key) {
      const val =
        this.configObject[configKey][key]['apply_on_units'] ||
        this.configObject[configKey][key]['apply_on_houses']
      if (val) {
        this.configObject[configKey][key]['select_listings'] = false
      }
    },
    setListingsIds(configKey, key, ids) {
      this.configObject[configKey][key]['selected_listings_ids'] = ids
    },
    allowedEvent(eventDate) {
      const event = this.$moment(eventDate)
      const timeNow = this.$moment()
      return event > timeNow
    },
    saveEvent() {
      this.newEvent['is_holiday'] = this.formType === 'holiday'
      this.newEvent['apply_on_units'] = true
      this.newEvent['apply_on_houses'] = true
      this.newEvent['select_listings'] = false
      this.$store.dispatch('addEventToSettings', this.newEvent)
      this.closeForm()
    },
    save() {
      this.enableSave = false
      this.$store.dispatch('editPricingSetting', this.configObject)
    },
    startCase(str) {
      return startCase(str)
    },
  },
}
</script>
