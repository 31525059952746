<template>
  <v-container>
    <confirmation-modal
      text="Are you sure you want to sync all listings?"
      @action="sync"
    >
      <template #activator="{ on }">
        <v-btn
          x-small
          depressed
          class="ml-2 font-weight-bold text--darken-3"
          color="green lighten-4"
          width="100"
          :loading="syncingListings"
          height="25"
          v-on="on"
          >Sync with CM</v-btn
        >
      </template>
    </confirmation-modal>
    <v-row class="justify-space-between align-center">
      <v-col cols="6">
        <v-text-field
          v-model="nicknameSearch"
          label="Search"
          prepend-inner-icon="search"
          outlined
          dense
          clearable
          hide-details
          @input="debounceLoadListing"
        />
      </v-col>
      <v-col cols="auto">
        <v-btn-toggle
          v-model="listingType"
          color="primary"
          dense
          mandatory
          @change="loadListings"
        >
          <v-btn value="all">{{ $t('All') }}</v-btn>
          <v-btn value="channel">{{ $t('Channel') }}</v-btn>
          <v-btn value="boom">{{ $t('Boom') }}</v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-data-table
      disable-pagination
      fixed-header
      height="70vh"
      :loading="isLoading"
      hide-default-footer
      :headers="headers"
      :items="items"
    >
      <template #[`item.nickname`]="{ item }">
        <router-link
          class="text-decoration-none"
          target="_blank"
          :to="{
            name: 'manage-property',
            params: { id: item.id, paramsTab: 'listing-channel-general' },
          }"
          >{{ item.nickname }}</router-link
        >
        <div class="font-weight-medium text-body-2">{{ item.title }}</div>
      </template>
      <template #[`item.channel_listing_id`]="{ item }">
        <v-icon v-if="item.channel_listing_id" color="green lighten-2"
          >mdi-check-circle</v-icon
        >
        <v-icon v-else color="red lighten-2">mdi-close-circle</v-icon>
      </template>
      <template #[`item.listing_id`]="{ item }">
        <v-icon v-if="item.listing_id" color="green lighten-2"
          >mdi-check-circle</v-icon
        >
        <v-icon v-else color="red lighten-2">mdi-close-circle</v-icon>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-btn
          v-if="item.channel_listing_id && !item.listing_id"
          x-small
          depressed
          class="ml-2 font-weight-bold grey--text text--darken-3"
          color="amber lighten-4"
          width="100"
          height="25"
          @click="showBoomListingDialog(item)"
          >Create New</v-btn
        >
        <v-dialog v-model="rowDialog[item.id]" max-width="800">
          <template #activator="{ on, attrs }">
            <v-btn
              v-if="item.channel_listing_id && !item.listing_id"
              x-small
              depressed
              class="ml-3 font-weight-bold grey--text text--darken-3"
              color="light-blue lighten-4"
              width="100"
              height="25"
              v-bind="attrs"
              v-on="on"
            >
              Connect</v-btn
            ></template
          >
          <v-card>
            <v-card-title class="text-h5 d-flex justify-center">
              <div class="p-relative flex text-center">
                <h2 class="text-h6">{{ $t('Connect Boom listing') }}</h2>
                <v-icon class="close-button" @click="rowDialog[item.id] = false"
                  >close</v-icon
                >
              </div>
            </v-card-title>
            <div class="pa-8 d-flex flex-column align-center">
              <listing-picker
                :value="selectedListing"
                title="Select Boom Listing"
                :show-dups="true"
                @input="listingTaskFieldChange($event)"
              />
              <v-card-actions class="mt-8">
                <confirmation-modal
                  :text="`Update all of ${item.nickname}'s listing information?`"
                  @action="connect(selectedListing, item)"
                >
                  <template #activator="{ on }">
                    <v-btn
                      depressed
                      :disabled="!selectedListing"
                      class="ml-2 font-weight-bold grey--text text--darken-3"
                      color="green lighten-4"
                      width="100"
                      v-on="on"
                      >Publish</v-btn
                    >
                  </template>
                </confirmation-modal>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>
        <v-tooltip v-if="item.missingFields" top>
          <template #activator="{ on, attrs }">
            <v-icon color="yellow darken-1" v-bind="attrs" small v-on="on">
              $info
            </v-icon>
          </template>
          <span>Missing: {{ item.missingFields }}</span>
        </v-tooltip>
        <confirmation-modal
          v-if="item.channel_listing_id && item.listing_id"
          :text="`Are you sure you want to ${
            item.listed ? 'Deactivate' : 'Activate'
          } this listing?`"
          @action="toggleActivate(item)"
        >
          <template #activator="{ on }">
            <v-btn
              x-small
              depressed
              class="ml-2 font-weight-bold grey--text text--darken-3"
              :color="item.listed ? 'red lighten-4' : 'green lighten-4'"
              width="100"
              height="25"
              v-on="on"
              >{{ item.listed ? 'Deactivate' : 'Activate' }}</v-btn
            >
          </template>
        </confirmation-modal>
        <confirmation-modal
          text="Are you sure you want to publish this listing?"
          @action="publish(item.listing_id)"
        >
          <template #activator="{ on }">
            <v-btn
              v-if="!item.channel_listing_id && item.listing_id"
              x-small
              depressed
              :disabled="!!item.missingFields"
              class="ml-2 font-weight-bold grey--text text--darken-3"
              color="green lighten-4"
              width="100"
              height="25"
              v-on="on"
              >Publish</v-btn
            >
          </template>
        </confirmation-modal>
        <confirmation-modal
          text="Are you sure you want to disconnect this listing from CM?"
          @action="disconnect(item.listing_id)"
        >
          <template #activator="{ on }">
            <v-btn
              v-if="item.channel_listing_id && item.listing_id && !item.listed"
              x-small
              depressed
              class="ml-2 font-weight-bold grey--text text--darken-3"
              color="red lighten-4"
              width="100"
              height="25"
              v-on="on"
              >Disconnect</v-btn
            >
          </template>
        </confirmation-modal>
      </template>
    </v-data-table>
    <v-pagination
      v-if="pagination && pagination.listings_count > 29"
      v-model="page"
      :length="Math.ceil(pagination.listings_count / pagination.per_page)"
      prev-icon="chevron_left"
      next-icon="chevron_right"
      class="mt-3 mb-3"
    ></v-pagination>
  </v-container>
</template>

<script>
import ConfirmationModal from 'components/modals/confirmation-modal.vue'
import { mapActions, mapState } from 'vuex'
import ListingPicker from 'components/listing/listing-picker'
import NavigationMixin from 'components/mixins/navigation-mixin'
import { debounce, isNil, get } from 'lodash'
export default {
  mixins: [NavigationMixin],
  components: {
    ConfirmationModal,
    ListingPicker,
  },
  data() {
    return {
      listings: [],
      channelOnlyListings: [],
      otaMap: {
        'all suite': '1',
        'all-inclusive resort': '2',
        apartment: '3',
        aparthotel: '3',
        'bed and breakfast': '4',
        cottage: '5',
        chalet: '7',
        condominium: '8',
        'guest house': '16',
        hotel: '20',
        lodge: '22',
        camping: '25',
        resort: '30',
        boat: '31',
        'vacation home': '34',
        house: '34',
        villa: '35',
        castle: '37',
        'private room in apartment': '68',
        'shared room': '69',
        cabin: '70',
        bungalow: '71',
        riad: '72',
        'recreational vehicle': '73',
        ryokan: '74',
        loft: '75',
        townhouse: '76',
      },
      isLoading: false,
      syncingListings: false,
      selectedListing: null,
      rowDialog: {},
      listingType: 'all',
      nicknameSearch: null,
      page: +this.$route.query.page || 1,
      pagination: null,
      debounceLoadListing: debounce(this.loadListings, 300),
    }
  },
  async mounted() {
    this.loadListings()
    this.watchQuery(['page'])
  },
  computed: {
    ...mapState('listings', ['combinedListings']),
    headers() {
      return [
        { text: 'Nickname', value: 'nickname' },
        {
          text: 'Channel Listing',
          value: 'channel_listing_id',
          align: 'center',
        },
        { text: 'Boom Listing', value: 'listing_id', align: 'center' },
        { text: 'PMS', value: 'pms', align: 'center' },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'end',
        },
      ]
    },
    items() {
      const listings = this.listings.map(l => ({
        address: l.address,
        nickname: l.nickname,
        id: l.id,
        title: l.title,
        channel_listing_id: l.channel_listings[0]?.id,
        pms: l.app_integration?.name,
        listing_id: l.id,
        missingFields: [
          'space',
          'standard_guests',
          'days_before_arrival',
          'ota_type',
          'check_in_time',
          'check_out_time',
          'floor',
          'beds',
          'street',
          'zip_code',
        ]
          .filter(field => isNil(l[field]))
          .join(', '),
      }))
      if (this.$route.query.page > 1) return listings
      const channelOnlyListings = this.channelOnlyListings.map(l => ({
        ...l,
        id: `channel_${l.id}`,
        channel_listing_id: l.id,
        pms: l.app_integration?.name,
        nickname: l.rentals_info?.Name['#value'],
      }))
      return [...channelOnlyListings, ...listings]
    },
  },
  watch: {
    '$route.query.page': {
      handler() {
        this.loadListings()
      },
    },
  },
  methods: {
    ...mapActions('listings', [
      'getCombinedListings',
      'publishListing',
      'connectListing',
      'updateListing',
      'syncFromCM',
      'disconnectListing',
    ]),
    async sync() {
      this.syncingListings = true
      await this.syncFromCM()
      this.syncingListings = false
    },
    async loadListings() {
      this.listings = []
      this.channelOnlyListings = []
      this.isLoading = true
      const { data } = await this.getCombinedListings({
        page: this.$route.query.page,
        listing_type: this.listingType,
        nickname_search: this.nicknameSearch,
      })
      this.listings = data.listings
      this.channelOnlyListings = data.channel_only_listings
      this.pagination = data.pagination
      this.isLoading = false
    },
    async publish(listingId) {
      this.isLoading = true
      try {
        await this.publishListing({ id: listingId })
        this.loadListings()
      } catch (error) {
        this.isLoading = false
      }
    },
    async connect(listingId, channelListing) {
      this.isLoading = true
      try {
        await this.connectListing({
          id: listingId,
          payload: { external_listing_id: channelListing?.external_listing_id },
        })
        this.loadListings()
        this.selectedListing = null
      } catch (error) {
        this.isLoading = false
      }
      if (channelListing) this.rowDialog[channelListing.id] = false
    },

    async disconnect(listingId) {
      this.isLoading = true
      try {
        await this.disconnectListing({ id: listingId })
        this.loadListings()
      } catch (error) {
        this.isLoading = false
      }
    },
    async toggleActivate({ listing_id, listed }) {
      this.isLoading = true
      await this.updateListing({ id: listing_id, payload: { listed: !listed } })
      this.loadListings()
    },
    listingTaskFieldChange(id) {
      this.selectedListing = id
    },
    showBoomListingDialog({ rentals_info }) {
      let description = get(rentals_info, 'Descriptions.Description')
      let summary = ''
      if (!Array.isArray(description)) {
        description = [description]
      }
      const foreignLang = description.find(
        d => d.LanguageID !== '1' && d.Text['#value']
      )
      if (foreignLang) {
        summary = foreignLang.Text['#value']
      } else {
        summary = description
          .filter(d => d.Text['#value'])
          .reduce((acc, curr) => {
            return acc + curr.Text['#value']
          }, '')
      }

      let otaName = null
      if (rentals_info.ObjectTypeID['#value'] == '67') {
        otaName = 'house'
      } else {
        otaName = Object.entries(this.otaMap).find(
          ([key, value]) => value === rentals_info.ObjectTypeID['#value']
        )[0]
      }
      const imagesArray = get(rentals_info, 'Images.Image')
      const profile_picture = Array.isArray(imagesArray)
        ? imagesArray[0]['#value']
        : imagesArray['#value']
      const [nickname, title] = rentals_info.Name['#value'].split(' · ')
      const lng = get(rentals_info, 'Coordinates.Longitude.#value')
      const lat = get(rentals_info, 'Coordinates.Latitude.#value')
      const initialFields = {
        title: title,
        name: nickname,
        ota_type: otaName,
        beds: rentals_info?.CompositionRoomsAmenities?.CompositionRoomAmenities.filter(
          a => a.CompositionRoomID === '257'
        ).length,
        lat: +lat,
        lng: +lng,
        description: summary,
        picture: profile_picture,
        space: rentals_info.Space['#value'],
        accommodates: rentals_info.CanSleepMax['#value'],
        standard_guests: rentals_info.StandardGuests['#value'],
        floor: rentals_info.Floor['#value'],
        days_before_arrival:
          rentals_info.ArrivalInstructions.DaysBeforeArrival['#value'],
        check_in_start: rentals_info.CheckInOut.CheckInFrom['#value'],
        check_in_end: rentals_info.CheckInOut.CheckInTo['#value'],
        check_out_time: rentals_info.CheckInOut.CheckOutUntil['#value'],
      }
      this.$store.commit('showModal', {
        name: 'ListingCreateModal',
        props: { initialFields, onSuccess: this.loadListings },
        isPersistent: false,
      })
    },
  },
}
</script>

<style></style>
