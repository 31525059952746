<template>
  <v-navigation-drawer
    temporary
    stateless
    :mini-variant="isMobile"
    mini-variant-width="90%"
    right
    app
    width="550"
    :overlay-opacity="0"
    style="top: 64px"
    :value="value"
    @input="drawerOpened"
  >
    <div
      v-if="value"
      v-click-outside="onClickOutside"
      class="pa-3"
      style="padding-bottom: 10%"
    >
      <listing-task-view-mobile
        :task-id="taskId"
        @listing-task-deleted="listingTaskDeleted"
        @listing-task-updated="listingTaskUpdated"
      />
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import NavigationMixin from 'components/mixins/navigation-mixin'
import LtMixin from 'components/mixins/lt-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import ColorsMixin from 'components/mixins/colors-mixin'
import ListingTaskViewMobile from 'components/listing-tasks/single-listing-task/listing-task-view-mobile'

export default {
  name: 'TaskDrawer',
  props: ['value', 'taskId'],
  components: { ListingTaskViewMobile },
  mixins: [NavigationMixin, LtMixin, FormattersMixin, ColorsMixin],
  data() {
    return {
      task: null,
      galleryProps: {
        class: 'ma-0',
        readonly: true,
        thumbnails: true,
        cols: 3,
      },
    }
  },
  computed: {
    ...mapState(['listingTaskLoading']),
  },
  methods: {
    ...mapActions(['getListingTaskBg']),
    drawerOpened(isOpened) {
      this.$emit('input', isOpened)
    },
    onClickOutside(event) {
      const isLastOverlay = document.querySelectorAll(
        '.v-overlay.v-overlay--active.theme--dark:not(.v-overlay--absolute)'
      ).length
      if (
        event.target._prevClass === 'v-overlay__scrim' &&
        isLastOverlay === 1
      ) {
        const { reportTaskDrawer: _u, tab: _t, ...query } = this.$route.query
        this.$router.push({ query }).catch(() => {})
      }
      if (
        event.target._prevClass === 'v-overlay__scrim' &&
        isLastOverlay === 2
      ) {
        this.$emit('on-click-outside')
      }
    },
    listingTaskDeleted() {
      this.$emit('on-click-outside')
    },
    listingTaskUpdated() {
      this.$emit('listing-task-updated')
    },
  },
}
</script>
