<template>
  <div>
    <v-progress-linear v-show="loadingGuestExpInfo" indeterminate />
    <v-card
      v-if="!isMobile"
      outlined
      class="d-flex justify-space-between py-4 px-8 rounded-lg pre-checkin-container"
    >
      <pre-checkin-stat
        label="Incomplete checkins"
        :value="managedReservationsStats.missing_today"
        color="var(--v-dark500-base)"
      />
      <pre-checkin-stat
        label="Incomplete checkins tomorrow"
        :value="managedReservationsStats.missing_tomorrow"
        color="var(--v-info600-base)"
      />
      <pre-checkin-stat
        label="Special requests"
        :value="managedReservationsStats.special_today"
        color="var(--v-success600-base)"
      />
      <pre-checkin-stat
        label="Special requests Tomorrow"
        :value="managedReservationsStats.special_tomorrow"
        color="var(--v-success600-base)"
      />
      <pre-checkin-stat
        label="Store purchases today"
        :value="managedReservationsStats.store_today"
        color="var(--v-secondary600-base)"
      />
      <pre-checkin-stat
        label="Store purchases tomorrow"
        :value="managedReservationsStats.store_tomorrow"
        color="var(--v-primary600-base)"
      />
      <pre-checkin-stat
        label="Store purchases last 24h"
        :value="
          managedReservationsStats.store_purchase_last_24
            ? toMoney(managedReservationsStats.store_purchase_last_24)
            : '-'
        "
        color="var(--v-warning600-base)"
      />
    </v-card>
    <v-row class="align-center">
      <v-col cols="6" sm="4" lg="2">
        <date-picker
          v-model="from"
          label="Check-in Date"
          :max="
            parseISODateWithOffset(
              new Date(new Date().setMonth(new Date().getMonth() + 10))
            ).substr(0, 10)
          "
          :min="
            parseISODateWithOffset(
              new Date(new Date().setMonth(new Date().getMonth() - 20))
            ).substr(0, 10)
          "
          @change="fetchReservations"
        />
      </v-col>
      <v-col cols="6" sm="4" lg="2">
        <v-autocomplete
          v-model="listingId"
          label="Listing"
          clearable
          hide-details
          :items="listingsPicker"
          item-text="nickname"
          item-value="id"
          dense
          outlined
          @change="fetchReservations({})"
        />
      </v-col>
      <v-col cols="6" sm="4" lg="2">
        <filters-select
          :value.sync="channel"
          label="Channel"
          :items="channelsItems"
          :selection-func="item => item.text"
          @change="fetchReservations({})"
        />
      </v-col>
      <v-col>
        <v-checkbox
          v-model="missingOnly"
          dense
          hide-details
          class="mt-0"
          label="Missing Checkins only"
          @change="fetchReservations({})"
        />
      </v-col>
    </v-row>
    <reservations
      hide-reconcile="true"
      :headers="headers"
      @pagination="paginationChanged"
      @refetch="fetchReservations"
    />
  </div>
</template>

<script>
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import { mapActions, mapGetters, mapState } from 'vuex'
import formattersMixin from 'components/mixins/formatters-mixin'
import DatePicker from 'components/form-fields/date-picker.vue'
import Common_functions from 'components/mixins/common_functions'
import Reservations from 'components/reservations.vue'
import FiltersSelect from 'components/form-fields/filters-select.vue'
import { CHANNELS } from 'components/channel-management/config'
import PreCheckinStat from '@/components/crm/pre-checkin-stat.vue'

export default {
  name: 'PreCheckIns',
  components: {
    FiltersSelect,
    Reservations,
    DatePicker,
    PreCheckinStat,
  },
  mixins: [PermissionsMixin, formattersMixin, Common_functions, DeviceMixin],
  methods: {
    ...mapActions(['getReviewsInfo', 'getManagedReservations']),
    fetchReservations(options = {}) {
      const to = this.$moment(this.from).add(1, 'days').format('YYYY-MM-DD')
      this.getManagedReservations({
        page: 1,
        per_page: this.pagination.itemsPerPage,
        from: this.from,
        to,
        missing_only: this.missingOnly,
        date_by: 'check_in',
        include_owner: true,
        status: 'confirmed',
        channel: this.channel,
        listingId: this.listingId,
        pre_stats: true,
        ...options,
      })
    },
    paginationChanged(data) {
      this.pagination = { ...this.pagination, ...data }
      this.fetchReservations(this.pagination)
    },
  },
  computed: {
    ...mapState(['loadingGuestExpInfo', 'managedReservationsStats']),
    ...mapGetters(['listingsPicker']),
    channelsItems() {
      return CHANNELS
    },
    headers() {
      return [
        {
          text: 'Guest',
          value: 'guest',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Filled',
          value: 'pre_check_in_filled',
          sortable: false,
        },
        {
          text: 'Check in',
          value: 'check_in',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Check out',
          value: 'check_out',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Listing',
          value: 'nickname',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Channel',
          value: 'channel',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Notes',
          value: 'dvr_notes',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Confirmation',
          value: 'confirmation_code',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Picture',
          value: 'identity',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Docs',
          value: 'additional_doc',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Guests count',
          value: 'guests_count',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Requests',
          value: 'special_requests',
          sortable: false,
        },
      ]
    },
  },
  data() {
    return {
      view: 'pending',
      missingOnly: false,
      listingId: null,
      channel: [],
      from: this.parseISODateWithOffset(new Date()).substr(0, 10),

      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
    }
  },
  async mounted() {
    this.fetchReservations()
  },
}
</script>
<style>
.pre-checkin-container {
  border-color: none !important;
}
</style>
