<template>
  <v-container class="pa-0" :fluid="isMobileOrIPad">
    <v-overlay v-if="fetching" :opacity="0.1" absolute class="flex-center">
      <v-progress-circular color="primary" indeterminate size="50" />
    </v-overlay>
    <div v-if="currentListingTask">
      <div class="pa-3 mt-3">
        <LtTitle :listing-task="currentListingTask" />
        <CatalogItemInlineEditor :listing-task="currentListingTask" />
        <div class="d-flex justify-space-between pt-2">
          <div>
            <lt-same-day-tasks
              v-if="
                currentListingTask.same_day_lts &&
                currentListingTask.same_day_lts.length
              "
              :items="currentListingTask.same_day_lts"
            />
          </div>
          <LtMenuActions
            :listing-task="currentListingTask"
            @listing-task-deleted="listingTaskDeleted"
          />
        </div>
      </div>
      <v-tabs v-model="tab" height="0">
        <v-tabs-items :value="tab || 'overview'" class="mb-15" touchless>
          <v-tab-item value="overview" class="mb-5 pb-5">
            <div class="pa-3 white">
              <EditableTextField
                :label="$t('Description')"
                :placeholder="$t('Add description')"
                class="text-field"
                type="textarea"
                :value="currentListingTask.description"
                :disabled="isContractor || isExperienceManager"
                @onupdate="changeDescription"
              />
            </div>
            <div class="pa-3 white">
              <EditableTextField
                :label="$t('Internal Comments')"
                :placeholder="$t('Add your comments here')"
                type="textarea"
                :disabled="isContractor || isExperienceManager"
                :value="currentListingTask.internal_comments"
                @onupdate="commentInternal"
              />
            </div>
            <div
              class="pa-3 mt-2 white d-flex align-center justify-space-between"
            >
              <div class="text-subtitle-2 text--secondary font-weight-bold">
                {{ $t('Time Tracked') }}:
              </div>
              <ClockInModal :task="currentListingTask" />
            </div>
            <div class="pa-3 white">
              <LtMetadata
                v-if="isMobileOrIPad"
                :listing-task="currentListingTask"
                @listing-task-updated="listingTaskUpdated"
              />
            </div>
            <div v-if="currentListingTask.when_to_come_text">
              <div class="font-weight-medium text-subtitle-1">
                {{ $t('When to come') }}:
              </div>
              <div class="rounded text-body-1">
                <span
                  v-html="
                    currentListingTask.when_to_come_text.replace(
                      /\r?\n|\r/g,
                      '<br>'
                    )
                  "
                />
              </div>
            </div>
            <div v-if="reservationRequests" class="mt-2">
              <div class="font-weight-medium text-subtitle-1">
                {{ $t('Special requests for guest') }}
              </div>
              {{ reservationRequests }}
            </div>
            <v-divider
              v-if="!isContractor && !isEmpty(currentListingTask.similar_lt)"
              class="my-2"
            />
            <v-sheet
              v-show="!isMobileOrIPad && feedOpen"
              elevation="2"
              style="width: 50%; height: 100%; overflow-y: hidden"
              class="setup-feed"
            >
            </v-sheet>
            <LtMetadata
              v-if="!isMobileOrIPad"
              :listing-task="currentListingTask"
              @listing-task-updated="listingTaskUpdated"
            />
            <LtSummary
              v-show="!isCleaner"
              :listing-task="currentListingTask"
              :is-report="true"
            />
          </v-tab-item>

          <v-tab-item
            value="comments"
            class="overflow-y-auto"
            style="height: 64vh"
          >
            <div>
              <div class="py-3">
                <text-editor
                  :mentions="usersItems"
                  :autofocus="true"
                  @text-submit="newComment"
                />
              </div>
            </div>
            <v-sheet
              id="commentsContainer"
              no-gutters
              class="overflow-y-auto flex-grow-1"
            >
              <div v-if="!commentsList.length">
                <div class="text-center mt-10">
                  <v-icon x-large color="secondary"
                    >fas fa-comment-slash</v-icon
                  >
                  <div class="pa-2 text--secondary text-h6">
                    {{ $t('No comments') }}
                  </div>
                </div>
              </div>
              <div
                v-for="(comment, i) in commentsList"
                v-else
                :key="i"
                class="pa-1"
              >
                <LtFeedItem
                  :unread="unseenComments.includes(comment.id)"
                  :comment="comment"
                  v-on="$listeners"
                  @add-message="addComment"
                />
                <div v-if="comment.replies.length" class="pl-2">
                  <LtFeedItem
                    v-for="reply in comment.replies"
                    :key="reply.id"
                    :comment="reply"
                    :unread="unseenComments.includes(comment.id)"
                  />
                </div>
              </div>
            </v-sheet>
          </v-tab-item>
          <v-tab-item value="logs" class="white pa-3">
            <lt-logs :logs="currentListingTask.log" />
          </v-tab-item>
          <v-tab-item value="attachments">
            <div class="overflow-y-auto" style="height: 100vh">
              <div class="pa-3 mt-3 white">
                <div
                  class="text-caption text-subtitle-1 font-weight-medium text--secondary"
                >
                  {{ $t('Description Images') }}
                </div>
                <v-divider />
                <gallery
                  folder="service-calls"
                  :thumbnails="true"
                  :listing-id="currentListingTask.listing_id"
                  :images="currentListingTask.desc_images"
                  :remove-img="removeDescImg"
                  :rotate-image="rotateDescImg"
                  :on-change="addDescImages"
                  :uploadable="true"
                  :cols="3"
                />
              </div>
              <div v-if="!hasSubTasks" class="pa-3 mt-3 white">
                <LtImagesGalley
                  :has-sub-tasks="hasSubTasks"
                  :listing-task="currentListingTask"
                />
              </div>
              <div class="pa-3 mt-3 white">
                <div
                  class="font-weight-medium text-caption text-subtitle-1 font-weight-medium text--secondary"
                >
                  Invoices
                </div>
                <v-divider />
                <gallery
                  folder="service-calls"
                  :thumbnails="true"
                  :cols="3"
                  :listing-id="currentListingTask.listing_id"
                  :images="currentListingTask.receipt_images"
                  :rotate-image="rotateReceipt"
                  :remove-img="removeReceipt"
                  :on-change="addReceiptImages"
                  :uploadable="true"
                />
              </div>
            </div>
          </v-tab-item>
          <v-tab-item value="subtasks" :class="isMobile ? '' : 'mb-5 pb-5'">
            <div class="pt-3 mt-3 white">
              <LtSubtaskCreateModal :listing-task-id="currentListingTask.id">
                <template #activator="slotData">
                  <v-btn class="white" elevation="0" small v-on="slotData.on">
                    <v-icon small class="pr-1" color="darkpurple"
                      >fas fa-plus
                    </v-icon>
                    {{ $t('New Subtask') }}
                  </v-btn>
                </template>
              </LtSubtaskCreateModal>
            </div>
            <div class="pa-2 white">
              <div v-for="listSubject in listSubjects" :key="listSubject">
                <LtSubtaskList
                  :title="listSubject"
                  class="mt-2"
                  :listing-task="currentListingTask"
                  :tasks="filterBySubject(listSubject)"
                />
              </div>
            </div>
            <div v-if="hasShadowSubTasks" class="pa-3 white">
              <div>
                <LtSubtaskList
                  title="Sub Tasks In Other Service Calls"
                  class="mt-2"
                  :listing-task="currentListingTask"
                  :tasks="currentListingTask.shadow_sub_tasks"
                  :readonly="true"
                />
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
      <v-bottom-navigation
        v-model="navTab"
        fixed
        color="info"
        :class="taskId && 'bottom-nav'"
      >
        <v-tab class="pa-0" href="#overview" @click="goToTab('overview')">
          <v-btn height="100%" width="100%">
            <span>{{ $t('Overview') }}</span>
            <v-icon>mdi-newspaper-variant</v-icon>
          </v-btn>
        </v-tab>
        <v-tab class="pa-0" href="#attachments" @click="goToTab('attachments')">
          <v-btn height="100%" width="100%">
            <span>{{ $t('Media') }}</span>
            <v-icon>mdi-image-multiple</v-icon>
          </v-btn>
        </v-tab>
        <v-tab class="pa-0" href="#comments" @click="goToTab('comments')">
          <v-btn height="100%" width="100%">
            <span>{{ $t('Comments') }}</span>
            <v-badge
              v-if="!!commentsList.length"
              offset-x="8"
              :content="commentsList.length"
              overlap
            >
              <v-icon>mdi-message-text</v-icon>
            </v-badge>
            <v-icon v-else>mdi-message-text</v-icon>
          </v-btn>
        </v-tab>
        <v-tab class="pa-0" href="#subtasks" @click="goToTab('subtasks')">
          <v-btn height="100%" width="100%">
            <span>{{ $t('Subtasks') }}</span>
            <v-badge
              v-if="hasSubTasks"
              offset-x="8"
              :content="currentListingTask.sub_tasks.length"
              overlap
            >
              <v-icon>mdi-file-tree</v-icon>
            </v-badge>
            <v-icon v-else>mdi-file-tree</v-icon>
          </v-btn>
        </v-tab>
        <v-tab
          v-if="!isMobile"
          class="pa-0"
          href="#logs"
          @click="goToTab('logs')"
        >
          <v-btn height="100%" width="100%">
            <span>{{ $t('Logs') }}</span>
            <v-icon>mdi-message-cog</v-icon>
          </v-btn>
        </v-tab>
      </v-bottom-navigation>
    </div>
  </v-container>
</template>

<script>
import LtMetadata from 'components/listing-tasks/lt-metadata'
import LtSummary from 'components/listing-tasks/single-listing-task/lt-summary'
import LtImagesGalley from 'components/listing-tasks/lt-images-galley'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import deviceMixin from 'components/mixins/device-mixin'
import EditableTextField from 'components/form-fields/editable-text-field'
import LtSubtaskList from 'components/listing-tasks/lt-subtask-list'
import LtSubtaskCreateModal from 'components/listing-tasks/lt-subtask-create-modal'
import ClockInModal from 'components/clock-in-modal'
import Gallery from 'components/common/gallery'
import LtTitle from 'components/listing-tasks/lt-title'
import MentionMixin from 'components/mixins/mention-mixin'
import CatalogItemInlineEditor from 'components/listing-tasks/catalog-item-inline-editor'
import LtSameDayTasks from 'components/listing-tasks/lt-same-day-tasks'
import LtFeedItem from 'components/listing-tasks/lt-feed-item'
import LtMenuActions from 'components/listing-tasks/single-listing-task/lt-menu-actions'
import ListingTaskViewMixin from 'components/listing-tasks/mixins/listing-task-view-mixin'
import LtLogs from 'components/listing-tasks/lt-logs.vue'
import TextEditor from 'components/common/text-editor.vue'
import { mapState } from 'vuex'

export default {
  name: 'ListingTaskView',
  components: {
    TextEditor,
    LtLogs,
    LtSameDayTasks,
    LtSubtaskCreateModal,
    CatalogItemInlineEditor,
    LtTitle,
    Gallery,
    LtSubtaskList,
    EditableTextField,
    LtImagesGalley,
    LtSummary,
    LtMetadata,
    LtFeedItem,
    ClockInModal,
    LtMenuActions,
  },
  mixins: [
    CommonFunctions,
    deviceMixin,
    PermissionsMixin,
    MentionMixin,
    ListingTaskViewMixin,
  ],
  data() {
    return {
      navTab: 'overview',
      commentInput: '',
      fetching: false,
    }
  },
  props: ['taskId'],
  watch: {
    '$route.params.id'(id) {
      this.fetchCurrentTask(id)
    },
    taskId(id) {
      this.fetchCurrentTask(id)
    },
    '$route.query.tab'() {
      if (!this.taskId) window.scrollTo(0, 0)
    },
  },
  async mounted() {
    const id = this.taskId || this.$route.params.id
    this.fetching = true
    await this.$store.dispatch('getListingTask', {
      id,
      router: this.$router,
      disableLoading: this.taskId,
    })
    this.fetching = false
    if (!this.$route.query.tab) {
      this.$router.replace({ query: { ...this.$route.query, tab: 'overview' } })
    }
  },
  beforeDestroy() {
    if (
      this.currentListingTask &&
      this.currentListingTask.id.toString() !== this.$route.params.id
    ) {
      this.$store.commit('updateCurrentListingTask', null)
    }
  },
  computed: {
    ...mapState('userGroups', ['groups']),
    usersItems() {
      const groups = this.groups.map(g => ({
        id: g.groups_users.map(u => u.user_id),
        value: g.name,
      }))
      const users = this.activeUsers.map(u => ({
        id: u.id,
        value: u.name,
      }))
      return users.concat(groups)
    },
  },
  methods: {
    async newComment(content) {
      this.addComment({ html: content })
      document.getElementById('commentsContainer').scrollTop = 0
      this.commentInput = ''
    },
    goToTab(tab) {
      this.$router.replace({
        query: { ...this.$route.query, tab },
      })
    },
    onEditorChange({ html }) {
      this.commentInput = html
    },
    fetchCurrentTask(id) {
      this.$store.dispatch('getListingTask', { id, router: this.$router })
      if (!this.$route.query.tab) {
        this.$router.replace({
          query: { ...this.$route.query, tab: 'overview' },
        })
      }
    },
    listingTaskDeleted() {
      this.$emit('listing-task-deleted')
    },
    listingTaskUpdated() {
      this.$emit('listing-task-updated')
    },
  },
}
</script>

<style scoped>
div >>> .v-expansion-panel-content__wrap {
  padding: 0;
}

.check-br {
  border-left: 10px solid var(--v-darkpurple);
}

.setup-feed {
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 200;
}

/* new */
.v-tab {
  text-transform: none;
  font-size: 12px;
}

.text-field {
  font-size: 12px !important;
}

.bottom-nav {
  bottom: 64px;
}
</style>
