import axios from '@/axios/config'
import Websocket from '@/utils/websocket'
import filter from 'lodash/fp/filter'
export default {
  state: {
    subApplicationChannel: null,
    showVersionAlert: false,
    cities: [],
    configuration: {
      links: {},
      currency: '',
      tax_method: 'company',
      timezone: 'utc',
    },
    listingCities: [],
    bankAccounts: [],
  },
  mutations: {
    updateSubApplicationChannel(state, data) {
      state.subApplicationChannel = data
    },
    updateListingCities(state, data) {
      state.listingCities = data
    },
    updateCities(state, data) {
      state.cities = data
    },
    updateAiConfigs(state, data) {
      state.AiConfigs = data
    },
    showVersionAlert(state, value) {
      state.showVersionAlert = value
    },
    updateConfiguration(state, value) {
      state.configuration = value
    },
    updateBankAccounts(state, value) {
      state.bankAccounts = value
    },
  },
  actions: {
    async subscribeToApplicationChannel({ commit }) {
      const handler = event => {
        if (event === 'ws-new-app-version') {
          commit('showVersionAlert', true)
        }
      }
      const sub = Websocket.subscribeToApplicationChannel(handler)
      commit('updateSubApplicationChannel', sub)
    },
    async unsubscribeFromApplicationChannel({ state, commit }) {
      Websocket.unsubscribe(state.subNotificationsChannel)
      commit('updateSubApplicationChannel', null)
    },
    async getListingsCities({ commit }) {
      const { data } = await axios.get(`/api/listings/cities`)
      commit('updateListingCities', data)
    },
    async getConfiguration({ commit, state }) {
      const { data } = await axios.get(`/api/config`)
      window.config = Object.assign({}, state.configuration, data)
      commit('updateConfiguration', data)
      return data
    },
    async getBankAccount({ commit }) {
      const { data } = await axios.get(`/api/bank-accounts/all`)
      commit('updateBankAccounts', data)
    },
    async getAllCities(context) {
      const { data } = await axios.get('/api/cities')
      context.commit('updateCities', data)
    },
    async getSettings(context) {
      context.commit('updateListingLoading', true)
      const { data } = await axios.get('/api/settings')
      context.commit('updateListingLoading', false)
      context.commit('updateSettings', data)
      return data
    },
    async getAiConfigs(context) {
      context.commit('updateListingLoading', true)
      const { data } = await axios.get('/api/ai-configs')
      context.commit('updateListingLoading', false)
      context.commit('updateAiConfigs', data)
      return data
    },
    async updateSettings(context, payload) {
      const { data } = await axios.patch('/api/settings', payload)
      context.commit('updateSettings', data)
    },
    async getMarketplaceIntegrations(context, payload) {
      const { data } = await axios.get('/api/marketplace', payload)
      context.commit('updateMarketplace', data)
    },

    async updateAiConfigs(context, payload) {
      const { data } = await axios.patch('/api/ai-configs', payload)
      context.commit('updateAiConfigs', data)
    },
  },
  getters: {
    showVersionAlert() {
      return false //state.showVersionAlert
    },
    companyAccounts(state) {
      return state.bankAccounts.filter(ba => ba.company_account)
    },
    listingCities(state) {
      return state.listingCities
    },
    integrations(state, _getters, rootState) {
      return filter(
        ai =>
          (ai.name !== 'boom' && ai.name !== 'boomcm') ||
          (state.configuration.is_channel_manager && ai.name === 'boomcm'),
        rootState.settings.app_integrations
      )
    },
    marketplaceIntegrations(state, _getters, rootState) {
      return filter(
        ai =>
          (ai.name !== 'boom' && ai.name !== 'boomcm') ||
          (state.configuration.is_channel_manager && ai.name === 'boomcm'),
        rootState.marketplace
      )
    },
  },
}
