<template>
  <v-tooltip v-model="tooltip" content-class="info-tooltip" bottom>
    <template #activator="{ attrs }">
      <v-chip
        v-click-outside="hideTooltip"
        small
        label
        v-bind="attrs"
        color="deep-purple lighten-5"
        class="font-weight-semibold mb-2"
        @click="showTooltip"
        >Reservation <v-icon x-small class="ml-1">mdi-calendar</v-icon></v-chip
      >
    </template>
    <div>
      <div class="text-end" style="margin-bottom: -20px">
        <v-btn icon @click="hideTooltip">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="ma-2 font-weight-medium text--secondary font-weight-bold">
        <div class="text-center mb-2 text-body-1 font-weight-bold">
          <span class="teal--text darken-3 text-h6 font-weight-bold">{{
            reservation.guest_amount
          }}</span>
          Guests
        </div>
        <v-divider />
        <div class="d-flex align-center mb-6 mt-2">
          <v-col cols="6">Check-in</v-col>
          <v-col
            cols="6"
            class="text-center teal--text font-weight-bold text-h5"
          >
            <div>
              {{ reservation.check_in_hour }}
            </div>
            <div class="text-caption font-weight-bold">
              {{ parseDate(reservation.check_in, 'MMM DD, YYYY') }}
            </div>
          </v-col>
        </div>
        <div class="d-flex align-center mb-8">
          <v-col cols="6">Check-out</v-col>
          <v-col
            cols="6"
            class="text-center teal--text font-weight-bold text-h5"
          >
            <div>
              {{ reservation.check_out_hour }}
            </div>
            <div class="text-caption font-weight-bold">
              {{ parseDate(reservation.check_out, 'MMM DD, YYYY') }}
            </div>
          </v-col>
        </div>
        <v-divider />
        <div
          v-if="hasSpecialRequests"
          class="mt-4 font-weight-medium text-body-2"
        >
          <div class="font-weight-bold">Special Requests:</div>
          <div class="d-flex mt-2 flex-wrap">
            <span
              v-for="(value, key) in reservation.special_requests"
              :key="key"
            >
              <v-chip
                v-if="value"
                small
                label
                color="deep-purple lighten-5"
                class="font-weight-semibold mr-2 mb-2"
                >{{ key }}</v-chip
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </v-tooltip>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'

export default {
  name: 'TaskReservationTooltip',
  components: {},
  mixins: [CommonFunctions],
  props: ['reservation'],
  data() {
    return {
      tooltip: false,
    }
  },
  computed: {
    hasSpecialRequests() {
      return Object.values(this.reservation.special_requests).some(
        request => request
      )
    },
  },
  methods: {
    showTooltip() {
      this.tooltip = true
      this.$emit('show-tooltip')
    },
    hideTooltip() {
      this.tooltip = false
      this.$emit('hide-tooltip')
    },
  },
}
</script>

<style class="scoped">
.chip {
  left: 50%;
  font-size: 11px;
  padding: 11px;
}
.info-tooltip {
  pointer-events: all !important;
  width: 300px;
  background-color: white !important;
  opacity: 1 !important;
  border-radius: 8px;
  color: black;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}
</style>
