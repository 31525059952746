<template>
  <v-sheet v-hotkey="filterKeyMap" class="chat-box full-width pa-4">
    <div
      class="chat-box-content d-flex align-center rounded pa-3 flex-column flex-sm-row"
    >
      <div class="align-self-start">
        <pre-made-messages-popup
          :disabled="(isCommunicationAgent || isSalesAgent) && !iAmAssigned"
          :after-message-chosen="afterMessageChosen"
        />
        <div v-if="showModule('ai')" style="margin-top: 5px">
          <v-btn
            :small="isMobile"
            elevation="0"
            :loading="loading"
            title="Generate with AI"
            @click.native="triggerAiAnswer"
          >
            <v-icon color="info">mdi-creation</v-icon>
          </v-btn>
        </div>
      </div>
      <v-divider class="mx-4" vertical />
      <div class="flex-grow-1 w-100">
        <div v-if="loading" class="text-center">
          <img src="@/assets/images/stars.gif" alt="stars" />
        </div>
        <v-textarea
          v-else
          ref="messageRef"
          v-model="message"
          placeholder="Start typing..."
          solo
          :readonly="disableWhatsapp"
          :no-resize="true"
          flat
          :auto-grow="false"
          :rows="isMobile ? 2 : 4"
          dense
          hide-details
          @keyup="onMsgDown"
        />
        <div v-if="isSMS" class="d-flex justify-space-between text-caption">
          <div class="warning--text">
            <span v-show="showError">
              SMS message can't contains more than {{ smsCharLimit }} chars
            </span>
          </div>
          <div>{{ charCounter }} / {{ smsCharLimit }}</div>
        </div>
        <div v-if="tooRisky" class="text-center">
          <span class="warning--text">
            Reservation is too risky, only admins can react
          </span>
        </div>
        <div v-if="writeActions.length > 0">
          <span
            v-for="(action, index) in writeActions"
            :key="`action-${index}`"
            class="text-caption secondary--text"
          >
            {{ action.user }} is typing..
          </span>
        </div>
      </div>
      <div
        style="display: flex; flex-direction: column"
        class="d-flex align-center ml-3 py-2 align-self-end"
      >
        <v-sheet
          color="info"
          class="d-flex align-center ml-3 py-2 align-self-end"
          rounded
        >
          <v-btn
            text
            dark
            small
            :disabled="cantSendMessage"
            @click="sendMessage"
          >
            <v-icon small>mdi-send</v-icon>
          </v-btn>
          <v-menu transition="slide-y-transition" bottom offset-y>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                class="text-capitalize"
                text
                dark
                small
                v-on="on"
              >
                <span class="text-caption">
                  {{ messageModule }}
                </span>
                <v-icon small> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="item in conversation.available_modules"
                :key="item"
                dense
                @click="messageModulePicker = item"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu
            v-if="isWhatsappSelected"
            elevation="0"
            class="elevation-0"
            top
            left
            nudge-top="40"
            content-class="elevation-0"
          >
            <template #activator="{ on, attrs }">
              <v-btn text dark small v-bind="attrs" v-on="on">
                <v-icon small>mdi-text-box-multiple</v-icon>
              </v-btn>
            </template>
            <v-list dense outlined>
              <v-list-item-group>
                <v-list-item
                  v-for="template in whatsappTemplates"
                  :key="`#template_${template.name}`"
                  class="text-body-2"
                  @click="selectTemplate(template)"
                >
                  {{ template.name }}</v-list-item
                >
                <v-divider />
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-sheet>
      </div>
    </div>
  </v-sheet>
</template>
<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import get from 'lodash/fp/get'
import axios from 'axios'
import { mapGetters, mapState, mapActions } from 'vuex'
import PreMadeMessagesPopup from 'components/conversations/pre-made-messages-popup'
import moment from 'moment'

export default {
  props: ['mode'],
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  data() {
    return {
      salesStatus: null,
      message: '',
      messageModulePicker: null,
      reportedStart: null,
      loading: false,
      charCounter: 0,
      smsCharLimit: 306,
      showError: false,
      is_pre_made: false,
      serviceCallSuggestedPayload: null,
    }
  },
  computed: {
    ...mapState([
      'user',
      'showTagPop',
      'whatsappTemplates',
      'serviceCallAISuggestion',
    ]),
    ...mapGetters(['currentUserId']),
    filterKeyMap() {
      return {
        'ctrl+enter': !this.cantSendMessage && this.sendMessage,
      }
    },
    iAmAssigned() {
      return (
        this.conversation.assigned_user_id &&
        this.conversation.assigned_user_id === this.currentUserId
      )
    },
    tooRisky() {
      return (
        this.reservation.source === 'Designedvr.com' &&
        this.reservation.status === 'inquiry' &&
        this.daysAhead(this.reservation.check_in) < 5
      )
    },
    isNote() {
      return this.messageModule === 'note'
    },
    isSMS() {
      return this.messageModule === 'sms'
    },
    writeActions() {
      let res = []
      if (
        this.user &&
        this.conversation.extra_data &&
        this.conversation.extra_data.actions
      ) {
        this.conversation.extra_data.actions.forEach(action => {
          if (
            action.user_id !== this.currentUserId &&
            action.type === 'start_writing' &&
            this.lessThenOneMinAgo(new Date(action.at))
          ) {
            res.push(action)
          }
        })
      }
      return res
    },
    pushedMessage() {
      return this.$store.state.pushedMessage
    },
    messageModule: {
      get() {
        if (this.messageModulePicker) {
          return this.messageModulePicker
        } else if (
          this.conversation.extra_data &&
          this.conversation.extra_data.last_message
        ) {
          let module = this.conversation.extra_data.last_message.module
          if (
            module !== 'whatsapp' &&
            module !== 'email' &&
            module !== 'sms' &&
            module !== 'note'
          ) {
            module = 'channel'
          }
          return module
        }
        return null
      },
      set(value) {
        this.messageModulePicker = value
      },
    },
    conversation() {
      return this.$store.state.chosenChatConversation || {}
    },
    conversationId() {
      return get('id', this.$store.state.chosenChatConversation)
    },
    reservation() {
      return this.$store.getters.chosenChatReservation
    },
    isWhatsappSelected() {
      return this.messageModule === 'whatsapp'
    },
    disableWhatsapp() {
      if (!this.isWhatsappSelected) return false
      const lastMsg = [...this.conversation.thread_plus_dvr_log]
        .reverse()
        .find(msg => msg.module === 'whatsapp' && msg.by === 'guest')
      if (!lastMsg) return true
      const diff = moment().diff(moment(lastMsg.sent_at), 'days')
      return diff > 0
    },
    cantSendMessage() {
      return (
        (this.disableWhatsapp && !this.message.length) ||
        !this.messageModule ||
        !this.message ||
        (this.isSMS && this.charCounter > this.smsCharLimit) ||
        (this.tooRisky && !this.isAdmin) ||
        (!this.iAmAssigned && !this.managementRoles)
      )
    },
  },
  watch: {
    conversationId() {
      this.message = ''
      this.is_pre_made = false
      this.$refs.messageRef.focus()
    },
    pushedMessage(val) {
      if (val) {
        this.message = val
        this.is_pre_made = false
      }
    },
    message(val) {
      if (
        val &&
        (!this.reportedStart ||
          this.$moment
            .duration(this.$moment().diff(this.reportedStart))
            .asSeconds() > 20)
      ) {
        this.reportedStart = this.$moment()
        this.markAsStartWriting()
      }
    },
  },

  methods: {
    ...mapActions(['setServiceCallAISuggestion']),
    onMsgDown(event) {
      if (event.target.value.length > this.smsCharLimit) {
        this.showError = true
      } else {
        this.showError = false
        if (event.ctrlKey && (event.code === 'Enter' || event.keyCode === 13)) {
          this.sendMessage()
          return
        }
      }
      this.charCounter = this.message.length
    },
    removeTag() {
      this.salesStatus = null
    },
    afterMessageChosen(message) {
      this.message = message.body
      this.is_pre_made = true
      this.salesStatus = message.tags[0]
    },
    lessThenOneMinAgo(date) {
      var duration = this.$moment.duration(
        this.$moment().diff(this.$moment(date))
      )
      return duration.asMinutes() < 1
    },
    markAsStartWriting() {
      this.$store.dispatch('conversationUpdateBG', {
        id: this.conversation.id,
        start_writing: true,
      })
    },
    sendMessage() {
      this.$store.dispatch('sendChatMessage', {
        id: this.conversation.id,
        message: this.message,
        module: this.messageModule,
        sales_status: this.salesStatus,
        is_pre_made: this.is_pre_made,
        reservation_id: this.reservation.reservation_guesty_id,
      })
      this.message = ''
      this.is_pre_made = false
      this.salesStatus = null
      this.charCounter = 0
    },
    selectTemplate(template) {
      this.message = template.body
      this.is_pre_made = true
    },
    async triggerAiAnswer() {
      this.loading = true
      this.setServiceCallAISuggestion(false)
      this.serviceCallSuggestedPayload = null
      const response = await axios.post('/api/conversations/ai-answer', {
        conversation_id: this.conversation.id,
        reservation_id: this.reservation.id,
        source: this.mode === 'cs' ? 'cs' : 'sales',
      })
      this.loading = false
      let conversationReloaded = false

      this.$emit('update-conversation-fun-level')

      if (response.data.actions && response.data.actions.length) {
        let action = response.data.actions.find(e =>
          [
            'update_conversation_quality_level_score',
            'escalate',
            'add_note',
          ].includes(e.action_name)
        )
        if (action) {
          this.$emit('update-conversation-fun-level')
        }
        action = response.data.actions.find(
          e => e.action_name === 'create_service_call'
        )
        if (action) {
          this.$emit('update-conversation-fun-level')
          await this.$store.dispatch('conversationUpdate', {
            escalated: true,
            id: this.conversation.id,
          })
          this.$emit('update-conversations')
          conversationReloaded = true
        }
        action = response.data.actions.find(e => e.action_name === 'escalate')
        if (action) {
          await this.$store.dispatch('conversationUpdate', {
            escalated: true,
            id: this.conversation.id,
          })
          this.$emit('update-conversations')
          conversationReloaded = true
        }
        $('.scrollable-dvr-partial')[0].scrollTop = $(
          '.scrollable-dvr-partial'
        )[0].scrollHeight
      }
    },
    createServiceCall() {
      this.$emit('create-service-call', this.serviceCallSuggestedPayload)
    },
    updateMessage(message) {
      this.message = message
    },
  },
  components: {
    PreMadeMessagesPopup,
  },
}
</script>
<style lang="scss" scoped>
.chat-box {
  &-content {
    border: 1px solid var(--v-info-base);
  }
}

@keyframes zoomAnimation {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
}

.highlighted-button {
  animation: zoomAnimation 3s ease-in-out 3;
}
</style>
