<template>
  <v-card
    style="width: 100%"
    class="d-flex flex-column"
    elevation="3"
    min-width="260"
  >
    <div
      v-if="isAdmin || hasAbility('super-pm')"
      class="d-flex justify-space-between align-center white pl-3 py-1"
    >
      <div class="d-flex align-center">
        <v-icon v-if="listingType === 'hotel'" color="primary" class="mr-2"
          >hotel
        </v-icon>
        <v-icon v-else color="primary" class="mr-2">house</v-icon>
        <v-icon v-if="stage === 'setup'" color="orange lighten-2" class="mr-2"
          >fas fa-truck-loading
        </v-icon>
        <tag
          v-if="listing.is_multi_unit"
          :class="['d-inline-block ml-1']"
          bg-color="lightgrey"
          >Multi
        </tag>
        <tag
          v-if="stage === 'long-term'"
          :class="['d-inline-block ml-1']"
          color="white"
          bg-color="black"
        >
          Long Term
        </tag>
      </div>
      <ConfirmationModal
        v-if="hasAbility('super-admin') || hasAbility('disable-listing')"
        text="Are you sure?"
        @action="activate"
      >
        <template #activator="{ on }">
          <v-switch
            v-model="active"
            class="my-1 pt-0"
            hide-details
            inset
            v-on="on"
          />
        </template>
      </ConfirmationModal>
    </div>
    <div class="p-relative">
      <v-img :src="imageSrc" class="listing-image" />
    </div>
    <div class="d-flex align-start px-3 py-2">
      <v-icon class="mt-1 ml-2 mr-2" small>fas fa-map-marker-alt</v-icon>
      <span v-if="!isDemoUser" class="text-caption">{{
        isDesignedVr ? listing.address : listing.nickname
      }}</span>
      <span v-if="isDemoUser" class="text-caption">{{
        listing.city_name
      }}</span>
    </div>
    <v-spacer />
    <score-bar
      v-if="(isAdmin || isInvestor) && listingType !== 'private'"
      :occupancy-month="occupancyMonth"
      :occupancy-three-month="occupancyThreeMonth"
      :occupancy-two-weeks="occupancyTwoWeeks"
      :score="score"
      :listing="listing"
    />
    <v-card-actions>
      <v-row no-gutters>
        <v-col
          v-if="
            isAdmin ||
            isOperationsPersonal ||
            isCleaningManager ||
            isCommunicationManager ||
            isAccountant ||
            isCommunicationAgent
          "
          class="pr-1"
        >
          <v-btn
            color="secondary"
            outlined
            block
            :to="addressEditFor(id)"
            class="text-capitalize"
            >{{ $t('Manage') }}
          </v-btn>
        </v-col>
        <v-col class="pl-1">
          <v-btn
            v-if="isAdmin || isInvestor"
            color="secondary"
            outlined
            block
            :to="addressFor(id)"
            class="text-capitalize"
            >{{ $t('View') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import scoreBar from './score-bar.vue'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import axios from 'axios'
import ConfirmationModal from './modals/confirmation-modal'
import Tag from 'components/common/tag.vue'

export default {
  components: {
    Tag,
    ConfirmationModal,
    'score-bar': scoreBar,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: [
    'listing',
    'image',
    'address',
    'id',
    'stage',
    'listingType',
    'score',
    'occupancyMonth',
    'occupancyTwoWeeks',
    'occupancyThreeMonth',
    'active',
    'requests',
  ],
  computed: {
    imageSrc() {
      if (this.stage === 'setup') {
        return 'https://res.cloudinary.com/do4tedxg6/image/upload/v1696448262/ygbvtwog1hrqual82mf4.png'
      }
      return this.image
    },
    activeText() {
      if (this.active) {
        return 'Deactivate'
      } else {
        return 'Activate'
      }
    },
  },
  methods: {
    async activate() {
      await this.$store.dispatch('listings/activateListing', {
        id: this.id,
        is_active: this.active,
      })
      this.$emit('update-listing', this.id, { active: this.active })
    },
    addressFor(id) {
      return { path: `/dashboard/property/${id}` }
    },
    addressEditFor(id) {
      return { path: `/dashboard/edit/${id}` }
    },
  },
}
</script>

<style lang="scss">
.listing-indicators {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  bottom: 7px;
  width: 100%;
}

.listing-image {
  height: 240px;
  object-fit: cover;
  width: 100%;
}

.listing-sources-count {
  width: 27px;
  height: 27px;
}
</style>
