<template>
  <div>
    <p class="mb-0">Project Scope</p>
    <v-row justify="space-between" align="center">
      <v-col cols="12" md="3">
        <v-select
          v-model="newScope.value"
          :items="scopeList"
          label="Choose scope"
          outlined
          dense
          hide-details
        />
      </v-col>
      <v-col cols="6" sm="5" md="3">
        <v-menu
          v-model="newScopeStartDateMenu"
          transition="scale-transition"
          offset-y
          required
          min-width="290px"
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="newScope.start_date"
              label="Start Date"
              outlined
              dense
              hide-details
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="newScope.start_date"
            min="2017-01-01"
            :max="
              new Date(new Date().setMonth(new Date().getMonth() + 12))
                .toISOString()
                .substr(0, 10)
            "
          />
        </v-menu>
      </v-col>
      <v-col cols="6" sm="5" md="3">
        <v-menu
          v-model="newScopeEndDateMenu"
          transition="scale-transition"
          offset-y
          required
          min-width="290px"
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="newScope.end_date"
              label="End Date"
              outlined
              dense
              hide-details
              v-on="on"
            />
          </template>
          <v-date-picker v-model="newScope.end_date" />
        </v-menu>
      </v-col>
      <!--      <v-col cols="6" sm="2" md="1" class="px-md-0">-->
      <!--        <v-checkbox-->
      <!--          v-model="newScope.is_paid"-->
      <!--          class="mt-0 pt-0"-->
      <!--          label="Paid"-->
      <!--          dense-->
      <!--          hide-details-->
      <!--          :disabled="!scopesToPayFor.includes(newScope.value)"-->
      <!--        />-->
      <!--      </v-col>-->
      <v-col cols="6" sm="2">
        <v-btn
          class="text-capitalize"
          color="grey lighten-3"
          elevation="0"
          block
          :disabled="
            !newScope.value || !newScope.start_date || !newScope.end_date
          "
          @click="addScopeItem(newScope)"
        >
          <v-icon small>mdi-plus</v-icon>
          <span class="ml-1">Add</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-simple-table v-if="scopes.length" class="mb-4">
      <template #default>
        <thead>
          <tr>
            <th>Scope</th>
            <th>Start Date</th>
            <th>End Date</th>
            <!--            <th>Is Paid</th>-->
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in scopes" :key="`scope-${index}`">
            <td class="text-capitalize">
              <del v-if="item.is_done">{{ item.name }}</del>
              <span v-else>{{ item.name }}</span>
            </td>
            <td>
              <v-menu
                v-model="editedScopeStartDateMenus[index]"
                transition="scale-transition"
                offset-y
                required
              >
                <template #activator="{ on }">
                  <span class="pointer" v-on="on">
                    {{ item.start_date }}
                  </span>
                </template>
                <v-date-picker
                  v-model="item.start_date"
                  min="2017-01-01"
                  :max="
                    new Date(new Date().setMonth(new Date().getMonth() + 2))
                      .toISOString()
                      .substr(0, 10)
                  "
                />
              </v-menu>
            </td>
            <td>
              <v-menu
                v-model="editedScopeEndDateMenus[index]"
                transition="scale-transition"
                offset-y
                required
              >
                <template #activator="{ on }">
                  <span class="pointer" v-on="on">{{ item.end_date }}</span>
                </template>
                <v-date-picker v-model="item.end_date" />
              </v-menu>
            </td>
            <!--            <td>-->
            <!--              <v-btn-->
            <!--                v-if="-->
            <!--                  item.hasOwnProperty('is_paid') ||-->
            <!--                  scopesToPayFor.includes(item.value)-->
            <!--                "-->
            <!--                icon-->
            <!--                @click="item.is_paid = !item.is_paid"-->
            <!--              >-->
            <!--                <v-icon :color="item.is_paid ? 'success' : 'warning'" small>-->
            <!--                  {{ item.is_paid ? 'check' : '$close' }}-->
            <!--                </v-icon>-->
            <!--              </v-btn>-->
            <!--              <span v-else class="grey&#45;&#45;text lighten-3">&mdash;</span>-->
            <!--            </td>-->
            <td style="width: 60px">
              <div class="d-flex">
                <v-btn icon @click="deleteScopeItem(index)">
                  <v-icon color="grey" x-small>$trash</v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-btn
      v-if="isSaveButton"
      color="primary"
      elevation="0"
      @click="$emit('on-save')"
    >
      Save
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ListingSetupScopes',
  props: {
    scopes: {
      type: Array,
      default: () => [],
    },
    isSaveButton: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    newScopeStartDateMenu: false,
    newScopeEndDateMenu: false,
    editedScopeStartDateMenus: {},
    editedScopeEndDateMenus: {},
    newScope: {
      is_done: false,
      is_paid: false,
    },
    editedScope: {},
    // scopesToPayFor: ['construction', 'design', 'estimates'],
    excludedScopes: [],
  }),
  computed: {
    scopeList() {
      return this.$store.state.scopeItems
    },
  },
  mounted() {
    if (this.scopes.length) {
      this.scopes.forEach(item => this.excludedScopes.push(item.value))
    }
  },
  methods: {
    addScopeItem(item) {
      if (item.value) {
        item.name = item.value.includes('_')
          ? item.value.split('_').join(' ')
          : item.value
        this.scopes.push(item)
        this.excludedScopes.push(item.value)
        this.$emit('update:scopes', this.scopes)
        this.newScope = { is_done: false, is_paid: false }
      }
    },
    deleteScopeItem(index) {
      this.excludedScopes.splice(index, 1)
      this.scopes.splice(index, 1)
      this.$emit('update:scopes', this.scopes)
    },
  },
}
</script>

<style scoped></style>
