<template>
  <v-col :cols="cols">
    <v-card
      v-if="card"
      elevation="0"
      :height="height"
      class="h-100"
      :color="card.color"
      rounded="lg"
      :style="`color: ${card.textColor}`"
    >
      <div :class="{ 'align-self-baseline': !horizontal }">
        <div class="p-absolute ma-2">
          <v-icon :color="card.iconColor" :size="isMobile ? 18 : 23">{{
            card.icon
          }}</v-icon>
        </div>
      </div>
      <div class="d-flex align-center" style="height: 100%">
        <v-tooltip top :disabled="!card.tooltip" color="grey lighten-3">
          <template #activator="{ on }">
            <div class="flex-grow-1 text-center" v-on="on">
              <div class="text-body-2 font-weight-medium">{{ card.label }}</div>
              <v-row v-if="loading" class="justify-center">
                <v-col cols="6">
                  <v-skeleton-loader class="mt-2" type="text" height="20" />
                </v-col>
              </v-row>
              <div
                v-else
                class="font-weight-medium"
                :class="['text-h6', { 'text-subtitle-1': isIPad, blur: blur }]"
              >
                {{ formatter(card) }}
              </div>
            </div>
          </template>
          <span class="black--text">{{ card.tooltip }}</span>
        </v-tooltip>
        <v-tooltip
          v-if="card2"
          top
          :disabled="!card2.tooltip"
          color="grey lighten-3"
        >
          <template #activator="{ on }">
            <div class="flex-grow-1 text-center" v-on="on">
              <div class="text-body-2 font-weight-medium center">
                {{ card2.label }}
              </div>
              <div class="font-weight-medium" style="font-size: 24px">
                {{ formatter(card2) }}
              </div>
            </div>
          </template>
          <span class="black--text">{{ card2.tooltip }}</span>
        </v-tooltip>
      </div>
    </v-card>
  </v-col>
</template>

<script>
import DeviceMixin from 'components/mixins/device-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  props: ['cols', 'height', 'card', 'card2', 'horizontal', 'loading', 'blur'],
  mixins: [DeviceMixin, FormattersMixin],
  methods: {
    formatter(card) {
      if (card.isCount) return card.value
      if (!card.value || !isFinite(card.value) || isNaN(card.value)) return '--'
      return card.isPercent
        ? `${card.value.toFixed(2)}%`
        : `${this.kFormatter(card.value)}`
    },
  },
}
</script>
<style scoped>
.icon {
  width: 45px;
  height: 45px;
  justify-content: center;
}
.blur {
  filter: blur(4px);
}
</style>
