<template>
  <v-row>
    <v-col>
      <reservation-guest-search
        @change="
          conv =>
            $emit('filter-change', 'conversation_id', conv.conversation_id)
        "
      />
    </v-col>
    <v-col>
      <listing-select
        label="Search listings"
        :clearable="true"
        :filter-change="$event => $emit('filter-change', 'listing_id', $event)"
      />
    </v-col>
    <v-col cols="1">
      <date-picker
        v-model="from"
        :min="isLeads && new Date().toISOString()"
        label="Checkin"
        @change="$event => $emit('filter-change', 'from', $event)"
      />
    </v-col>
    <v-col cols="1">
      <date-picker
        v-model="to"
        :min="isLeads && new Date().toISOString()"
        label="Checkout"
        @change="$event => $emit('filter-change', 'to', $event)"
      />
    </v-col>
    <v-col cols="1">
      <v-text-field
        v-model="fromPrice"
        label="From $"
        outlined
        dense
        @change="$event => $emit('filter-change', 'from_price', $event)"
      />
    </v-col>
    <v-col cols="1">
      <v-text-field
        v-model="toPrice"
        label="To $"
        outlined
        dense
        @change="$event => $emit('filter-change', 'to_price', $event)"
      />
    </v-col>
    <v-col cols="1">
      <v-select
        v-model="timeSinceCreation"
        clearable
        label="Time since created"
        :items="periods"
        outlined
        dense
        @change="
          $event => $emit('filter-change', 'time_since_creation', $event)
        "
      />
    </v-col>
    <v-col cols="auto">
      <filters-select
        v-if="!isLeads"
        :value="statuses"
        label="Reservation status"
        :items="reservationStatuses"
        @change="$event => $emit('filter-change', 'reservation_status', $event)"
      >
        <template #selection="{ item }">
          <v-chip
            style="height: 20px"
            class="mr-2 font-weight-medium px-1"
            small
            label
            >{{ item }}</v-chip
          >
        </template>
      </filters-select>
    </v-col>
  </v-row>
</template>

<script>
import ReservationGuestSearch from 'components/common/reservation-guest-search'
const STATUSES = [
  'inquiry',
  'declined',
  'expired',
  'canceled',
  'closed',
  'reserved',
  'confirmed',
  'awaiting_payment',
]

import ListingSelect from 'components/listing-select'
import DatePicker from 'components/form-fields/date-picker.vue'
import FiltersSelect from 'components/form-fields/filters-select'
export default {
  props: ['isLeads'],
  components: {
    FiltersSelect,
    ReservationGuestSearch,
    ListingSelect,
    DatePicker,
  },
  data() {
    return {
      fromPrice: null,
      toPrice: null,
      timeSinceCreation: null,
      from: null,
      to: null,
      periods: ['Last 24 hours', 'Last 48 hours', 'Last week', 'Any'],
      statuses: null,
      reservationStatuses: STATUSES,
    }
  },
}
</script>

<style></style>
