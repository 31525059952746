<template>
  <v-row>
    <v-col cols="12" lg="3">
      <service-codes v-if="listing" :listing="listing" @save="save" />
    </v-col>
    <v-col cols="12" lg="9">
      <info-items-panel v-if="!loading" :listing-id="listing.id" />
      <v-card class="mt-4 pa-6">
        <v-card-title
          class="text-body-1 font-weight-medium pa-0 mb-2 d-flex justify-space-between"
          ><div>Arrival Instructions</div>
          <div>
            <v-btn
              color="success"
              depressed
              small
              :disabled="disableCheckinInstructionsSave"
              @click="saveArrivalInstructions"
              >Save</v-btn
            >
          </div></v-card-title
        >
        <v-textarea
          v-model="checkinInstuctions"
          outlined
          rows="3"
          dense
          label="Instruction on how to get to the property..."
        ></v-textarea>
        <gallery
          :thumbnails="true"
          :uploadable="true"
          image-value="src"
          :images="pics"
          :on-change="addPics"
          :remove-img="removePic"
          :cols="2"
        />
      </v-card>
      <v-card class="mt-4 pa-6">
        <div>
          <div class="text-body-1 font-weight-medium mb-1">
            Custom Address Link Url
          </div>
          <v-text-field v-model="customAddressUrl" outlined rows="3" dense>
            <template #append>
              <v-btn
                color="primary"
                depressed
                small
                :disabled="disableCustomAddressUrlSave"
                @click="saveCustomAddressUrl"
                >Save</v-btn
              ></template
            >
          </v-text-field>
        </div>
        <div>
          <div class="text-body-1 font-weight-medium mb-1">
            Travel Guide Url
          </div>
          <v-text-field v-model="travelGuideUrl" outlined rows="3" dense>
            <template #append>
              <v-btn
                color="primary"
                depressed
                small
                :disabled="disableTravelGuideUrlSave"
                @click="saveTravelGuideUrl"
                >Save</v-btn
              ></template
            >
          </v-text-field>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import InfoItemsPanel from './info-items-panel.vue'
import ServiceCodes from 'components/service-codes/service-codes.vue'
import Gallery from 'components/common/gallery'
import { mapState } from 'vuex'

export default {
  components: {
    InfoItemsPanel,
    ServiceCodes,
    Gallery,
  },
  mixins: [PermissionsMixin, DeviceMixin],
  props: ['objName'],
  data: () => ({
    enableSave: false,
    newFact: null,
    checkinInstuctions: null,
    pics: [],
    customAddressUrl: null,
    travelGuideUrl: null,
    servicesNames: [
      'internet',
      'water',
      'electricity',
      'association',
      'insurance',
    ],
  }),
  computed: {
    ...mapState(['loading']),
    listing() {
      return this.$store.state[this.objName]
    },
    disableCheckinInstructionsSave() {
      return this.checkinInstuctions === this.initialCheckinInstuctions
    },
    disableCustomAddressUrlSave() {
      return this.customAddressUrl === this.initialCustomAddressUrl
    },
    disableTravelGuideUrlSave() {
      return this.travelGuideUrl === this.initialTravelGuideUrl
    },
  },
  mounted() {
    const {
      boom_checkin_instructions,
      checkin_instructions,
      custom_address_url,
      travel_guide_url,
      checkin_instructions_media,
    } = this.listing.extra_info
    this.checkinInstuctions = boom_checkin_instructions || checkin_instructions
    this.initialCheckinInstuctions = this.checkinInstuctions
    this.pics = checkin_instructions_media || []
    this.customAddressUrl = custom_address_url
    this.travelGuideUrl = travel_guide_url
    this.initialCustomAddressUrl = this.customAddressUrl
    this.initialTravelGuideUrl = this.travelGuideUrl
  },
  methods: {
    save(services) {
      this.$store.dispatch('updateListingServices', {
        id: this.listing.id,
        services,
      })
      this.enableSave = false
    },
    updateField(payload) {
      this.$store.dispatch('listings/updateListing', {
        id: this.listing.id,
        payload,
      })
    },
    saveArrivalInstructions() {
      this.$store.dispatch('listings/updateListing', {
        id: this.listing.id,
        payload: { boom_checkin_instructions: this.checkinInstuctions },
      })
      this.initialCheckinInstuctions = this.checkinInstuctions
    },
    addPics(pics) {
      const newPics = pics.map(img => ({
        src: img,
      }))
      this.pics = [...this.pics, ...newPics]
      this.updateField({ checkin_instructions_media: this.pics })
    },
    removePic(pic) {
      this.pics = this.pics.filter(p => pic !== p.src)
      this.updateField({ checkin_instructions_media: this.pics })
    },
    saveCustomAddressUrl() {
      this.$store.dispatch('listings/updateListing', {
        id: this.listing.id,
        payload: { custom_address_url: this.customAddressUrl },
      })
      this.initialCustomAddressUrl = this.customAddressUrl
    },
    saveTravelGuideUrl() {
      this.updateField({ travel_guide_url: this.travelGuideUrl })
      this.initialTravelGuideUrl = this.travelGuideUrl
    },
  },
}
</script>
<style scoped>
:deep() .v-input__append-inner {
  margin: 8px 0;
}
</style>
