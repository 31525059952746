<template>
  <v-stepper-content :step="1">
    <div class="text-h5 font-weight-medium">Basic Info</div>
    <v-row class="align-center">
      <v-col cols="12" class="d-flex no-gutters" sm="auto">
        <MenuGroup>
          <DvrTimePicker
            label="Check-in"
            border-radius="left"
            :value.sync="checkInTime"
            :allowed-hours="allowedCheckInHours"
          />
          <DvrTimePicker
            :allowed-hours="allowedCheckoutHours"
            label="Check-out"
            border-radius="right"
            :value.sync="checkOutTime"
          />
        </MenuGroup>
      </v-col>
      <v-col cols="12" sm="auto">
        <GuestsSelect :value.sync="guests" />
      </v-col>
    </v-row>
    <div class="text-h6 font-weight-thin mt-4">Guests Names</div>
    <v-form ref="form" class="flex d-flex mt-3">
      <v-row no-gutters>
        <v-col
          v-for="(guest, ind) in guests.adults + guests.kids + guests.babies"
          :key="'guest' + ind"
          cols="12"
          sm="6"
          class="pr-6"
        >
          <v-text-field
            v-model="guestNames[ind]"
            :rules="[required]"
            outlined
            :placeholder="`Full name guest ${ind + 1}`"
            dense
            :label="`Guest ${ind + 1}`"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-stepper-content>
</template>

<script>
import MenuGroup from 'components/common/menu-group'
import GuestsSelect from 'components/common/guests-select'
import formRules from 'components/mixins/form-rules-mixin'
import DvrTimePicker from 'components/common/dvr-time-picker'
export default {
  name: 'GuestsInfo',
  components: { DvrTimePicker, GuestsSelect, MenuGroup },
  mixins: [formRules],
  props: ['preCheckIn', 'validate'],
  data() {
    return {
      guests: {
        adults: this.preCheckIn.guest_count_info.adults || 1,
        kids: this.preCheckIn.guest_count_info.kids,
        babies: this.preCheckIn.guest_count_info.babies,
      },
      // guestNames: this.preCheckIn.guest_count_info.guests,
      checkInTime:
        this.preCheckIn.times_info.requested_check_in_time.split(' ')[0],
      checkOutTime:
        this.preCheckIn.times_info.requested_check_out_time.split(' ')[0],
    }
  },
  computed: {
    allowedCheckInHours() {
      return [16, 17, 18, 19, 20, 21, 22, 23]
    },
    allowedCheckoutHours() {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    },
  },
  watch: {
    validate() {
      if (this.$refs.form.validate()) {
        const payload = {
          times_info: {
            requested_check_in_time: this.$moment(
              this.checkInTime.replace(':', ''),
              'Hmm'
            ).format('HH:mm a'),
            requested_check_out_time: this.$moment(
              this.checkOutTime.replace(':', ''),
              'Hmm'
            ).format('HH:mm a'),
          },
          guest_count_info: { ...this.guests, guests: this.guestNames },
        }
        this.$emit('step-forward', payload)
      }
    },
  },
}
</script>

<style scoped></style>
