<template>
  <v-stepper-content class="fill-height" :step="step">
    <div>
      <v-img
        v-if="listing.picture"
        :src="listing.picture"
        height="150"
        width="100%"
      />
      <div class="text-caption grey--text lighten-2 font-weight-medium mt-2">
        TicketID: #{{ currentListingTask.id }}
      </div>
      <div class="text-h6 my-2">
        {{ currentListingTask.description }}
      </div>
      <div
        v-if="currentListingTask.internal_comments && !isInvestor"
        class="mb-2"
      >
        <div class="text-subtitle-1 secondary--text">Note:</div>
        <div>{{ currentListingTask.internal_comments }}</div>
      </div>
      <div class="d-flex">
        <v-avatar tile color="rounded elevation-2">
          <v-icon color="black">mdi-google-maps</v-icon>
        </v-avatar>
        <div
          class="d-flex flex-column justify-space-between align-start px-3 py-2"
        >
          <span class="text-caption">{{ $t('Listing') }}</span>
          <span class="text-body-2 font-weight-medium ellipsis-1">{{
            listing.nickname
          }}</span>
        </div>
      </div>
      <div class="d-flex">
        <v-avatar tile color="rounded elevation-2">
          <v-icon color="black">mdi-clock-outline</v-icon>
        </v-avatar>
        <div
          class="d-flex flex-column justify-space-between align-start px-3 py-2"
        >
          <span class="text-caption">{{ $t('Scheduled at') }}</span>
          <span
            v-if="currentListingTask.scheduled_at"
            class="text-body-2 font-weight-medium ellipsis-1"
            >{{ parseDateTZ(currentListingTask.scheduled_at, 'HH:mm') }} -
            {{ endTime }}</span
          >
          <span v-else class="text-body-2 font-weight-medium ellipsis-1"
            >N/A</span
          >
        </div>
      </div>
      <div class="d-flex">
        <v-avatar tile color="rounded elevation-2">
          <v-icon color="black">mdi-home-assistant</v-icon>
        </v-avatar>
        <div
          class="d-flex flex-column justify-space-between align-start px-3 py-2"
        >
          <span class="text-caption">{{ $t('Review') }}</span>
          <span>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <span
                  class="text-body-2 font-weight-medium text-decoration-underline primary--text pointer"
                  color="primary"
                  v-on="on"
                  >Details <v-icon v-bind="attrs" small> $info </v-icon></span
                >
              </template>
              <review-card :review="currentReview" />
            </v-tooltip>
          </span>
        </div>
      </div>
      <div class="d-flex">
        <v-avatar tile color="rounded elevation-2">
          <v-icon color="black">mdi-lock</v-icon>
        </v-avatar>
        <div
          class="d-flex flex-column justify-space-between align-start px-3 py-2"
        >
          <span class="text-caption">{{ $t('Door Code') }}</span>
          <span>
            <confirmation-modal
              v-if="!showCodes"
              :text="`Please knock 3 times and wait 10 seconds before entry. (<b>${currentListingTask.add_today_avialability_text}</b>)`"
              @action="showCodes = true"
            >
              <template #activator="{ on }">
                <span
                  class="text-body-2 font-weight-medium text-decoration-underline primary--text pointer"
                  v-on="on"
                  >Show codes
                </span>
              </template>
            </confirmation-modal>
            <span v-else class="text-body-2 font-weight-medium">
              {{ currentListingTask.door_code }}
            </span>
          </span>
        </div>
      </div>
      <div class="d-flex">
        <v-avatar tile color="rounded elevation-2">
          <v-icon color="black">mdi-calendar-check</v-icon>
        </v-avatar>
        <div
          class="d-flex flex-column justify-space-between align-start px-3 py-2"
        >
          <span class="text-caption">{{ $t('Availability') }}</span>
          <span class="text-body-2 font-weight-medium ellipsis-1">{{
            $t(currentListingTask.listing_available_time)
          }}</span>
        </div>
      </div>
      <div v-if="createdBy" class="d-flex">
        <v-avatar tile color="rounded elevation-2">
          <v-icon color="black">mdi-account</v-icon>
        </v-avatar>
        <div
          class="d-flex flex-column justify-space-between align-start px-3 py-2"
        >
          <span class="text-caption">{{ $t('Created By') }}</span>
          <div class="text-body-2 font-weight-medium ellipsis-1">
            <span>{{ createdBy.name }}</span>
            <v-btn
              v-if="createdBy.phone"
              class="mx-2"
              :href="`tel:${createdBy.phone}`"
              x-small
              outlined
              color="info"
            >
              <v-icon size="12">fas fa-phone</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <div v-if="currentListingTask.mini_extra_data" class="mt-4">
        <task-reservation-tooltip
          :reservation="currentListingTask.mini_extra_data.next_res_info"
        />
      </div>

      <div class="mt-4">
        <div class="text-subtitle-1 font-weight-medium">{{ $t('Media') }}</div>
        <gallery
          :cols="4"
          :readonly="true"
          :thumbnails="true"
          :uploadable="true"
          folder="service-calls"
          :listing-id="currentListingTask.listing_id"
          :remove-img="removeBeforeImg"
          :rotate-image="rotateBeforeImg"
          :on-change="addBeforeImages"
          :images="
            currentListingTask.before_images.concat(
              currentListingTask.desc_images
            )
          "
        />
      </div>
    </div>
  </v-stepper-content>
</template>

<script>
import Gallery from 'components/common/gallery.vue'
import TaskReservationTooltip from 'components/todays-tasks/task-reservation-tooltip.vue'
import Common_functions from 'components/mixins/common_functions'
import ListingTaskViewMixin from 'components/listing-tasks/mixins/listing-task-view-mixin'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import get from 'lodash/fp/get'
import ReviewCard from 'components/reviews/review-card.vue'

export default {
  name: 'InitialStep',
  mixins: [Common_functions, ListingTaskViewMixin, PermissionsMixin],
  components: {
    ReviewCard,
    ConfirmationModal,
    Gallery,
    TaskReservationTooltip,
  },
  props: ['step'],
  data() {
    return {
      showCodes: false,
      dialog: false,
    }
  },
  computed: {
    listing() {
      return this.currentListingTask.listing || {}
    },
    currentReview() {
      return this.currentListingTask.review
    },
    catalogKey() {
      return get('listing_task_catalog_item.key', this.currentListingTask)
    },
    listingAvailable() {
      return (
        this.currentListingTask.door_code &&
        !this.isDone(this.currentListingTask.status) &&
        this.currentListingTask.listing_available_time !== 'Occupied'
      )
    },
    createdBy() {
      return this.usersMap[this.currentListingTask.created_by_id]
    },
    endTime() {
      return this.$moment(this.currentListingTask.scheduled_at)
        .add(this.currentListingTask.planned_duration, 'hours')
        .utc()
        .format('HH:mm')
    },
  },
  methods: {
    removeBeforeImg(link) {
      const before_images = this.currentListingTask.before_images.filter(
        img => img !== link
      )
      this.updateTask({ before_images })
    },
    rotateBeforeImg(oldLink) {
      return newLink => {
        const before_images = this.currentListingTask.before_images.map(img =>
          img === oldLink ? newLink : img
        )
        this.updateTask({ before_images })
      }
    },
    addBeforeImages(imgs) {
      this.updateTask({
        before_images: this.currentListingTask.before_images.concat(imgs),
      })
      this.sendImagesUpdatedNotification()
    },
    close() {
      this.dialog = false
    },
  },
}
</script>

<style scoped></style>
