<template>
  <v-sheet class="white overflow-y-auto">
    <v-container fluid>
      <v-card-title class="py-6">
        <div class="p-relative flex text-center">
          <h2 class="text-h6">{{ $t('Create Boom listing') }}</h2>
          <v-icon class="close-button" @click="close">close</v-icon>
        </div>
      </v-card-title>
      <v-form
        ref="createListing"
        lazy-validation
        @submit.prevent="createListing"
      >
        <v-card-text class="px-6">
          <p class="text-body-1 font-weight-medium">Location details</p>
          <div class="mb-1">
            <v-text-field
              v-model="listingObj.name"
              label="Nickname"
              outlined
              dense
              :rules="[required]"
            />
          </div>
          <div class="mb-1">
            <v-text-field
              v-model="listingObj.title"
              label="Title"
              outlined
              dense
              :rules="titleRules"
              :min="20"
            />
          </div>
          <div v-if="false" class="mb-1">
            <v-text-field
              v-model="listingObj.city"
              label="City"
              outlined
              dense
              :rules="[required]"
            />
          </div>
          <div v-if="false" class="mb-1">
            <v-text-field
              v-model="listingObj.country"
              label="Country"
              outlined
              dense
              :rules="[required]"
            />
          </div>
          <div class="mb-1">
            <v-text-field
              id="autocomplete"
              v-model="listingObj.address"
              label="Address"
              outlined
              dense
              :rules="[required]"
            />
          </div>
          <div v-if="false" class="mb-1">
            <v-text-field
              v-model="listingObj.lat"
              label="Latitude"
              outlined
              dense
            />
          </div>
          <div v-if="false" class="mb-1">
            <v-text-field
              v-model="listingObj.lng"
              label="Longitude"
              outlined
              dense
            />
          </div>
          <div v-if="false" class="mb-1">
            <v-text-field
              v-model="listingObj.address"
              label="FormattedAddress"
              outlined
              dense
            />
          </div>
          <div class="mb-1">
            <v-textarea
              v-model="listingObj.description"
              label="Description"
              outlined
              dense
            />
          </div>
          <v-flex v-if="false" xs8>
            <v-text-field
              v-model="listingObj.zip_code"
              label="Zip Code"
              outlined
              dense
              :rules="zipCodeRules"
            />
          </v-flex>
          <div v-if="false" class="mb-1">
            <v-text-field
              v-model="listingObj.street"
              label="Street"
              outlined
              dense
              :rules="[required]"
            />
          </div>
          <div class="mb-1">
            <v-checkbox
              v-model="listingObj.is_multi_unit"
              label="Property is multi unit"
              hide-details
              class="d-inline-block"
            />
          </div>
        </v-card-text>
        <v-card-text class="px-6">
          <p class="text-body-1 font-weight-medium">Listing details</p>
          <div class="mb-1">
            <v-text-field
              v-model="listingObj.beds"
              label="Num of bedrooms"
              type="number"
              :rules="[required, isPositive]"
              :max="30"
              :min="1"
              outlined
              dense
            />
          </div>
          <div class="mb-1">
            <v-select
              v-model="listingObj.ota_type"
              label="Listing Type"
              :items="otaTypes"
              outlined
              dense
              :rules="[required]"
            />
          </div>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="listingObj.space"
                label="Space (sqm)"
                outlined
                dense
                :rules="[required]"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="listingObj.standard_guests"
                label="Guest capacity"
                type="number"
                :rules="[required, isPositive]"
                :min="1"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="listingObj.accommodates"
                label="Maximum Guests"
                type="number"
                :rules="[required, isPositive]"
                :min="1"
                outlined
                dense
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="listingObj.floor"
                label="Floor"
                type="number"
                :rules="[required]"
                :min="1"
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="px-6">
          <p class="text-body-1 font-weight-medium">Check In/Out</p>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="listingObj.days_before_arrival"
                label="Day prior arrival"
                type="number"
                :rules="[required]"
                outlined
                dense
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="listingObj.check_in_start"
                label="Check-in start"
                :placeholder="'16:00'"
                :rules="[required]"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="listingObj.check_in_end"
                label="Check-in end"
                :placeholder="'15:00'"
                :rules="[required]"
                outlined
                dense
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="listingObj.check_out_time"
                label="Check-out start"
                :placeholder="'12:00'"
                :rules="[required]"
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-6 py-4">
          <v-spacer />
          <v-btn
            class="text-capitalize"
            color="secondary"
            text
            @click.native="close"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            class="text-capitalize"
            elevation="0"
            type="submit"
            >Save</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-container>
  </v-sheet>
</template>

<script>
import axios from 'axios'
import { loadGmaps } from '@/modules/remote-resources'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import FormRules from 'components/mixins/form-rules-mixin'

export default {
  name: 'ListingCreateModal',
  mixins: [FormRules],
  props: ['initialFields', 'onSuccess'],
  data() {
    return {
      listingObj: {
        zip_code: '',
        country: '',
        street: '',
        lat: '',
        lng: '',
        city: '',
        address: '',
        description: '',
        check_in_start: '16:00',
        check_in_end: '22:00',
        check_out_time: '11:00',
        days_before_arrival: 0,
        floor: 1,
        is_multi_unit: false,
        ...(this.initialFields || {}),
      },
      propertyTypes: [],
      otaTypes: [],
      depositTypes: [],
    }
  },
  async mounted() {
    this.initGoogleAutocomplete()
    const { data } = await axios.get('/api/channel-manager/dictionaries')

    this.otaTypes = data.ota_property_types
    this.depositTypes = Object.entries(data.deposit_types).map(
      ([key, value]) => ({
        text: value,
        value: key,
      })
    )
    if (this.listingObj.lat && this.listingObj.lng) {
      this.setInitialLocation(this.listingObj.lat, this.listingObj.lng)
    }
  },
  methods: {
    async createListing() {
      if (this.$refs.createListing.validate()) {
        await this.$store.dispatch('listings/createListing', this.listingObj)
        this.onSuccess && this.onSuccess()
        this.close()
      }
    },
    async setInitialLocation(lat, lng) {
      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.updateLocationFields(results[0])
          }
        }
      })
    },
    close() {
      this.$store.commit('hideModal')
      this.$emit('close')
    },
    async initGoogleAutocomplete() {
      const googlePlaces = get(window, 'google.maps.places', null)
      if (isNil(googlePlaces)) await loadGmaps()

      const inputElem = document.getElementById('autocomplete')
      this.autocomplete = new window.google.maps.places.Autocomplete(inputElem)
      this.autocomplete.addListener(
        'place_changed',
        this.onAutocomplete.bind(this)
      )
    },
    updateLocationFields(place) {
      this.listingObj.street = this.parsePlaceAttribute('route', place)
      this.listingObj.zip_code = this.parsePlaceAttribute('postal_code', place)
      this.listingObj.country = this.parsePlaceAttribute('country', place)
      this.listingObj.city = this.parsePlaceAttribute('locality', place)
      this.listingObj.address = place.formatted_address
      this.listingObj.lat = place.geometry.location.lat()
      this.listingObj.lng = place.geometry.location.lng()
    },
    onAutocomplete() {
      const place = this.autocomplete.getPlace()
      if (isEmpty(place.address_components)) return false
      this.updateLocationFields(place)
    },

    parsePlaceAttribute(attribute, place) {
      const addressComponent =
        place.address_components.find(
          component => component.types[0] === attribute
        ) || {}
      if (isEmpty(addressComponent) || isNil(addressComponent.long_name)) {
        return ''
      }

      return addressComponent.long_name
    },
  },
}
</script>

<style lang="scss" scoped>
.pac-item {
  line-height: 35px;
}

.pac-container.hdpi.pac-logo:after {
  background-image: none !important;
}
</style>
