<template>
  <v-dialog v-model="dialog" :persistent="true" :fullscreen="true">
    <template #activator="props">
      <slot name="customBtn" v-bind="props" />
      <v-btn v-if="!$scopedSlots.customBtn" small color="info" v-on="props.on">
        <v-icon left small>mdi-plus</v-icon> {{ $t('Reservation') }}
      </v-btn>
    </template>

    <v-sheet class="mx-auto" height="100%">
      <v-stepper v-model="steps" elevation="0" vertical>
        <v-stepper-step complete-icon="check" :complete="steps > 1" step="1">
          Reservation Details
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-form ref="form" @submit.prevent="create">
            <v-sheet class="py-2 px-5 my-3 secondary lighten-1">
              <div class="text-h6 py-1 font-weight-light">Reservation</div>
              <v-row no-gutters>
                <v-col class="px-2" cols="12">
                  <v-select
                    v-if="!isHotelReception && !isOwnerVacation"
                    v-model="reservation.reservation_type"
                    outlined
                    dense
                    :items="[
                      { text: 'STR', value: null },
                      { text: 'Long Term', value: 'long_term' },
                    ]"
                  />
                  <v-autocomplete
                    v-if="!isOwnerVacation"
                    v-model="reservation.listing_id"
                    label="Listing"
                    :items="listingsPicker"
                    dense
                    :rules="[required]"
                    item-text="nickname"
                    item-value="id"
                    outlined
                    @change="onListingChange"
                  />
                </v-col>
                <v-col v-if="isOwnerVacation" cols="6" class="px-2">
                  <date-picker
                    v-model="reservation.check_in"
                    :hide-details="false"
                    label="Check-In"
                    :rules="[required]"
                    :allowed-dates="allowedDatesOwner"
                    @change="
                      reservation.check_out
                        ? ''
                        : (reservation.check_out = reservation.check_in)
                    "
                  />
                </v-col>
                <v-col v-if="isOwnerVacation" cols="6" class="px-2">
                  <date-picker
                    v-model="reservation.check_out"
                    label="Check-Out"
                    :hide-details="false"
                    :rules="[required]"
                    :allowed-dates="allowedDatesCheckoutOwner"
                  />
                </v-col>
                <v-col v-else class="px-2" cols="10">
                  <date-picker
                    v-model="reservation.range"
                    :range="true"
                    :hide-details="false"
                    :rules="[required, dateRange]"
                    :allowed-dates="isStr ? allowedDates : undefined"
                    :event-color="eventColors"
                    label="Dates"
                    @change="calcFareAmount"
                  />
                </v-col>
                <v-col v-if="!isOwnerVacation" class="px-2" cols="2">
                  <v-text-field
                    v-model.number="reservation.guests_count"
                    label="No. Guests"
                    :rules="[required, isPositive]"
                    outlined
                    dense
                    :max="20"
                    type="number"
                  />
                </v-col>
                <v-col class="px-2" cols="12">
                  <v-textarea
                    v-model="reservation.dvr_notes"
                    label="Notes"
                    outlined
                    dense
                    rows="3"
                  />
                </v-col>
              </v-row>
            </v-sheet>
            <v-sheet class="py-2 px-5 secondary my-3 lighten-1">
              <div class="text-h6 py-1 font-weight-light">Guest</div>
              <v-row no-gutters>
                <v-col v-if="!isOwnerVacation" class="px-2" cols="12">
                  <v-autocomplete
                    v-model="reservation.guest"
                    :items="guests"
                    :search-input.sync="search"
                    item-value="guesty_id"
                    item-text="full_name"
                    return-object
                    no-filter
                    outlined
                    dense
                    label="Existing Guest"
                    @change="guestSelect"
                  >
                    <template #item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          class="info--text"
                          v-html="item.full_name"
                        />
                        <v-list-item-subtitle v-html="item.emails.join(', ')" />
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col class="px-2" cols="6">
                  <v-text-field
                    v-model="reservation.first_name"
                    label="First Name"
                    :rules="[required]"
                    outlined
                    dense
                  />
                </v-col>
                <v-col class="px-2" cols="6">
                  <v-text-field
                    v-model="reservation.last_name"
                    label="Last Name"
                    :rules="[required]"
                    outlined
                    dense
                  />
                </v-col>
                <v-col class="px-2" cols="12">
                  <v-text-field
                    v-model="reservation.email"
                    label="Email"
                    :rules="emailRules"
                    outlined
                    dense
                  />
                </v-col>
                <v-col class="px-2" cols="12">
                  <v-text-field
                    v-model="reservation.phone"
                    label="Phone"
                    :rules="[required]"
                    outlined
                    dense
                  />
                </v-col>
                <v-col v-if="isStr && !isOwnerVacation" class="px-2" cols="12">
                  <v-text-field
                    v-model="reservation.city"
                    label="home town"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
            </v-sheet>
            <v-sheet
              v-if="!isOwnerVacation"
              class="py-2 px-5 secondary my-3 lighten-1"
            >
              <div class="text-h6 py-1 font-weight-light">Financial</div>
              <v-row no-gutters>
                <v-col class="px-2" cols="6">
                  <v-text-field
                    v-model.number="reservation.fare_accommodation"
                    label="Accommodation fare"
                    :rules="[required, nonNegative]"
                    outlined
                    type="number"
                    dense
                    @change="priceChange"
                  />
                </v-col>
                <v-col class="px-2" cols="6">
                  <v-text-field
                    v-model.number="reservation.fare_cleaning"
                    label="Cleaning fare"
                    outlined
                    type="number"
                    dense
                  />
                </v-col>
                <v-col v-if="!isStr" class="px-2" cols="6">
                  <v-text-field
                    v-model.number="reservation.management_commission"
                    label="Management Commission"
                    outlined
                    type="number"
                    dense
                  />
                </v-col>
                <v-col v-if="!isStr" class="px-2" cols="6">
                  <v-text-field
                    v-model.number="reservation.client_profit"
                    label="Owner Profit"
                    outlined
                    type="number"
                    dense
                  />
                </v-col>
                <v-col v-if="totalTax && isStr" class="px-2" cols="12">
                  +Tax total: {{ totalTax.toLocaleString('en') }}
                </v-col>
                <v-col v-else-if="total" class="px-2" cols="12">
                  {{ dollarFormatter(total) }}
                </v-col>
              </v-row>
            </v-sheet>
            <div class="text-right py-2">
              <v-btn text class="ml-auto" @click="close"> Cancel </v-btn>
              <v-btn
                color="primary"
                :loading="loading"
                class="ml-auto"
                type="submit"
              >
                {{ isStr && !isOwnerVacation ? 'Continue' : 'Create' }}
              </v-btn>
            </div>
          </v-form>
        </v-stepper-content>
        <v-stepper-step
          v-if="isStr && !isOwnerVacation"
          complete-icon="check"
          :complete="steps > 2"
          step="2"
        >
          Payment Details
        </v-stepper-step>
        <v-stepper-content v-if="isStr" step="2">
          <stripe-element
            v-if="intentId"
            button-text="PAY NOW"
            :email="reservation.email"
            :public-key="publicKey"
            :intent-id="intentId"
            :client-secret="clientSecret"
            @payment-success="paymentSuccess"
            @payment-failed="paymentFailed"
            @payment-processing="paymentProcess"
          >
            <template #disclaimer>
              <div v-if="amount" class="text-right py-2">
                <tag size="sm" :inverted="true" color="warning">
                  <span class="text-subtitle-2"
                    >* You are about to charge third of the reservation price.
                    <strong class="mx-2">{{ dollarFormatter(amount) }}</strong>
                  </span>
                </tag>
              </div>
            </template>
          </stripe-element>
          <div class="text-right py-2">
            <v-btn
              color="success"
              :loading="loading"
              class="ml-auto"
              @click="skipPayment"
            >
              Skip without pay
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper>
    </v-sheet>
  </v-dialog>
</template>

<script>
import FormRules from 'components/mixins/form-rules-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import DatePicker from 'components/form-fields/date-picker'
import { mapGetters } from 'vuex'
import Toaster from '@/utils/toaster'
import StripeElement from 'components/stripe/stripe-element'
import FormattersMixin from 'components/mixins/formatters-mixin'
import Tag from 'components/common/tag'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import axios from 'axios'

export default {
  name: 'ReservationCreateModal',
  components: { Tag, StripeElement, DatePicker },
  props: ['preFilled', 'isOwnerVacation'],
  mixins: [FormRules, CommonFunctions, FormattersMixin, PermissionsMixin],
  data() {
    return {
      reservation: this.initialReservation(),
      listing: {},
      currentRates: {},
      dialog: false,
      chosenGuest: null,
      guests: [],
      publicKey: null,
      clientSecret: null,
      createdReservation: {},
      intentId: null,
      steps: 1,
      search: '',
      amount: null,
      reservationLoading: false,
    }
  },
  mounted() {
    if (this.$store.state.currentListing) {
      this.listing = this.$store.state.currentListing
    }
  },
  computed: {
    ...mapGetters(['listingsPicker']),
    isStr() {
      return this.reservation.reservation_type !== 'long_term'
    },
    totalTax() {
      return this.round(
        ((this.reservation.fare_accommodation || 0) +
          (this.reservation.fare_cleaning || 0)) *
          1.13
      )
    },
    total() {
      return (
        this.reservation.fare_accommodation + this.reservation.fare_cleaning
      )
    },
  },
  watch: {
    search(val) {
      if (!val) {
        return
      }
      this.fetchEntriesDebounced(val)
    },
    async dialog(isOpen) {
      if (isOpen && this.preFilled && this.preFilled.listingId) {
        this.reservation = {
          range: this.preFilled.range ? this.preFilled.range : [],
          listing_id: this.preFilled ? +this.preFilled.listingId : null,
          guests_count: this.preFilled ? +this.preFilled.guests_count : null,
        }
        this.currentRates = await this.$store.dispatch(
          'listings/getListingRates',
          this.reservation.listing_id
        )
        this.calcFareAmount(this.reservation.range)
      }
    },
  },
  methods: {
    ...mapGetters(['currentUser']),
    async paymentSuccess() {
      Toaster.show([{ type: 'success', text: 'Payment done successfully' }])
      this.$store.commit('updateLoading', false)
      this.$emit('charge-success')
      this.skipPayment()
    },
    paymentProcess() {
      this.$store.commit('updateLoading', true)
    },
    async paymentFailed() {
      Toaster.show([{ type: 'error', text: 'Payment failed to process' }])
      this.$store.commit('updateLoading', false)
    },
    fetchEntriesDebounced(newValue) {
      // cancel pending call
      clearTimeout(this._timerId)

      // delay new call 500ms
      this._timerId = setTimeout(async () => {
        this.guests = await this.$store.dispatch('guests/fetchGuests', {
          term: newValue,
        })
      }, 1000)
    },
    async onListingChange() {
      this.listing = this.listingsPicker.find(
        l => l.id === this.reservation.listing_id
      )
      this.currentRates = await this.$store.dispatch(
        'listings/getListingRates',
        this.reservation.listing_id
      )
    },
    calcFareAmount(dates) {
      const datesArray = this.generateDateRangeArray(dates[0], dates[1])
      this.reservation.fare_accommodation = datesArray.reduce((acc, date) => {
        acc += this.currentRates[date].price
        return acc
      }, 0)
    },
    guestSelect(guest) {
      this.chosenGuest = guest
      const [first_name, last_name] = guest.full_name.split(' ')
      this.reservation.first_name = first_name
      this.reservation.last_name = last_name
      this.reservation.phone = guest.phones[0]
      this.reservation.email = guest.emails[0]
      this.reservation.guest_id = guest.guesty_id
    },
    priceChange() {
      this.reservation.management_commission =
        this.listing.commission * (this.reservation.fare_accommodation || 0)
      this.reservation.client_profit =
        this.reservation.fare_accommodation -
        this.reservation.management_commission
    },
    allowedDates(date) {
      const dayBefore = this.$moment(date)
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
      return (
        (this.currentRates[date] &&
          this.currentRates[date].status === 'available') ||
        (this.currentRates[dayBefore] &&
          this.currentRates[date] &&
          this.currentRates[date].status !== 'available' &&
          this.currentRates[dayBefore].status === 'available')
      )
    },
    eventColors(date) {
      if (
        this.currentRates[date] &&
        this.currentRates[date].status === 'available'
      ) {
        return 'green lighten-1'
      }
      return ''
    },
    close() {
      this.$refs.form.reset()
      this.$nextTick(() => {
        this.reservation = this.initialReservation()
        this.createdReservation = {}
        this.amount = null
        this.publicKey = null
        this.clientSecret = null
        this.intentId = null
        this.dialog = false
      })
    },
    skipPayment() {
      this.$emit('oncreate', this.createdReservation.reservation_guesty_id)
      this.close()
    },
    allowedDatesOwner(val) {
      return !this.listing.allowed_vacation_dates[val]
    },
    allowedDatesCheckoutOwner(val) {
      if (
        this.listing.allowed_vacation_dates[val] &&
        !this.listing.allowed_check_out_vacation_dates[val]
      ) {
        return false
      } else {
        if (this.reservation.check_in) {
          let m = this.$moment(this.reservation.check_in)
          if (m.isBefore(val)) {
            let noGap = true
            while (m.isBefore(val) && noGap) {
              if (
                this.listing.allowed_vacation_dates[m.format('YYYY-MM-DD')] &&
                !this.listing.allowed_check_out_vacation_dates[
                  m.format('YYYY-MM-DD')
                ]
              ) {
                noGap = false
              }
              m = this.$moment(m.add(1, 'days').toString())
            }
            return noGap
          } else if (this.reservation.check_in === val) {
            return false
          } else {
            return false
          }
        } else {
          return true
        }
      }
    },
    async createReservation(payload) {
      const { res, stripe_public_key, client_secret, intent_id, amount } =
        await this.$store.dispatch('reservation/createReservation', payload)
      if (this.isOwnerVacation) {
        await this.$store.dispatch('changeListing', this.listing.id)
      }
      if (!res) {
        return
      }
      this.createdReservation = res
      if (this.createdReservation && !intent_id) {
        this.skipPayment()
        return
      }
      this.amount = amount
      this.clientSecret = client_secret
      this.publicKey = stripe_public_key
      this.intentId = intent_id
      this.steps = 2
    },
    async createInternalReservation(payload) {
      const { res } = await this.$store.dispatch(
        'reservation/createInternalReservation',
        payload
      )
      this.createdReservation = res
      this.skipPayment()
    },
    async create() {
      if (this.$refs.form.validate()) {
        this.$store.commit('updateLoading', true)
        if (
          this.chosenGuest &&
          this.chosenGuest.emails[0] !== this.reservation.email
        ) {
          this.reservation.guest_id = null
        }
        if (this.isOwnerVacation) {
          const data = {
            ...this.reservation,
            listing_id: this.listing.id,
            fare_accommodation: 0,
            fare_cleaning: 0,
            source: 'Owner',
            status: 'confirmed',
            guests_count: 1,
            range: [this.reservation.check_in, this.reservation.check_out],
          }
          await this.createReservation(data)
        } else if (this.isStr && !this.isOptima) {
          await this.createReservation(this.reservation)
        } else {
          await this.createInternalReservation(this.reservation)
        }
      }
    },
    initialReservation() {
      return {
        listing_id: null,
        reservation_type: 'long_term',
        fare_accommodation: 0,
        fare_cleaning: 0,
        management_commission: 0,
        client_profit: 0,
        processing_fee: 0,
        first_name: this.isOwnerVacation ? this.currentUser().first_name : null,
        last_name: this.isOwnerVacation ? this.currentUser().last_name : null,
        email: this.isOwnerVacation ? this.currentUser().email : null,
        phone: this.isOwnerVacation ? this.currentUser().phone : null,
      }
    },
  },
}
</script>

<style scoped></style>
