<template>
  <v-dialog v-model="chosenItem" persistent max-width="800px">
    <v-card v-if="!isEmpty(chosenItemMedia)" class="pa-4">
      <v-card-title class="mb-4">
        <div class="p-relative flex">
          <h2 v-if="infoDef" class="d-flex align-center text-h6">
            <span>{{ infoDef.topic }}</span>
            <v-icon>mdi-chevron-right</v-icon>
            <span>{{ infoDef.sub_topic }}</span>
          </h2>
          <h2 v-else class="text-h6 text-center">Media files</h2>
          <v-icon class="close-button" @click="closeInfo">close</v-icon>
        </div>
      </v-card-title>
      <v-card-subtitle>
        {{ chosenItem.comments }}
      </v-card-subtitle>
      <v-card-text>
        <gallery
          :thumbnails="true"
          :images="chosenItemMedia"
          :cols="4"
          :readonly="true"
        />
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title> No Media </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import find from 'lodash/find'
import Gallery from 'components/common/gallery'
import cloneDeep from 'lodash/cloneDeep'

export default {
  components: { Gallery },
  mixins: [CommonFunctions, PermissionsMixin],
  computed: {
    chosenItemMedia() {
      const item = this.chosenItem
      if (item.movie_url) {
        let images = cloneDeep(item.images)
        images.push(item.movie_url)
        return images
      } else {
        return item.images
      }
    },
    chosenItem() {
      return this.$store.state.chosenInfoItem
    },
    infoDef() {
      if (this.$store.state.listingInfoDef) {
        return find(
          this.$store.state.listingInfoDef.listingInfoDefs,
          infoItemDef => {
            return infoItemDef.id === this.chosenItem.listing_info_def_id
          }
        )
      }
      return null
    },
  },
  methods: {
    closeInfo() {
      this.$store.commit('updateChosenInfoItem', null)
    },
  },
}
</script>
