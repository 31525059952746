<template>
  <v-container>
    <div class="d-flex justify-space-between align-center my-3">
      <div class="text-h6">Amenities</div>
      <v-text-field
        v-model="searchTerm"
        :label="$t('Search')"
        hide-details
        outlined
        append-icon="search"
        dense
        class="col-sm-6 col-12 px-3 mt-2 mb-2"
      />
      <div class="d-inline-flex">
        <v-btn
          small
          color="primary"
          :disabled="disabledSave"
          @click="updateListing"
          >Save
        </v-btn>
      </div>
    </div>
    <v-sheet max-height="700" class="overflow-y-auto px-3">
      <v-row no-gutters>
        <v-col
          v-for="amenity in filteredAmenities"
          :key="amenity"
          cols="12"
          sm="6"
          md="4"
          class="pa-1"
        >
          <v-checkbox
            v-model="list"
            :label="amenity"
            :value="amenity"
            color="primary"
          />
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import axios from 'axios'
import filter from 'lodash/filter'

export default {
  name: 'AmenitiesEdit',
  props: ['value', 'isChannelManager'],
  data() {
    return {
      list: this.value,
      amenities: [],
      disabledSave: true,
      syncing: false,
      searchTerm: '',
    }
  },
  computed: {
    filteredAmenities() {
      return filter(this.amenities, item =>
        item.toLowerCase().includes(this.searchTerm.toLowerCase())
      )
    },
  },
  async mounted() {
    const path = this.isChannelManager
      ? '/api/settings/cm-amenities'
      : '/api/settings/amenities'
    const { data } = await axios.get(path)
    this.amenities = data
  },
  methods: {
    async updateListing() {
      await this.$store.dispatch('listings/updateListing', {
        id: this.$route.params.id,
        payload: {
          amenities: this.list,
        },
      })
      this.disabledSave = true
    },
  },
  watch: {
    list() {
      this.disabledSave = false
    },
  },
}
</script>

<style scoped></style>
