<template>
  <v-chip :class="serviceLevelClass" label small>
    <v-icon small>{{ serviceLevelIcon }}</v-icon>
  </v-chip>
</template>

<script>
export default {
  props: ['serviceLevel'],
  data() {
    return {
      levelIcon: {
        1: 'join_full',
        2: 'join_left',
        3: 'join_right',
      },
      levelClass: {
        1: 'diamond',
        2: 'gold',
        3: 'silver white--text',
      },
      levelText: {
        1: 'Diamond',
        2: 'Gold',
        3: 'Silver',
      },
    }
  },
  computed: {
    serviceLevelIcon() {
      return this.levelIcon[this.serviceLevel] || 'join_inner'
    },
    serviceLevelClass() {
      return this.levelClass[this.serviceLevel] || 'bronze white--text'
    },
    serviceLevelText() {
      return this.levelText[this.serviceLevel] || 'Bronze'
    },
  },
}
</script>
