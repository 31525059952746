<template>
  <form-dialog title="Add Invoice Item" @submit="submitInvoice">
    <template #activator="{ on }">
      <v-btn color="blue" dark depressed v-on="on">Add Invoice Item</v-btn>
    </template>
    <div class="d-flex">
      <v-select
        v-model="type"
        class="mr-2"
        label="Type"
        outlined
        dense
        :rules="[required]"
        :items="INVOICE_TYPES"
      />
      <v-text-field
        v-model.number="amount"
        outlined
        dense
        label="Amount ($)"
        :rules="[required]"
      />
    </div>
    <v-text-field
      v-model="description"
      outlined
      dense
      label="Description"
      :rules="[required]"
    />
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import { INVOICE_TYPES, INVOICE_CHANGE } from 'components/reservation/config'
import FormRulesMixin from 'components/mixins/form-rules-mixin'

export default {
  components: { FormDialog },
  mixins: [FormRulesMixin],
  props: ['reservation'],
  data() {
    return {
      type: null,
      amount: null,
      INVOICE_TYPES,
      description: '',
    }
  },
  computed: {
    shouldLimitAmount() {
      return (
        this.reservation.listing.is_resort && this.type === 'guest_discount'
      )
    },
  },
  methods: {
    resetForm() {
      this.type = null
      this.amount = null
      this.description = null
    },
    submitInvoice() {
      this.$emit('add-invoice', {
        type: this.type,
        amount: this.amount * INVOICE_CHANGE[this.type],
        description: this.description,
      })
      this.resetForm()
    },
  },
}
</script>
