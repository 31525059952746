<template>
  <v-container>
    <v-card class="overflow-y-auto">
      <v-card-title class="text-h6 text-uppercase d-flex justify-space-between">
        <div class="text-capitalize">
          <v-icon
            small
            class="mr-1"
            left
            :color="isActive ? 'success' : 'error'"
            >fas fa-circle</v-icon
          >Boom Channel Manager
        </div>
      </v-card-title>
      <v-card-text>
        <div class="text-body-1">
          Manage your channels inside boom dashboard
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <cm-credentials v-if="isActive" />
        <form-dialog title="Boom channel manager" @submit="connectToBoom">
          <template #activator="{ on }">
            <v-btn
              :disabled="isActive"
              :loading="connecting"
              outlined
              small
              v-on="on"
              >{{ isActive ? 'Connected' : 'Connect' }}</v-btn
            >
          </template>
          <div class="text-body-2">
            Fill your contact details for marketing purpose on the channels.
          </div>
          <v-text-field
            v-model="firstName"
            :rules="[required]"
            label="First Name"
            dense
            outlined
          />
          <v-text-field
            v-model="lastName"
            :rules="[required]"
            label="Last Name"
            dense
            outlined
          />
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="Contact Email"
            dense
            outlined
          />
          <v-text-field
            v-model="phone"
            label="Contact Phone(include country prefix)"
            :rules="phoneRules"
            dense
            outlined
          />
          <google-address
            v-model="place"
            :rules="[required]"
            @change="locationChanged"
          />
        </form-dialog>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import MarketplaceMixin from 'components/marketplace/marketplace-mixin'
import FormDialog from 'components/common/form-dialog.vue'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import axios from 'axios'
import GoogleAddress from 'components/common/google-address.vue'
import CmCredentials from 'components/channel-management/cm-credentials.vue'

export default {
  name: 'Boomcm',
  components: { CmCredentials, GoogleAddress, FormDialog },
  mixins: [MarketplaceMixin, FormRulesMixin],
  data() {
    return {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      place: null,
      country: null,
      city: null,
      connecting: false,
    }
  },
  methods: {
    locationChanged(data) {
      this.country = data.country
      this.city = data.city
    },
    async connectToBoom() {
      this.connecting = true
      await axios.post('/api/boom-cm/connect', {
        first_name: this.firstName,
        last_name: this.lastName,
        phone: this.phone,
        email: this.email,
        marketplace_id: this.marketplaceId,
        country: this.country,
        city: this.city,
      })
      this.connecting = false
    },
  },
}
</script>

<style scoped></style>
