<template>
  <div>
    <v-row v-if="mode === 'sc-mode'">
      <v-col cols="7" class="full-scrollable-dvr">
        <v-btn
          color="primary"
          class="text-capitalize float-right"
          elevation="0"
          @click="mode = 'chat-mode'"
        >
          Chat mode
        </v-btn>
        <tasks-calendar
          v-if="currentUserId"
          ref="taskCal"
          hide-stats="true"
          order-by="start"
          :initial-filters="{
            division: ['Customer Support'],
            categories: [],
          }"
          :single-view="true"
          initial-view="listDay"
        />
      </v-col>
      <v-col cols="5" class="full-scrollable-dvr">
        <v-sheet v-show="feedOpenId">
          <v-slide-x-transition>
            <LtFeed
              class="bl-1"
              :logs="log"
              :users="activeUsers"
              :unseen="unseenComments"
              :title="title"
              :comments-list="commentsList"
              :internal-comments="internalComments"
              @add-message="addComment"
              @update-comment="commentInternal"
            />
          </v-slide-x-transition>
        </v-sheet>
      </v-col>
    </v-row>
    <v-container
      v-if="mode === 'chat-mode' && (isMobileOrIPad || isLaptop)"
      fluid
    >
      <v-bottom-navigation app :value="activeTab">
        <v-btn
          absolute
          class="cyan-icon"
          value="conversations"
          @click="onMobileTabChange('conversations')"
        >
          <v-icon>all_inbox</v-icon>
        </v-btn>

        <v-btn
          :disabled="!conversation"
          class="cyan-icon"
          value="conversation"
          @click="onMobileTabChange('conversation')"
        >
          <v-icon>chat</v-icon>
        </v-btn>

        <v-btn
          :disabled="!conversation"
          class="cyan-icon"
          value="conversation-info"
          @click="onMobileTabChange('conversation-info')"
        >
          <v-icon>info</v-icon>
        </v-btn>
      </v-bottom-navigation>
      <div>
        <v-row v-show="activeTab === 'conversations'" class="pl-3 pr-3">
          <v-row class="pa-1 mb-2 align-center" wrap>
            <v-col v-if="chatStats" cols="12" class="pl-1">
              <v-btn-toggle
                v-model="displayType"
                class="align-center"
                borderless
              >
                <v-btn
                  v-if="managementRoles"
                  class="sm-overline-text"
                  value="Un-Resolved"
                >
                  <span class="hidden-sm-and-down"
                    >Open tickets -
                    {{ chatStats ? chatStats.unresolved : '0' }}</span
                  >
                  <v-icon
                    :class="chatStats && chatStats.unresolved ? 'red-warn' : ''"
                    right
                    >highlight_off
                  </v-icon>
                </v-btn>
                <v-btn class="sm-overline-text" value="Potential-Inquiries">
                  <span class="hidden-sm-and-down"
                    >Potential Inquiries -
                    {{ chatStats ? chatStats.potential_inquiries : '0' }}</span
                  >
                  <v-icon
                    :class="
                      chatStats && chatStats.potential_inquiries
                        ? 'red-warn'
                        : ''
                    "
                    right
                    >mdi-archive-eye-outline
                  </v-icon>
                </v-btn>
                <v-btn class="sm-overline-text" value="Un-Assigned">
                  <span class="hidden-sm-and-down"
                    >Un-Assigned -
                    {{ chatStats ? chatStats.un_assigned : '0' }}</span
                  >
                  <v-icon
                    :class="
                      chatStats && chatStats.un_assigned ? 'red-warn' : ''
                    "
                    right
                    >group_add
                  </v-icon>
                </v-btn>
                <v-btn class="sm-overline-text" value="My-Tickets">
                  <span class="hidden-sm-and-down"
                    >My-Tickets -
                    {{ chatStats ? chatStats.my_tickets : '0' }}</span
                  >
                  <v-icon
                    :class="chatStats && chatStats.my_tickets ? 'red-warn' : ''"
                    right
                    >group_add
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="managementRoles"
                  class="sm-overline-text"
                  value="Need-to-Follow"
                >
                  <span class="hidden-sm-and-down"
                    >Open Followups -
                    {{ chatStats ? chatStats.need_to_follow_up : '0' }}</span
                  >
                  <v-icon
                    :class="
                      chatStats && chatStats.need_to_follow_up ? 'red-warn' : ''
                    "
                    right
                    >group_add
                  </v-icon>
                </v-btn>
                <v-btn value="all" class="sm-overline-text">
                  <span class="hidden-sm-and-down">All</span>
                  <v-icon right>chat_bubble_outline</v-icon>
                </v-btn>
                <v-btn value="sales" class="sm-overline-text">
                  <span class="hidden-sm-and-down">Sales</span>
                  <v-icon right>monetization_on</v-icon>
                </v-btn>
                <v-btn value="customer-support" class="sm-overline-text">
                  <span class="hidden-sm-and-down">C-S</span>
                  <v-icon right>contact_mail</v-icon>
                </v-btn>
                <v-btn
                  v-if="
                    chatStats &&
                    chatStats.last_24_unanswered &&
                    chatStats.last_24_unanswered > 0
                  "
                  class="sm-overline-text"
                  value="Un-Answered"
                >
                  <span class="hidden-sm-and-down"
                    >Un-Answered -
                    {{ chatStats ? chatStats.last_24_unanswered : '0' }}</span
                  >
                  <v-icon
                    :class="
                      chatStats && chatStats.last_24_unanswered
                        ? 'red-warn'
                        : ''
                    "
                    right
                    >question_answer
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="
                    chatStats &&
                    chatStats.last_24_unread &&
                    chatStats.last_24_unread > 0
                  "
                  class="sm-overline-text"
                  value="Un-Read"
                >
                  <span class="hidden-sm-and-down"
                    >Un-Read -
                    {{ chatStats ? chatStats.last_24_unread : '0' }}</span
                  >
                  <v-icon
                    right
                    :class="
                      chatStats && chatStats.last_24_unanswered
                        ? 'red-warn'
                        : ''
                    "
                    >mark_email_unread
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="
                    chatStats &&
                    chatStats.have_payment_problem &&
                    chatStats.have_payment_problem > 0
                  "
                  class="sm-overline-text"
                  value="Unpaid"
                >
                  <span class="hidden-sm-and-down"
                    >Unpaid -
                    {{ chatStats ? chatStats.have_payment_problem : '0' }}</span
                  >
                  <v-icon
                    :class="
                      chatStats && chatStats.last_24_unanswered
                        ? 'red-warn'
                        : ''
                    "
                    right
                    >payment
                  </v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="12">
              <chat-conversations
                :current-conversation="conversation"
                :get-conversations="getConversations"
              />
            </v-col>
          </v-row>
        </v-row>
        <v-row v-show="activeTab === 'conversation'" bb-1>
          <conversation-messages
            v-if="conversation"
            :conversation="conversation"
            :get-conversations="getConversations"
            @view-assignee-conversations="assigneeChange"
          />
          <conversation-chat-box v-if="conversation" />
        </v-row>
        <v-row v-show="activeTab === 'conversation-info'">
          <conversation-info
            v-if="conversation"
            :fetching="fetchingChatConversation"
            :conversation="conversation"
            :is-full="true"
          />
        </v-row>
      </div>
    </v-container>
    <listing-info-pop v-if="showListingInfoPop" />
    <v-container
      v-if="mode === 'chat-mode' && (isLargeLaptop || isDesktop)"
      class="py-0"
      fluid
    >
      <v-row>
        <v-col cols="12" class="pa-0" style="overflow: auto">
          <v-row no-gutters class="flex-nowrap">
            <v-sheet elevation="0" width="440" class="d-block">
              <v-btn-toggle
                v-if="!isHotelReception"
                mandatory
                class="d-flex"
                color="primary"
                :value="chatType"
                @change="updateChatType($event)"
              >
                <v-btn value="sales" class="flex">Sales</v-btn>
                <v-btn value="customerSupport" class="flex">CS</v-btn>
              </v-btn-toggle>
              <chat-filters
                v-if="!isHotelReception"
                :items="filters"
                :type="displayType"
                @type-change="displayType = $event"
              />
              <chat-additional-filters
                :search-term.sync="searchTerm"
                :mode.sync="mode"
                :filters="extraFilters"
                :type="displayType"
                :sales-status="salesStatus"
                :from-price="fromPrice"
                :to-price="toPrice"
                :res-statuses="resStatuses"
                :time-since-creation="timeSinceCreation"
                @change-type="displayType = $event"
                @listing-change="listingChange"
                @sales-status-change="saveSalesStatus"
                @from-price-change="fromPriceChange"
                @to-price-change="toPriceChange"
                @reservation-status-change="saveResStatus"
                @time-since-creation-change="saveTimeSinceCreation"
                @save-from="saveFrom"
                @save-to="saveTo"
              />
              <chat-conversations
                :current-conversation="conversation"
                :conversations="chatConversations"
                @scroll="getConversations({ add: true })"
              />
            </v-sheet>
            <v-col class="d-flex p-relative">
              <v-overlay
                v-if="conversationLoading"
                :opacity="0.1"
                absolute
                class="flex-center"
              >
                <v-progress-circular indeterminate size="50" />
              </v-overlay>
              <div class="d-flex flex">
                <v-sheet class="flex-grow-1 d-flex flex-column">
                  <conversation-messages
                    v-if="conversation"
                    :conversation="conversation"
                    :get-conversations="getConversations"
                    @view-assignee-conversations="assigneeChange"
                  />
                  <v-divider />
                  <conversation-chat-box v-if="conversation" />
                </v-sheet>
                <v-sheet
                  v-if="isLargeLaptop || isDesktop"
                  class="d-flex flex-column"
                >
                  <conversation-info
                    v-if="conversation"
                    :key="conversation.id"
                    :fetching="fetchingChatConversation"
                    :conversation="conversation"
                  />
                </v-sheet>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import MentionMixin from 'components/mixins/mention-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import ChatConversations from './chat-conversations'
import ConversationMessages from './conversation-messages'
import ConversationChatBox from './conversation-chat-box.vue'
import ConversationInfo from './conversation-info.vue'
import ListingInfoPop from './listing-info-pop.vue'
import TasksCalendar from './calendar/tasks-calendar'
import toNumber from 'lodash/fp/toNumber'
import LtFeed from './listing-tasks/lt-feed'
import find from 'lodash/fp/find'
import orderBy from 'lodash/fp/orderBy'
import debounce from 'lodash/debounce'
import ChatFilters from 'components/chat-filters'
import ChatAdditionalFilters from 'components/chat-additional-filters'
import { isEmpty } from 'lodash'

const CHAT_TYPES = {
  customerSupport: 'customer_support',
  sales: 'sales',
}
export default {
  components: {
    ChatAdditionalFilters,
    ChatFilters,
    LtFeed,
    TasksCalendar,
    ConversationMessages,
    ChatConversations,
    ConversationChatBox,
    ConversationInfo,
    ListingInfoPop,
  },
  mixins: [CommonFunctions, PermissionsMixin, MentionMixin, DeviceMixin],
  beforeCreate() {
    if (this.$route.params.id) {
      this.$router.push({
        name: 'crm',
        params: { paramsTab: 'cs' },
        query: { conversation: this.$route.params.id },
      })
    }
  },
  data() {
    return {
      mode: 'chat-mode',
      specialFilters: null,
      displayType: 'all',
      allowDisplayTypeWatcher: true,
      listingId: '',
      from: null,
      to: null,
      timeSinceCreation: 'Any',
      fromPrice: 0,
      resStatuses: [],
      chatStatuses: {
        sales: [
          'inquiry',
          'canceled',
          'declined',
          'expired',
          'closed',
          'reserved',
          'awaiting_payment',
        ],
        customerSupport: ['confirmed'],
      },
      salesStatus: [],
      toPrice: 1000000,
      drawer: true,
      lightRules: false,
      items: [
        { title: 'Home', icon: 'dashboard' },
        { title: 'About', icon: 'question_answer' },
      ],
      filters: [
        {
          text: 'Unanswered',
          icon: 'mdi-message-question-outline',
          value: 'Un-Answered',
        },
        { text: 'Unassigned', icon: '$people_plus', value: 'Un-Assigned' },
        { text: 'My tickets', icon: '$my_tickets', value: 'My-Tickets' },
        {
          text: 'Follow-ups',
          icon: 'mdi-account-plus',
          value: 'Need-to-Follow',
        },
        {
          text: 'Open tickets',
          icon: 'mdi-close-circle-outline',
          value: 'Un-Resolved',
        },
        { text: 'All', icon: '$inbox', value: 'all' },
      ],
      extraFilters: [
        { text: 'Reserved', icon: 'mdi-cart-check', value: 'reserved' },
        {
          text: 'Potential Inquiries',
          icon: 'mdi-archive-eye-outline',
          value: 'Potential-Inquiries',
        },
        {
          text: 'Unpaid',
          icon: 'mdi-credit-card-remove-outline',
          value: 'Unpaid',
        },
      ],
      debouncer: null,
      searchTerm: '',
    }
  },
  computed: {
    ...mapGetters(['currentUserId', 'conversationLoading']),
    ...mapGetters('users', ['activeUsers']),
    ...mapState([
      'listingTasks',
      'chatConversations',
      'chatStats',
      'chatType',
      'fetchingChatConversation',
    ]),
    currentTask() {
      if (this.$route.query.feed_id) {
        return find(t => t.id == this.$route.query.feed_id, this.listingTasks)
      } else {
        return null
      }
    },
    commentsList() {
      if (this.currentTask) {
        return orderBy(
          ['created_at'],
          ['desc'],
          this.currentTask.listing_task_comments.filter(c => !c.parent_id)
        )
      } else {
        return []
      }
    },
    internalComments() {
      if (this.currentTask) {
        return this.currentTask.internal_comments || ''
      }
      return ''
    },
    title() {
      if (this.currentTask) {
        return (
          this.currentTask.mini_extra_data.listing_name +
          ' - ' +
          this.currentTask.description
        )
      } else {
        return ''
      }
    },
    log() {
      if (this.currentTask) {
        return this.currentTask.log
      } else {
        return []
      }
    },
    feedOpenId() {
      return this.$route.query.feed_id
        ? toNumber(this.$route.query.feed_id)
        : null
    },
    activeTab() {
      return this.$store.getters.chatMobileView
    },
    showListingInfoPop() {
      return this.$store.state.showListingInfoPop
    },
    salesStatues() {
      return this.$store.state.conversationTags
    },
    conversation() {
      return this.$store.getters.currentConversation
    },
  },
  watch: {
    displayType(_newValue, oldValue) {
      if (this.allowDisplayTypeWatcher && oldValue) {
        this.filterTypeChange()
      }
    },
    chatType() {
      this.filterTypeChange()
    },
    searchTerm(val) {
      this.debouncer(val)
    },
  },
  mounted() {
    this.resStatuses = this.isHotelReception
      ? []
      : this.chatStatuses[this.chatType]
    this.displayType =
      this.$route.params.id || this.isHotelReception ? 'all' : 'Un-Resolved'
    this.updateConversationsPagination(null)
    if (isEmpty(this.$store.state.conversationTags)) {
      this.$store.dispatch('getChatConversationTags')
    }
    this.getConversations()
    this.refreshMessages = setInterval(() => {
      if (this.$route.fullPath.includes('chat')) {
        this.getConversations({ isRefresh: true })
      }
    }, 90000)
    this.refreshConversation = setInterval(() => {
      if (
        this.conversation &&
        this.$route.fullPath.includes('chat') &&
        !this.listingLoading
      ) {
        this.$store.dispatch('getChatMessages', this.conversation.id)
      }
    }, 20000)
    // this.$store.dispatch('getIOTDevices')
    this.debouncer = debounce(term => {
      this.updateConversationsPagination(null)
      this.getConversations({ searchTerm: term })
    }, 750)
  },
  beforeDestroy() {
    clearInterval(this.intervalFunc)
    clearInterval(this.refreshConversation)
    clearInterval(this.refreshMessages)
  },
  methods: {
    ...mapMutations(['updateConversationsPagination', 'updateChatType']),
    ...mapActions({
      fetchConversations: 'getChatConversations',
      refreshConversations: 'refreshChatConversations',
    }),
    filterTypeChange(data = {}) {
      this.$store.commit('updateConversationsPagination', null)
      this.resStatuses = this.isHotelReception
        ? []
        : this.chatStatuses[this.chatType]

      if (this.displayType === 'reserved') {
        this.resStatuses = ['reserved']
      } else if (this.displayType === 'My-Tickets') {
        this.resStatuses = []
      }
      this.getConversations(data)
    },
    assigneeChange(data) {
      this.allowDisplayTypeWatcher = false
      this.displayType = 'all'
      this.filterTypeChange(data)
      this.allowDisplayTypeWatcher = true
    },
    addComment({ html, parent_id }) {
      this.$store.dispatch('addComment', {
        id: this.$route.query.feed_id,
        html,
        parent_id,
      })
      setTimeout(() => {
        this.$refs.taskCal.getCurrentListingTasks()
      }, 200)
    },
    commentInternal(value) {
      this.$store.dispatch('updateListingTask', {
        id: this.$route.query.feed_id,
        internal_comments: value,
      })
      setTimeout(() => {
        this.$refs.taskCal.getCurrentListingTasks()
      }, 200)
    },
    saveFrom(date) {
      this.$store.commit('updateConversationsPagination', null)
      this.from = date
      this.getConversations()
    },
    saveTo(date) {
      this.$store.commit('updateConversationsPagination', null)
      this.to = date
      this.getConversations()
    },
    toggleUnpaid() {
      this.$store.commit('updateConversationsPagination', null)
      this.getConversations()
    },
    saveResStatus(newVal) {
      this.$store.commit('updateConversationsPagination', null)
      this.resStatuses = newVal
      this.getConversations()
    },
    listingChange(newVal) {
      this.$store.commit('updateConversationsPagination', null)
      this.listingId = newVal
      this.getConversations()
    },
    saveSalesStatus(newVal) {
      this.$store.commit('updateConversationsPagination', null)
      this.salesStatus = newVal
      this.getConversations()
    },
    saveTimeSinceCreation(newVal) {
      this.$store.commit('updateConversationsPagination', null)
      this.timeSinceCreation = newVal
      this.getConversations()
    },
    fromPriceChange(price) {
      this.$store.commit('updateConversationsPagination', null)
      this.fromPrice = price
      this.getConversations()
    },
    toPriceChange(price) {
      this.$store.commit('updateConversationsPagination', null)
      this.toPrice = price
      this.getConversations()
    },
    getConversations(data = {}) {
      const { add, isRefresh, assignee, searchTerm, is_done } = data
      const filters = {}
      if (searchTerm) {
        filters.search_term = searchTerm
      }
      if (this.displayType === 'Unpaid') {
        filters.unpaid = true
      }
      if (assignee) {
        filters.assigned_user_id = assignee
      }
      if (is_done) {
        filters.is_done = is_done
      }
      if (this.displayType === 'Un-Resolved') {
        filters.unresolved = true
      }
      if (this.displayType === 'Un-Answered') {
        filters.last_24_unanswered = true
      }
      if (this.displayType === 'Un-Assigned') {
        filters.unassigned = true
      }
      if (this.displayType === 'My-Tickets') {
        filters.my_tickets_only = true
      }
      if (this.displayType === 'Need-to-Follow') {
        filters.need_to_follow_up = true
      }
      if (this.displayType === 'Potential-Inquiries') {
        filters.potential_inquiries = true
      }
      if (this.from) {
        filters.from = this.from
      }
      if (this.listingId) {
        filters.listing_id = this.listingId
      }
      if (this.$route.params.id) {
        filters.conversation_id = this.$route.params.id
      }
      if (this.to) {
        filters.to = this.to
      }
      if (this.fromPrice) {
        filters.from_price = this.fromPrice
      }
      if (this.toPrice) {
        filters.to_price = this.toPrice
      }
      if (this.resStatuses.length) {
        filters.reservation_status = this.resStatuses
      }
      if (this.salesStatus) {
        filters.sales_status = this.salesStatus
      }
      if (this.timeSinceCreation) {
        filters.time_since_creation = this.timeSinceCreation
      }
      if (add) {
        filters.add = true
      }

      filters.department = CHAT_TYPES[this.chatType]

      if (isRefresh) {
        this.refreshConversations(filters)
      } else {
        this.fetchConversations(filters)
      }
    },
    onMobileTabChange(tab) {
      this.$store.commit('updateChatMobileView', tab)
    },
  },
}
</script>
