<template>
  <div class="text-center my-4 my-md-0">
    <div class="text-caption">
      {{ title }}
    </div>
    <div
      :class="valueClasses || 'font-weight-bold text-h6 text-capitalize'"
      v-html="value"
    ></div>
    <div v-if="subtitle" class="text-body-2 mt-3">
      {{ subtitle }}
    </div>
    <div v-if="secondarySubtitle" class="text-caption">
      {{ secondarySubtitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailsBox',
  props: ['title', 'value', 'subtitle', 'secondarySubtitle', 'valueClasses'],
}
</script>

<style scoped></style>
