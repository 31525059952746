<template>
  <v-file-input
    v-model="file"
    accept=".csv"
    label="CSV Upload"
    outlined
    dense
    hide-details
    @change="$emit('change', file)"
  ></v-file-input>
</template>

<script>
export default {
  name: 'FileUpload',
  data() {
    return {
      file: null,
    }
  },
}
</script>
