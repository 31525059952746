<template>
  <v-menu v-model="menu" offset-y :disabled="readonly">
    <template #activator="{ on, attrs }">
      <v-chip v-if="value" v-bind="attrs" small :disabled="readonly" v-on="on">
        <v-icon class="mr-2" :color="priorityColor(value)" x-small>
          mdi-circle
        </v-icon>
        <span class="text-capitalize">
          {{ value }}
        </span>
      </v-chip>
      <v-chip
        v-else
        v-bind="attrs"
        class="text-lowercase"
        small
        :disabled="readonly"
        v-on="on"
      >
        <span class="text-caption">set priority</span>
      </v-chip>
    </template>
    <v-list dense>
      <v-list-item
        v-for="(priority, index) in priorities"
        :key="index"
        @click="update(priority)"
      >
        <v-list-item-title class="text-capitalize">
          <v-icon class="mr-2" :color="priorityColor(priority)" x-small>
            mdi-circle
          </v-icon>
          <span class="text-capitalize">
            {{ priority }}
          </span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import ColorsMixin from 'components/mixins/colors-mixin'

export default {
  name: 'LtPriority',
  components: {},
  mixins: [ColorsMixin],
  props: ['value', 'readonly'],
  data() {
    return {
      menu: false,
      priorities: ['Critical', 'High', 'Med', 'Low'],
    }
  },
  methods: {
    closeMenu() {
      this.menu = false
    },
    update(priority) {
      if (priority !== this.value) {
        this.$emit('input', priority)
        this.$emit('change', priority)
      }
      this.closeMenu()
    },
  },
}
</script>

<style scoped></style>
