import { render, staticRenderFns } from "./taskim.vue?vue&type=template&id=44656d00&scoped=true&"
import script from "./taskim.vue?vue&type=script&lang=js&"
export * from "./taskim.vue?vue&type=script&lang=js&"
import style0 from "./taskim.vue?vue&type=style&index=0&id=44656d00&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44656d00",
  null
  
)

export default component.exports