<template>
  <v-sheet>
    <v-tabs v-model="paramsTab" :fixed-tabs="isMobileOrIPad" class="pa-4">
      <v-tab href="#user-info"> {{ $t('Info') }}</v-tab>
      <v-tab v-if="isInvestor || isAdmin" href="#user-notifications">
        {{ $t('Notifications') }}</v-tab
      >
      <v-tab-item id="user-info" class="pa-4">
        <user-profile v-if="currentUser" />
      </v-tab-item>
      <v-tab-item id="user-notifications" class="pa-4">
        <user-notifications />
      </v-tab-item>
    </v-tabs>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import UserProfile from 'components/user/user-profile'
import UserNotifications from 'components/user/user-notifications'
import DeviceMixin from 'components/mixins/device-mixin'

export default {
  components: { UserNotifications, UserProfile },
  mixins: [PermissionsMixin, DeviceMixin],
  computed: {
    ...mapGetters(['currentUser']),
  },
}
</script>
