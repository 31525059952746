<template>
  <div v-if="settings">
    <v-row>
      <v-col cols="12" sm="3">
        <v-btn-toggle
          v-model="selectedSettings"
          class="flex-column d-flex"
          color="primary"
        >
          <v-btn value="reviews">Reviews</v-btn>
          <v-btn value="cs" :disabled="!showModule('ai')"
            >Customer Support</v-btn
          >
          <v-btn value="sales" :disabled="!showModule('ai')">Sales</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12" sm="9">
        <v-sheet elevation="2" rounded class="pa-4" height="100%">
          <v-row v-if="selectedSettings === 'reviews'">
            <v-col cols="12" sm="8" class="ml-3">
              <div class="text-h6">AI Reviews config</div>
              <v-row class="justify-space-between align-start">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Co Pilot
                  </span>
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    v-model="settings.reviews.co_pilot"
                    class="mt-0"
                    hide-details
                    inset
                  />
                </v-col>
              </v-row>
              <div>
                <v-checkbox
                  v-model="settings.reviews.analyze_reviews"
                  label="Create review analysis and tags"
                  :disabled="!settings.reviews.co_pilot"
                  dense
                  hide-details
                />
              </div>
              <div>
                <v-checkbox
                  v-model="settings.reviews.suggest_sc"
                  label="Suggest service calls based on review"
                  :disabled="!settings.reviews.co_pilot"
                  dense
                  hide-details
                />
              </div>
              <div>
                <v-checkbox
                  v-model="settings.reviews.suggested_reply"
                  label="Suggest public reply to guests"
                  :disabled="!settings.reviews.co_pilot"
                  dense
                  hide-details
                />
              </div>
              <v-divider class="my-3" />
              <v-row class="justify-space-between align-start mt-5">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Auto Pilot
                  </span>
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    v-model="settings.reviews.auto_pilot"
                    class="mt-0"
                    :disabled="!isDesignedVr"
                    hide-details
                    inset
                  />
                </v-col>
              </v-row>
              <div>
                <v-checkbox
                  v-model="settings.reviews.create_reviews_service_calls"
                  label="Create service calls on reviews for operations team"
                  :disabled="!settings.reviews.auto_pilot"
                  dense
                  hide-details
                />
              </div>
              <div>
                <v-checkbox
                  v-model="settings.reviews.auto_reply_to_guests"
                  :disabled="!settings.reviews.auto_pilot"
                  label="Auto reply to guests"
                  dense
                  hide-details
                />
              </div>
            </v-col>
          </v-row>
          <v-row v-if="selectedSettings === 'cs'">
            <v-col cols="12" sm="8" class="ml-3">
              <v-checkbox
                v-model="settings.log_to_note"
                label="Log to notes"
                dense
                hide-details
              />
            </v-col>
          </v-row>
          <v-row v-if="selectedSettings === 'cs'">
            <v-col cols="12" sm="8" class="ml-3">
              <div class="text-h6">AI Customer Support config</div>
              <v-row class="justify-space-between align-start">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Co Pilot
                  </span>
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    v-model="settings.cs.co_pilot"
                    class="ma-0"
                    hide-details
                    inset
                  />
                </v-col>
              </v-row>
              <div>
                <v-checkbox
                  v-model="settings.cs.suggested_response"
                  label="Suggested response"
                  :disabled="!settings.cs.co_pilot"
                  dense
                  hide-details
                />
              </div>
              <div>
                <v-checkbox
                  v-model="settings.cs.tag_conversations"
                  label="Tag conversation and update guest satisfaction score"
                  :disabled="!settings.cs.co_pilot"
                  dense
                  hide-details
                />
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-3" v-if="selectedSettings === 'cs'" />
          <v-row v-if="selectedSettings === 'cs' && isDesignedVr">
            <v-col cols="12" sm="8" class="ml-3">
              <v-row class="justify-space-between align-start">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Auto Pilot
                  </span>
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    v-model="settings.cs.auto_pilot"
                    class="ma-0"
                    hide-details
                    inset
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-row v-show="settings.cs.auto_pilot">
              <v-col
                cols="12"
                sm="8"
                class="ml-3"
                style="margin-bottom: -38px; margin-top: -15px"
              >
                <multi-property-picker
                  v-model="settings.cs.auto_pilot_listing_ids"
                  :disabled="!settings.cs.auto_pilot"
                />
              </v-col>
              <v-col cols="12" sm="8" class="ml-3">
                <div style="margin-bottom: 10px; font-size: 13px">
                  <strong>Work hours</strong>
                </div>
                <v-select
                  v-model="settings.cs.auto_pilot_start_time"
                  :items="timeEntries"
                  dense
                  outlined
                  :label="$t('From')"
                  style="margin-bottom: -10px"
                  :disabled="!settings.cs.auto_pilot"
                >
                </v-select>
                <v-select
                  v-model="settings.cs.auto_pilot_end_time"
                  :items="timeEntries"
                  dense
                  outlined
                  :label="$t('To')"
                  style="margin-bottom: -30px"
                  :disabled="!settings.cs.auto_pilot"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="8" class="ml-3">
                <div style="margin-bottom: 10px; font-size: 13px">
                  <strong>Work days</strong>
                </div>
                <div style="display: flex">
                  <div
                    v-for="day in csDays"
                    :class="`${dayItemClassFor(day)} ${
                      day.selected ? 'day-item-selected' : ''
                    } ${
                      settings.cs.auto_pilot
                        ? 'day-item-enabled'
                        : 'day-item-disabled'
                    }`"
                    :key="day.name"
                    @click="toggleCsDay(day)"
                  >
                    {{ day.name }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <!--            <v-col cols="12" sm="8" class="ml-3">-->
            <!--              <v-select-->
            <!--                v-model="settings.cs.auto_pilot_tone"-->
            <!--                :items="aiAgentTones"-->
            <!--                dense-->
            <!--                outlined-->
            <!--                :label="$t('Tone')"-->
            <!--                style="margin-bottom: -10px"-->
            <!--                :disabled="!settings.cs.auto_pilot"-->
            <!--              >-->
            <!--              </v-select>-->
            <!--              <v-select-->
            <!--                v-model="settings.cs.auto_pilot_answer_only_after"-->
            <!--                :items="aiAnswerOnlyAfter"-->
            <!--                dense-->
            <!--                outlined-->
            <!--                :label="$t('Answer only after')"-->
            <!--                style="margin-bottom: -10px"-->
            <!--                :disabled="!settings.cs.auto_pilot"-->
            <!--              >-->
            <!--              </v-select>-->
            <!--              <v-select-->
            <!--                v-model="settings.cs.auto_pilot_notify_slack_channel"-->
            <!--                :items="aiCsNotifySlackChannels"-->
            <!--                dense-->
            <!--                outlined-->
            <!--                :label="$t('Notify Slack Channel')"-->
            <!--                style="margin-bottom: -30px"-->
            <!--                :disabled="!settings.cs.auto_pilot"-->
            <!--              >-->
            <!--              </v-select>-->
            <!--            </v-col>-->
          </v-row>
          <v-row
            v-if="
              selectedSettings === 'cs' &&
              settings.cs_policies_with_defaults.length
            "
            style="width: 90%"
          >
            <v-col cols="12" class="ml-3">
              <v-row class="justify-space-between align-start mt-5">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    <strong>Customer Support Policies</strong>
                  </span>
                </v-col>

                <v-col class="text-end">
                  <v-btn
                    v-if="
                      !csPolicyGroups ||
                      !csPolicyGroups.find(e => e.generation_in_progress)
                    "
                    small
                    class="float-right"
                    alt="Generate policies"
                    @click="generateAllCsPolicies"
                  >
                    Generate policies
                  </v-btn>
                  <v-btn
                    v-else
                    disabled
                    small
                    class="float-right"
                    alt="Generate policies"
                  >
                    <v-icon class="rotating">mdi-loading</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-col md="auto" style="padding: 0" class="policy-tab">
                <v-tabs
                  v-model="selectedTab"
                  class="pa-0 ma-0"
                  style="background-color: white"
                  @change="csPolicyTopicChange"
                >
                  <v-tab v-for="item in csPolicyTabs" :key="item">{{
                    item
                  }}</v-tab>
                </v-tabs>
              </v-col>
              <div>
                <v-row
                  v-for="policyGroup in csPolicyGroups"
                  :key="`${policyGroup.topic} > ${policyGroup.subject}`"
                  class="mt-3"
                  style="margin-right: 0"
                >
                  <v-col
                    cols="12"
                    style="
                      display: flex;
                      padding: 0;
                      padding-left: 12px;
                      margin-top: 30px;
                    "
                  >
                    <div>
                      <strong>{{ policyGroup.subject }}</strong>
                    </div>
                    <v-btn
                      v-if="!policyGroup.generation_in_progress"
                      style="margin-left: auto"
                      small
                      alt="Generate policies"
                      @click="generateCsPolicy(policyGroup)"
                    >
                      Generate policy
                    </v-btn>
                    <v-btn
                      v-else
                      disabled
                      small
                      style="margin-left: auto"
                      alt="Generate policies"
                    >
                      <v-icon class="rotating">mdi-loading</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    style="padding: 0; padding-top: 10px; padding-left: 12px"
                  >
                    <div class="d-flex" style="flex-direction: column">
                      <div style="margin-top: 10px; margin-bottom: 20px">
                        <v-checkbox
                          v-model="policyGroup.requires_approval"
                          hide-details
                          :label="$t('Requires Approval')"
                        >
                        </v-checkbox>
                      </div>
                      <contractor-select
                        :value.sync="policyGroup.approver_id"
                        icon="$account_alt"
                        :label="$t('Approver')"
                        :enable-sug="true"
                      />
                      <v-textarea
                        v-model="policyGroup.description"
                        outlined
                        dense
                        hide-details
                        class="flex"
                        :placeholder="policyGroup.default_rule"
                        :label="$t('Description')"
                      />
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="selectedSettings === 'cs'">
            <v-col cols="12" class="ml-3">
              <v-row class="justify-space-between align-start mt-5">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    AI Agent Instructions
                  </span>
                </v-col>
              </v-row>
              <v-col md="auto" style="padding: 0">
                <div class="d-flex">
                  <v-textarea
                    v-model="settings.cs_agent_instructions"
                    outlined
                    dense
                    hide-details
                    class="flex"
                    rows="15"
                  />
                </div>
              </v-col>
            </v-col>
          </v-row>
          <v-row v-if="selectedSettings === 'cs'">
            <v-col cols="12" class="ml-3">
              <v-row class="justify-space-between align-start mt-5">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Add Note Trigger Rule
                  </span>
                </v-col>
              </v-row>
              <v-col md="auto" style="padding: 0">
                <div class="d-flex">
                  <v-textarea
                    v-model="settings.add_note_cs_trigger_rule"
                    outlined
                    dense
                    hide-details
                    class="flex"
                  />
                </div>
              </v-col>
            </v-col>
          </v-row>
          <v-row v-if="selectedSettings === 'sales'">
            <v-col cols="12" sm="8" class="ml-3">
              <v-checkbox
                v-model="settings.log_to_note"
                label="Log to notes"
                dense
                hide-details
              />
            </v-col>
          </v-row>
          <v-row v-if="selectedSettings === 'sales'">
            <v-col cols="12" sm="8" class="ml-3">
              <div class="text-h6">AI Sales config</div>
              <v-row class="justify-space-between align-start">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Co Pilot
                  </span>
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    v-model="settings.sales.co_pilot"
                    class="ma-0"
                    hide-details
                    inset
                  />
                </v-col>
              </v-row>
              <div>
                <v-checkbox
                  v-model="settings.sales.suggested_response"
                  label="Suggested response"
                  :disabled="!settings.sales.co_pilot"
                  dense
                  hide-details
                />
              </div>
              <div>
                <v-checkbox
                  v-model="settings.sales.tag_conversations"
                  label="Tag conversation and update guest satisfaction score"
                  :disabled="!settings.sales.co_pilot"
                  dense
                  hide-details
                />
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-3" v-if="selectedSettings === 'sales'" />
          <v-row v-if="selectedSettings === 'sales' && isDesignedVr">
            <v-col cols="12" sm="8" class="ml-3">
              <v-row class="justify-space-between align-start">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Auto Pilot
                  </span>
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    v-model="settings.sales.auto_pilot"
                    class="ma-0"
                    hide-details
                    inset
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-row v-show="settings.sales.auto_pilot">
              <v-col
                cols="12"
                sm="8"
                class="ml-3"
                style="margin-bottom: -38px; margin-top: -15px"
              >
                <multi-property-picker
                  v-model="settings.sales.auto_pilot_listing_ids"
                  :disabled="!settings.sales.auto_pilot"
                />
              </v-col>
              <v-col cols="12" sm="8" class="ml-3">
                <div style="margin-bottom: 10px; font-size: 13px">
                  <strong>Work hours</strong>
                </div>
                <v-select
                  v-model="settings.sales.auto_pilot_start_time"
                  :items="timeEntries"
                  dense
                  outlined
                  :label="$t('From')"
                  style="margin-bottom: -10px"
                  :disabled="!settings.sales.auto_pilot"
                >
                </v-select>
                <v-select
                  v-model="settings.sales.auto_pilot_end_time"
                  :items="timeEntries"
                  dense
                  outlined
                  :label="$t('To')"
                  style="margin-bottom: -30px"
                  :disabled="!settings.sales.auto_pilot"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="8" class="ml-3">
                <div style="margin-bottom: 10px; font-size: 13px">
                  <strong>Work days</strong>
                </div>
                <div style="display: flex">
                  <div
                    v-for="day in salesDays"
                    :class="`${dayItemClassFor(day)} ${
                      day.selected ? 'day-item-selected' : ''
                    } ${
                      settings.sales.auto_pilot
                        ? 'day-item-enabled'
                        : 'day-item-disabled'
                    }`"
                    :key="day.name"
                    @click="toggleSalesDay(day)"
                  >
                    {{ day.name }}
                  </div>
                </div>
              </v-col>
            </v-row>

            <!--            <v-col cols="12" sm="8" class="ml-3">-->
            <!--              <v-select-->
            <!--                v-model="settings.sales.auto_pilot_tone"-->
            <!--                :items="aiAgentTones"-->
            <!--                dense-->
            <!--                outlined-->
            <!--                :label="$t('Tone')"-->
            <!--                style="margin-bottom: -10px"-->
            <!--                :disabled="!settings.sales.auto_pilot"-->
            <!--              >-->
            <!--              </v-select>-->
            <!--              <v-select-->
            <!--                v-model="settings.sales.auto_pilot_answer_only_after"-->
            <!--                :items="aiAnswerOnlyAfter"-->
            <!--                dense-->
            <!--                outlined-->
            <!--                :label="$t('Answer only after')"-->
            <!--                style="margin-bottom: -10px"-->
            <!--                :disabled="!settings.sales.auto_pilot"-->
            <!--              >-->
            <!--              </v-select>-->
            <!--              <v-select-->
            <!--                v-model="settings.sales.auto_pilot_notify_slack_channel"-->
            <!--                :items="aiSalesNotifySlackChannels"-->
            <!--                dense-->
            <!--                outlined-->
            <!--                :label="$t('Notify Slack Channel')"-->
            <!--                style="margin-bottom: -30px"-->
            <!--                :disabled="!settings.sales.auto_pilot"-->
            <!--              >-->
            <!--              </v-select>-->
            <!--            </v-col>-->
          </v-row>
          <v-row
            v-if="
              selectedSettings === 'sales' &&
              settings.sales_policies_with_defaults.length
            "
            style="width: 90%"
          >
            <v-col cols="12" class="ml-3">
              <v-row class="justify-space-between align-start mt-5">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    <strong>Sales Policies</strong>
                  </span>
                </v-col>
                <v-col class="text-end">
                  <v-btn
                    v-if="
                      !salesPolicyGroups ||
                      !salesPolicyGroups.find(e => e.generation_in_progress)
                    "
                    class="float-right"
                    small
                    alt="Generate policies"
                    @click="generateAllSalesPolicies"
                  >
                    Generate policies
                  </v-btn>
                  <v-btn
                    v-else
                    disabled
                    class="float-right"
                    alt="Generate policies"
                  >
                    <v-icon class="rotating">mdi-loading</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-col md="auto" style="padding: 0" class="policy-tab">
                <v-tabs
                  v-model="selectedTab"
                  class="pa-0 ma-0"
                  style="background-color: white"
                  @change="salesPolicyTopicChange"
                >
                  <v-tab v-for="item in salesPolicyTabs" :key="item">{{
                    item
                  }}</v-tab>
                </v-tabs>
              </v-col>
              <div>
                <v-row
                  v-for="policyGroup in salesPolicyGroups"
                  :key="`${policyGroup.topic} > ${policyGroup.subject}`"
                  class="mt-3"
                  style="margin-right: 0"
                >
                  <v-col
                    cols="12"
                    style="
                      display: flex;
                      padding: 0;
                      padding-left: 12px;
                      margin-top: 30px;
                    "
                  >
                    <div>
                      <strong>{{ policyGroup.subject }}</strong>
                    </div>
                    <v-btn
                      v-if="!policyGroup.generation_in_progress"
                      style="margin-left: auto"
                      small
                      alt="Generate policies"
                      @click="generateSalesPolicy(policyGroup)"
                    >
                      Generate policy
                    </v-btn>
                    <v-btn
                      v-else
                      disabled
                      style="margin-left: auto"
                      alt="Generate policies"
                    >
                      <v-icon class="rotating">mdi-loading</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    style="padding: 0; padding-top: 10px; padding-left: 12px"
                  >
                    <div class="d-flex" style="flex-direction: column">
                      <div style="margin-top: 10px; margin-bottom: 20px">
                        <v-checkbox
                          v-model="policyGroup.requires_approval"
                          hide-details
                          :label="$t('Requires Approval')"
                        >
                        </v-checkbox>
                      </div>
                      <contractor-select
                        :value.sync="policyGroup.approver_id"
                        icon="$account_alt"
                        :label="$t('Approver')"
                        :enable-sug="true"
                      />

                      <v-textarea
                        v-model="policyGroup.description"
                        outlined
                        dense
                        hide-details
                        class="flex"
                        :placeholder="policyGroup.default_rule"
                        :label="$t('Description')"
                      />
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="selectedSettings === 'sales'">
            <v-col cols="12" class="ml-3">
              <v-row class="justify-space-between align-start mt-5">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    AI Agent Instructions
                  </span>
                </v-col>
              </v-row>
              <v-col md="auto" style="padding: 0">
                <div class="d-flex">
                  <v-textarea
                    v-model="settings.sales_agent_instructions"
                    outlined
                    dense
                    hide-details
                    class="flex"
                    rows="15"
                  />
                </div>
              </v-col>
            </v-col>
          </v-row>
          <v-row v-if="selectedSettings === 'sales'">
            <v-col cols="12" class="ml-3">
              <v-row class="justify-space-between align-start mt-5">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Add Note Trigger Rule
                  </span>
                </v-col>
              </v-row>
              <v-col md="auto" style="padding: 0">
                <div class="d-flex">
                  <v-textarea
                    v-model="settings.add_note_sales_trigger_rule"
                    outlined
                    dense
                    hide-details
                    class="flex"
                  />
                </div>
              </v-col>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <v-col cols="12" class="text-end">
        <v-btn
          v-if="settingsChange"
          color="primary"
          class="float-right"
          @click="save"
          >Save
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import isEmpty from 'lodash/isEmpty'
import axios from 'axios'
import MultiPropertyPicker from '../multi-property-picker.vue'
import ContractorSelect from '../contractor-select.vue'

export default {
  components: {
    MultiPropertyPicker,
    ContractorSelect,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  data() {
    return {
      settingsChange: false,
      settings: null,
      selectedSettings: 'reviews',
      selectedTab: 0,
      csPolicyDownload: false,
      salesPolicyDownload: false,
      csDays: [
        { name: 'SUN', selected: false },
        { name: 'MON', selected: false },
        { name: 'TUE', selected: false },
        { name: 'WED', selected: false },
        { name: 'THU', selected: false },
        { name: 'FRI', selected: false },
        { name: 'SAT', selected: false },
      ],
      salesDays: [
        { name: 'SUN', selected: false },
        { name: 'MON', selected: false },
        { name: 'TUE', selected: false },
        { name: 'WED', selected: false },
        { name: 'THU', selected: false },
        { name: 'FRI', selected: false },
        { name: 'SAT', selected: false },
      ],
    }
  },
  watch: {
    settings: {
      handler(newValue, oldValue) {
        if (!isEmpty(oldValue)) {
          this.settingsChange = true
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.settings = { ...this.settingsGetter }
    this.csDays.forEach(e => {
      e.selected = (this.settings.cs.auto_pilot_days || []).includes(e.name)
    })
    this.salesDays.forEach(e => {
      e.selected = (this.settings.sales.auto_pilot_days || []).includes(e.name)
    })
    this.csPolicyGroups = this.filterCsPolicyGroups()
    this.salesPolicyGroups = this.filterSalesPolicyGroups()
    this.timeEntries = this.generateTimeEntries()
    this.aiAgentTones = this.generateAiAgentTones()
    this.aiAnswerOnlyAfter = this.generateAiAnswerOnlyAfter()
    this.aiCsNotifySlackChannels = this.generateAiCsNotifySlackChannels()
    this.aiSalesNotifySlackChannels = this.generateAiSalesNotifySlackChannels()
  },
  computed: {
    settingsGetter() {
      return this.$store.state.AiConfigs
    },
    csPolicyTabs() {
      return Array.from(
        new Set(this.settings.cs_policies_with_defaults.map(e => e.topic))
      )
    },
    salesPolicyTabs() {
      return Array.from(
        new Set(this.settings.sales_policies_with_defaults.map(e => e.topic))
      )
    },
  },
  methods: {
    filterCsPolicyGroups() {
      return this.settings.cs_policies_with_defaults.filter(
        e => e.topic === this.csPolicyTabs[this.selectedTab]
      )
    },
    filterSalesPolicyGroups() {
      return this.settings.sales_policies_with_defaults.filter(
        e => e.topic === this.salesPolicyTabs[this.selectedTab]
      )
    },
    save() {
      this.$store.dispatch('updateAiConfigs', this.settings)
      this.settingsChange = false
    },
    csPolicyTopicChange() {
      this.csPolicyGroups = this.filterCsPolicyGroups()
    },
    salesPolicyTopicChange() {
      this.salesPolicyGroups = this.filterSalesPolicyGroups()
    },
    async generateAllCsPolicies() {
      this.settings.cs_policy_generation_in_progress = true
      const url = '/api/ai-configs/download_policies?department=cs&all=true'
      axios.get(url)
    },
    async generateCsPolicy(policy) {
      this.$set(policy, 'generation_in_progress', true)
      const url = `/api/ai-configs/download_policies?department=cs&subject=${policy.subject}&topic=${policy.topic}`
      axios.get(url)
    },
    async generateAllSalesPolicies() {
      this.settings.sales_policy_generation_in_progress = true
      const url = '/api/ai-configs/download_policies?department=sales&all=true'
      axios.get(url)
    },
    async generateSalesPolicy(policy) {
      this.$set(policy, 'generation_in_progress', true)
      const url = `/api/ai-configs/download_policies?department=sales&subject=${policy.subject}&topic=${policy.topic}`
      axios.get(url)
    },
    toggleCsDay(day) {
      if (!this.settings.cs.auto_pilot) {
        return
      }
      day.selected = !day.selected
      this.settings.cs.auto_pilot_days = this.csDays
        .filter(e => e.selected)
        .map(e => e.name)
      this.settingsChange = true
    },
    toggleSalesDay(day) {
      if (!this.settings.sales.auto_pilot) {
        return
      }
      day.selected = !day.selected
      this.settings.sales.auto_pilot_days = this.salesDays
        .filter(e => e.selected)
        .map(e => e.name)
      this.settingsChange = true
    },
    dayItemClassFor(day) {
      if (day.name === 'SUN') {
        return 'day-item first-day-item'
      } else if (day.name === 'SAT') {
        return 'day-item last-day-item'
      } else {
        return 'day-item'
      }
    },
    generateTimeEntries() {
      let entries = []
      for (let i = 0; i < 24; i++) {
        let hour = i < 10 ? `0${i}` : `${i}`
        entries.push(`${hour}:00`)
        entries.push(`${hour}:30`)
      }
      return entries
    },
    generateAiAgentTones() {
      return [
        'Relaxed, Sales-Oriented',
        'Professional, Informative',
        'Friendly, Customer Service',
        'Empathetic, Supportive',
        'Enthusiastic, Motivational',
        'Calm, Reassuring',
        'Authoritative, Commanding',
        'Playful, Entertaining',
        'Sincere, Heartfelt',
        'Analytical, Data-Driven',
      ]
    },
    generateAiAnswerOnlyAfter() {
      return [
        'Immediate',
        'Brief Thinking Period (1-2 seconds)',
        'Post-Analysis Delay (3-5 seconds)',
        'Randomized Response Time',
      ]
    },
    generateAiCsNotifySlackChannels() {
      return [
        'High Priority Ticket Alerts',
        'Customer Satisfaction Scores',
        'Frequent Issue Tracking',
        'Knowledge Base Updates',
        'Weekly Support Summary',
      ]
    },
    generateAiSalesNotifySlackChannels() {
      return [
        'AI Sales Inquiries',
        'Pipeline Status Updates',
        'Competitor Activity Insights',
      ]
    },
  },
}
</script>
<style>
.policy-tab .v-tabs-bar {
  background-color: white !important;
  margin-top: 35px;
}
.rotating {
  animation: rotation 2s infinite linear;
}
.day-item-selected {
  background-color: var(--v-primary-darken2) !important;
}
.day-item-disabled {
  cursor: default !important;
  opacity: 0.3;
}
.first-day-item {
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
}
.last-day-item {
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}
.day-item-enabled {
  cursor: pointer;
}
.day-item-enabled:hover {
  background-color: var(--v-primary-darken1) !important;
}
.day-item {
  color: white;
  padding: 20px;
  background-color: var(--v-primary-base);
  border: 0.5px solid white;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
