<template>
  <div v-if="reviewReport.stats" class="d-flex flex-column pb-4">
    <alerts-drawer :value="showAlerts" @update-listing-tasks="updateTasks" />

    <v-container fluid class="px-4">
      <v-row class="pl-2">
        <v-col cols="auto">
          <v-select
            v-model="regionFilter"
            :label="$t('Regions')"
            outlined
            hide-details
            dense
            clearable
            :items="listingRegions"
            small-chips
            @change="regionChanged"
          />
        </v-col>
      </v-row>
      <v-skeleton-loader v-if="!reviewReport.stats" type="date-picker" />

      <review-stats
        :show-gauge="true"
        :stats="reviewReport.stats"
        :listings="reviewReport.listings"
      />
      <report-today-stats
        :vacancy-counts="vacancyCounts"
        :stats="stats"
        :review-counts="reviewCounts"
      />
    </v-container>
    <div>
      <v-tabs
        v-model="section"
        :optional="true"
        slider-color="white"
        background-color="#f6f6f7"
        active-class="white"
        show-arrows
      >
        <v-tab href="#listings">
          <span class="text-capitalize">{{ $t('Listings') }}</span>
        </v-tab>
        <v-tab href="#service-calls">
          <span class="text-capitalize"
            >{{ $t('Service Calls') }} ({{ totalServiceCalls }})</span
          >
        </v-tab>
        <v-tab href="#pending-expenses">
          <span class="text-capitalize"
            >{{ $t('Pending Approval') }} ({{
              stats.pending_expenses_count + stats.pending_approval_count
            }})</span
          >
        </v-tab>
        <v-tab href="#pending-reviews">
          <span class="text-capitalize"
            >{{ $t('Pending Reviews') }} ({{
              stats.pending_reviews_count
            }})</span
          >
        </v-tab>
      </v-tabs>
      <v-container fluid>
        <v-progress-linear v-show="fetchingReportData" indeterminate />
        <div v-if="section === 'listings'">
          <v-row>
            <v-col cols="12">
              <v-btn-toggle
                v-model="taskFilter"
                color="primary"
                class="overflow-auto"
              >
                <v-btn :small="isMobile" value="checkinAnOutToday">
                  In & Out ({{ vacancyCounts['Check-in and Checkout today'] }})
                </v-btn>
                <v-btn :small="isMobile" value="checkoutToday">
                  Out ({{
                    vacancyCounts['Checkout today'] +
                    vacancyCounts['Check-in and Checkout today']
                  }})
                </v-btn>
                <v-btn :small="isMobile" value="checkinToday">
                  In ({{
                    vacancyCounts['Check-in today'] +
                    vacancyCounts['Check-in and Checkout today']
                  }})
                </v-btn>
                <v-btn :small="isMobile" value="freeToday">
                  Free ({{ vacancyCounts['Free today'] }})
                </v-btn>
                <v-btn :small="isMobile" value="occupiedToday">
                  Occupied ({{ vacancyCounts['Occupied'] }})
                </v-btn>
                <v-btn :small="isMobile" value="all-listings">
                  All ({{ vacancyCounts['all'] }})</v-btn
                >
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center mb-3">
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="listingFilter"
                outlined
                dense
                :label="$t('Filter Listings')"
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="d-flex justify-start justify-sm-end pa-0"
            >
              <v-radio-group v-model="typeToggle" row class="mt-0 mx-1">
                <v-radio class="mr-1" value="all">
                  <template #label>
                    <div :class="radioClass">All</div>
                  </template></v-radio
                >
                <v-radio class="mr-1" value="unit">
                  <template #label>
                    <div :class="radioClass">Unit</div>
                  </template></v-radio
                >
                <v-radio class="mr-1" value="regular">
                  <template #label>
                    <div :class="radioClass">Listing</div>
                  </template></v-radio
                >
              </v-radio-group>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="filteredListings"
            disable-pagination
            disable-sort
            mobile-breakpoint="0"
            fixed-header
            hide-default-footer
            height="70vh"
            @click:row="item => onReportSelect(item.id)"
          >
            <template #item.nickname="{ item }">
              <span>
                <v-icon v-if="item.is_dirty" color="error" left size="18"
                  >fas fa-broom</v-icon
                >
                <v-icon v-else left color="success" size="18"
                  >fas fa-broom</v-icon
                >
              </span>
              <span class="mx-1">{{ item.nickname }}</span>
            </template>
            <template #[`item.avg`]="{ item }">
              <div class="d-flex">
                {{ round(item.avg, 2) || '-' }}
                <span
                  :class="
                    prevListingAvgs[item.id] &&
                    prevListingAvgs[item.id].avg &&
                    prevListingAvgs[item.id].avg >= 9.6
                      ? 'success--text'
                      : 'grey--text'
                  "
                  class="text-secondary font-weight-regular text-caption ml-1"
                  >({{
                    prevListingAvgs[item.id]
                      ? round(prevListingAvgs[item.id].avg, 2)
                      : '-'
                  }})</span
                >
              </div>
            </template>
            <template #[`item.month_expense_ratio`]="{ item }">
              <div class="d-flex">
                {{ toPercent(item.month_expense_ratio) || '-' }}
                <span
                  :class="
                    item.last_month_expense_ratio &&
                    item.last_month_expense_ratio <= 50
                      ? 'success--text'
                      : 'grey--text'
                  "
                  class="text-secondary font-weight-regular text-caption ml-1"
                  >({{ toPercent(item.last_month_expense_ratio) || '-' }})</span
                >
              </div>
            </template>
          </v-data-table>
        </div>
        <div v-else-if="section === 'pending-reviews'">
          <v-btn-toggle
            v-model="reviewFilter"
            color="primary"
            class="overflow-auto"
          >
            <v-btn value="checkup"
              >{{ $t('Checkup') }} ({{
                reviewCounts.checkup.pos + reviewCounts.checkup.neg
              }})</v-btn
            >
            <v-btn value="survey"
              >{{ $t('Survey') }} ({{
                reviewCounts.survey.pos + reviewCounts.survey.neg
              }})</v-btn
            >
            <v-btn value="ota"
              >{{ $t('OTA') }} ({{
                reviewCounts.ota.pos + reviewCounts.ota.neg
              }})</v-btn
            >
            <v-btn value="all">{{ $t('All') }} ({{ reviewCounts.all }})</v-btn>
          </v-btn-toggle>
          <review-row
            v-for="review in reviews"
            :key="review.id"
            :review="review"
            @validate-review="validateReviewHandler"
          />
        </div>
        <div v-if="section === 'service-calls'">
          <v-row>
            <v-col cols="12">
              <v-select
                v-if="isMobile"
                v-model="taskFilter"
                dense
                outlined
                filled
                color="primary"
                item-color="primary"
                hide-details
                :label="$t('Filter')"
                :items="[
                  {
                    text: `${$t('Today Tasks')} (${stats.today_tasks_count})`,
                    value: 'today-tasks',
                  },
                  {
                    text: `${$t('My Tasks')} (${stats.my_tasks_count})`,
                    value: 'my-tasks',
                  },
                  {
                    text: `${$t('Unassigned')} (${stats.unassigned_count})`,
                    value: 'unassigned',
                  },
                  {
                    text: `${$t('Critical/High')} (${
                      stats.high_open_issues_count
                    })`,
                    value: 'high-open',
                  },
                  {
                    text: `${$t('Owner Approvals')} (${
                      stats.owner_approvals_count
                    })`,
                    value: 'owner-approvals',
                  },
                  {
                    text: `${$t('Review tasks')} (${stats.review_tasks_count})`,
                    value: 'review-tasks',
                  },
                  {
                    text: `${$t('Med/Low')} (${stats.med_low_issues_count})`,
                    value: 'med-low',
                  },
                  {
                    text: `${$t('Overdue')} (${stats.overdue_count})`,
                    value: 'overdue',
                  },
                  {
                    text: `${$t('Rejected')} (${stats.reject_count})`,
                    value: 'rejected',
                  },
                  {
                    text: `${$t('Claims')} (${stats.claims_count})`,
                    value: 'claims',
                  },
                ]"
              />
              <v-btn-toggle
                v-else
                v-model="taskFilter"
                color="primary"
                class="overflow-auto"
              >
                <v-btn :small="isMobile" value="today-tasks"
                  >{{ $t('Today Tasks') }} ({{
                    stats.today_tasks_count
                  }})</v-btn
                >
                <v-btn :small="isMobile" value="my-tasks"
                  >{{ $t('My Tasks') }} ({{ stats.my_tasks_count }})</v-btn
                >
                <v-btn :small="isMobile" value="unassigned"
                  >{{ $t('Unassigned') }} ({{ stats.unassigned_count }})</v-btn
                >
                <v-btn :small="isMobile" value="high-open"
                  >{{ $t('Critical/High') }} ({{
                    stats.high_open_issues_count
                  }})</v-btn
                >
                <v-btn :small="isMobile" value="owner-approvals"
                  >{{ $t('Owner Approvals') }} ({{
                    stats.owner_approvals_count
                  }})</v-btn
                >
                <v-btn :small="isMobile" value="review-tasks"
                  >{{ $t('Review tasks') }} ({{
                    stats.review_tasks_count
                  }})</v-btn
                >
                <v-btn :small="isMobile" value="med-low"
                  >{{ $t('Med/Low') }} ({{ stats.med_low_issues_count }})</v-btn
                >
                <v-btn :small="isMobile" value="overdue"
                  >{{ $t('Overdue') }} ({{ stats.overdue_count }})</v-btn
                >
                <v-btn :small="isMobile" value="rejected"
                  >{{ $t('Reject') }} ({{ stats.reject_count }})</v-btn
                >
                <v-btn :small="isMobile" value="claims"
                  >{{ $t('Claims') }} ({{ stats.claims_count }})</v-btn
                >
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="auto">
              <multi-property-picker
                v-model="filters.listings_id"
                :filter-change="updateData"
                :hide-details="true"
              />
            </v-col>
            <v-col cols="12" md="auto">
              <filters-select
                :label="$t('Status')"
                :value.sync="filters.status"
                :items="ltStatuses"
                @change="updateData"
              />
            </v-col>
            <v-col cols="12" md="auto">
              <filters-select
                :label="$t('Priority')"
                :value.sync="filters.priorities"
                :items="['Critical', 'High', 'Med', 'Low']"
                @change="updateData"
              />
            </v-col>
            <v-col cols="12" md="auto">
              <contractor-select
                :label="$t('Assignee')"
                :filter-change="contractorChange"
                :value.sync="filters.assigned_contractor_id"
              />
            </v-col>
            <v-col cols="12" md="auto">
              <catalog-quick-select
                :simple-mode="true"
                :filter-change="catalogChange"
                :value="filters.listing_task_catalog_item_id"
              />
            </v-col>
          </v-row>
          <maintenance-task
            v-for="task in listingTasks"
            v-show="listingTasks.length"
            :key="task.id"
            :is-selected="+taskId === task.id"
            :task="task"
            :nickname="task.listing.nickname"
          />
          <v-col
            v-show="isEmpty(listingTasks)"
            cols="12"
            class="mt-3 grey-text headline-font center-text mt-5 mb-5"
            >{{ $t('No tasks found') }}</v-col
          >
        </div>
        <div v-if="section === 'pending-expenses'">
          <v-btn-toggle
            v-model="expenseFilter"
            color="primary"
            class="overflow-auto"
          >
            <v-btn :small="isMobile" value="service-calls">
              Service Calls ({{ listingTasks.length }})
            </v-btn>
            <v-btn :small="isMobile" value="expenses">
              Expenses ({{ expenses.length }})
            </v-btn>
          </v-btn-toggle>
          <v-col v-if="expenseFilter === 'service-calls'" cols="12">
            <maintenance-task
              v-for="task in listingTasks"
              v-show="listingTasks.length"
              :key="task.id"
              :is-selected="+taskId === task.id"
              :task="task"
              :nickname="task.listing.nickname"
            />
            <v-col
              v-show="isEmpty(listingTasks)"
              cols="12"
              class="mt-3 grey-text headline-font center-text mt-5 mb-5"
              >{{ $t('No tasks found') }}</v-col
            >
          </v-col>
          <v-list v-if="expenseFilter === 'expenses'" class="text-left pt-0">
            <listing-expense
              v-for="expense in expenses"
              :key="expense.id"
              :validatable="expenseValidatable"
              :nickname="expense.listing.nickname"
              :expense="expense"
              @validate-expense="validateExpenseHandler"
              @on-inquiry="tabChange"
              @on-change="dataChange"
            />
            <v-col
              v-show="isEmpty(expenses)"
              cols="12"
              class="mt-3 grey-text headline-font center-text mt-5 mb-5"
              >{{ $t('No expenses found') }}</v-col
            >
          </v-list>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import FormattersMixins from 'components/mixins/formatters-mixin'
import NavigationMixin from 'components/mixins/navigation-mixin'

import { mapState, mapGetters, mapActions } from 'vuex'
import { keyBy } from 'lodash/fp'
import { sortBy } from 'lodash'
import ReviewStats from 'components/listing-report/review-stats.vue'
import ReviewRow from 'components/listing-report/review-row.vue'
import MaintenanceTask from 'components/listing-report/maintenance-task'
import ListingExpense from 'components/listing-report/listing-expense'
import ColorsMixin from 'components/mixins/colors-mixin'
import ReportTodayStats from 'components/listing-report/report-today-stats.vue'
import AlertsDrawer from 'components/listing-report/alerts-drawer.vue'
import AreaReport from 'components/mixins/area-report'
import FiltersSelect from 'components/form-fields/filters-select.vue'
import Common_functions from 'components/mixins/common_functions'
import MultiPropertyPicker from 'components/multi-property-picker.vue'
import ContractorSelect from 'components/contractor-select.vue'
import CatalogQuickSelect from 'components/catalog-quick-select.vue'

export default {
  name: 'ReviewReportPage',
  mixins: [
    FormattersMixins,
    NavigationMixin,
    ColorsMixin,
    AreaReport,
    Common_functions,
  ],
  components: {
    CatalogQuickSelect,
    ContractorSelect,
    MultiPropertyPicker,
    FiltersSelect,
    AlertsDrawer,
    ReportTodayStats,
    ListingExpense,
    MaintenanceTask,
    ReviewRow,
    ReviewStats,
  },
  data() {
    return {
      listingFilter: '',
      expenseFilter: 'service-calls',
      panel: [],
      filters: {
        status: [],
      },
      reviews: [],
      regionFilter: this.$route.query.region_ids,
      tabClass: 'text-body-2 font-weight-bold',
      issuesTabs: '',
      radioClass: 'text-caption text-capitalize font-weight-medium',
      typeToggle: 'all',
    }
  },
  async mounted() {
    this.regionFilter =
      +this.$route.query.region_ids ||
      +this.currentUser.region ||
      (this.listingRegions[0] ? this.listingRegions[0].value : null)

    if (!this.$route.query.region_ids) {
      this.$router.push({
        query: { region_ids: this.regionFilter, section: 'service-calls' },
      })
    } else {
      await this.getReviewReport({
        region_ids: this.regionFilter,
      })
      this.$nextTick(() => {
        this.updateData()
      })
    }
  },
  watch: {
    updatedReportTask(task) {
      if (task) {
        const index = this.report.listing_tasks.findIndex(
          lt => lt.id === task.id
        )
        if (index !== -1) {
          this.$set(this.report.listing_tasks, index, task)
        }
      }
    },
    paramsTab(value) {
      if (value === 'area-report' && !this.$route.query.section) {
        this.$router.push({
          query: { region_ids: this.regionFilter, section: 'service-calls' },
        })
      }
    },
    async quickTasksCount() {
      await this.getReviewReport({
        region_ids: this.regionFilter,
      })
      this.updateData()
    },
    async '$route.query.section'(newSection) {
      if (
        newSection === 'listings' &&
        ![
          'checkinToday',
          'checkoutToday',
          'checkinAnOutToday',
          'freeToday',
          'occupiedToday',
          'all-listings',
        ].includes(this.taskFilter)
      ) {
        this.taskFilter = 'checkinAnOutToday'
      } else if (newSection === 'service-callss') {
        this.taskFilter = 'today-tasks'
      } else if (newSection === 'pending-expenses') {
        this.taskFilter = 'pending-expenses'
        return this.updateData()
      }
    },
    async '$route.query.region_ids'() {
      await this.getReviewReport({ region_ids: this.regionFilter })
      this.updateData()
      this.updateReviews()
    },
    async '$route.query.filter'(newFilter) {
      if (
        this.section === 'listings' &&
        [
          'checkinToday',
          'checkoutToday',
          'checkinAnOutToday',
          'freeToday',
          'occupiedToday',
          'all-listings',
        ].includes(newFilter)
      ) {
        return
      }
      if (this.section === 'pending-reviews') {
        return this.updateReviews()
      }
      if (this.section === 'pending-expenses') return
      this.updateData()
    },
  },
  methods: {
    ...mapActions('listingReport', ['getListingReport', 'getReviewReport']),
    ...mapActions('expenses', ['validateExpense']),
    updateTasks() {
      this.section = 'pending-expenses'
    },
    onReportSelect(id) {
      this.$router.push({
        path: `/dashboard/service-calls/area-report/listing/${id}`,
        query: { section: 'service-calls', filter: 'my-tasks' },
      })
    },
    contractorChange(contractorID) {
      this.filters.assigned_contractor_id = contractorID
      this.updateData()
    },
    catalogChange(catalogID) {
      this.filters.listing_task_catalog_item_id = catalogID
      this.updateData()
    },
    updateData() {
      this.getListingReport({
        region_ids: this.regionFilter,
        filter:
          this.section === 'pending-expenses'
            ? 'pending-expenses'
            : this.taskFilter,
        ...this.filters,
      })
    },
    async updateReviews() {
      const filter = this.$route.query.filter
      const buckets = ['survey', 'checkup']
      if (buckets.includes(filter)) {
        return (this.reviews = this.reviewReport.reviews.filter(
          r => r.source === filter
        ))
      }
      if (filter === 'all') return (this.reviews = this.reviewReport.reviews)
      else {
        this.reviews = this.reviewReport.reviews.filter(
          r => !buckets.includes(r.source)
        )
      }
    },
    async validateExpenseHandler(id) {
      await this.validateExpense(id)
      await this.getListingReport({
        region_ids: this.regionFilter,
        filter: this.taskFilter,
      })
      this.getReviewReport({ region_ids: this.regionFilter })
    },
    async validateReviewHandler(id) {
      await this.$store.dispatch('approveReview', id)
      await this.getListingReport({
        region_ids: this.regionFilter,
        filter: this.taskFilter,
      })
      await this.getReviewReport({ region_ids: this.regionFilter })
      this.updateReviews()
    },
    regionChanged() {
      this.addToQuery({
        region_ids: this.regionFilter,
        section: this.section === 'all' ? 'listings' : this.section,
      })
    },
    tabChange() {
      this.getListingReport({
        region_ids: this.regionFilter,
        filter: this.taskFilter,
      })
    },
    dataChange() {
      this.getReviewReport({
        region_ids: this.regionFilter,
      })
      this.$nextTick(() => {
        this.updateData()
      })
    },
    toggleFilter(listing_type) {
      if (this.typeToggle === 'all') return true
      return this.typeToggle === 'regular'
        ? ['regular', 'hotel'].includes(listing_type)
        : this.typeToggle === listing_type
    },
    emptyStatsObject(object) {
      if (object) {
        return Object.values(object).every(val => val === 0)
      }
    },
  },
  computed: {
    ...mapState('listingReport', [
      'fetchingReportData',
      'report',
      'updatedReportTask',
    ]),
    ...mapGetters(['currentUser', 'quickTasksCount', 'listingsPicker']),
    ...mapState('regions', ['regions']),
    showAlerts() {
      return !!this.$route.query.alerts
    },
    expenseValidatable() {
      return !['client-refunds', 'supplies'].includes(this.section)
    },
    listingTasks() {
      return this.prioritize(this.report.listing_tasks)
    },
    expenses() {
      return this.report.expenses
    },
    headers() {
      const prevAvgs = this.reviewReport.prev_listing_avgs || []
      const nonUnitListings = this.reviewReport.listings.filter(
        l => l.listing_type !== 'unit'
      )
      return [
        { text: 'Listing', value: 'nickname', readonly: true, width: 1 },
        {
          text: `Rev. (${prevAvgs.filter(a => a.avg > 9.6).length} / ${
            nonUnitListings.length
          })`,
          value: 'avg',
          width: 100,
          readonly: true,
        },
        {
          text: `Exp. (${
            nonUnitListings.filter(
              a =>
                a.last_month_expense_ratio !== null &&
                a.last_month_expense_ratio < 50
            ).length
          } / ${nonUnitListings.length})`,
          value: 'month_expense_ratio',
          readonly: true,
          width: 100,
        },
      ]
    },
    listingAvgs() {
      return keyBy('id', this.reviewReport.listing_avgs)
    },
    prevListingAvgs() {
      return keyBy('id', this.reviewReport.prev_listing_avgs)
    },
    filteredListings() {
      let listings = this.reviewReport.listings.map(l => ({
        ...l,
        avg: this.listingAvgs[l.id] && this.listingAvgs[l.id].avg,
      }))
      listings = listings.filter(
        l =>
          (!this.regionFilter || this.regionFilter === l.region_id) &&
          this.toggleFilter(l.listing_type) &&
          l.nickname &&
          l.nickname.toLowerCase().includes(this.listingFilter.toLowerCase())
      )

      listings = sortBy(listings, ['nickname', 'avg'], ['asc'])

      const AVAILABILITIES = {
        checkinToday: ['Check-in today', 'Check-in and Checkout today'],
        checkoutToday: ['Checkout today', 'Check-in and Checkout today'],
        checkinAnOutToday: ['Check-in and Checkout today'],
        occupiedToday: ['Occupied'],
        freeToday: ['Free today'],
      }
      return this.taskFilter !== 'all-listings'
        ? listings.filter(
            l =>
              l.listing_type !== 'hotel' &&
              AVAILABILITIES[this.taskFilter].includes(
                l.today_avialability_text
              )
          )
        : listings
    },
    vacancyCounts() {
      return this.reviewReport.listings
        .filter(l => this.toggleFilter(l.listing_type))
        .reduce(
          (acc, curr) => {
            if (curr.listing_type !== 'hotel') {
              acc[curr.today_avialability_text] += 1
            }
            acc['all'] += 1
            return acc
          },
          {
            'Check-in today': 0,
            'Checkout today': 0,
            'Check-in and Checkout today': 0,
            'Free today': 0,
            Occupied: 0,
            all: 0,
          }
        )
    },
    reviewCounts() {
      return this.reviewReport.reviews.reduce(
        (acc, curr) => {
          const bucket = acc[curr.source] || acc.ota
          curr.normilized_scrore_overall >= 5 ? bucket.pos++ : bucket.neg++
          acc.all++
          return acc
        },
        {
          checkup: { pos: 0, neg: 0 },
          survey: { pos: 0, neg: 0 },
          ota: { pos: 0, neg: 0 },
          all: 0,
          reviewTasks: this.stats.review_tasks_count,
        }
      )
    },
    listingRegions() {
      return this.regions.map(r => ({
        text: r.name,
        value: r.id,
      }))
    },
    reviewReport() {
      return this.$store.state.listingReport.regionReportStats
    },
  },
}
</script>

<style scoped>
.icon {
  order: 0;
}
::v-deep .v-progress-circular__overlay {
  stroke-linecap: round;
}
.header {
  order: 1;
}
</style>
