<template>
  <div class="comp-wrapper">
    <FullCalendar id="pricing-cal" ref="calendar" :options="config">
      <template #dayCellContent="{ date }">
        <div class="text-end">
          <span class="text-caption">{{ $moment(date).format('DD') }}</span>
          <v-tooltip
            bottom
            color="darkpurple"
            :disabled="!hasPricing(date)"
            min-width="300"
          >
            <template #activator="{ on }">
              <v-row
                no-gutters
                class="text-center text-subtitle-1 pricing-tag justify-center align-center font-weight-medium"
                v-on="on"
              >
                <v-col cols="12"> {{ dollarFormatter(getPrice(date)) }} </v-col>
                <v-col
                  v-if="amountOfUnitsText(date) || minNights(date)"
                  cols="12"
                  class="d-flex justify-space-between align-center px-1"
                >
                  <div v-show="amountOfUnitsText(date)" class="text-caption">
                    <v-icon color="white" x-small>fas fa-home</v-icon>
                    <span class="ml-1">{{ amountOfUnitsText(date) }}</span>
                  </div>
                  <div v-show="minNights(date)" class="text-caption">
                    <v-icon color="white" x-small>fas fa-moon</v-icon>
                    {{ minNights(date) }}
                  </div>
                </v-col>
                <v-col v-if="diff(date)" cols="12">
                  <span class="mx-1 text-caption">Diff</span>
                  <span>({{ diff(date) }})</span>
                </v-col>
              </v-row>
            </template>
            <DayPriceInfo
              v-if="hasPricing(date)"
              :rate="hasPricing(date)"
              :date="formatDateRate(date)"
            />
          </v-tooltip>
        </div>
      </template>
    </FullCalendar>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import round from 'lodash/fp/round'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import getOr from 'lodash/fp/getOr'
import DayPriceInfo from 'components/day-price-info'
import { mapState } from 'vuex'
import FormattersMixin from 'components/mixins/formatters-mixin'
export default {
  name: 'PricingCalendar',
  components: {
    DayPriceInfo,
    FullCalendar,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: ['calendar'],

  mounted() {
    let calApi = this.calendar
    if (!calApi) {
      calApi = this.$refs.calendar.getApi()
      this.$emit('update:calendar', calApi)
    }
    if (calApi) {
      $('#pricing-cal button.fc-button-primary').on('click', () => {
        this.$store.dispatch('getPricingMonthlyStats', {
          date: calApi.view.title,
          listingId: this.currentPricing.listing_id,
        })
        this.$store.dispatch('getPricingYearlyStats', calApi.view.title)
      })
    }
    this.$store.dispatch('getPricingMonthlyStats', {
      date: calApi.view.title,
      listingId: this.currentPricing.listing_id,
    })
    this.$store.dispatch('getPricingYearlyStats', calApi.view.title)
    setTimeout(() => {
      calApi.updateSize()
    }, 500)
  },
  computed: {
    ...mapState(['currentPricing']),
    config() {
      const classByDateRate = this.classByDateRate
      return {
        plugins: [dayGridPlugin],
        themeSystem: 'Yeti',
        initialView: 'dayGridMonth',
        aspectRatio: 2,
        dayCellClassNames({ date }) {
          return classByDateRate(date)
        },
        eventColor: 'transparent',
      }
    },
  },
  methods: {
    classByDateRate(date) {
      if (this.currentPricing.days_rates[this.formatDateRate(date)]) {
        const day = this.currentPricing.days_rates[this.formatDateRate(date)]

        if (day['status'] === 'available') {
          let styleClass = 'white--text'
          const ratio = day['price'] / day['base_price']
          if (ratio < 0.9) {
            styleClass += ' price-low'
          } else if (ratio < 1.05) {
            styleClass += ' price-mid'
          } else if (ratio < 1.2) {
            styleClass += ' price-high'
          } else {
            styleClass += ' price-very-high'
          }
          return styleClass
        } else if (day['status'] === 'success') {
          return 'cyan-icon primary--text'
        } else if (day['status'] === 'unavailable') {
          return 'error--text  blocked-cal'
        }
        return ''
      }
    },
    hasPricing(date) {
      return this.currentPricing.days_rates[this.formatDateRate(date)]
    },
    formatDateRate(date) {
      return this.$moment(date).format('YYYY-MM-DD')
    },
    diff(date) {
      const day = this.currentPricing.days_rates[this.formatDateRate(date)]
      if (day) {
        return round(day['price'] - day['beyond_pricing'])
      }
      return null
    },
    amountOfUnitsText(date) {
      const day = this.currentPricing.days_rates[this.formatDateRate(date)]
      if (day && day['available_count']) {
        return `${day['available_count']}`
      } else {
        return null
      }
    },
    minNights(date) {
      const day = this.currentPricing.days_rates[this.formatDateRate(date)]

      return day && day.min_days
    },
    getPrice(date) {
      return round(
        getOr(
          '',
          'price',
          this.currentPricing.days_rates[this.formatDateRate(date)]
        )
      )
    },
  },
  data() {
    return {
      tooltip: false,
    }
  },
}
</script>
<style lang="scss">
.pricing-tag {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}
.price-very-high {
  background-color: darken(#7ebac0, 30%);
}

.price-high {
  background-color: darken(#7ebac0, 15%);
}

.price-mid {
  background-color: #7ebac0;
}

.price-low {
  background-color: lighten(#7ebac0, 5%);
}
.blocked-cal {
  background-color: #8080801f !important;
}
</style>
