var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('expense-modal',{attrs:{"edit":true,"edit-expense":_vm.editExpense},on:{"on-submit":_vm.handleOnExpenseUpdate}}),_c('v-card',{staticClass:"overflow-y-auto"},[_c('v-card-title',[(_vm.isAdmin && _vm.isDesignedVr)?_c('p',{staticClass:"small-text"},[_vm._v(" "+_vm._s(_vm.expense.id)+" ")]):_vm._e(),_c('expense-card-header',{attrs:{"expense-id":_vm.expense.id,"expense":_vm.expense,"listing-name":_vm.expense.listing && _vm.expense.listing.nickname,"title":"Expenses Details","sub-title":_vm.expense.sub_topic,"hide-actions":_vm.isInExpensePage || _vm.isMobile,"delete-enabled":_vm.deleteEnabled,"edit-enabled":_vm.editEnabled},on:{"delete-expense":_vm.handleDeleteExpense}})],1),_c('v-divider'),_c('div',{staticClass:"pa-6"},[(_vm.expense.type === 'BusinessExpense')?_c('span',[_vm._v(" * Company expense *")]):_vm._e(),(_vm.expense.unit_number)?_c('div',[_c('h5',{staticClass:"cyan-icon"},[_vm._v(_vm._s(_vm.expense.unit_number))])]):_vm._e(),_c('expense-card-title',{attrs:{"title":_vm.$t(_vm.expenseType),"show-link":!_vm.isInvestor &&
          (_vm.expense.listing_task_id ||
            _vm.expense.material_task_id ||
            _vm.expense.work_info.req_id ||
            _vm.expense.work_info.lt_id),"lt-id":_vm.expense.listing_task_id ||
          _vm.expense.material_task_id ||
          _vm.expense.work_info.lt_id,"req-id":_vm.expense.work_info.req_id}}),(_vm.description)?_c('div',[_c('div',{staticClass:"text-caption"},[_vm._v("Description")]),_c('div',[_vm._v(_vm._s(_vm.description))])]):_vm._e(),(_vm.managementRoles && _vm.listingTask.approval_comments)?_c('div',[_c('h5',{staticClass:"cyan-icon"},[_vm._v("approval comments")]),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.listingTask.approval_comments))])]):_vm._e()],1),_c('v-divider'),_c('div',{staticClass:"pa-6"},[_c('expense-card-info',{attrs:{"created-by-name":_vm.isInvestor ? null : _vm.expense.created_by_name,"paid-at":_vm.parseDate(_vm.paidAt),"done-at":_vm.parseDate(_vm.doneAt),"material-cost":_vm.materialsSum,"management-fee":_vm.listingTask.management_fee,"labor-cost":_vm.expense.amount}})],1),_c('v-divider'),(_vm.descriptionImages.length)?_c('div',{staticClass:"pa-6"},[_c('div',{staticClass:"font-weight-medium"},[_vm._v("Media")]),_c('gallery',{attrs:{"thumbnails":true,"cols":4,"images":_vm.descriptionImages,"readonly":true}})],1):_vm._e(),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(_vm.listingTask.desc_images),expression:"listingTask.desc_images"}]}),(!_vm.imageSrc && _vm.expense.attached_image)?_c('div',{staticClass:"pa-6"},[_vm._v(" Images are uploading "),_c('v-progress-circular',{staticClass:"ml-2",attrs:{"size":20,"indeterminate":"","color":"primary"}})],1):_vm._e(),(!_vm.isEmpty(_vm.expense.work_info.items))?_c('div',[_c('expense-card-storage-items',{attrs:{"list":_vm.expense.work_info.items}})],1):_vm._e(),(!_vm.isEmpty(_vm.listingTask.sub_tasks) || !_vm.isEmpty(_vm.subTasks))?_c('div',{staticClass:"pa-6"},[_c('div',{staticClass:"font-weight-medium pb-4"},[_vm._v("Subtasks:")]),_c('expense-card-subtasks',{attrs:{"list":_vm.listingTask.sub_tasks}})],1):_c('v-row',{staticClass:"mx-0 pa-6"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[(
            !_vm.isEmpty(_vm.listingTask.before_images) || _vm.listingTask.before_comments
          )?_c('div',{staticClass:"flex"},[_c('p',{staticClass:"font-weight-medium"},[_vm._v("Before job:")]),_c('p',[_vm._v(_vm._s(_vm.listingTask.before_comments))]),_c('gallery',{attrs:{"thumbnails":true,"images":_vm.listingTask.before_images,"readonly":true}})],1):_vm._e()]),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[(
            !_vm.isEmpty(_vm.listingTask.after_images) || _vm.listingTask.after_comments
          )?_c('div',{staticClass:"flex"},[_c('p',{staticClass:"font-weight-medium"},[_vm._v("After job:")]),_c('p',[_vm._v(_vm._s(_vm.listingTask.after_comments))]),_c('gallery',{attrs:{"thumbnails":true,"images":_vm.listingTask.after_images,"readonly":true}})],1):_vm._e()])],1),_c('v-divider'),(!_vm.isEmpty(_vm.listingTask.receipt_images))?_c('div',{staticClass:"py-6 px-8"},[_c('p',{staticClass:"font-weight-medium"},[_vm._v("Receipt images:")]),_c('gallery',{attrs:{"thumbnails":true,"images":_vm.listingTask.receipt_images,"cols":4,"readonly":true}})],1):_vm._e(),(_vm.hasAbility('hide-expense') && _vm.isDesignedVr)?_c('v-card-actions',{staticClass:"d-flex justify-space-between"},[_c('expense-actions',{attrs:{"expense":_vm.expense},on:{"action":_vm.updateExpense}}),_c('div',[(_vm.isDesignedVr)?_c('inquiry-expense-modal',{attrs:{"expense-id":_vm.expense.id,"disabled":_vm.canDeleteFinancialData(_vm.expense.paid_at, _vm.currentUser) ||
            !_vm.expense.user_id},on:{"on-inquiry":_vm.fetchData}}):_vm._e(),(_vm.isAdmin || _vm.isFieldPm)?_c('v-btn',{staticClass:"green--text font-weight-bold ml-2",attrs:{"depressed":"","disabled":_vm.expense.validated_by_id,"small":"","color":"green lighten-5"},on:{"click":function($event){$event.stopPropagation();return _vm.validateExpenseHandler.apply(null, arguments)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-check-circle-outline")]),_vm._v(" Validate")],1):_vm._e()],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }