function loadAsyncScript(url, options = {}) {
  const script = document.createElement('script')
  if (options.id) script.id = options.id
  script.setAttribute('async', '')
  script.setAttribute('defer', '')
  script.src = url
  script.onload = options.callback
  document.getElementsByTagName('head')[0].appendChild(script)
}

function loadGmaps() {
  return new Promise(resolve => {
    setTimeout(() => {
      const gmapsKey = 'AIzaSyCzvYaJgsS0jhNhEjzREy_G9Quie3zc7A4'
      loadAsyncScript(
        `https://maps.googleapis.com/maps/api/js?key=${gmapsKey}&libraries=places`,
        { callback: resolve }
      )
    }, 500)
  })
}

export { loadGmaps, loadAsyncScript }
