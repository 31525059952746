<template>
  <v-card mt-5>
    <v-progress-linear
      v-show="loading"
      color="secondary"
      class="ma-0"
      :indeterminate="true"
    />
    <v-card-title>
      <span class="text-h5">Bank Accounts</span>
    </v-card-title>
    <v-card-text>
      <v-divider />
      <v-container grid-list-md>
        <v-row>
          <v-col cols="6">
            <bank-account-picker
              v-model="listing.bank_account_id"
              label="Listing Bank Account"
              :clearable="true"
              :outlined="true"
            />
          </v-col>
          <v-col v-if="listing.bank_account" cols="6" class="grey-text">
            Status: {{ listing.bank_account.status }} <br />
            Account name: {{ listing.bank_account.account_name }} <br />
            Account id: {{ listing.bank_account.account_id }} <br />
            Source: {{ listing.bank_account.source }}
          </v-col>
        </v-row>
        <v-divider />
        <v-row>
          <v-col cols="6">
            <bank-account-picker
              v-model="listing.owner_bank_account_id"
              label="Listing Owner Bank Account"
              :clearable="true"
              :outlined="true"
            />
          </v-col>
          <v-col v-if="listing.owner_bank_account" cols="6" class="grey-text">
            Status: {{ listing.owner_bank_account.status }} <br />
            Account name: {{ listing.owner_bank_account.account_name }} <br />
            Account id: {{ listing.owner_bank_account.account_id }} <br />
            Source: {{ listing.owner_bank_account.source }}
          </v-col>
        </v-row>
        <v-divider
          v-if="currentEditListing && currentEditListing.referrer_percent"
        />
        <v-row v-if="currentEditListing && currentEditListing.referrer_percent">
          <v-col cols="6">
            <bank-account-picker
              v-model="listing.referrer_bank_account_id"
              label="Listing Referrer Bank Account"
              :clearable="true"
              :outlined="true"
            />
          </v-col>
          <v-col
            v-if="listing.referrer_bank_account"
            cols="6"
            class="grey-text"
          >
            Status: {{ listing.referrer_bank_account.status }} <br />
            Account name: {{ listing.referrer_bank_account.account_name }}
            <br />
            Account id: {{ listing.referrer_bank_account.account_id }} <br />
            Source: {{ listing.referrer_bank_account.source }}
          </v-col>
        </v-row>
      </v-container>
      <v-divider />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="blue darken-1" text @click.native="save">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import formRules from 'components/mixins/form-rules-mixin'
import BankAccountPicker from 'components/accounting/bank-account-picker.vue'
import { cloneDeep } from 'lodash'

export default {
  components: { BankAccountPicker },
  mixins: [formRules],
  data() {
    return {
      loading: false,
      listing: {},
    }
  },
  mounted() {
    this.listing = cloneDeep(this.currentEditListing)
  },
  computed: {
    ...mapState(['currentEditListing']),
  },
  watch: {
    currentEditListing(val) {
      this.listing = cloneDeep(val)
    },
  },
  methods: {
    save() {
      this.loading = true
      axios
        .post(`/listings/${this.currentEditListing.id}/bank-accounts`, {
          bank_account_id: this.listing.bank_account_id,
          owner_bank_account_id: this.listing.owner_bank_account_id,
          referrer_bank_account_id: this.listing.referrer_bank_account_id,
        })
        .then(response => (this.listing = cloneDeep(response.data)))
        .catch(alert)
        .finally(() => (this.loading = false))
    },
  },
}
</script>
