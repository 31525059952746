<template>
  <v-dialog v-model="dialog" max-width="500">
    <template #activator="{ on }">
      <v-btn color="primary" elevation="0" class="text-capitalize" v-on="on">
        Reset Credentials
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Reset User Creds</v-card-title>
      <v-form ref="form" @submit.prevent="resetCreds">
        <v-card-text>
          <v-text-field
            v-model="oldEmail"
            type="email"
            label="Email"
            outlined
            readonly
            dense
          />
          <v-text-field
            v-model="email"
            type="email"
            label="New Email"
            outlined
            :rules="[required]"
            :error-messages="errors"
            dense
          />
          <v-text-field
            v-model="password"
            outlined
            label="Password"
            type="password"
            hide-details
            readonly
            :rules="[required]"
            dense
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn small outlined color="primary" @click="closeModal"
            >Cancel</v-btn
          >
          <v-btn small type="submit" color="primary">Submit</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import formRules from 'components/mixins/form-rules-mixin'
import CommonFunctions from 'components/mixins/common_functions'

export default {
  name: 'ResetCredentialsModal',
  mixins: [formRules, CommonFunctions],
  props: ['currentEmail', 'conversationId'],
  data() {
    return {
      oldEmail: this.currentEmail,
      email: null,
      password: null,
      dialog: false,
      errors: null,
    }
  },
  watch: {
    currentEmail(val) {
      this.$refs.form.reset()
      this.oldEmail = val
      this.password = this.generatePassword()
    },
  },
  mounted() {
    this.password = this.generatePassword()
  },
  methods: {
    async resetCreds() {
      if (this.$refs.form.validate()) {
        const { oldEmail, password, email } = this
        try {
          await this.$store.dispatch('users/resetUserCredentials', {
            old_email: oldEmail,
            password,
            email,
            conversation_id: this.conversationId,
          })
          this.closeModal()
        } catch (err) {
          this.errors = [err.response.data.error]
        }
      }
    },
    closeModal() {
      this.dialog = false
      this.email = null
      this.errors = null
    },
  },
}
</script>

<style scoped></style>
