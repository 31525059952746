<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template #activator="{ on }">
      <v-col cols="12" class="pa-0" v-on="!tooltip && !disabled && on">
        <v-row class="mt-4 mb-4 ml-2 mr-2 task-row">
          <span :class="['pa-0', 'availability', availabilityClass]" />
          <v-col cols="12" md="9" class="pr-1">
            <div class="font-weight-bold d-flex mb-1 justify-space-between">
              <div class="ellipsis-1 mr-2">
                <div>{{ task.mini_extra_data.listing_name }}</div>
              </div>
            </div>
            <div class="ellipsis-3 text-start">
              {{ task.description }}
            </div>
          </v-col>
          <v-col cols="12" md="3" class="text-end text-caption px-0">
            <task-reservation-tooltip
              v-if="task.mini_extra_data.next_res_info"
              :tooltip.sync="tooltip"
              :reservation="task.mini_extra_data.next_res_info"
              @show-tooltip="showTooltip"
              @hide-tooltip="hideTooltip"
            />
            <div class="text--secondary mx-1">
              <tag
                :class="['d-inline-block ml-1']"
                color="black"
                bg-color="lightgrey"
              >
                {{ availabilityText }}
              </tag>
            </div>
          </v-col>
          <v-col class="pt-0 pb-2 px-2 mx-1" cols="12">
            <div class="text-caption d-flex">
              <v-icon class="mr-1" :color="priorityColor(task.priority)" small
                >mdi-circle</v-icon
              >
              <span class="mr-3">
                {{ task.priority }}
              </span>

              <span v-if="task.allDay" class="font-light">All Day</span>
              <span v-else class="font-light">
                {{ startTime }} - {{ endTime }}</span
              >
              <v-spacer></v-spacer>
              <tag
                class="mr-1"
                :bg-color="listingTaskStatusColor(task.status)"
                :class="['d-inline-block ml-1']"
                color="white"
              >
                {{ task.status }}
              </tag>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </template>
    <v-card>
      <v-card-title class="text-body-1 word-break d-flex align-baseline">
        <div class="flex-grow-1">{{ task.mini_extra_data.listing_name }}</div>
        <div class="text-end" style="margin-bottom: -20px">
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-subtitle class="text-body-1 pt-1">
        {{ task.description }}
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="6" class="text-center">
            <v-btn
              depressed
              color="primary"
              class="task-button"
              @click="openTask"
            >
              <v-icon x-large>mdi-clipboard-text-outline</v-icon>
            </v-btn>
            <div class="font-weight-bold mt-2">Service Call</div>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn
              depressed
              color="yellow"
              class="task-button"
              @click="directionsTo(task)"
            >
              <v-icon x-large>mdi-google-maps</v-icon>
            </v-btn>
            <div class="font-weight-bold mt-2">Directions</div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FormattersMixin from 'components/mixins/formatters-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import ColorsMixin from 'components/mixins/colors-mixin'
import LtMixin from 'components/mixins/lt-mixin'

import DeviceMixin from 'components/mixins/device-mixin'
import Tag from 'components/common/tag.vue'
import TaskReservationTooltip from 'components/todays-tasks/task-reservation-tooltip.vue'

export default {
  components: { Tag, TaskReservationTooltip },
  mixins: [ColorsMixin, DeviceMixin, FormattersMixin, CommonFunctions, LtMixin],
  props: ['task', 'disabled'],
  data() {
    return {
      dialog: null,
      tooltip: false,
    }
  },
  computed: {
    availabilityText() {
      const availabilityText =
        this.availabilityTextMap[this.task.add_today_avialability_text]
      if (this.checkOutType) {
        return `${this.checkOutType} ${availabilityText}`
      } else if (this.checkInType) {
        return `${this.checkInType} ${availabilityText}`
      } else {
        return availabilityText
      }
    },
    startTime() {
      return this.parseDate(this.task.scheduled_at, 'h:mm')
    },
    endTime() {
      return this.parseDate(this.task.scheduled_at_end, 'h:mm')
    },
    plannedDeparture() {
      return this.extraData.late_checkout_purchase
    },
    plannedArrival() {
      return this.extraData.early_check_in_purchase
    },
    originArrival() {
      return this.extraData.origin_check_in_time
    },
    originDeparture() {
      return this.extraData.origin_check_out_time
    },
    checkInType() {
      if (this.plannedArrival && this.plannedArrival !== this.originArrival) {
        return +this.plannedArrival.split(':')[0] <
          +this.originArrival.split(':')[0]
          ? 'ECI'
          : 'LCI'
      }
      return ''
    },
    checkOutType() {
      if (
        this.plannedDeparture &&
        this.plannedDeparture !== this.originDeparture
      ) {
        return +this.plannedDeparture.split(':')[0] >
          +this.originDeparture.split(':')[0]
          ? 'LCO'
          : 'ECO'
      }
      return ''
    },
    availabilityClass() {
      if (this.checkOutType) {
        return `${this.checkOutType} ${this.task.add_today_avialability_text}`
      } else if (this.checkInType) {
        return `${this.checkInType} ${this.task.add_today_avialability_text}`
      } else {
        return this.task.add_today_avialability_text
      }
    },
    extraData() {
      return this.task.mini_extra_data || this.task.extra_data || {}
    },
  },
  methods: {
    openTask() {
      const url = `/dashboard/listing-task/${this.task.id}`
      this.$router.push(url)
    },
    showTooltip() {
      this.tooltip = true
      this.$emit('update:tooltip', true)
    },
    hideTooltip() {
      this.tooltip = false
      this.$emit('update:tooltip', false)
    },
  },
}
</script>
<style scoped>
.task-button {
  height: 80px !important;
  width: 80px !important;
}
.word-break {
  word-break: initial;
}
.task-row {
  position: relative;
}
.availability {
  width: 4px;
  height: 100%;
  position: absolute;
  border-radius: 0;
}
</style>
