<template>
  <v-dialog v-model="storeDialog" persistent>
    <template #activator="{ on, attrs }">
      <v-btn
        v-if="hasAbility('create-store-purchase')"
        depressed
        :disabled="disabled"
        v-bind="attrs"
        color="pink"
        class="ma-5 white--text"
        v-on="on"
        ><v-icon small class="mr-1">mdi-gift-outline</v-icon> Addons Store
      </v-btn>
    </template>
    <v-card>
      <div class="d-flex flex-column align-end">
        <v-btn class="ma-3 p-fixed" icon @click="closeStore"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <iframe
          v-if="storeDialog"
          title="Addons Store"
          class="store-iframe"
          :src="storePortalPath"
          frameborder="0"
          allowFullScreen="true"
          Content-Security-Policy="frame-ancestors 'self'"
        ></iframe>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'

export default {
  mixins: [CommonFunctions],
  props: ['disabled', 'confirmationCode'],
  data() {
    return { storeDialog: null }
  },
  computed: {
    storePortalPath() {
      return `${process.env.VUE_APP_TOURIST_PORTAL_URL}/tourist/store/reservation/${this.confirmationCode}?externalCheckout=true`
    },
  },
  methods: {
    closeStore() {
      this.$emit('close-store')
      this.storeDialog = null
    },
  },
}
</script>
<style>
.store-iframe {
  width: 100%;
  height: 100vh;
}
</style>
