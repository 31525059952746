<template>
  <v-dialog v-model="dialog" persistent max-width="800">
    <template #activator="{ on }">
      <div>
        <v-btn v-if="!quickMode" dark color="info" v-on="on"
          >New setup listing
        </v-btn>
        <v-list-item v-else link v-on="on">
          <div @click="menu = false">
            <v-list-item-title class="text-body-2">
              <v-icon class="mr-2">$home</v-icon>
              {{ $t('New Listing') }}
            </v-list-item-title>
          </div>
        </v-list-item>
      </div>
    </template>
    <v-card>
      <v-card-title class="pa-6">
        <div class="p-relative flex text-center">
          <h2 class="text-h6">Add new listing</h2>
          <v-icon class="close-button" @click="close">close</v-icon>
        </div>
      </v-card-title>
      <v-divider />
      <v-form>
        <v-card-text class="px-6">
          <p class="text-body-1 font-weight-medium">Listing details</p>
          <div class="mb-6">
            <v-select
              v-model="setupListing.listing_type"
              :items="['hotel', 'regular']"
              label="Listing Type"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details
              @change="typeChanged"
            />
          </div>
          <div v-if="isHotel" class="mb-6">
            <multi-property-picker
              v-model="setupListing.units"
              :close-on-content-click="false"
            />
          </div>
          <div class="mb-6">
            <v-select
              v-model="setupListing.stage"
              :items="['setup', 'regular', 'long-term']"
              label="Listing stage"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details
            />
          </div>
          <div class="mb-6">
            <v-text-field
              v-model="setupListing.address"
              label="Address"
              outlined
              required
              dense
              hide-details
            />
            <span v-if="$v.address.$error" class="warning--text">
              {{ $t('Required field') }}
            </span>
          </div>
          <div class="mb-3">
            <v-select
              v-model="setupListing.investor_id"
              :items="investors"
              label="Investor"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details
            />
          </div>
          <v-row>
            <v-col v-if="setupListingStage" cols="12" sm="6">
              <v-select
                v-model="setupListing.status"
                :items="[
                  'Upcoming',
                  'Pre Setup',
                  'Setup',
                  'Construction',
                  'Design',
                  'Done',
                ]"
                label="Setup Status"
                outlined
                dense
                hide-details
                required
              />
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                v-model="setupListing.baths"
                label="Baths"
                prepend-inner-icon="$shower"
                outlined
                dense
                hide-details
                required
              />
              <span v-if="$v.baths.$error" class="warning--text">
                {{ $t('Required field') }}
              </span>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                v-model="setupListing.beds"
                label="Beds"
                prepend-inner-icon="$bed_doubled"
                required
                outlined
                dense
                hide-details
              />
              <span v-if="$v.beds.$error" class="warning--text">
                {{ $t('Required field') }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4">
              <v-checkbox
                v-model="setupListing.has_pool"
                label="Pool"
                class="mt-0"
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-checkbox
                v-model="setupListing.has_jacuzzi"
                label="Jacuzzi"
                class="mt-0"
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-checkbox
                v-model="setupListing.has_garden"
                label="Garden"
                class="mt-0"
                dense
                hide-details
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-text v-if="setupListingStage" class="px-6">
          <p class="font-weight-medium text-body-1">Project Details</p>
          <v-row>
            <v-col cols="6" md="4">
              <v-select
                v-model="setupListing.priority"
                :items="priorities"
                :label="$t('Priority')"
                dense
                outlined
                hide-details
              >
                <template #item="{ item }">
                  <v-icon class="mr-2" :color="priorityColor(item)" x-small
                    >mdi-circle</v-icon
                  >
                  <span class="text-capitalize">
                    {{ item }}
                  </span>
                </template>
                <template #selection="{ item }">
                  <v-icon class="mr-2" :color="priorityColor(item)" x-small
                    >mdi-circle</v-icon
                  >
                  <span class="text-capitalize">
                    {{ item }}
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="6" md="4">
              <v-menu
                v-model="launchDateMenu"
                transition="scale-transition"
                offset-y
                required
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="setupListing.launch_date"
                    label="Launch Date"
                    outlined
                    dense
                    hide-details
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="setupListing.launch_date" />
              </v-menu>
            </v-col>
          </v-row>
          <listing-setup-scopes :scopes.sync="scopes" />
        </v-card-text>
        <v-divider />
        <v-card-text>
          <personal
            :hide-save="true"
            :auto-suggest="true"
            :on-change="updatePersonal"
            :personal="personal"
            class="mb-4"
            :override-personal-types="[
              'cleaning_supervisor',
              'pool',
              'pest',
              'landscape',
              'host',
              'region_manager',
            ]"
          />
          <p class="font-weight-medium text-body-1">Attachments</p>
          <dvr-media-upload :value.sync="images" folder="listing-setups" />
        </v-card-text>
      </v-form>
      <v-divider />
      <v-card-actions class="px-6 py-4">
        <v-spacer />
        <v-btn
          class="text-capitalize"
          color="secondary"
          text
          @click.native="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary"
          class="text-capitalize"
          elevation="0"
          @click.native="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import Personal from 'components/listing/personal'
import DvrMediaUpload from 'components/common/dvr-media-upload'
import ListingSetupScopes from 'components/listing-setup-scopes'
import ColorsMixin from 'components/mixins/colors-mixin'
import MultiPropertyPicker from 'components/multi-property-picker.vue'

export default {
  components: {
    MultiPropertyPicker,
    ListingSetupScopes,
    DvrMediaUpload,
    Personal,
  },
  mixins: [CommonFunctions, PermissionsMixin, ColorsMixin],
  validations: {
    address: {
      required,
    },
    listingType: {
      required,
    },
    beds: {
      required,
    },
    baths: {
      required,
    },
  },
  data() {
    return {
      menu: false,
      launchDateMenu: false,
      dialog: false,
      personal: {
        office_manager: null,
        realator: null,
        designer: null,
        cleaning_supervisor: null,
      },
      scopes: [],
      images: [],
      priorities: ['low', 'med', 'high'],
      setupListing: {
        address: null,
        listing_type: 'regular',
        status: 'Pre Setup',
        priority: 'low',
        units: [],
      },
    }
  },
  computed: {
    ...mapGetters('users', ['investors']),
    listingType() {
      return this.setupListing.listing_type
    },
    isHotel() {
      return this.listingType === 'hotel'
    },
    listingStage() {
      return this.setupListing.stage
    },
    setupListingStage() {
      return this.listingStage === 'setup'
    },
    startedAt() {
      return this.setupListing.started_at
    },
    investor() {
      return this.setupListing.investor_id
    },
    address() {
      return this.setupListing.address
    },
    beds() {
      return this.setupListing.beds
    },
    baths() {
      return this.setupListing.baths
    },
  },
  props: ['quickMode'],
  methods: {
    ...mapActions(['createListingSetup']),
    typeChanged() {
      this.setupListing.units = []
    },
    updatePersonal(personal) {
      this.personal = personal
    },
    close() {
      this.dialog = false
      this.setupListing = {
        address: null,
        listing_type: 'regular',
        status: 'Pre Setup',
        priority: 'low',
      }
      this.scopes = []
      this.images = []
      this.personal = {
        office_manager: null,
        realator: null,
        designer: null,
        cleaning_supervisor: null,
      }
    },
    async save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        let data = {}
        if (this.images.length) {
          data.image = this.images[0]
        }
        if (this.setupListing.address) {
          data.address = this.setupListing.address
        }
        if (this.setupListing.stage) {
          data.stage = this.setupListing.stage
        }
        data.personal = this.personal
        if (this.setupListing.beds) {
          data.beds = this.setupListing.beds
        }
        if (this.setupListing.investor_id) {
          data.investor_id = this.setupListing.investor_id
        }
        if (this.setupListing.listing_type) {
          data.listing_type = this.setupListing.listing_type
        }
        if (this.setupListing.status) {
          data.status = this.setupListing.status
        }
        if (this.setupListing.baths) {
          data.baths = this.setupListing.baths
        }
        if (this.setupListing.started_at) {
          data.started_at = this.setupListing.started_at
        }
        if (this.setupListing.has_pool) {
          data.has_pool = this.setupListing.has_pool
        }
        if (this.setupListing.has_garden) {
          data.has_garden = this.setupListing.has_garden
        }
        if (this.setupListing.has_jacuzzi) {
          data.has_jacuzzi = this.setupListing.has_jacuzzi
        }
        if (this.setupListing.priority) {
          data.priority = this.setupListing.priority
        }
        if (this.setupListing.launch_date) {
          data.launch_date = this.setupListing.launch_date
        }
        if (this.isHotel) {
          data.units = this.setupListing.units
        }
        if (this.scopes.length) {
          data.scope = this.scopes
        }
        await this.createListingSetup(data)
        this.close()
      }
    },
  },
}
</script>
