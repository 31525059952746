<template>
  <v-container class="pa-6">
    <v-row class="justify-space-between align-center py-3" no-gutters>
      <v-spacer />
      <month-year-picker v-model="dates" :is-yearly="true" />
    </v-row>
    <v-row />
    <reviews-portfolio :year="dates.year" :listing-ids="[+$route.params.id]" />
    <v-row>
      <v-col cols="12">
        <h3>Published:</h3>
      </v-col>
      <v-col v-for="(integration, i) in items" :key="i" cols="auto">
        <v-card
          min-width="350"
          class="grey lighten-3 pa-3"
          elevation="0"
          rounded
        >
          <div class="d-flex flex-wrap justify-space-between align-center">
            <div class="d-flex align-center">
              <span class="pa-1 white rounded ba-1 ba-black">
                <v-icon x-large
                  >${{
                    integration.platform.toLowerCase().replace(/[. ]/g, '')
                  }}</v-icon
                >
              </span>
              <span class="text-subtitle-1 font-weight-medium ml-1">{{
                integration.platform
              }}</span>
            </div>
            <a
              target="_blank"
              class="info--text text-caption font-weight-medium text-decoration-underline text-capitalize"
              :href="getLink(integration)"
            >
              {{ $t('view on channel') }}
            </a>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import values from 'lodash/values'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { mapState, mapActions } from 'vuex'
import MonthYearPicker from 'components/common/month-year-picker'
import FormattersMixin from 'components/mixins/formatters-mixin'
import ReviewsPortfolio from 'components/portfolio/reviews-portfolio'

export default {
  components: {
    ReviewsPortfolio,
    MonthYearPicker,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: ['miniIntgrations'],
  data: function () {
    return {
      dates: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },
      reviews: [],
      pagination: {},
      yearlyStats: {},
      allowedIntegrations: [
        'Airbnb',
        'Booking.com',
        'Designedvr.com',
        'Expedia',
        'Google',
        'HomeAway',
        'HomeToGo',
        'Marriott',
        'PlumGuide',
        'TripAdvisor',
      ],
    }
  },
  computed: {
    ...mapState({
      integrations: state => state.currentListing.integrations,
    }),
    items() {
      return values(this.integrations).filter(
        item =>
          this.getLink(item) && this.allowedIntegrations.includes(item.platform)
      )
    },
  },
  methods: {
    ...mapActions(['updateIntegrations']),
    getLink(item) {
      if (item.links) {
        return item.links[0]
      } else {
        return item.link
      }
    },
    logo(platform) {
      return require(`@/assets/images/platforms/${platform}.png`)
    },
  },
}
</script>

<style scoped>
.ba-black {
  border-color: black !important;
}
</style>
