<template>
  <v-card :loading="fetching" class="pa-3 overflow-y-auto">
    <v-card-title>Account - {{ account.name }}</v-card-title>
    <v-form ref="form" @submit.prevent="submit">
      <v-card-text>
        <v-text-field v-model="editAccount.name" label="Name" outlined dense />
        <v-text-field
          v-model="editAccount.stripe_public_key"
          label="Public Key"
          outlined
          dense
        />
        <v-text-field
          v-model="editAccount.stripe_secret_key"
          label="Secret Key"
          placeholder="**********"
          outlined
          dense
        />
        <div v-for="(hook, ind) in hooks" :key="ind" class="pa-2">
          <div class="justify-space-between align-center d-flex">
            <span class="text-subtitle-1 font-weight-medium py-3"
              >Hooks #{{ ind + 1 }}</span
            >
            <v-icon
              v-if="!hook.id"
              class="ml-auto"
              small
              color="error"
              @click="removeHook(ind)"
              >$trash</v-icon
            >
          </div>
          <v-text-field
            v-model="hook.url"
            :rules="[required]"
            label="URL"
            dense
            outlined
          />
          <v-autocomplete
            v-model="hook.enabled_events"
            outlined
            :items="webhooksList"
            label="Webhooks"
            multiple
            :rules="[required]"
            small-chips
          />
          <v-divider />
        </div>
        <v-btn outlined class="my-3" color="info" @click="addHook"
          ><v-icon left>fas fa-plus</v-icon> New hook
        </v-btn>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="info" outlined small @click="close">cancel</v-btn>
        <v-btn color="info" type="submit" small>save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from '@/axios/config'
import { STRIPE_WEBHOOKS } from '@/consts'
import FormRulesMixin from 'components/mixins/form-rules-mixin'

export default {
  name: 'StripeAccountModal',
  props: ['account', 'webhooks'],
  mixins: [FormRulesMixin],
  data() {
    return {
      editAccount: { ...this.account },
      hooks: [],
      web: [],
      additionalHooks: [],
      fetching: false,
    }
  },
  async mounted() {
    this.fetching = true
    const { data } = await axios.get(
      `/api/stripe-accounts/${this.account.id}/webhooks`
    )
    this.fetching = false
    this.hooks = data
  },
  computed: {
    webhooksList() {
      return STRIPE_WEBHOOKS
    },
  },
  methods: {
    close() {
      this.$store.commit('hideModal')
    },
    addHook() {
      this.hooks.push({
        url: '',
        enabled_events: [],
      })
    },
    removeHook(ind) {
      this.hooks.splice(ind, 1)
    },
    submit() {
      if (this.$refs.form.validate()) {
        const payload = {}
        if (
          this.editAccount.stripe_public_key !== this.account.stripe_public_key
        ) {
          payload.stripe_public_key = this.editAccount.stripe_public_key
        }
        if (
          this.editAccount.stripe_secret_key !== this.account.stripe_secret_key
        ) {
          payload.stripe_secret_key = this.editAccount.stripe_secret_key
        }
        this.$store.dispatch('stripe/updateStripeAccount', {
          id: this.account.id,
          webhooks: this.hooks,
          ...payload,
        })
        this.close()
      }
    },
  },
}
</script>

<style scoped></style>
