import get from 'lodash/fp/get'
import moment from 'moment-timezone'
import formattersMixin from 'components/mixins/formatters-mixin'

const date = date => moment(date).utc().format('YYYY-MM-DD')
const datetime = date => moment.unix(date).utc().format('YYYY-MM-DD')
const dollarFormatter = formattersMixin.methods.dollarFormatter

export const summaryValues = item => {
  return [
    {
      label: 'Price',
      value: dollarFormatter(item.price),
    },
    {
      label: 'Guests',
      value: item.guests_count,
    },
    {
      label: 'Nights',
      value: item.nights_count,
    },
    {
      label: 'Channel',
      value: item.source,
    },
    {
      label: 'PreCheck In',
      value:
        get('pre_check_in.status', item) === 'Done' ? 'Filled' : 'Not Filled',
    },
  ]
}

export const guestValues = item => {
  return [
    {
      label: 'Guest Name',
      value: get('guest.full_name', item),
    },
    {
      label: 'Phone',
      value: item.guest.phones.join(', ') || '-',
    },
    {
      label: 'Email',
      value: item.guest.emails.join(', ') || '-',
    },
    {
      label: 'Receives Marketing',
      value: item.guest.marketing_enabled ? 'Yes' : 'No',
    },
  ]
}

export const preCheckInValues = item => {
  return [
    {
      label: 'Email',
      value: get('pre_check_in.client_info.email', item),
    },
    {
      label: 'Phone',
      value: get('pre_check_in.client_info.phone_number', item),
    },
    {
      label: 'Requests Parkings',
      value: get('pre_check_in.client_info.need_parking', item) ? 'Yes' : 'No',
    },
    {
      label: 'Cars',
      value: get('pre_check_in.client_info.car_amount', item),
    },
    {
      label: 'Adults',
      value: get('pre_check_in.guest_count_info.adults', item) || '-',
    },
    {
      label: 'Children',
      value: get('pre_check_in.guest_count_info.kids', item) || '-',
    },
    {
      label: 'Babies',
      value: get('pre_check_in.guest_count_info.babies', item) || '-',
    },
    {
      label: 'Guests',
      value:
        (get('pre_check_in.guest_count_info.guests', item) || []).join(', ') ||
        '-',
    },
    {
      label: 'Special Requests',
      value:
        (Object.keys(item.special_requests) || [])
          .filter(k => item.special_requests[k])
          .join(', ') || '-',
    },
  ]
}
export const INVOICE_CHANGE = {
  additional_charge: 1,
  guest_discount: -1,
}
export const INVOICE_TITLES = {
  guest_discount: 'Guest Discount',
  additional_charge: 'Additonal Charge',
}

export const INVOICE_TYPES = [
  { text: INVOICE_TITLES.guest_discount, value: 'guest_discount' },
  { text: INVOICE_TITLES.additional_charge, value: 'additional_charge' },
]

export const paymentIntentsHeaders = [
  { text: 'Payment Intents', value: 'type' },
  { text: 'Amount', value: 'amount' },
  { text: 'Status', value: 'status' },
  { text: 'Paid at', value: 'paid_at' },
  { text: '', value: 'actions' },
]
export const paymentItemsHeaders = [
  { text: 'Payment Items', value: 'type' },
  { text: '', value: 'amount' },
  { text: '', value: 'paid_at' },
  { text: '', value: 'status' },
]
export const invoiceHeaders = [
  { text: 'Invoices', value: 'type' },
  { text: '', value: 'amount' },
]

export const paymentIntents = item => {
  return item.payment_intents.map(intent => {
    const { amount_captured, amount_refunded } = intent.charges.data[0] || {}
    return {
      id: intent.id,
      type: intent.metadata.type,
      amount: intent.amount / 100,
      status: intent.status,
      client_secret: intent.client_secret,
      payment_intent_id: intent.payment_intent_id,
      amount_received: intent.amount_received / 100,
      paid_at: datetime(intent.created),
      charges: intent.charges.data.flatMap(charge => [
        charge,
        ...charge.refunds.data,
      ]),
      isRefundable: amount_refunded < amount_captured,
    }
  })
}

export const paymentItems = item => {
  return item.payment_items.map(payment => ({
    type: 'Payment',
    amount: dollarFormatter(payment.amount),
    paid_at: date(payment.paid_at),
    status: payment.status,
  }))
}

export const invoiceItems = item => {
  return item.invoice_items.map(invoice => ({
    type: INVOICE_TITLES[invoice.title] || invoice.title,
    amount: dollarFormatter(invoice.amount),
  }))
}
