<template>
  <v-card class="overflow-y-auto">
    <v-card-title class="d-flex justify-space-between align-center">
      <div>Calendar Block</div>
      <reservation-create-modal
        class="ml-auto"
        :pre-filled="preFilled"
        @oncreate="createReservation"
      />
    </v-card-title>
    <v-form ref="form" @submit.prevent="save">
      <v-card-text>
        <v-row>
          <v-col cols="6" md="4">
            <date-picker
              v-model="block.from"
              label="from"
              :rules="[required]"
              :hide-details="true"
              :allowed-dates="dateIsAllowed"
            />
          </v-col>
          <v-col cols="6" md="4">
            <date-picker
              v-model="block.to"
              :allowed-dates="dateIsAllowed"
              label="to"
              :hide-details="true"
              :rules="[required]"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model.number="block.price"
              type="number"
              :hide-details="true"
              label="Price"
              outlined
              dense
            />
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model.number="block.minNights"
              type="number"
              label="Min Nights"
              outlined
              :hide-details="true"
              dense
            />
          </v-col>
          <v-col cols="6" md="4">
            <v-select
              v-model="block.status"
              label="Status"
              outlined
              dense
              :items="[
                { text: 'Blocked', value: 'unavailable' },
                { text: 'Available', value: 'available' },
              ]"
              :hide-details="true"
            />
          </v-col>
        </v-row>
        <v-textarea
          v-model="block.note"
          outlined
          dense
          label="Note"
          :rules="block.status === 'unavailable' ? [required] : []"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="info" outlined text @click="close">Cancel</v-btn>
        <v-btn color="info" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import DatePicker from 'components/form-fields/date-picker'
import FormRules from 'components/mixins/form-rules-mixin'
import ReservationCreateModal from 'components/reservation/reservation-create-modal'
export default {
  name: 'CalendarBlockModal',
  components: { ReservationCreateModal, DatePicker },
  mixins: [FormRules],
  props: [
    'from',
    'to',
    'note',
    'listingId',
    'minNights',
    'status',
    'onChange',
    'onReservationCreate',
    'rates',
  ],
  data() {
    return {
      block: {
        from: this.from,
        to: this.to,
        note: this.note,
        minNights: this.minNights,
        status: this.status,
      },
      preFilled: {
        range: [this.from, this.to],
        listingId: this.listingId,
      },
    }
  },
  methods: {
    close() {
      this.$store.commit('hideModal')
    },
    dateIsAllowed(date) {
      return this.rates[date] && this.rates[date].status === 'available'
    },
    createReservation(res_id) {
      this.onReservationCreate(res_id)
      this.close()
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.$store.commit('updateLoading', true)
        const rates = await this.$store.dispatch(
          'listingCalendar/blockCalendar',
          {
            ...this.block,
            listingId: this.listingId,
          }
        )
        this.$store.commit('updateLoading', false)
        this.onChange(rates)
        this.close()
      }
    },
  },
}
</script>

<style scoped></style>
