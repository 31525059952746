var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-stepper-content',{staticClass:"pa-0",attrs:{"step":2}},[_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"text-h6 font-weight-medium"},[_vm._v("Special Requests")]),_c('v-row',{staticClass:"align-center flex-column"},_vm._l((_vm.allowedRequests),function(request,idx){return _c('v-col',{key:idx},[_c('AddRemoveItem',{attrs:{"label":request.key,"value":request.amount,"max":request.key === 'Pool fence' ? 1 : 2},on:{"update:value":function($event){return _vm.$set(request, "amount", $event)}}})],1)}),1),(
        _vm.preCheckIn.has_assigned_parking ||
        _vm.preCheckIn.has_parking_pass ||
        _vm.preCheckIn.listing_num_of_rooms >= 3
      )?_c('div',[_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"text-h6 font-weight-medium"},[_vm._v("Do you need parking place?")]),_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.clientInfo.need_parking),callback:function ($$v) {_vm.$set(_vm.clientInfo, "need_parking", $$v)},expression:"clientInfo.need_parking"}},[_c('v-radio',{attrs:{"label":"Yes","value":true}}),_c('v-radio',{attrs:{"label":"No","value":false}})],1),(_vm.preCheckIn.has_parking_pass && _vm.clientInfo.need_parking)?_c('span',{staticClass:"warning--text my-2"},[_vm._v(" A parking pass will be available for purchase from our store. ")]):_vm._e(),(_vm.clientInfo.need_parking)?_c('div',[(
            _vm.preCheckIn.has_assigned_parking || _vm.preCheckIn.has_parking_pass
          )?_c('v-form',{ref:"form",staticClass:"flex d-flex"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"grey--text pb-3",attrs:{"cols":"12"}},[_vm._v(" If you don't have the details yet you would be able to update this form again on your arrival date ")]),_c('v-col',{staticClass:"pr-3",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Car model","prepend-inner-icon":"directions_car"},model:{value:(_vm.clientInfo.car_model),callback:function ($$v) {_vm.$set(_vm.clientInfo, "car_model", $$v)},expression:"clientInfo.car_model"}})],1),_c('v-col',{staticClass:"pr-3",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Licence plate","prepend-inner-icon":"straighten"},model:{value:(_vm.clientInfo.licence_plate),callback:function ($$v) {_vm.$set(_vm.clientInfo, "licence_plate", $$v)},expression:"clientInfo.licence_plate"}})],1)],1)],1):_c('AddRemoveItem',{attrs:{"label":"How many cars?","value":_vm.clientInfo.car_amount,"min":1,"max":3,"description":_vm.carAmountWarn},on:{"update:value":function($event){return _vm.$set(_vm.clientInfo, "car_amount", $event)}}})],1):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }