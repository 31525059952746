<template>
  <div>
    <v-data-table
      :headers="HEADERS"
      :loading="logsLoading"
      :items="logs"
      :options="pagination"
      :server-items-length="pagination.itemsLength"
      :footer-props="{ 'items-per-page-options': [15, 30, 50, 100, -1] }"
      @pagination="paginationChanged($event)"
    >
      <template #item.description="{ item }">
        <router-link
          v-if="item.review_id"
          color="primary"
          x-small
          icon
          :to="'/dashboard/reviews-dashboard/manage?id=' + item.review_id"
          class="text-decoration-none"
          :class="isMobile ? 'small-text' : ''"
          target="_blank"
          ><v-icon left small>mdi-open-in-new</v-icon>
        </router-link>
        <router-link
          v-if="item.conversation"
          color="primary"
          x-small
          icon
          :to="
            '/dashboard/guest-experience/cs?conversation=' +
            item.conversation.guesty_id
          "
          class="text-decoration-none"
          :class="isMobile ? 'small-text' : ''"
          target="_blank"
          ><v-icon left small>mdi-open-in-new</v-icon>
        </router-link>
        <div>
          <span
            class="ellipsis-2"
            :style="`-webkit-line-clamp: ${
              showMoreItemId === item.id ? 100 : 10
            } !important`"
            v-html="item.description.replace(/\n/g, '<br>')"
          >
          </span>
          <a @click="() => toggleShowMore(item.id)">{{
            showMoreItemId === item.id ? 'Show Less' : 'Show More'
          }}</a>
        </div>
      </template>
      <template #item.event_type="{ item }">
        <div class="text-uppercase">{{ item.event_type }}</div>
      </template>
      <template #item.created_at="{ item }">
        <div>
          {{ parseDate(item.created_at, 'YYYY-MM-DD HH:mm', { local: true }) }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import NavigationMixin from 'components/mixins/navigation-mixin'
import axios from 'axios'
import Common_functions from 'components/mixins/common_functions'

const HEADERS = [
  { text: 'Description', value: 'description' },
  { text: 'Type', value: 'event_type' },
  { text: 'Timestamp', value: 'created_at', fieldType: 'date' },
  { text: 'AI Suggestion Used', value: 'ai_suggestion_used_humanized' },
]

export default {
  mixins: [NavigationMixin, Common_functions],
  data() {
    return {
      HEADERS,
      pagination: {},
      page: 1,
      logsLoading: false,
      listingId: null,
      logs: [],
      showMoreItemId: null,
    }
  },
  methods: {
    async getLogs(nextPagination) {
      this.logsLoading = true
      const { logs, pagination } = await this.getAiLogs({
        page: nextPagination.page || 1,
        per_page: nextPagination.itemsPerPage,
      })
      this.pagination = {
        itemsLength: pagination.count,
        itemsPerPage: pagination.per_page,
        page: pagination.page,
      }
      this.logs = logs
      this.logsLoading = false
    },
    async getAiLogs(params) {
      const { data } = await axios.get(`/api/ai-logs`, { params })
      return data
    },
    paginationChanged(pagination) {
      this.getLogs(pagination)
    },
    toggleShowMore(itemId) {
      if (this.showMoreItemId === itemId) {
        this.showMoreItemId = null
      } else {
        this.showMoreItemId = itemId
      }
    },
  },
}
</script>

<style></style>
