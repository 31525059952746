<template>
  <form-dialog v-model="dialog" title="Payment" @submit="createPayment">
    <template #activator="{ on }">
      <v-btn
        title="amount"
        :loading="loading"
        color="info"
        class="mx-1"
        :disabled="disabled"
        v-on="on"
        ><v-icon left>fas fa-coins</v-icon> Collect</v-btn
      >
    </template>
    <v-btn-toggle
      v-model="paymentType"
      mandatory
      color="primary"
      class="mb-6 d-flex"
    >
      <v-btn color="flex" value="credit">Credit </v-btn>
      <v-btn class="flex" value="cash"> Cash </v-btn>
    </v-btn-toggle>
    <v-text-field
      v-model.number="amount"
      label="Amount"
      :rules="[
        required,
        paymentType === 'credit'
          ? max(intentsSum)
          : max(reservation.balance_due),
      ]"
      dense
      outlined
    />
    <v-select
      v-if="paymentType === 'credit'"
      v-model="methodId"
      dense
      :rules="[required]"
      outlined
      :clearable="false"
      label="Payment Method"
      :items="creditCards"
    />
    <v-textarea
      v-if="paymentType === 'cash'"
      v-model="note"
      outlined
      :rules="[required]"
      dense
      label="Note"
    />
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import FormRules from 'components/mixins/form-rules-mixin'
import axios from '@/axios/config'
import { first, get, isEmpty } from 'lodash/fp'
export default {
  name: 'CollectPayment',
  components: { FormDialog },
  mixins: [FormRules],
  props: ['reservation'],
  data() {
    return {
      amount: null,
      dialog: null,
      paymentType: null,
      note: null,
      methodId: null,
      loading: false,
    }
  },
  computed: {
    intentsSum() {
      return this.intent ? this.intent.amount / 100 : 0
    },
    intent() {
      return this.reservation.payment_intents.find(
        intent =>
          intent.metadata.type === 'accommodation' &&
          intent.status !== 'succeeded' &&
          intent.status !== 'canceled'
      )
    },
    reservationId() {
      return this.reservation.id
    },
    creditCards() {
      return this.reservation.payment_methods.map(method => ({
        value: method.payment_method_id,
        text: `xxxx-${method.last4} (${method.exp_month}/${method.exp_year})`,
      }))
    },
    disabled() {
      return !this.reservation.balance_due
    },
  },
  async mounted() {
    this.amount = this.intentsSum
    this.methodId = get('value', first(this.creditCards))
  },
  watch: {
    dialog(val) {
      if (val) {
        this.paymentType = this.intent ? 'credit' : 'cash'
      }
    },
    reservationId() {
      this.amount = this.intentsSum
      this.methodId = get('value', first(this.creditCards))
    },
  },
  methods: {
    async createPayment() {
      this.loading = true
      try {
        await axios.post(`/api/reservations/${this.reservation.id}/payment`, {
          amount: this.amount,
          paymentMethodId: this.methodId,
          payment_type: this.paymentType,
          note: this.note,
          intent: this.intent && this.intent.payment_intent_id,
        })
        this.$emit('success')
        this.loading = false
      } catch (err) {
        console.log(err)
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
