<template>
  <div class="pa-3 mt-3 white">
    <div
      class="d-flex align-center py-2 text-caption text-md-subtitle-1 justify-space-between"
    >
      <div>
        <span class="mb-2 mr-2">{{ $t('Summary') }}</span>
        <v-icon v-show="!isMobileOrIPad" color="info" @click="showSummary"
          >fas fa-file-alt</v-icon
        >
      </div>
      <add-repair-materials
        v-if="!(isContractor && !onPayroleContractor)"
        :task-id="listingTask.id"
      />
    </div>
    <div :class="['text-caption', 'rounded-lg']">
      <div
        v-for="expense in listingTask.material_expenses"
        :key="expense.id"
        class="d-flex align-center py-2"
      >
        <span
          class="info--text pointer col-6 pa-0"
          @click="showMaterialsExpense(expense)"
        >
          {{ $t(expense.short_reason) }}
        </span>
        <confirmation-modal
          text="Are you sure you want to delete this expense ?"
          @action="removeMaterial(expense.id)"
        >
          <template #activator="{ on }">
            <v-icon color="warning" small v-on="on">fas fa-minus-circle</v-icon>
          </template>
        </confirmation-modal>
        <div class="col-6 pa-0 px-4 text-subtitle-2">
          {{ dollarFormatter(expense.amount) }}
        </div>
      </div>
      <v-form ref="summaryForm">
        <div class="d-flex align-center py-2">
          <div class="black--text flex col-6 pa-0">
            {{ $t('Time Spent (Hours)') }}
          </div>
          <div class="flex col-6 pa-0">
            <v-text-field
              v-model.number="total_hours"
              hide-details="auto"
              type="number"
              :hint="clockInHint"
              dense
              :rules="externalAssignee ? [required, nonNegative] : []"
              class="px-md-4"
              :disabled="hoursDisabled"
              @input="debouncedInput('total_hours')"
            />
          </div>
        </div>
        <v-divider v-if="isMobileOrIPad" />
        <div
          v-if="
            (!isContractor || !onPayroleContractor) && !isCleaningSupervisor
          "
          class="d-flex align-center py-2"
        >
          <div class="black--text flex col-6 pa-0">{{ $t('Labor costs') }}</div>
          <div class="flex col-6 pa-0">
            <v-text-field
              v-model.number="amount"
              type="number"
              dense
              :persistent-hint="!subTasksDone"
              :hint="amountHint"
              hide-details="auto"
              :disabled="isAmountDisabled"
              :rules="[amountValidator]"
              class="px-md-4"
              @blur="debouncedInput('amount')"
            />
          </div>
        </div>
        <div v-if="listingTask.deposit" class="d-flex align-center py-2">
          <div class="black--text flex col-6 pa-0">{{ $t('Deposit') }}</div>
          <div class="flex col-6 pa-0">
            <v-text-field
              v-model.number="deposit"
              hide-details="auto"
              :disabled="
                isContractor || listingTask.manual_approved || readonly
              "
              class="px-md-4"
              dense
              @blur="debouncedInput('deposit')"
            />
          </div>
        </div>
        <div
          v-if="listingTask.management_fee && managementRoles"
          class="d-flex align-center py-2"
        >
          <div class="black--text flex col-6 pa-0">
            {{ $t('Management fee') }}
          </div>
          <div class="flex col-6 pa-0">
            <v-text-field
              v-model.number="management_fee"
              disabled
              hide-details="auto"
              class="px-md-4"
              dense
            />
          </div>
        </div>
        <v-divider v-if="isMobileOrIPad" />
        <div
          :class="[
            'd-none',
            'flex-wrap',
            'py-2',
            {
              'd-flex': !isContractor || !onPayroleContractor,
            },
          ]"
          class="align-center"
        >
          <div class="black--text flex col-6 pa-0">
            {{ $t('Total charged') }}
          </div>
          <div class="flex col-6 pa-0">
            <v-text-field
              :value="amount + materialsSum + managementFee"
              class="px-md-4"
              dense
              disabled
              type="number"
            />
          </div>
          <div
            v-if="listingTask.reg_cleaning && !taskDone && perHour > 50"
            class="red-warn pa-1"
          >
            <v-icon class="red-warn">warning_amber</v-icon>
            {{
              $t('Are you sure this is the desired amount, by calculation its')
            }}
            {{ dollarFormatter(perHour) }}
            {{ $t('per hour') }}.
          </div>
          <div
            v-if="
              listingTask.management_fee &&
              managementRoles &&
              !listingTask.manual_approved
            "
            class="flex col-12 orange--text text-subtitle-2 px-0"
          >
            <div>
              {{ $t('Management fee of') }}
              <b>{{ dollarFormatter(listingTask.management_fee) }}</b>
              {{ $t('added to the final amount') }}.
            </div>
            <div>
              {{ $t('Total of') }}

              <b>{{
                dollarFormatter(listingTask.amount + listingTask.management_fee)
              }}</b>
              {{ $t('will be charged from the house') }}
              .
            </div>
            <div>
              Click <a class="info--text" @click.prevent="removeFee">here</a>
              {{ $t('to remove') }}
            </div>
          </div>
        </div>
        <div
          v-if="
            managementRoles &&
            (listingTask.dvr_hours_chrage || listingTask.dvr_hours_chrage === 0)
          "
          class="d-flex align-center py-2"
        >
          <div class="black--text flex col-6 pa-0">
            {{ $t('Company cost') }}

            <v-tooltip v-if="listingTask.dvr_hours_chrage === 0" top>
              <template #activator="{ on }">
                <v-icon small class="icon alt red-warn mr-1 ml-2" v-on="on"
                  >fas fa-exclamation-triangle
                </v-icon>
              </template>
              <v-row class="center-text" wrap style="width: 300px">
                <v-col cols="12">
                  <h4>
                    {{
                      $t(
                        "Amount reported to be paid to the contractor is 0, check if it's right"
                      )
                    }}
                    .
                  </h4>
                </v-col>
              </v-row>
            </v-tooltip>
          </div>
          <div class="flex col-6 pa-0">
            <v-text-field
              :value="listingTask.dvr_hours_chrage"
              disabled
              hide-details="auto"
              class="px-md-4"
              dense
              type="number"
            />
          </div>
        </div>
        <div
          v-if="
            (isCompensation || isReviewInterrogation || isClaim) &&
            managementRoles
          "
          class="d-flex align-center py-2"
        >
          <div class="black--text flex col-6 pa-0">{{ $t('Tag issues') }}</div>
          <div class="flex col-6 pa-0">
            <v-autocomplete
              v-model="tags"
              small-chips
              dense
              hide-details
              multiple
              :items="reviewTags"
              class="px-md-4"
              chips
              tags
              @blur="debouncedInput('tags')"
            />
          </div>
        </div>
        <v-divider v-if="isMobileOrIPad" />
        <div
          v-if="
            !hasWarranty &&
            listingTask.amount > warrantyAmount &&
            managementRoles
          "
          class="d-flex align-center py-2"
        >
          <div class="black--text flex col-6 pa-0">
            {{ $t('Does this work include warranty?') }}
          </div>
          <div class="flex col-6 pa-0">
            <warranty-modal :listing-task="listingTask" />
          </div>
        </div>
        <v-btn
          v-if="!taskDone && !pendingCaptureStoreItem"
          color="success"
          class="my-3"
          outlined
          :disabled="!subTasksDone || listingTaskLoading"
          block
          @mousedown="moveToDone"
        >
          {{ $t('Move to done') }}
        </v-btn>
        <div
          v-if="listingTask.status === 'Owner Approval'"
          class="text-subtitle-2 warning--text"
        >
          {{ $t('Task is waiting for owner approval') }}
        </div>
      </v-form>

      <div
        v-if="taskDone && !listingTask.manual_approved && isContractor"
        class="text-subtitle-2 warning--text"
      >
        {{ $t('Task is waiting for manager approval') }}
      </div>
      <div v-if="canApproveTask && expenseTypeRequired" class="mt-3">
        <v-autocomplete
          v-model="expenseType"
          dense
          label="Expense Type"
          outlined
          :disabled="expenseType === 'DVR-Store'"
          :items="expenseTypesTran"
        />
      </div>
      <div v-if="canApproveTask">
        <v-divider v-if="isMobileOrIPad" />
        <v-row
          v-if="avgTime || avgPrice"
          class="justify-space-between black--text align-center"
        >
          <v-col cols="12" class="py-0 text-subtitle-1 black--text">
            {{ $t('Estimations') }}
          </v-col>
          <v-col v-if="avgPrice" cols="6">
            {{ $t('The Avg Price is') }}({{ avgPrice || 'N/A' }})
          </v-col>
          <v-col
            v-if="avgPrice"
            :class="{
              'success--text': avgPrice - amount > 0,
              'error--text': avgPrice - amount <= 0,
            }"
            cols="6"
          >
            {{ avgPrice - amount > 0 ? 'You saved ' : 'You spent additional ' }}
            {{ dollarFormatter(Math.abs(avgPrice - amount)) }}
          </v-col>
          <v-col v-if="avgTime" cols="6">
            {{ $t('Avg time') }}( ({{ avgTime || 'N/A' }})
          </v-col>
          <v-col
            v-if="avgTime"
            :class="{
              'success--text': avgTime - listingTask.total_hours > 0,
              'error--text': avgTime - listingTask.total_hours <= 0,
            }"
            cols="6"
          >
            <span class="font-weight-medium"
              >{{ avgTime - listingTask.total_hours }}h</span
            >
          </v-col>
        </v-row>
        <div class="d-flex pt-2">
          <v-btn class="flex ma-1" color="error" @click="openRejectModal">
            {{ $t('Reject') }}
          </v-btn>
          <div class="d-flex flex flex-column">
            <v-btn
              class="flex ma-1"
              :disabled="isApprovedDisabled"
              color="success"
              @click="approveExpense"
            >
              {{ $t('Approve') }}
            </v-btn>
            <div
              v-if="isApprovedDisabled"
              class="flex text-caption error--text px-1"
            >
              {{ $t('Not allowed to approve') }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="listingTask.manual_approved && !isContractor">
        <v-btn
          v-if="listingTask.expense"
          class="mb-2"
          block
          color="info"
          elevation="0"
          @click="showExpense"
        >
          {{ $t('View expense') }}
        </v-btn>
        <ConfirmationModal
          text="Are you sure you want to undo expense ?"
          @action="undoApprove"
        >
          <template #activator="slotData">
            <v-btn
              class="mb-2"
              block
              color="warning"
              :disabled="undoDisabled"
              outlined
              elevation="0"
              v-on="slotData.on"
            >
              {{
                listingTask.expense_id
                  ? $t('Undo (delete expense)')
                  : $t('Undo approval')
              }}
            </v-btn>
          </template>
        </ConfirmationModal>
      </div>
      <payment-approval
        v-if="paymentApproved"
        :payment="listingTask.ach_transaction"
      />
    </div>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import AccountingMixin from 'components/mixins/accounting-mixin'
import deviceMixin from 'components/mixins/device-mixin'
import formRules from 'components/mixins/form-rules-mixin'
import { TASK_STATUSES } from '@/consts'
import { sumBy, every, isEmpty, get, getOr, isNumber } from 'lodash/fp'
import ConfirmationModal from 'components/modals/confirmation-modal'
import { mapGetters, mapState } from 'vuex'
import WarrantyModal from 'components/warranty-modal'
import PaymentApproval from 'components/listing-tasks/single-listing-task/payment-approval.vue'
import AddRepairMaterials from 'components/listing-tasks/add-repair-materials'
import FormattersMixin from 'components/mixins/formatters-mixin'
import ListingTaskMethodsMixin from 'components/listing-tasks/mixins/listing-task-methods-mixin'
import debounce from 'lodash/debounce'

export default {
  name: 'LtSummary',
  components: {
    WarrantyModal,
    ConfirmationModal,
    PaymentApproval,
    AddRepairMaterials,
  },
  mixins: [
    CommonFunctions,
    formRules,
    PermissionsMixin,
    AccountingMixin,
    deviceMixin,
    FormattersMixin,
    ListingTaskMethodsMixin,
  ],
  props: ['listingTask', 'isReport'],
  data() {
    const { amount, management_fee, total_hours, deposit, tags } =
      this.listingTask
    return {
      expenseType:
        get('auto_listing_task_rule.auto_expense_type', this.listingTask) ||
        get('listing_task_catalog_item.auto_expense_type', this.listingTask),
      amount,
      total_hours,
      deposit,
      management_fee,
      tags,
      taskDialogMode: '', // can be of type 'expense' or 'summary'
      warrantyAmount: 500,
    }
  },
  mounted() {
    if (this.total_hours === null) {
      this.total_hours = getOr(
        null,
        'listing_task_catalog_item.avg_duration',
        this.listingTask
      )
    }
    if (this.payrollAssignee && this.totalAmount && this.amount === null) {
      this.amount = this.totalAmount
    }
  },
  methods: {
    debouncedInput: debounce(function (key) {
      if (this.listingTask[key] !== this[key]) {
        this.$store.dispatch('updateListingTask', {
          id: this.listingTask.id,
          [key]: this[key],
        })
      }
    }, 300), // 300ms delay
    amountValidator(value) {
      return (
        (!value && !this.deposit) ||
        (value && value >= this.deposit) ||
        'Amount should include the deposit'
      )
    },
    approveExpense() {
      const { total_hours } = this
      if (this.$refs.summaryForm.validate()) {
        this.$store.dispatch('approveListingTask', {
          id: this.listingTask.id,
          listingId: this.listingTask?.listing.id,
          expense_type: this.amount ? this.expenseType : null,
          total_hours: total_hours || 0,
          total_charge: this.amount || 0,
          showLoading: !this.isReport,
        })
      }
    },
    moveToDone() {
      if (this.$refs.summaryForm.validate()) {
        const { total_hours } = this
        const payload = {
          id: this.listingTask.id,
          status: 'Done',
          total_hours: total_hours || 0,
          amount: this.amount,
        }
        if (this.listingTask.in_loop) {
          const condition =
            this.listingTask.listing_task_template.loop_condition_text
          this.$store.commit('showModal', {
            name: 'LtLoopMoveToDoneModal',
            props: { condition, payload, listingTask: this.listingTask },
          })
        } else {
          this.$store.dispatch('updateListingTask', payload)
        }
      }
    },
    removeMaterial(id) {
      this.$store.dispatch('removeRepairMaterials', {
        id: this.listingTask.id,
        expenseId: id,
      })
    },
    undoApprove() {
      this.$store.dispatch('undoApproval', this.listingTask.id)
    },
    removeFee() {
      this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        management_fee: '',
      })
    },
    showMaterialsExpense(expense) {
      this.$store.commit('showModal', {
        name: 'ExpenseCard',
        props: {
          expense,
          listingTask: this.listingTask,
        },
      })
    },
    showExpense() {
      this.$store.commit('showModal', {
        name: 'ExpenseCard',
        props: {
          expense: this.listingTask.expense,
          listingTask: this.listingTask,
          subTasks: this.listingTask.sub_tasks,
        },
      })
    },
    showSummary() {
      this.$store.commit('showModal', {
        name: 'SummaryCard',
        props: {
          listingTask: this.listingTask,
        },
        isPersistent: false,
      })
    },
    openRejectModal() {
      this.$store.commit('showModal', {
        name: 'RejectModal',
        props: {
          listingTaskId: this.listingTask.id,
          status: TASK_STATUSES.REJECTED,
        },
      })
    },
    editCatalogItem() {
      this.$store.commit('showModal', {
        name: 'EditCatalogItemModal',
        props: {
          catalogItem: this.listingTask.listing_task_catalog_item,
          onChange: () => {
            this.$store.dispatch('getListingTaskBg', this.listingTask.id)
          },
        },
      })
    },
  },
  computed: {
    ...mapState(['listingTaskLoading']),
    ...mapGetters('users', ['usersMap']),
    ...mapGetters(['currentUser']),
    readonly() {
      return this.listingTask.status === 'Owner Approval'
    },
    expenseTypesTran() {
      return this.expensesTypes.map(e => ({ text: this.$t(e), value: e }))
    },
    hasWarranty() {
      return this.listingTask.listing_task_warranty
    },
    extraData() {
      return this.listingTask.extraData
    },
    undoDisabled() {
      if (this.listingTask.expense) {
        return this.canDeleteFinancialData(
          this.listingTask.expense.paid_at,
          this.currentUser
        )
      }
      return false
    },
    isCompensation() {
      return (
        this.listingTask.sub_topic &&
        this.listingTask.sub_topic.includes('Compensation')
      )
    },
    isReviewInterrogation() {
      return this.listingTask.review_id
    },
    isClaim() {
      return (
        this.listingTask.sub_topic &&
        this.listingTask.sub_topic.includes('Claim')
      )
    },
    perHour() {
      if (this.laborCost && this.totalHours) {
        return this.laborCost / this.totalHours
      }
      return 0
    },
    listingDeposit() {
      return this.listingTask.deposit
    },
    managementFee() {
      return this.listingTask.management_fee || 0
    },
    expenseTypeRequired() {
      return this.amount > 0
    },
    inspectionMode() {
      return this.listingTask.task_type === 'inspection'
    },
    avgPrice() {
      if (this.listingTask.listing_task_catalog_item) {
        return (
          (this.listingTask.listing_task_catalog_item.min_amount +
            this.listingTask.listing_task_catalog_item.max_amount) /
          2
        )
      }
      return 0
    },
    avgTime() {
      if (this.listingTask.listing_task_catalog_item) {
        return this.listingTask.listing_task_catalog_item.avg_duration || 0
      }
      return 0
    },
    taskDone() {
      return this.isDone(this.listingTask.status)
    },
    ownerApproved() {
      return this.listingTask.owner_approved_at
    },
    hoursDisabled() {
      if (this.readonly) {
        return true
      }
      return (
        this.listingTask.manual_approved ||
        (this.taskDone && !this.managementRoles)
      )
    },
    isAmountDisabled() {
      if (this.readonly) {
        return true
      }
      if (this.ruleFixedPrice && !this.managementRoles) {
        return true
      }
      if (this.isDesignedVr && this.externalContractor) {
        return true
      }
      if (!this.subTasksDone) {
        return true
      }
      return (
        this.listingTask.is_paid ||
        this.listingTask.manual_approved ||
        (!this.contractor.on_payrole && this.isContractor && this.taskDone)
      )
    },
    isApprovedDisabled() {
      const expenseRequired = this.expenseTypeRequired && !this.expenseType
      const { requested_approver_id } = this.listingTask
      if (requested_approver_id) {
        return requested_approver_id !== this.currentUser.id || expenseRequired
      }
      return expenseRequired
    },
    contractor() {
      if (this.listingTask.assigned_contractor_id === this.currentUser.id) {
        return this.currentUser
      }
      return this.usersMap[this.listingTask.assigned_contractor_id] || {}
    },
    laborCost() {
      if (!isNaN(this.amount)) {
        return this.amount
      }
      return null
    },
    hasSubTasks() {
      return !isEmpty(this.listingTask.sub_tasks)
    },
    subTasksDone() {
      if (
        this.listingTask.task_type === 'cleaning' ||
        this.listingTask.task_type === 'inspection'
      ) {
        return every(
          t => t.is_valid || t.status === 'Done',
          this.listingTask.sub_tasks
        )
      }
      return every(
        t => t.status === 'Done' || t.status === "Can't do",
        this.listingTask.sub_tasks
      )
    },
    totalHours() {
      return this.listingTask.total_hours
    },
    materialsSum() {
      return sumBy('amount', this.listingTask.material_expenses)
    },
    catalogHasPrice() {
      return isNumber(this.listingTask.mini_extra_data.avg_price_per_hour)
    },
    catalogHasDuration() {
      if (!this.listingTask.listing_task_catalog_item) {
        return false
      }
      return isNumber(this.listingTask.listing_task_catalog_item.avg_duration)
    },
    externalAssignee() {
      return (
        (this.contractor.role === 'contractor' ||
          this.contractor.role === 'cleaner') &&
        !this.contractor.on_payrole
      )
    },
    payrollAssignee() {
      return this.contractor.role === 'contractor' && this.contractor.on_payrole
    },
    ruleFixedPrice() {
      const ruleAmount = get('auto_listing_task_rule.amount', this.listingTask)
      return isNumber(ruleAmount)
    },
    totalAmount() {
      if (this.externalAssignee) {
        return this.amount
      }
      const ruleAmount = get('auto_listing_task_rule.amount', this.listingTask)
      if (isNumber(ruleAmount)) {
        return ruleAmount
      }
      let totalHours = this.total_hours
      const avgPrice = get(
        'mini_extra_data.avg_price_per_hour',
        this.listingTask
      )
      if (totalHours != null && avgPrice != null) {
        return totalHours * avgPrice
      }
      return this.amount
    },
    totalHint() {
      const hourlyPaid = this.contractor.hourly_pay
      const totalHours = this.listingTask.total_hours
      if (this.listingTask.reg_cleaning && this.listingTask.total_hours) {
        return 30 * this.listingTask.total_hours
      }
      if (this.subTasksDone && !this.contractor.on_payrole) {
        return sumBy('price', this.listingTask.sub_tasks) || ''
      }
      if (totalHours && hourlyPaid) {
        return totalHours * hourlyPaid
      }
      return '0'
    },
    amountHint() {
      if (!this.subTasksDone) {
        return this.$t('Not all tasks are done')
      }
      if (this.managementRoles) {
        return this.totalHint.toString()
      }
      return ''
    },
    totalTime() {
      return sumBy('duration', this.working)
    },
    clockInHint() {
      return (this.totalTime || 0) / 60 + ' Hours (by clock-ins)'
    },
    working() {
      return this.listingTask.working_cleaners
    },
    canApproveTask() {
      return (
        (this.hasAbility('task-approval') ||
          this.isAdmin ||
          this.isPropertyManager) &&
        this.taskDone &&
        !this.listingTask.manual_approved
      )
    },
    pendingCaptureStoreItem() {
      return (
        get('store_item_purchase.status', this.listingTask) === 'authorized'
      )
    },
    paymentApproved() {
      return (
        this.listingTask.ach_transaction &&
        this.listingTask.ach_transaction.status === 'Confirmed'
      )
    },
  },
  watch: {
    totalHours(val) {
      if (!isNaN(val)) {
        this.total_hours = val
        this.amount = this.totalAmount
      }
    },
    listingDeposit(val) {
      this.deposit = val
    },
    managementFee(val) {
      this.management_fee = val
    },
  },
}
</script>

<style scoped>
.v-divider {
  border-color: #e1e1e1 !important;
}
</style>
