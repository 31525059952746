import { every } from 'lodash/fp'
import pickBy from 'lodash/fp/pickBy'
import uniq from 'lodash/uniq'
import map from 'lodash/map'
import compact from 'lodash/compact'
import { getQuillDefaultOptions } from '@/utils/editor-options'
import { TASK_STATUSES } from '@/consts'
import filter from 'lodash/filter'
import ExpoNotifications from '@/utils/expoNotifications'
import orderBy from 'lodash/orderBy'

export default {
  methods: {
    isDone(status) {
      return (
        status === TASK_STATUSES.DONE ||
        status === TASK_STATUSES.CANT_DO ||
        status === TASK_STATUSES.CLOSED
      )
    },
    filterBySubject(subject) {
      const subjectValue = subject === 'Sub Tasks' ? null : subject
      return orderBy(
        filter(
          this.currentListingTask.sub_tasks,
          subTask => subTask.list_subject === subjectValue
        ),
        ['id'],
        ['asc']
      )
    },
    sendImagesUpdatedNotification(id) {
      ExpoNotifications.dashboardPush({
        title: 'Images Updated Successfully',
        link_task_id: id,
      })
    },
    addDescImages(images) {
      this.updateTask({
        desc_images: this.currentListingTask.desc_images.concat(images),
      })
      this.sendImagesUpdatedNotification()
    },
    addReceiptImages(images) {
      this.updateTask({
        receipt_images: this.currentListingTask.receipt_images.concat(images),
      })
      this.sendImagesUpdatedNotification()
    },
    removeDescImg(link) {
      const desc_images = this.currentListingTask.desc_images.filter(
        img => img !== link
      )
      this.updateTask({ desc_images })
    },
    rotateDescImg(oldLink) {
      return newLink => {
        const desc_images = this.currentListingTask.desc_images.map(img =>
          img === oldLink ? newLink : img
        )
        this.updateTask({ desc_images })
      }
    },
    rotateReceipt(oldLink) {
      return newLink => {
        const receipt_images = this.currentListingTask.receipt_images.map(img =>
          img === oldLink ? newLink : img
        )
        this.updateTask({ receipt_images })
      }
    },
    removeReceipt(link) {
      const receipt_images = this.currentListingTask.receipt_images.filter(
        img => img !== link
      )
      this.updateTask({ receipt_images })
    },
    changeDescription(description) {
      this.updateTask({ description })
    },
    commentInternal(comment) {
      return this.updateTask({ internal_comments: comment })
    },
    updateTask(payload) {
      return this.$store.dispatch('updateListingTask', {
        id: this.currentListingTask.id,
        ...payload,
      })
    },
    addComment({ html, parent_id }) {
      return this.$store.dispatch('addComment', {
        id: this.currentListingTask.id,
        html,
        parent_id,
      })
    },
  },
}
