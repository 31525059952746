import axios from '@/axios/config'

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    setReservations(state, payload) {
      state.list = payload
    },
  },
  actions: {
    async alterReservation(store, { id, ...payload }) {
      try {
        const { data } = await axios.put(`/api/reservations/${id}`, payload)
        return data
      } catch (err) {
        console.error(err)
      }
    },
    async updateReservation({ commit }, { id, ...payload }) {
      commit('updateListingLoading', true)
      try {
        const { data } = await axios.put(
          `/api/reservations/${id}/info`,
          payload
        )
        commit('updateListingLoading', false)
        return data
      } catch (err) {
        commit('updateListingLoading', false)
        console.error(err)
      }
    },
    async createReservation({ commit }, payload) {
      try {
        commit('updateLoading', true, { root: true })
        const { data } = await axios.post(`/api/reservations/`, payload)
        commit('updateLoading', false, { root: true })
        return data
      } catch (err) {
        commit('updateLoading', false, { root: true })
        console.error(err)
      }
      return {}
    },
    async createInternalReservation({ commit }, payload) {
      try {
        commit('updateLoading', true, { root: true })
        const { data } = await axios.post(`/api/reservations/internal`, payload)
        commit('updateLoading', false, { root: true })
        return data
      } catch (err) {
        commit('updateLoading', false, { root: true })
        console.error(err)
      }
      return {}
    },
    async getReservation({ commit }, { id, include_cal, refresh }) {
      const { data } = await axios.get(`/api/reservations/${id}`, {
        params: { include_cal, refresh },
      })
      commit('setCurrentListingCalendar', { data }, { root: true })
      return data
    },
    async getReservations({ commit }, date) {
      try {
        const { data } = await axios.get('/api/reservations', {
          params: {
            date,
          },
        })
        commit('setReservations', data)
      } catch (err) {
        console.log(err)
      }
    },
    async searchReservations(store, { term }) {
      const { data } = await axios.get('/api/conversations/search', {
        params: { term },
      })
      return data
    },
    async addInvoice(store, { reservation_id, ...data }) {
      return axios.post(`/api/reservation/${reservation_id}/add-invoice`, data)
    },
    async emailInvoice(_, { id, ...data }) {
      return axios.post(`/api/reservations/${id}/email-invoice`, data)
    },
  },
}
