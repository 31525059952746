<template>
  <v-row>
    <v-col cols="8">
      <v-select
        v-model="app"
        class="mt-2 mb-2"
        label="Application"
        outlined
        dense
        hide-details
        item-text="company_name"
        return-object
        :items="apps"
        @change="appChange"
      />
      <filters-select
        :value.sync="reports"
        class="mt-2 mb-2"
        label="Report"
        outlined
        dense
        clearable
        hide-details
        multiple
        :selection-func="item => item.text"
        :items="items"
        small-chips
      />
      <v-select
        v-model="approvedModules"
        class="mt-2 mb-2"
        dense
        outlined
        hide-details
        multiple
        small-chips
        label="Modules"
        :items="[
          'ops',
          'ai',
          'accounting',
          'comm',
          'bi',
          'guest-exp',
          'channel-manager',
          'projects',
          'store',
          'hotels',
          'pricing',
        ]"
      />
      <v-select
        v-model="calculation"
        dense
        class="mt-2 mb-2"
        outlined
        hide-details
        label="Calculation"
        :items="['active_listings', 'active_listings_with_reservations']"
      />
      <v-text-field
        v-model.number="pricePerDoor"
        class="mt-2 mb-2"
        label="PPD"
        outlined
        dense
        hide-details
      />

      <v-btn color="primary" outlined @click="save"> Save</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import FiltersSelect from 'components/form-fields/filters-select.vue'
export default {
  name: 'BiManagement',
  components: { FiltersSelect },
  data() {
    return {
      reportsItems: [],
      reports: [],
      app: null,
      calculation: null,
      apps: [],
      approvedModules: [],
    }
  },
  async mounted() {
    const { data } = await axios.get('/api/bi/reports/management')
    this.apps = data.apps
    this.reportsItems = data.reports
  },
  methods: {
    appChange(app) {
      const appReportsKeys = app.bi_reports.map(r => r.key)
      this.reports = this.reportsItems
        .filter(r => appReportsKeys.indexOf(r.key) !== -1)
        .map(r => r.key)
      this.approvedModules = app.approved_modules
      this.pricePerDoor = app.boom_payment_model.per_door
      this.calculation = app.boom_payment_model.calculation
    },
    async save() {
      axios.patch(`/api/settings/${this.app.id}/reports`, {
        bi_reports: this.reports,
        modules: this.approvedModules,
        per_door: this.pricePerDoor,
        calculation: this.calculation,
      })
    },
  },
  computed: {
    items() {
      return this.reportsItems.map(r => ({ text: r.title, value: r.key }))
    },
  },
}
</script>

<style scoped></style>
