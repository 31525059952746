<template>
  <key-base-integration />
</template>

<script>
import KeyBaseIntegration from 'components/marketplace/key-base-integration.vue'

export default {
  name: 'Optima',
  components: { KeyBaseIntegration },
}
</script>

<style scoped></style>
