<template>
  <v-layout wrap> LOS </v-layout>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

export default {
  components: {},
  mixins: [CommonFunctions, PermissionsMixin],
  data: function () {
    return {}
  },

  methods: {},
}
</script>
