import { LISTING_TYPES } from '@/consts'

const UTILITIES = [
  'provider_name',
  'account_number',
  'account_holder_name',
  'billing_address',
  'billing_phone',
  'website',
  'username',
  'password',
]

const HOTEL_UNIT_CODES = [
  'Main door guests #1',
  'Main door guests #2',
  'Main door private',
  'Main door cleaning',
  'Private wifi name',
  'Private wifi password',
  'Gate code',
]

export const SERVICES = {
  door_codes: [
    'Main door guests #1',
    'Main door guests #2',
    'Main door cleaning',
    'Main door private',
    'Secondary Door',
    'Laundry Door',
    'Main Gate',
    'Additional door',
  ],
  internal_codes: [
    'reserve key box',
    'reserve key location',
    'cleaning storage',
    'maintenance storage',
    'pool equipment storage',
  ],
  master_codes: ['Main door master'],
  parking_capacity: ['spaces'],
  iot: ['cctv', 'ring ping code', 'echobee serial'],
  wifi: ['Main wifi name', 'Main wifi code'],
  internet: [...UTILITIES],
  water: [...UTILITIES],
  electricity: [...UTILITIES],
  association: [...UTILITIES],
  insurance: [...UTILITIES],
  gas: [...UTILITIES],
  septi: [...UTILITIES],
  custom_codes: [],
}

const HOTEL_SERVICES = units => ({
  door_codes: ['Laundry room', 'Storage room'],
  units_codes: units.map(unit => ({
    [unit.nickname]: [...HOTEL_UNIT_CODES],
  })),
  internal_codes: [
    'reserve key box',
    'reserve key location',
    'cleaning storage',
    'maintenance storage',
    'pool equipment storage',
  ],
  master_codes: ['Main door master'],
  iot: ['cctv', 'ring ping code', 'echobee serial'],
  wifi: ['Main wifi name', 'Main wifi code'],
  internet: [...UTILITIES],
  water: [...UTILITIES],
  electricity: [...UTILITIES],
  association: [...UTILITIES],
  insurance: [...UTILITIES],
  custom_codes: [],
})

const mapUnits = (sectionKey, service, services) => {
  let fields = {}
  Object.keys(service).forEach(unit => {
    service[unit].forEach(field => {
      fields[field] =
        (services[sectionKey] && services[sectionKey][unit][field]) || ''
    })
  })
  return fields
}

export const initialServices = listing => {
  const { services, units, listing_type } = listing || {}
  const SERVICE_CODES =
    listing_type === LISTING_TYPES.HOTEL ? HOTEL_SERVICES(units) : SERVICES

  const mappedServices = Object.keys(SERVICE_CODES).reduce(
    (sections, sectionKey) => {
      sections[sectionKey] = SERVICE_CODES[sectionKey].reduce(
        (sectionServices, serviceKey) => {
          if (typeof serviceKey === 'object') {
            sectionServices[Object.keys(serviceKey)[0]] = mapUnits(
              sectionKey,
              serviceKey,
              services
            )
          } else {
            sectionServices[serviceKey] =
              (services[sectionKey] && services[sectionKey][serviceKey]) || ''
          }
          return sectionServices
        },
        { ...services[sectionKey] }
      )
      return sections
    },
    {}
  )
  const allServices = { ...services, ...mappedServices }
  if (!allServices.custom_codes) allServices['custom_codes'] = {}
  return allServices
}

export const SERVICE_LABELS = {
  door_codes: 'Door codes',
  provider_name: 'Provider Name',
  account_number: 'Account Number',
  account_holder_name: 'Account Holder Name',
  billing_address: 'Billing Address',
  billing_phone: 'Billing Phone',
  iot: 'IOT',
  internal_codes: 'Internal Codes',
  master_codes: 'Master Codes',
  custom_codes: 'Custom Codes',
  units_codes: 'Unit Codes',
  gas: 'Gas',
  septi: 'Septi/Sewer',
  parking_capacity: 'Parking Capacity',
}
