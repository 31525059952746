<template>
  <div :style="`height:${buttonHeight}px`">
    <camera
      v-if="openCamera"
      @closed="openCamera = false"
      @upload="fileInputChanged"
    />
    <v-progress-linear v-if="progress" :value="progress"></v-progress-linear>
    <input
      v-show="false"
      ref="file"
      type="file"
      :multiple="multiple"
      accept="image/png,image/jpeg,image/jpg,image/webp,video/*,.pdf"
      @change="fileInputChanged"
    />

    <div>{{ label }}</div>
    <v-menu v-if="isAndroid" top right>
      <template #activator="{ on }">
        <v-sheet
          class="align-center upload-box rounded justify-center d-flex flex-column py-4"
          :class="disabled && 'opacity-30'"
          height="100%"
          width="100%"
          v-on="on"
        >
          <v-progress-circular
            v-if="loader && isUploading"
            indeterminate
            color="primary"
          />
          <v-icon v-else color="info">mdi-file-image-plus</v-icon>
          <div class="info--text">{{ camera ? 'Camera' : buttonText }}</div>
        </v-sheet>
      </template>
      <v-list dense>
        <v-list-item dense @click="openCamera = true">
          <v-list-item-title class="info--text">Camera</v-list-item-title>
        </v-list-item>
        <v-list-item dense @click="openFileInputDialog">
          <v-list-item-title class="info--text">Gallery</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-sheet
      v-else-if="thumbnail && !isAndroid"
      class="align-center upload-box rounded justify-center d-flex flex-column py-4"
      :class="disabled && 'opacity-30'"
      height="100%"
      width="100%"
      @click="openFileInputDialog"
    >
      <v-progress-circular
        v-if="loader && isUploading"
        indeterminate
        color="primary"
      />
      <v-icon v-else color="info">mdi-file-image-plus</v-icon>
      <div class="info--text text-center">
        {{ camera ? 'Camera' : buttonText }}
      </div>
    </v-sheet>

    <v-btn
      v-else
      small
      outlined
      color="info"
      :disabled="disabled"
      @click="openFileInputDialog"
    >
      <v-progress-circular
        v-if="loader && isUploading"
        indeterminate
        color="primary"
        size="14"
      />
      <v-icon v-else small>upload</v-icon>
      {{ buttonText }}
    </v-btn>
    <span v-show="required && errorMessage" class="error--text">{{
      errorMessage
    }}</span>
    <v-row v-if="preview" class="ma-0">
      <v-row v-if="multiple">
        <v-col v-for="file in value" :key="file" :cols="previewCols">
          <ImageView :on-remove="removeImg" small-width="100%" :src="file" />
        </v-col>
      </v-row>
      <v-col v-else :cols="previewCols">
        <ImageView :on-remove="removeImg" small-width="100%" :src="value" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ImageView from 'components/common/image-view'
import { uploadToCloudinary } from '@/utils/uploadToCloudinary'
import DeviceMixin from 'components/mixins/device-mixin'
import { mapGetters } from 'vuex'
import Camera from 'components/layouts/camera.vue'

export default {
  name: 'MediaUpload',
  components: { Camera, ImageView },
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  props: {
    value: {
      type: [Array, String],
    },
    onChange: {
      type: Function,
      default: () => ({}),
    },
    limitation: {
      type: Function,
    },
    buttonHeight: {
      type: [Number],
    },
    thumbnail: {
      type: Boolean,
      default: false,
    },
    isAndroid: {
      type: Boolean,
      default: false,
    },
    uploading: {
      type: Boolean,
      default: false,
    },
    camera: {
      type: Boolean,
      default: false,
    },
    loader: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    previewCols: {
      type: Number,
      default: 2,
    },
    buttonText: {
      type: String,
      default: 'Choose photo',
    },
    label: {
      type: String,
    },
    folder: {
      type: String,
    },
    compress: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isUploading: false,
      progress: null,
      openCamera: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserId']),
  },
  methods: {
    openFileInputDialog() {
      if (!this.disabled) {
        this.$refs.file.click()
      }
    },
    async filterFilesByResolution(files) {
      const checks = files.map(file => this.limitation(file))
      const results = await Promise.all(checks)

      return files.filter((_, index) => results[index])
    },
    async fileInputChanged(newFiles) {
      let payload = Array.isArray(newFiles)
        ? newFiles
        : Object.values(newFiles.target.files)
      if (this.limitation) {
        payload = await this.filterFilesByResolution(payload)
      }
      const onUploadingChange = value => {
        this.isUploading = value
        this.$emit('update:uploading', value)
      }
      const onUploadProgress = value => {
        this.progress = value
        this.$emit('update:progress', value)
      }

      const config = {
        folder: this.folder,
        compress: this.compress,
      }
      uploadToCloudinary(
        payload,
        {
          onUploadingChange,
          onUploadProgress,
        },
        config
      )
        .then(urls => {
          this.progress = null
          let value = this.multiple ? (this.value || []).concat(urls) : urls[0]
          this.$emit('update:value', value)
          this.onChange(value)
        })
        .catch(err => {
          this.error = err
        })
    },
    removeImg(src) {
      let value = null
      if (this.multiple) {
        value = this.value.filter(f => f !== src)
      }
      this.$emit('update:value', value)
      this.onChange(value)
    },
  },
}
</script>

<style scoped>
.upload-box {
  border: 1px dashed var(--v-info-base);
  position: relative;
  cursor: pointer;
}
</style>
