var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{staticClass:"mt-2 mb-2",attrs:{"label":"Application","outlined":"","dense":"","hide-details":"","item-text":"company_name","return-object":"","items":_vm.apps},on:{"change":_vm.appChange},model:{value:(_vm.app),callback:function ($$v) {_vm.app=$$v},expression:"app"}}),_c('filters-select',{staticClass:"mt-2 mb-2",attrs:{"value":_vm.reports,"label":"Report","outlined":"","dense":"","clearable":"","hide-details":"","multiple":"","selection-func":item => item.text,"items":_vm.items,"small-chips":""},on:{"update:value":function($event){_vm.reports=$event}}}),_c('v-select',{staticClass:"mt-2 mb-2",attrs:{"dense":"","outlined":"","hide-details":"","multiple":"","small-chips":"","label":"Modules","items":[
        'ops',
        'ai',
        'accounting',
        'comm',
        'bi',
        'guest-exp',
        'channel-manager',
        'projects',
        'store',
        'hotels',
        'pricing',
      ]},model:{value:(_vm.approvedModules),callback:function ($$v) {_vm.approvedModules=$$v},expression:"approvedModules"}}),_c('v-select',{staticClass:"mt-2 mb-2",attrs:{"dense":"","outlined":"","hide-details":"","label":"Calculation","items":['active_listings', 'active_listings_with_reservations']},model:{value:(_vm.calculation),callback:function ($$v) {_vm.calculation=$$v},expression:"calculation"}}),_c('v-text-field',{staticClass:"mt-2 mb-2",attrs:{"label":"PPD","outlined":"","dense":"","hide-details":""},model:{value:(_vm.pricePerDoor),callback:function ($$v) {_vm.pricePerDoor=_vm._n($$v)},expression:"pricePerDoor"}}),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.save}},[_vm._v(" Save")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }