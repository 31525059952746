<template>
  <form-dialog title="Reservation Edit" @submit="submit" @open="getRates">
    <template #activator="{ on }">
      <v-icon class="pointer" :color="color || 'info'" v-on="on"
        >mdi-pencil</v-icon
      >
    </template>
    <v-row v-if="ratesLoaded" no-gutters>
      <v-col cols="12">
        <v-autocomplete
          v-model="reservation.listingId"
          label="Listing"
          :items="listingsItems"
          dense
          item-text="nickname"
          item-value="id"
          outlined
          @change="getRates"
        />
      </v-col>
      <v-col cols="12">
        <date-picker
          v-model="reservation.range"
          :range="true"
          :hide-details="true"
          :allowed-dates="allowedDates"
          :event-color="eventColors"
          label="Dates"
        />
        <div class="d-flex justify-space-between">
          <span class="text-subtitle-2">Origin accommodation fare:</span>
          <span class="text-caption">{{
            dollarFormatter(originAccommodation)
          }}</span>
        </div>
        <div class="d-flex justify-space-between">
          <span class="text-subtitle-2">New accommodatio fare:</span>
          <span class="text-caption">{{
            dollarFormatter(totalAccommodation)
          }}</span>
        </div>
        <div class="text-caption error--text pb-3 pt-1">
          * Taxes are calculated automatically
        </div>
        <div>
          <v-checkbox
            v-model="preservePrice"
            dense
            class="mt-0"
            label="Preserve price"
          />
          <v-text-field
            v-if="!preservePrice"
            v-model.number="reservation.fareAccommodation"
            label="Accommodation Fare"
            dense
            outlined
          />
        </div>
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="reservation.status"
          outlined
          dense
          :items="['confirmed', 'inquiry', 'canceled']"
          :label="$t('Status')"
        />
      </v-col>
    </v-row>
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import DatePicker from 'components/form-fields/date-picker'
import CommonFunctions from 'components/mixins/common_functions'
import { mapGetters } from 'vuex'
import equal from 'lodash/fp/isEqual'
import isEmpty from 'lodash/fp/isEmpty'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'ReservationEditModal',
  components: { DatePicker, FormDialog },
  mixins: [CommonFunctions, FormattersMixin],
  props: [
    'checkIn',
    'checkOut',
    'listing',
    'reservationId',
    'color',
    'status',
    'fareAccommodation',
    'cleaningFee',
  ],
  data() {
    return {
      preservePrice: true,
      currentRates: {},
      reservation: {
        range: [this.parseDate(this.checkIn), this.parseDate(this.checkOut)],
        listingId: this.listing.id,
        status: this.status,
        fareAccommodation: null,
      },
    }
  },
  async mounted() {
    this.currentRates = await this.$store.dispatch(
      'listings/getListingRates',
      this.reservation.listingId
    )
  },
  watch: {
    totalAccommodation(val) {
      this.reservation.fareAccommodation = val
    },
  },
  computed: {
    ...mapGetters(['listingsPicker']),
    ratesLoaded() {
      return !isEmpty(this.currentRates)
    },
    listingsItems() {
      if (this.listing.listing_type === 'unit' || this.listing.is_multi_unit) {
        return this.listingsPicker.filter(l => l.listing_type === 'unit')
      }
      return this.listingsPicker.filter(l => l.listing_type !== 'unit')
    },
    originAccommodation() {
      if (this.listing.is_resort) {
        return this.fareAccommodation + (this.cleaningFee || 0)
      }
      return this.fareAccommodation
    },
    totalAccommodation() {
      const dateArr = this.generateDateRangeArray(
        this.reservation.range[0],
        this.reservation.range[1]
      )
      if (isEmpty(this.currentRates)) {
        return 0
      }
      return dateArr.reduce((sum, item) => {
        if (!this.currentRates[item]) {
          return sum
        }
        return sum + this.currentRates[item].price
      }, 0)
    },
  },
  methods: {
    async getRates() {
      this.currentRates = await this.$store.dispatch(
        'listings/getListingRates',
        this.reservation.listingId
      )
    },
    allowedDates(date) {
      if (isEmpty(this.currentRates)) {
        return true
      }
      const dayBefore = this.$moment(date)
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
      return (
        (this.currentRates[date] &&
          this.currentRates[date].status === 'available') ||
        this.$moment(date).isBetween(
          this.parseDate(this.checkIn),
          this.parseDate(this.checkOut),
          undefined,
          '[]'
        ) ||
        (this.currentRates[dayBefore] &&
          this.currentRates[date] &&
          this.currentRates[date].status !== 'available' &&
          this.currentRates[dayBefore].status === 'available' &&
          this.reservation.listingId === this.listing.id)
      )
    },
    eventColors(date) {
      if (
        this.currentRates[date] &&
        this.currentRates[date].status === 'available'
      ) {
        return 'green lighten-1'
      }
      return ''
    },
    async submit() {
      const payload = {}
      payload.listing_id = this.reservation.listingId
      payload.fare_accommodation = this.preservePrice
        ? this.originAccommodation
        : this.reservation.fareAccommodation
      if (
        !equal(this.reservation.range, [
          this.parseDate(this.checkIn),
          this.parseDate(this.checkOut),
        ])
      ) {
        payload.check_in = this.reservation.range[0]
        payload.check_out = this.reservation.range[1]
      }
      if (this.reservation.status !== this.status) {
        payload.status = this.reservation.status
      }
      this.$store.commit('updateLoading', true)
      const response = await this.$store.dispatch(
        'reservation/alterReservation',
        {
          id: this.reservationId,
          ...payload,
        }
      )
      this.$store.commit('updateLoading', false)
      if (response) {
        this.$emit('change')
      }
    },
  },
}
</script>

<style scoped></style>
