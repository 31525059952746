<template>
  <tr>
    <td v-if="!listingManageContext" class="text-center">
      <v-flex>
        <v-checkbox
          v-if="isPropertyManager || isAdmin"
          v-model="item.selected"
          hide-details
          @click.native.stop="addAutoRuleSelected(item)"
        />
      </v-flex>
      <v-flex>
        <v-icon
          v-if="!viewMode && (isAdmin || isPropertyManager)"
          small
          style="cursor: pointer"
          @click="updateEditRule(item)"
        >
          edit
        </v-icon>
      </v-flex>
      <v-flex>
        <v-icon
          v-if="!viewMode && (isAdmin || isPropertyManager)"
          small
          class="mr-2"
          style="cursor: pointer"
          @click="deleteRule(item.id)"
        >
          delete
        </v-icon>
      </v-flex>
    </td>
    <td v-if="!listingManageContext" class="text-center">
      <v-row class="row wrap">
        <v-col cols="12">
          <span v-if="isAdmin" class="small-text grey--text">
            {{ item.id }}</span
          >
          <div
            v-if="item.is_master_rule"
            class="purple-icon bolder pointer"
            @click="$emit('open-rules')"
          >
            MASTER RULE ({{ item.master_stats.rules_amount }} rules)
          </div>
          <div v-else>
            {{ item.listing_nickname }}
            <span v-if="item.created_auto" class="small-text"
              >* Automated rule</span
            >
          </div>
        </v-col>
      </v-row>
    </td>
    <td class="text-center">
      <v-layout
        wrap
        align-center
        :style="item.active ? '' : 'min-width: 85px;'"
      >
        <v-flex>
          <v-switch
            v-if="!viewMode"
            v-model="item.active"
            @change="itemChange($event, 'active')"
          ></v-switch>
          <span
            v-if="viewMode"
            :class="item.active ? 'green-health' : 'red-health'"
          >
            {{ item.active ? 'On' : 'Off' }}
          </span>
        </v-flex>
      </v-layout>
    </td>
    <td class="text-center">
      <v-row class="column">
        <v-col cols="12" class="pa-0">
          <span class="cyan-icon bolder mr-1"
            >{{ item.task_type }} - {{ item.sub_topic }}</span
          >
        </v-col>
        <v-col cols="12" class="pa-0">
          <span>{{ truncateBy(item.description, 120) }}</span>
        </v-col>
      </v-row>
    </td>
    <td class="text-center">
      <span v-if="item.amount || item.amount === 0" class="purple-icon">{{
        item.amount
      }}</span>
      <span v-else>Not specified</span>
    </td>
    <td class="text-center">
      Every
      <span
        v-if="
          item.rules.every_priod && !item.rules.every_priod.includes('check')
        "
        >{{ item.rules.every_number }}</span
      >
      {{ item.rules.every_priod }}
      <v-dialog v-model="open" max-width="900px">
        <template #activator="{ on }">
          <v-icon small class="icon alt" color="primary" v-on="on"
            >fas fa-question-circle
          </v-icon>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">Tasks</span>
          </v-card-title>

          <v-card-text>
            <v-layout wrap style="min-width: 210px">
              <v-flex v-if="item.rules.allowed_days" xs12>
                <span class="white--text ml-2"
                  >At {{ item.rules.allowed_days.join(',') }} only</span
                >
              </v-flex>
              <v-flex v-if="item.rules.max_days_between" xs12>
                <span class="white--text ml-2"
                  >Max {{ item.rules.max_days_between }} days between</span
                >
              </v-flex>
              <v-flex v-if="item.rules.min_days_between" xs12>
                <span
                  class="white--text ml-2"
                  style="border-bottom: 1px dotted white"
                  >Min {{ item.rules.min_days_between }} days between</span
                >
              </v-flex>
            </v-layout>
            <v-layout
              v-for="(task, index) in item.created_tasks"
              :key="index"
              wrap
              class="bb-1 pb-1"
              style="min-width: 350px"
            >
              <v-flex class="pl-2 xs12">
                <router-link
                  class="ml-1 secondary--text"
                  target="_blank"
                  :to="{
                    path: `/dashboard/listing-task/${task.id}`,
                  }"
                >
                  <span>{{ task.id }} </span>
                </router-link>
                |
                <span v-if="task.contractor_name">{{
                  task.contractor_name
                }}</span>
                <span v-else>{{ nameById(task.assigned_contractor_id) }}</span>
                | {{ parseDate(task.scheduled_at) }} - {{ task.status }} - Rule:
                {{ task.auto_reason }}
                <span v-if="task.auto_overriden" class="pl-3 bolder"
                  >*Overriden*
                  <span
                    v-if="
                      parseDate(task.original_scheduled_at) !=
                      parseDate(task.scheduled_at)
                    "
                    >moved from orignal:
                    {{ parseDate(task.original_scheduled_at) }}</span
                  ></span
                >
              </v-flex>
            </v-layout>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="open = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </td>
    <td class="text-center">
      {{ item.task_type }}
    </td>
    <td class="text-center">
      <div>
        {{
          item.personnel
            ? item.personnel.name
            : nameById(item.assigned_contractor_id)
        }}
      </div>
      <div
        v-if="item.personnel && !item.assigned_contractor_id"
        class="text-caption"
      >
        {{ item.personnel ? '(personnel)' : '' }}
      </div>
      <div
        v-if="
          item.personnel &&
          item.personnel.catalog &&
          !item.assigned_contractor_id
        "
        class="small-text"
      >
        by catalog
      </div>
    </td>
    <td class="text-center">
      {{ parseDate(item.created_at) }} <br v-if="item.rules.start_date" />
      <span v-if="item.rules.start_date" class="cyan-icon"
        >Starting: {{ parseDate(item.rules.start_date) }}</span
      >
    </td>
    <td class="text-center">
      {{
        item.last_done_task
          ? $moment.utc(item.last_done_task).format('YYYY-MM-DD')
          : 'Not Yet'
      }}
    </td>
    <td class="text-center">{{ item.planned_duration }} Hours</td>
  </tr>
</template>

<script>
import axios from 'axios'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

export default {
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['item', 'viewMode', 'afterChange'],
  data() {
    return {
      singleSelect: false,
      open: false,
      menu: false,
      menu2: false,
    }
  },
  computed: {},
  methods: {
    updateEditRule(item) {
      this.$store.commit('showModal', {
        name: 'AutoListingTaskRuleModal',
        props: {
          editMode: true,
          afterSaveFunc: this.afterChange,
          currentRule: Object.assign({}, item),
          listingId: this.$router.currentRoute.path.split('/')[3],
        },
      })
    },
    deleteRule() {
      const userConfirm = confirm('Are you sure you want to delete?')
      if (userConfirm) {
        axios
          .delete(`/api/auto-listing-tasks/${this.item.id}`)
          .then(() => {
            this.$store.commit('updateListingLoading', false)
            if (this.afterChange) {
              this.afterChange()
            } else {
              this.$store.dispatch('getAutoListingTaskRules', {
                id: this.listingManageContext
                  ? this.$router.currentRoute.path.split('/')[3]
                  : 'All',
              })
            }
          })
          .catch(error => {
            alert(error)
          })
      }
    },
    itemChange(val, key) {
      if (key === 'active' && !val && this.item.is_master_rule) {
        const userConfirm = confirm(
          'Are you sure you want to disable master rule? this will stop creating rules in the future.'
        )
        if (!userConfirm) {
          return
        }
      }
      this.item[key] = val

      this.$store.commit('updateListingLoading', true)
      axios
        .post(`/api/auto-listing-tasks/${this.item.id}`, this.item)
        .then(() => {
          this.$store.commit('updateListingLoading', false)
          if (this.afterChange) {
            this.afterChange()
          } else {
            this.$store.dispatch('getAutoListingTaskRules', {
              id: this.listingManageContext
                ? this.$router.currentRoute.path.split('/')[3]
                : 'All',
            })
          }
        })
        .catch(error => {
          alert(error)
        })
    },
    saveDate(date) {
      this.$refs.menu.save(date)
    },
  },
}
</script>
