<template>
  <v-container>
    <v-card class="overflow-y-auto">
      <v-card-title class="text-h6 text-uppercase d-flex justify-space-between">
        <div class="text-capitalize">{{ integrationName }}</div>
      </v-card-title>
      <v-card-text>
        <div class="text-body-1">
          Sync your bank accounts data with Boom dashboard
        </div>
        <div class="text-subtitle-1 font-weight-medium mt-4">
          Connected Institutions
        </div>
        <v-list>
          <v-list-item v-for="account in integration.creds" :key="account.key">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">{{
                account.value
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="d-flex flex-row">
              <plaid-link :disabled="btnLoading" :update="true" />
              <confirmation-modal
                v-if="integration"
                :text="`Are you sure you want to disconnect ${account.value} ?`"
                @action="disconnect"
              >
                <template #activator="{ on }">
                  <v-btn
                    outlined
                    dense
                    text
                    small
                    :loading="btnLoading"
                    class="mx-2"
                    color="error"
                    v-on="on"
                    >Remove</v-btn
                  >
                </template>
              </confirmation-modal>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="justify-end">
        <plaid-link v-if="!integration.id" />
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import PlaidLink from 'components/plaid/plaid-link.vue'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'
import axios from '@/axios/config'
import { mapGetters } from 'vuex'

export default {
  name: 'Plaid',
  data() {
    return {
      btnLoading: false,
    }
  },
  components: { ConfirmationModal, PlaidLink },
  computed: {
    ...mapGetters(['integrations']),

    integrationName() {
      return this.$route.params.name
    },
    integration() {
      return this.integrations.find(i => i.name === this.integrationName) || {}
    },
  },
  methods: {
    async disconnect() {
      this.btnLoading = true
      try {
        await axios.post('/api/plaid/disconnect')
      } catch (_e) {
        this.btnLoading = false
      }
      this.btnLoading = false
    },
  },
}
</script>

<style scoped></style>
