<template>
  <div>
    <v-progress-linear v-show="loadingGuestExpInfo" indeterminate />
    <conversation-drawer @update-conversations="updateConversations" />
    <v-row v-if="stats && !isMobile">
      <stat-card
        label="Today Sales"
        :value="dollarFormatter(stats.marked_sales_amount, 0)"
        :secondary="`${stats.marked_sales_count} Sales`"
        tooltip="Marked as sales in the last day"
      />
      <stat-card
        label="Potential Sales"
        :value="dollarFormatter(stats.potential_sales_amount, 0)"
        :secondary="`${stats.potential_sales_count} inquires`"
        tooltip="Total price of inquires in the last day"
      />
      <stat-card
        label="Conversion Rate"
        :value="`${toPercent(stats.conversation_rate, 1) || '-'}`"
        :secondary="`${stats.marked_sales_count}/${stats.potential_sales_count}`"
        tooltip="Conversion in sales in the last day"
      />
      <stat-card
        :label="`Top seller - ${$moment().format('MMM DD')}`"
        :value="dollarFormatter(topSellerDailyAmount, 0) || '-'"
        :tooltip="sellersDailyText"
      >
        <template #secondary>
          <div v-if="topSellerDailyName" class="d-flex justify-center">
            <v-icon class="mx-1" small color="yellow">fas fa-star</v-icon>
            <span
              class="text-capitalize text-subtitle-2 teal--text text--darken-0 font-weight-medium"
              >{{ topSellerDailyName }}</span
            >
            <v-icon class="mx-1" small color="yellow">fas fa-star</v-icon>
          </div>
          <div v-else class="d-flex justify-center">
            <span
              class="text-capitalize text-subtitle-2 teal--text text--darken-0 font-weight-medium"
              >-</span
            >
          </div>
        </template>
      </stat-card>
      <stat-card
        :label="`Total sales - ${$moment().format('MMMM')}`"
        :value="dollarFormatter(totalSales, 0) || '-'"
        :tooltip="sellersText"
      >
        <template #secondary>
          <div v-if="topSellerName" class="d-flex justify-center">
            <v-icon class="mx-1" small color="yellow">fas fa-star</v-icon>
            <span
              class="text-capitalize text-subtitle-2 teal--text text--darken-0 font-weight-medium"
              >{{ topSellerName }}</span
            >
            <v-icon class="mx-1" small color="yellow">fas fa-star</v-icon>
          </div>
        </template>
      </stat-card>
      <stat-card
        label="Remarketing tries"
        :value="stats.win_back_count"
        :secondary="`${stats.win_back_prev_count} prev day`"
        tooltip="Messages sent to guests that didn't book initially"
      />
    </v-row>
    <v-tabs
      v-if="stats"
      v-model="tab"
      grow
      class="mt-2"
      background-color="teal lighten-5"
      @change="tabChange"
    >
      <dashboard-tab
        href="#hot_leads"
        label="Unanswered"
        :value="stats.hot_leads_count"
      />
      <dashboard-tab
        href="#active_leads"
        label="Active leads"
        :value="stats.active_leads_count"
      />
      <dashboard-tab
        href="#follow_ups"
        label="Follow ups"
        :value="stats.follow_ups_count"
      />
      <dashboard-tab
        href="#my_tickets"
        label="My Tickets"
        :value="stats.my_tickets_count"
      />
      <dashboard-tab
        href="#awaiting_payment"
        label="Awaiting Payment"
        :value="stats.awaiting_payment_count"
      />
      <dashboard-tab
        href="#recently_confirmed"
        label="Recently Confirmed"
        :value="stats.recently_confirmed_count"
      />
      <dashboard-tab href="#all" label="All" :value="stats.all_count" />
    </v-tabs>
    <div>
      <conversation-filters
        v-if="tab === 'all'"
        :filters="filters"
        @filter-change="updateFilter"
      />
      <conversations-table
        :headers="headers"
        :items="conversations"
        :options="pagination"
        @update-conversations="refreshSales"
        @row-click="rowClick"
        @paginate="paginate"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import dashboardTab from 'components/common/dashboard-tab.vue'
import formattersMixin from 'components/mixins/formatters-mixin'
import colorsMixin from 'components/mixins/colors-mixin'
import ConversationsTable from 'components/crm/conversations-table.vue'
import ConversationFilters from './conversation-filters.vue'
import StatCard from 'components/common/stat-card.vue'
import ConversationDrawer from './conversation-drawer.vue'
import { isEmpty } from 'lodash/fp'
import CommonFunctions from 'components/mixins/common_functions'
import { sumBy } from 'lodash'
import DeviceMixin from 'components/mixins/device-mixin'

export default {
  components: {
    dashboardTab,
    ConversationsTable,
    ConversationFilters,
    StatCard,
    ConversationDrawer,
  },
  mixins: [formattersMixin, colorsMixin, CommonFunctions, DeviceMixin],
  computed: {
    ...mapState(['loadingGuestExpInfo']),
    topSeller() {
      if (!isEmpty(this.stats.sales_board)) {
        return this.stats.sales_board[0]
      }
      return null
    },
    topSellerDaily() {
      if (!isEmpty(this.stats.sales_board_daily)) {
        return this.stats.sales_board_daily[0]
      }
      return null
    },
    topSellerDailyAmount() {
      if (this.topSellerDaily) {
        return this.topSellerDaily[1]
      }
      return null
    },
    totalSales() {
      return this.stats.marked_sales_month
    },
    topSellerName() {
      if (this.topSeller) {
        return this.nameById(this.topSeller[0])
      }
      return null
    },
    topSellerDailyName() {
      if (this.topSellerDaily) {
        return this.nameById(this.topSellerDaily[0])
      }
      return null
    },
    sellersText() {
      let txt = '<div>'
      txt += '<h4>This month stats </h4>'
      if (!isEmpty(this.stats.sales_board)) {
        this.stats.sales_board.forEach((seller, idx) => {
          txt += `<p class="mt-1 mb-1 pa-0"><span class="bolder mr-2">${
            idx + 1
          }.</span>${this.nameById(seller[0])}: ${this.dollarFormatter(
            seller[1],
            0
          )}</p>`
        })
      }
      txt += '</div>'
      return txt
    },
    sellersDailyText() {
      let txt = '<div>'
      txt += '<h4>This day stats </h4>'
      if (!isEmpty(this.stats.sales_board_daily)) {
        this.stats.sales_board_daily.forEach((seller, idx) => {
          txt += `<p class="mt-1 mb-1 pa-0"><span class="bolder mr-2">${
            idx + 1
          }.</span>${this.nameById(seller[0])}: ${this.dollarFormatter(
            seller[1],
            0
          )}</p>`
        })
      }
      txt += '</div>'
      return txt
    },
  },
  data() {
    return {
      conversations: null,
      tab: this.$route.query.conversation ? 'all' : 'hot_leads',
      stats: null,
      pagination: { page: 1 },
      filters: {},
      headers: [
        {
          text: '',
          value: 'reservation_status',
          sortable: false,
        },
        {
          text: 'Guest',
          value: 'guest_full_name',
          sortable: false,
        },
        {
          text: 'Dates',
          value: 'extra_data.reservation_check_in',
          sortable: false,
        },
        {
          text: 'Property',
          value: 'extra_data.listing_nickname',
          sortable: false,
        },
        {
          text: 'Price',
          value: 'reservation_price',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Last Msg',
          value: 'last_message_from_guest',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Assignee',
          value: 'assigned_user_id',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Source',
          value: 'extra_data.reservation_source',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Action',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      items: [{}],
    }
  },
  mounted() {
    this.getSales({
      [this.tab]: true,
      conversation_id: this.$route.query.conversation,
    })
    this.intervalStats = setInterval(
      () => this.getSales({ stats_only: true }),
      1000 * 30
    )
    this.intervalList = setInterval(
      () => this.refreshSales({ hideLoader: true }),
      1000 * 60
    )
  },
  beforeDestroy() {
    clearInterval(this.intervalStats)
    clearInterval(this.intervalList)
  },
  methods: {
    ...mapActions(['getSalesInfo']),
    async getSales(filters = {}) {
      const { data } = await this.getSalesInfo({ ...filters })
      this.stats = data.stats

      if (filters.stats_only) return
      this.conversations = data.conversations
      this.pagination = {
        page: +data.pagination.page,
        itemsPerPage: data.pagination.per_page,
        totalItems: data.pagination.conversations_count,
      }
    },
    tabChange(tab) {
      this.filters = {}
      this.getSales({ [tab]: true })
    },
    updateFilter(field, val) {
      this.filters = { ...this.filters, [field]: val }
      this.getSales({ [this.tab]: true, ...this.filters })
    },
    paginate(page) {
      if (page === this.pagination.page) return
      this.getSales({ [this.tab]: true, ...this.filters, page })
    },
    refreshSales(options = {}) {
      this.getSales({
        [this.tab]: true,
        ...this.filters,
        page: this.pagination.page,
        ...options,
      })
    },
    updateConversations() {
      this.getSales({ [this.tab]: true, ...this.filters, page: 1 })
    },
    rowClick({ guesty_id }) {
      this.$router.push({ query: { conversation: guesty_id } })
    },
  },
}
</script>
<style scoped>
::v-deep .v-data-footer {
  justify-content: end;
}
::v-deep .v-tabs-bar {
  height: 100%;
}
</style>
