<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-icon small color="inherit" v-bind="attrs" v-on="on"> info </v-icon>
    </template>
    <slot />
  </v-tooltip>
</template>

<script>
export default {
  name: 'InfoTooltip',
}
</script>

<style></style>
