<template>
  <div v-if="reservation" class="pa-8 pt-4 blue-grey lighten-5">
    <v-row class="primary">
      <v-progress-linear
        v-show="reservationLoading"
        indeterminate
        color="white"
        class="mb-0"
      />
      <v-col
        cols="12"
        md="10"
        class="d-flex justify-space-around white--text flex-wrap"
      >
        <details-box
          title="RESERVATION"
          :value="guest.full_name"
          :subtitle="reservation.confirmation_code"
        />
        <details-box title="STATUS" :value="reservation.status" />
        <details-box
          title="CHECK IN"
          :value="parseDate(reservation.check_in)"
          :subtitle="checkInTime"
          :secondary-subtitle="
            reservation.luggage_drop &&
            `Luggage Drop ${reservation.luggage_drop}`
          "
        />
        <details-box
          title="CHECK OUT"
          :value="parseDate(reservation.check_out)"
          :subtitle="checkOutTime"
        />
        <details-box title="GUESTS" :value="reservation.guests_count" />
        <details-box title="LISTING" :value="listing.nickname" />
      </v-col>
      <v-col cols="12" md="auto ml-auto white--text">
        <router-link
          v-if="guest.conversation && $route.name !== 'guests-chat'"
          class="mx-2 text-decoration-none white--text"
          :to="{
            name: 'guests-chat',
            params: { id: guest.conversation.guesty_id },
          }"
          target="_blank"
        >
          <v-icon>$chat</v-icon>
        </router-link>
        <reservation-edit-modal
          v-if="reservation.is_editable"
          :key="reservation.id"
          :fare-accommodation="reservation.fare_accommodation"
          :reservation-id="reservation.id"
          :check-in="reservation.check_in"
          :check-out="reservation.check_out"
          :status="reservation.status"
          color="white"
          :listing="reservation.listing"
          :cleaning-fee="reservation.cleaning_fee"
          @change="getReservation({ refresh: true })"
        />
        <router-link
          v-if="reservation.cleaning_listing_task_id"
          :to="{
            name: 'lt-page',
            params: { id: reservation.cleaning_listing_task_id },
          }"
          class="text-decoration-none mx-2 white--text"
          target="_blank"
        >
          <v-icon>mdi-broom</v-icon>
        </router-link>
        <a
          v-if="reservation.reservation_guesty_id"
          class="mx-2 text-decoration-none white--text"
          :href="`https://app.guesty.com/reservations/${reservation.reservation_guesty_id}/summary`"
          target="_blank"
        >
          <v-icon>mdi-open-in-new</v-icon>
        </a>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-row class="white mr-1 pb-3">
          <v-col
            cols="12"
            :class="[sectionHeaderClasses, 'd-flex justify-space-between']"
          >
            <div>{{ $t('Summary') }}</div>
            <v-menu v-model="menu">
              <template #activator="{ on, attrs }">
                <v-btn icon color="primary" v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list-item-group v-model="menuItem">
                <v-list>
                  <v-list-item @click="viewInvoice"> View Invoice </v-list-item>
                  <v-list-item @click="viewInvoice">
                    <reservation-email-modal
                      :key="reservation.id"
                      :invoice-email="reservation.guest.emails[0]"
                      :reservation-id="reservation.reservation_guesty_id"
                      @close="closeMenu"
                    />
                  </v-list-item>
                </v-list>
              </v-list-item-group>
            </v-menu>
          </v-col>
          <v-col
            v-for="item in summaryValues"
            :key="item.label"
            class="text-center text-body-1"
          >
            <div class="text-caption">{{ item.label }}</div>
            <div :class="valueClasses">
              {{ item.value }}
            </div>
          </v-col>
          <v-col cols="12" class="ml-3">
            <editable-text-field
              placeholder="Add note"
              label="Notes"
              :value="reservation.dvr_notes"
              @onupdate="updateNotes"
            />
          </v-col>
        </v-row>
        <v-row class="white mr-1 mt-3 pb-3">
          <div
            class="flex d-flex justify-space-between"
            :class="sectionHeaderClasses"
          >
            <div>Guest</div>
            <guest-edit
              v-if="guest.id && !reservationLoading"
              :guest="guest"
              @onsuccess="getReservation"
            />
          </div>
          <v-col cols="12" class="d-flex justify-space-around">
            <details-box
              title="Guest Name"
              :value="guest.full_name"
              :value-classes="valueClasses"
            />
            <details-box
              title="Phone"
              :value="guestPhone"
              :value-classes="valueClasses"
            />
            <details-box
              title="emails"
              :value="guestEmail"
              :value-classes="valueClasses"
            />
            <details-box
              title="Receives Marketing"
              :value="guest.marketing_enabled ? 'Yes' : 'No'"
              :value-classes="valueClasses"
            />
          </v-col>
        </v-row>
        <v-row v-if="preCheckInEdit" class="white mr-1 mt-3 pb-3">
          <v-col cols="12" :class="sectionHeaderClasses"
            >Pre-CheckIn Info
            <trip-pre-check-in
              :pre-check-in-data="preCheckInEdit"
              :code="reservation.confirmation_code"
              @updated-precheckin="getReservation"
            />
          </v-col>
          <v-col
            v-for="item in preCheckInValues"
            :key="item.label"
            cols="3"
            class="text-center text-body-1"
          >
            <div class="text-caption">{{ item.label }}</div>
            <div :class="valueClasses">{{ item.value }}</div>
          </v-col>
          <v-col
            v-if="reservation.pre_check_in && reservation.pre_check_in.id_pic"
            cols="3"
          >
            <img
              class="rounded-lg"
              width="100%"
              :src="reservation.pre_check_in.id_pic"
            />
          </v-col>
        </v-row>
        <v-row class="white mr-1 mt-3">
          <v-col cols="12">
            <reservation-calendar
              v-if="$store.state.currentListing.days_rates"
              :add-on-res="reservation"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row class="white">
          <v-col cols="12">
            <div
              :class="`${sectionHeaderClasses} d-flex align-center justify-space-between`"
            >
              <div>
                Finance |
                <span
                  :class="`${
                    reservation.balance_due ? 'red--text' : 'green--text'
                  }`"
                >
                  Balance: {{ dollarFormatter(reservation.balance_due) }}
                </span>
              </div>
              <div>
                <collect-payment
                  :loading="reservationLoading"
                  :reservation="reservation"
                  @success="getReservation"
                />
                <add-invoice-modal
                  :reservation="reservation"
                  @add-invoice="addInvoice"
                />
              </div>
            </div>
            <v-data-table
              v-if="invoiceItems.length"
              class="text-capitalize mb-4"
              disable-sort
              hide-default-footer
              :headers="invoiceHeaders"
              :items="invoiceItems"
            />
            <v-data-table
              v-if="paymentItems.length"
              class="text-capitalize mb-4"
              disable-sort
              hide-default-footer
              :headers="paymentItemsHeaders"
              :items="paymentItems"
            />
            <h5 class="ml-4">Payments attempts</h5>
            <reservation-intents-table
              v-if="reservation"
              :reservation="reservation"
              :get-reservation="getReservation"
            />
          </v-col>
        </v-row>
        <v-row class="white mt-3 pb-2">
          <v-col cols="12" :class="sectionHeaderClasses"> Store </v-col>
          <v-col cols="12">
            <purchase-table
              v-if="reservation"
              :guest-id="reservation.guest_id"
            />
          </v-col>
          <v-col>
            <quick-charge
              :reservation="reservation"
              :tourist-email="reservation.guest.emails[0]"
              @get-purchases="getPurchases"
            />
            <store-dialog
              :disabled="!reservation.guest.emails[0]"
              :confirmation-code="reservation.confirmation_code"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  summaryValues,
  paymentItems,
  invoiceItems,
  paymentIntentsHeaders,
  invoiceHeaders,
  paymentItemsHeaders,
  preCheckInValues,
} from 'components/reservation/config.js'
import ReservationCalendar from 'components/calendar/reservation-calendar'
import PurchaseTable from 'components/store/purchase-table'
import QuickCharge from 'components/store/quick-charge'
import StoreDialog from 'components/store/store-dialog'
import { mapActions, mapGetters } from 'vuex'
import TripPreCheckIn from 'components/pre-check-in/trip-pre-check-in'
import ReservationEditModal from './reservation-edit-modal'
import AddInvoiceModal from 'components/reservation/add-invoice-modal'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DetailsBox from 'components/common/details-box'
import CollectPayment from 'components/reservation/collect-payment'
import ReservationEmailModal from 'components/reservation/reservation-email-modal.vue'
import FormattersMixin from 'components/mixins/formatters-mixin'
import GuestEdit from 'components/reservation/guest-edit'
import EditableTextField from 'components/form-fields/editable-text-field'
import ReservationIntentsTable from 'components/reservation/reservation-intents-table'
import { last } from 'lodash'

export default {
  components: {
    ReservationIntentsTable,
    EditableTextField,
    GuestEdit,
    CollectPayment,
    DetailsBox,
    ReservationEditModal,
    ReservationCalendar,
    PurchaseTable,
    QuickCharge,
    StoreDialog,
    TripPreCheckIn,
    AddInvoiceModal,
    ReservationEmailModal,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  data() {
    return {
      headerValues: null,
      summaryValues: null,
      reservation: null,
      paymentIntents: [],
      paymentItems: [],
      expanded: [],
      invoiceItems: [],
      preCheckInValues: null,
      invoiceHeaders,
      paymentIntentsHeaders,
      paymentItemsHeaders,
      sectionHeaderClasses: 'font-weight-bold text-body-1 ma-3 blue-grey--text',
      valueClasses: 'font-weight-bold blue-grey--text text--darken-2',
      preCheckInEdit: null,
      guest: { emails: [], phones: [] },
      listing: {},
      reservationLoading: false,
      menuItem: null,
      menu: false,
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    guestEmail() {
      return this.guest.primary_email || last(this.guest.emails)
    },
    guestPhone() {
      return this.guest.primary_phone || last(this.guest.phones)
    },
    checkInTime() {
      if (this.reservation.planned_arrival) {
        return this.reservation.planned_arrival
      }
      return this.parseDateTZ(
        this.reservation.check_in,
        'HH:mm',
        this.listing.timezone
      )
    },
    checkOutTime() {
      if (this.reservation.planned_departure) {
        return this.reservation.planned_departure
      }
      return this.parseDateTZ(
        this.reservation.check_out,
        'HH:mm',
        this.listing.timezone
      )
    },
    resortsZone() {
      return !!this.currentUser.zone && this.currentUser.zone.name === 'Resorts'
    },
  },
  watch: {
    '$route.params.id': {
      handler() {
        this.getReservation()
      },
    },
  },
  mounted() {
    this.getReservation()
  },
  methods: {
    ...mapActions(['getTouristInfo', 'getStorePurchases']),
    updateNotes(notes) {
      this.$store.dispatch('reservation/updateReservation', {
        id: this.reservation.id,
        dvr_notes: notes,
      })
    },
    async getReservation(options = {}) {
      this.reservationLoading = true
      this.reservation = await this.$store.dispatch(
        'reservation/getReservation',
        { id: this.$route.params.id, include_cal: true, ...options }
      )
      if (
        this.reservation.confirmation_code &&
        this.reservation.status === 'confirmed'
      ) {
        this.preCheckInEdit = await this.$store.dispatch(
          'getPreCheckIn',
          this.reservation.confirmation_code
        )
      }

      this.guest = { ...this.reservation.guest }
      this.listing = this.reservation.listing
      this.summaryValues = summaryValues(this.reservation)
      this.paymentItems = paymentItems(this.reservation)
      this.invoiceItems = invoiceItems(this.reservation)
      this.preCheckInValues = preCheckInValues(this.reservation)
      this.reservationLoading = false
    },
    async getPurchases() {
      this.reservationLoading = true
      await this.getStorePurchases({
        guest_id: this.reservation.guest_id,
        page: 1,
      })
      this.reservationLoading = false
    },
    async addInvoice(invoice) {
      this.reservationLoading = true
      await this.$store.dispatch('reservation/addInvoice', {
        reservation_id: this.reservation.reservation_guesty_id,
        ...invoice,
      })
      this.getReservation()
    },
    viewInvoice() {
      window.open(
        `/api/reservations/${this.reservation.reservation_guesty_id}/invoice`,
        '_blank'
      )
    },
    closeMenu() {
      this.menu = false
    },
  },
}
</script>
