<template>
  <v-data-table
    :sort-by="['first_name']"
    :items="tableItems"
    :headers="headers"
    v-on="$listeners"
  >
    <template #top>
      <v-row class="px-3 align-center">
        <v-col cols="6" md="2">
          <v-text-field
            v-model="searchTerm"
            label="Search by name..."
            prepend-inner-icon="search"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="6" md="2">
          <v-select
            v-model="userRole"
            outlined
            multiple
            dense
            :items="rolesItems()"
            clearable
            hide-details
            prepend-inner-icon="category"
            label="Role"
          />
        </v-col>
        <v-col cols="6" md="2">
          <v-select
            v-model="department"
            outlined
            dense
            multiple
            hide-details
            :items="divisions"
            clearable
            label="Department"
          />
        </v-col>
        <v-col cols="6" md="2">
          <v-select
            v-model="status"
            outlined
            dense
            hide-details
            :items="['Active', 'Inactive']"
            clearable
            :label="$t('Status')"
          />
        </v-col>
        <v-col cols="6" md="1" class="mr-3">
          <v-switch v-model="payroll" :label="$t('Payroll')" />
        </v-col>
        <v-col cols="6" md="1">
          <v-switch v-model="showPreferred" :label="$t('Preferred')" />
        </v-col>
        <v-col cols="12" class="text-right">
          <user-create-modal />
        </v-col>
      </v-row>
    </template>
    <template #item.active="{ item }">
      <confirmation-modal
        :text="`Are you sure you want to ${
          item.active ? 'deactivate' : 'activate'
        } this user?`"
        title="User activation"
        @action="toggleUser(!item.active, 'active', item.id)"
      >
        <template #activator="{ on }">
          <v-switch
            readonly
            :input-value="item.active"
            :disabled="
              !hasAbility('users-management') || currentUserId === item.id
            "
            v-on="on"
          />
        </template>
      </confirmation-modal>
    </template>
    <template #item.on_payrole="{ item }">
      <td class="text-sm-center">
        <v-switch
          v-model="item.on_payrole"
          @change="toggleUser($event, 'on_payrole', item.id)"
        />
      </td>
    </template>
    <template #item.last_sign_in_at="{ item }">
      <td class="text-sm-center min-w-150">
        {{ parseDate(item.last_sign_in_at) }}
      </td>
    </template>
    <template #item.can_auto_approve="{ item }">
      <td class="text-sm-center">
        <v-switch
          v-model="item.can_auto_approve"
          @change="toggleUser($event, 'can_auto_approve', item.id)"
        />
      </td>
    </template>
    <template #item.preferred="{ item }">
      <td class="text-sm-center">
        <v-switch
          v-model="item.preferred"
          @change="toggleUser($event, 'preferred', item.id)"
        />
      </td>
    </template>
    <template #item.action="{ item }">
      <td class="text-sm-center">
        <v-btn
          :ripple="false"
          icon
          text
          color="primary"
          @click="showEditModal(item)"
        >
          <v-icon size="25">edit</v-icon>
        </v-btn>
        <v-btn
          v-if="isAdmin && item.role !== 'admin'"
          :ripple="false"
          icon
          text
          color="primary"
          @click="showPasswordModal(item.id)"
        >
          <v-icon size="20">fas fa-key</v-icon>
        </v-btn>
        <a
          v-if="hasAbility('super-admin')"
          target="_blank"
          class="text-decoration-none"
          :href="`/api/login-as-user/${item.id}`"
        >
          <v-icon>mdi-account-arrow-right</v-icon>
        </a>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import PermissionsFunctions from 'components/mixins/permissions-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import UserCreateModal from 'components/user/user-create-modal'
import { mapGetters, mapState } from 'vuex'
import RolesMixin from 'components/mixins/roles-mixin'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'

export default {
  name: 'UsersTable',
  components: { ConfirmationModal, UserCreateModal },
  mixins: [PermissionsFunctions, CommonFunctions, RolesMixin],
  props: ['headers', 'fetchAction', 'items'],
  data() {
    return {
      searchTerm: '',
      userRole: null,
      department: null,
      status: 'Active',
      payroll: null,
      showPreferred: null,
    }
  },
  methods: {
    rolesItems() {
      return [...this.roles, { text: 'Admin', value: 'admin' }]
    },
    showEditModal(user) {
      this.$store.commit('showModal', {
        name: 'UserEdit',
        props: {
          user,
        },
        maxWidth: '600',
        scrollable: false,
      })
    },
    showPasswordModal(userId) {
      this.$store.commit('showModal', {
        name: 'ResetPasswordModal',
        props: {
          userId,
        },
        maxWidth: '500',
      })
    },
    toggleUser(value, key, id) {
      this.$store.dispatch('users/updateUserNew', { id, [key]: value })
    },
  },
  computed: {
    ...mapGetters(['currentUserId']),
    ...mapState('users', ['usersStats']),
    tableItems() {
      let users = this.items
      if (this.status) {
        users = users.filter(user =>
          this.status === 'Active' ? user.active : !user.active
        )
      }
      if (this.payroll) {
        users = users.filter(user => this.payroll === user.on_payrole)
      }

      if (this.userRole) {
        users = users.filter(user => this.userRole.includes(user.role))
      }
      if (this.department) {
        users = users.filter(user => this.department.includes(user.department))
      }
      if (this.showPreferred) {
        users = users.filter(user => user.preferred)
      }
      if (this.searchTerm) {
        users = users.filter(
          user =>
            `${user.first_name} ${user.last_name}`
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(this.searchTerm.toLowerCase())
        )
      }
      return users
    },
  },
}
</script>

<style scoped></style>
