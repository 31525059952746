<template>
  <v-card min-width="360" elevation="0">
    <conversation-info-title
      title="Reservation"
      :guesty-id="reservation.reservation_guesty_id"
    >
      <template #actions>
        <v-btn
          v-if="reservation.reservation_guesty_id"
          :to="`/dashboard/reservation/${reservation.reservation_guesty_id}`"
          target="_blank"
          icon
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
        <v-btn
          v-if="reservation.cleaning_listing_task_id"
          :to="{
            name: 'lt-page',
            params: { id: reservation.cleaning_listing_task_id },
          }"
          target="_blank"
          icon
        >
          <v-icon>mdi-broom</v-icon>
        </v-btn>
        <v-btn
          v-if="$route.name !== 'guests-chat'"
          target="_blank"
          icon
          :to="{ name: 'guests-chat', params: { id: conversation.guesty_id } }"
        >
          <v-icon>$chat</v-icon>
        </v-btn>
        <reservation-edit-modal
          v-if="reservation.is_editable"
          :key="reservation.id"
          :fare-accommodation="reservation.fare_accommodation"
          :cleaning-fee="reservation.cleaning_fee"
          :reservation-id="reservation.id"
          :check-in="reservation.check_in"
          :status="reservation.status"
          :check-out="reservation.check_out"
          :listing="listing"
          @change="onReservationEdit"
        />
      </template>
    </conversation-info-title>
    <v-divider />
    <v-card-text>
      <div class="d-flex align-center mb-4">
        <user-avatar :name="conversation.guest.full_name" :size="50" />
        <div class="ml-4">
          <p class="text-body-1 black--text mb-0">
            {{ conversation.guest.full_name }}
          </p>
          <v-btn
            class="pa-0"
            :href="`tel:${conversation.guest.phones[0]}`"
            text
            x-small
          >
            <v-icon class="mr-1" x-small>mdi-phone</v-icon>
            {{ conversation.guest.phones[0] }}
          </v-btn>
        </div>
      </div>
      <p v-if="conversation.registered_email">
        <v-icon>person</v-icon>
        <span class="cyan-icon">Registered email: </span>
        {{ conversation.registered_email }}
      </p>
      <reservations-select
        :readonly="singleReservation"
        :pre-selected="reservation.reservation_guesty_id"
        :res-options="conversation.related_reservations"
        @changed="reservationChanged"
      />
      <v-row wrap no-gutters class="my-2">
        <v-col
          v-if="
            isAdmin ||
            isCommunicationManager ||
            isCommunicationAgent ||
            isSalesAgent ||
            isSalesManager
          "
          cols="12"
        >
          <check-in-out-form
            :reservation="reservation"
            @change="updateCheckInOutTimes"
          />
        </v-col>
        <v-col
          v-if="!reservation.is_merchant && reservation.status === 'confirmed'"
          cols="12"
        >
          <div class="d-flex text-capitalize text-body-1">
            <span>
              <span class="bolder">Accommodation:</span>
              <span class="text-body-2 green--text"
                >{{
                  `Paid via channel (${dollarFormatter(reservation.price)})`
                }}
              </span>
            </span>
          </div>
        </v-col>
        <v-col
          v-for="(intent, idx) in paymentIntents"
          :key="idx"
          cols="12"
          class=""
        >
          <div class="d-flex justify-space-between text-capitalize text-body-1">
            <span>
              <span class="bolder"
                >{{
                  intent.metadata['type'] === 'accommodation'
                    ? 'Reservation'
                    : 'Deposit'
                }}:</span
              >
              <span :class="['body-2', textClass(intent)]"
                >{{ `${paymentStatus(intent)} (${intent.amount / 100})` }}
              </span>
            </span>
            <span
              v-show="
                intent.metadata['type'] === 'accommodation' ||
                (intent.metadata['type'] === 'deposit' &&
                  reservation.should_pay_deposit)
              "
            >
              <a
                v-show="isAdmin"
                class="text-decoration-none"
                target="_blank"
                :href="`https://dashboard.stripe.com/payments/${intent.payment_intent_id}`"
                ><v-icon small>fab fa-stripe-s</v-icon></a
              >
              <a
                class="text-decoration-none"
                target="_blank"
                :href="`${portalDomain}/checkout/${intent.payment_intent_id}`"
                ><v-icon small>fas fa-external-link-alt</v-icon></a
              >
              <CopyClipboard
                description="Copy payment link"
                :text="`${portalDomain}/checkout/${intent.payment_intent_id}`"
                >Copy Link</CopyClipboard
              ></span
            >
          </div>
        </v-col>
        <v-col v-if="hasRefunds" cols="12">
          <div class="notification--text font-weight-medium text-body-2">
            Has Refunds
            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon small v-on="on">info</v-icon>
              </template>
              <div>More information can be found in payment tab</div>
            </v-tooltip>
          </div>
        </v-col>
        <v-col v-if="disputes.length" cols="12">
          <div class="font-weight-medium warning--text text-body-2">
            Has Disputes
            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon small v-on="on">info</v-icon>
              </template>
              <v-sheet> More information can be found in payment tab</v-sheet>
            </v-tooltip>
          </div>
        </v-col>
        <v-col v-if="noticeMessage" cols="12">
          <div class="d-flex text-capitalize text-body-1">
            <span>
              <span class="bolder">Res Notification:</span>
              <span class="red--text text-body-2">
                {{ `${noticeMessage} notice` }}
              </span>
            </span>
          </div>
        </v-col>
      </v-row>
      <v-layout column>
        <v-btn
          v-if="
            !reservation.pre_approved &&
            reservation.status === 'inquiry' &&
            reservation.source === 'Airbnb'
          "
          target="_blank"
          class="cyanish white--text full-width pr-2"
          text
          @click="markPreApproved"
          >Pre-approve
        </v-btn>
        <span v-if="reservation.pre_approved" class="green-health"
          >Pre-approved</span
        >

        <span
          v-if="
            showModule('comm') &&
            reservation &&
            !reservation.pre_check_in &&
            reservation.status === 'confirmed'
          "
          :class="'red-warn'"
          ><v-icon class="red-icon">warning</v-icon>
          Pre-check-in: Not filled
          <span>
            <CopyClipboard :text="preCheckInLink" />
          </span>
        </span>
        <span
          v-if="
            reservation &&
            reservation.pre_check_in &&
            reservation.status === 'confirmed'
          "
          :class="
            reservation.pre_check_in.status === 'New'
              ? 'red-warn'
              : 'green-text'
          "
        >
          <v-icon
            v-if="reservation.pre_check_in.status === 'New'"
            class="red-icon"
            >warning</v-icon
          >
          Pre-check-in:
          {{
            reservation.pre_check_in.status === 'New' ? 'Not filled' : 'Filled'
          }}</span
        >
        <v-tooltip
          v-if="
            reservation.pre_check_in &&
            reservation.pre_check_in.status !== 'New'
          "
          top
        >
          <template #activator="{ on }">
            <v-icon small class="icon ml-1 alt help-icon" v-on="on"
              >fas fa-question-circle
            </v-icon>
          </template>
          <v-layout wrap style="min-width: 100px">
            <pre-check-in-card
              :pre-check-in="reservation.pre_check_in"
            ></pre-check-in-card>
          </v-layout>
        </v-tooltip>
        <v-layout wrap mt-2 mb-2>
          <v-flex v-if="!conversation.extra_data['approval_request']" xs6 pr-1>
            <v-btn
              v-if="reservation.status === 'reserved'"
              color="success"
              class="full-width white--text"
              @click="approveRes"
              >approve
            </v-btn>
          </v-flex>
          <v-flex v-if="!conversation.extra_data['approval_request']" xs6 pl-1>
            <v-btn
              v-if="reservation.status === 'reserved'"
              color="error"
              class="full-width white--text"
              @click="declineRes"
              >decline
            </v-btn>
          </v-flex>
          <span v-if="conversation.extra_data['approval_request']">
            Request has been
            {{ conversation.extra_data['approval_request'] }}, might take few
            min to update.
          </span>
        </v-layout>
      </v-layout>
      <v-layout
        wrap
        align-center
        mt-2
        mb-2
        style="min-width: 90%"
        class="center-text bolder"
      >
        <v-flex v-if="reservation" class="font-light bolder" xs5 sm5>
          {{ parseDate(reservation.check_in) }}
        </v-flex>
        <v-flex v-if="reservation" class="font-light" xs2 sm2>
          <v-icon class="cyan-icon">arrow_right_alt</v-icon>
        </v-flex>
        <v-flex v-if="reservation" class="font-light bolder" xs5 sm5>
          {{ parseDate(reservation.check_out) }}
        </v-flex>
      </v-layout>
      <v-row no-gutters class="py-2 my-2">
        <v-col cols="12">
          <div class="text-subtitle-2 font-weight-medium">
            <span
              v-if="region"
              class="text-capitalize font-weight-medium secondary--text"
              >{{ region.name }} -
            </span>
            <span
              >{{ listing.nickname }} <CopyClipboard :text="listing.nickname"
            /></span>
          </div>
        </v-col>
        <v-col cols="4">
          <v-img class="flex" :src="listing.picture" />
        </v-col>
        <v-col cols="4" class="d-flex flex-column align-center">
          <span class="text-subtitle-2 font-weight-medium">Listing Risk</span>
          <div class="flex d-flex justify-center align-center">
            <v-chip
              label
              :color="sensitivityColor(reservation.listing.sensitivity)"
              >{{ reservation.listing.sensitivity }}</v-chip
            >
          </div>
        </v-col>
        <v-col cols="4">
          <div class="text-subtitle-2 font-weight-medium text-center">
            Guest Risk
          </div>
          <div class="text-center">
            <v-tooltip
              v-if="reservation.risk_analysis"
              left
              max-width="350px"
              color="white"
              top
            >
              <template #activator="{ on }">
                <v-progress-circular
                  :rotate="-90"
                  :size="70"
                  :width="15"
                  :value="reservation.risk_analysis.risk_score * 100"
                  :color="getRiskColor(reservation.risk_analysis.risk_score)"
                  v-on="on"
                >
                  {{ round(reservation.risk_analysis.risk_score * 100) }}
                </v-progress-circular>
              </template>
              <v-row class="grey--text">
                <v-col
                  v-if="!isEmpty(reservation.risk_analysis.bad_reasons)"
                  cols="12"
                  class="red-warn headline-font center-text"
                >
                  Bad indications:
                </v-col>
                <v-col
                  v-for="badReason in reservation.risk_analysis.bad_reasons"
                  :key="badReason"
                  cols="12"
                  class="center-text"
                  mt-1
                  mb-1
                >
                  <v-icon class="red-warn">report_problem</v-icon>
                  {{ badReason }}
                </v-col>
                <v-col
                  v-if="!isEmpty(reservation.risk_analysis.good_reasons)"
                  cols="12"
                  class="green--text headline-font center-text"
                >
                  Good indications:
                </v-col>
                <v-col
                  v-for="goodReason in reservation.risk_analysis.good_reasons"
                  :key="goodReason"
                  cols="12"
                  class="center-text"
                  mt-1
                  mb-1
                >
                  <v-icon class="green--text">done_outline</v-icon>
                  {{ goodReason }}
                </v-col>
              </v-row>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-layout wrap align-center mt-1 mb-1 class="center-text bolder">
        <v-flex v-if="reservation" class="font-light br-1" xs3>
          <h3 class="grey-text">Price</h3>
          <span class="bolder light-purple">{{
            dollarFormatter(calcReservationPrice(reservation))
          }}</span>
        </v-flex>
        <v-flex v-if="reservation" class="font-light br-1" xs3>
          <h3 class="grey-text">Guests</h3>
          <span class="bolder light-purple">{{
            reservation.guests_count
          }}</span>
        </v-flex>
        <v-flex v-if="reservation" class="font-light br-1" xs3>
          <h3 class="grey-text">Nights</h3>
          <span class="bolder light-purple">{{
            reservation.nights_count
          }}</span>
        </v-flex>
        <v-flex v-if="reservation" class="font-light" xs3>
          <h3 class="grey-text">Channel</h3>
          <span
            class="bolder"
            :class="sourceColor(conversation.extra_data['reservation_source'])"
            >{{ reservation.source }}</span
          >
        </v-flex>
      </v-layout>
      <v-row no-gutters class="py-2">
        <v-col cols="auto" class="pa-1">
          <listing-task-module
            v-if="!loading && !listingLoading"
            :pre-filled-listing-id="[reservation.listing_id]"
            class="mt-2 mb-2"
            button-text="SC"
            :reservation="reservation"
            :after-save-func="afterTaskCreate"
          />
        </v-col>
        <v-col
          v-if="reservation.status === 'confirmed' && isDesignedVr"
          cols="auto"
          class="pa-1"
        >
          <v-btn depressed color="primary" @click="sendInfo">
            <v-icon pr-1>lock</v-icon>
            Send Codes
          </v-btn>
        </v-col>
        <v-col cols="auto" class="pa-1">
          <v-btn depressed color="primary" @click="openInfo">
            <v-icon pr-1>info</v-icon>
            Info
          </v-btn>
        </v-col>
        <v-col cols="auto" class="pa-1">
          <v-dialog v-model="dialog" width="1200">
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                color="primary"
                append-icon="search"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>search</v-icon>
                Listings
              </v-btn>
            </template>
            <v-card class="pa-3">
              <listings-search v-if="dialog" :pre-config="preConfig" />
            </v-card>
          </v-dialog>
        </v-col>
        <v-col v-if="isDesignedVr" cols="auto" class="pa-1">
          <v-btn
            class="text-capitalize"
            color="info"
            elevation="0"
            @click="showCatalogInfo"
          >
            Knowledge center
          </v-btn>
        </v-col>
      </v-row>
      <v-row wrap justify-center class="mt--1">
        <v-col cols="12">
          <reservation-calendar
            v-if="
              $store.state.currentListing &&
              $store.state.currentListing.days_rates
            "
            :add-on-res="reservation"
            :slim-version="true"
            listing-type="currentEditListing"
          />
        </v-col>
        <v-col
          v-if="listing.has_assigned_parking || listing.has_parking_pass"
          cols="12"
        >
          <v-expansion-panels mt-3>
            <v-expansion-panel key="final">
              <v-expansion-panel-header>
                <span class="small-text">
                  <v-icon class="green--text">local_parking</v-icon>
                  Parking info
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="column med-font">
                  <p v-if="listing.has_parking_pass">Has parking pass</p>
                  <p v-if="listing.has_assigned_parking">
                    Has assigned parking:
                    {{ listing.assigned_parking_desc }}
                  </p>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col v-if="showModule('store')" cols="12">
          <v-expansion-panels mt-3>
            <v-expansion-panel key="final">
              <v-expansion-panel-header>
                <span class="small-text">
                  <v-icon class="dark-yellow">store</v-icon>
                  Store items options
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="column">
                  <v-col
                    v-for="storeItem in reservationStoreItems"
                    :key="storeItem.id"
                    cols="12"
                    class="pa-1"
                  >
                    <div class="d-flex justify-space-between">
                      <div>
                        <div
                          v-if="storeItem.status === 'not_available'"
                          class="grey--text"
                        >
                          <v-icon>remove</v-icon>
                          <span class="bolder">{{ storeItem.item.name }}</span>
                          <br />
                          <span>not available.</span>
                        </div>

                        <div v-else-if="storeItem.status === 'available'">
                          <v-icon class="cyan-icon">done_outline</v-icon>
                          <span class="bolder">{{ storeItem.item.name }}</span>
                          <br />
                          <span>can be purchased.</span>
                        </div>

                        <div
                          v-else-if="
                            storeItem.status === 'purchased' &&
                            storeItem.granted
                          "
                        >
                          <v-icon class="green--text"
                            >mdi-hand-heart-outline
                          </v-icon>
                          <span class="bolder">{{ storeItem.item.name }}</span>
                          <br />
                          <span>granted.</span>
                        </div>

                        <div v-else-if="storeItem.status === 'purchased'">
                          <v-icon class="green--text">money</v-icon>
                          <span class="bolder">{{ storeItem.item.name }}</span>
                          <br />
                          <span>purchased.</span>
                        </div>

                        <div v-else-if="storeItem.status === 'future'">
                          <v-icon class="yellow-health">mdi-lock</v-icon>
                          <span class="bolder">{{ storeItem.item.name }}</span>
                          <br />
                          <span>
                            will be available to purchase from<br />
                            {{
                              parseDateAndHourInEst(
                                storeItem.item.available_from
                              )
                            }}
                            EST
                          </span>
                        </div>
                      </div>
                      <div>
                        <div class="d-flex flex-column align-end">
                          <span v-if="storeItem.price.total">
                            ({{ dollarFormatter(storeItem.price.total) }})
                          </span>
                          <span v-else-if="storeItem.price.start_from">
                            (from
                            {{ dollarFormatter(storeItem.price.start_from) }})
                          </span>
                        </div>
                        <store-item-dialog
                          v-if="showGrantItemButton(storeItem)"
                          :reservation-id="reservation.id"
                          :store-item="storeItem.item"
                          :price="storeItem.price"
                          :options="storeItem.options"
                          :status="storeItem.status"
                          @after-grant="conversationRefresh"
                        >
                          <template #activator="{ on }">
                            <v-btn color="primary" outlined x-small v-on="on">
                              Grant
                            </v-btn>
                          </template>
                        </store-item-dialog>
                      </div>
                    </div>
                    <v-divider />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12">
          <v-expansion-panels mt-3>
            <v-expansion-panel key="final">
              <v-expansion-panel-header>
                <span class="small-text">
                  <v-icon class="purple-icon">manage_accounts</v-icon>
                  Personal
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <Personal
                  :personal="listing.personal"
                  disabled
                  :hide-save="true"
                  :override-personal-types="[
                    'cleaning_supervisor',
                    'pool',
                    'pest',
                    'landscape',
                    'host',
                    'region_manager',
                  ]"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12">
          <v-expansion-panels mt-3>
            <v-expansion-panel key="final">
              <v-expansion-panel-header>
                <span class="small-text">
                  <v-icon class="cyan-icon">house</v-icon>
                  House public info
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col v-if="listing.marketing_content.space" cols="12">
                    <h4>The space</h4>
                    <span
                      class="small-text"
                      v-html="listing.marketing_content.space"
                    ></span>
                  </v-col>
                  <v-col v-if="listing.marketing_content.notes" cols="12">
                    <h4>Notes</h4>
                    <span
                      class="small-text"
                      v-html="listing.marketing_content.notes"
                    ></span>
                  </v-col>
                  <v-col v-if="listing.marketing_content.access" cols="12">
                    <h4>Access</h4>
                    <span
                      class="small-text"
                      v-html="listing.marketing_content.access"
                    ></span>
                  </v-col>
                  <v-col v-if="listing.marketing_content.summary" cols="12">
                    <h4>Summary</h4>
                    <span
                      class="small-text"
                      v-html="listing.marketing_content.summary"
                    ></span>
                  </v-col>
                  <v-col v-if="listing.marketing_content.houseRules" cols="12">
                    <h4>House Rules</h4>
                    <span
                      class="small-text"
                      v-html="listing.marketing_content.houseRules"
                    ></span>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col v-if="listing.terms" cols="12">
          <v-expansion-panels mt-3>
            <v-expansion-panel
              v-if="
                listing.terms.cancellation || reservation.cancellation_policy
              "
              key="final"
            >
              <v-expansion-panel-header>
                <span class="small-text">
                  <v-icon class="red-warn">cancel_presentation</v-icon>
                  Cancellation info
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col v-if="listing.terms.cancellation" cols="12">
                    <h4 class="red-warn">House Cancellation</h4>
                    <span
                      class="small-text"
                      v-html="listing.terms.cancellation"
                    ></span>
                  </v-col>
                  <v-col v-if="reservation.cancellation_policy" cols="12">
                    <h4 class="red-warn">Reservation Cancellation</h4>
                    <span
                      class="small-text"
                      v-html="reservation.cancellation_policy"
                    ></span>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-flex
        v-if="isAdmin && reservation.balance_due > 0 && reservation.is_merchant"
      >
        <v-btn class="greenish purple-icon" @click="markAspaid(reservation.id)"
          >Mark as paid
        </v-btn>
      </v-flex>
    </v-card-text>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ListingTaskModule from './listing-task-module'
import ReservationCalendar from 'components/calendar/reservation-calendar'
import PreCheckInCard from 'components/pre-check-in/pre-check-in-card'
import CopyClipboard from 'components/copy-clipboard'
import ReservationsSelect from 'components/chat/reservations-select'
import ListingsSearch from 'components/listings-search'
import get from 'lodash/fp/get'
import Personal from 'components/listing/personal'
import ConversationInfoTitle from 'components/chat/conversation-info-title'
import { orderBy } from 'lodash'
import StoreItemDialog from 'components/store/store-item-dialog'
import CheckInOutForm from 'components/common/check-in-out-form'
import ReservationEditModal from 'components/reservation/reservation-edit-modal'

import { mapMutations, mapState } from 'vuex'
import FormattersMixin from 'components/mixins/formatters-mixin'
import UserAvatar from 'components/common/user-avatar.vue'

export default {
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: ['conversation', 'singleReservation'],
  computed: {
    ...mapState('regions', ['regions']),
    reservationStoreItems() {
      return orderBy(
        this.reservation.store_items_options || [],
        'item.is_manual',
        'desc'
      )
    },
    preConfig() {
      return {
        guestAmount: get('beds', this.listing) ? this.listing.beds * 2 : 2,
        from: this.$moment(this.reservation.check_in)
          .toISOString()
          .substr(0, 10),
        to: this.$moment(this.reservation.check_out)
          .toISOString()
          .substr(0, 10),
      }
    },
    region() {
      return this.regions.find(r => r.id === this.listing.region_id)
    },
    portalDomain() {
      return 'https://guest.easyair.io'
    },
    listing() {
      return this.reservation.listing
    },
    reservation() {
      return this.$store.getters.chosenChatReservation
    },
    noticeMessage() {
      const messages = this.conversation.guest.automated_messages || {}
      if (messages['24_h_payment_warn']) {
        return '3rd'
      }
      if (messages['6_h_payment_warn']) {
        return '2nd'
      }
      if (messages['3_h_payment_warn']) {
        return '1st'
      }
      return ''
    },
    preCheckInLink() {
      return `${this.portalDomain}/tourist/trips/${this.reservation.confirmation_code}`
    },
    hasRefunds() {
      return this.getReservationRefunds(this.reservation.payment_intents).length
    },
    paymentIntents() {
      return (this.reservation.payment_intents || []).filter(
        pi =>
          pi.metadata.type === 'accommodation' || pi.metadata.type === 'deposit'
      )
    },
    disputes() {
      return this.getReservationDisputes(this.reservation.payment_intents)
    },
  },
  methods: {
    ...mapMutations(['showModal']),
    sensitivityColor(sensitivity) {
      if (sensitivity === 'Low') {
        return 'success'
      }
      if (sensitivity === 'Med') {
        return 'notification'
      }
      if (sensitivity === 'High') {
        return 'warning'
      }
      return 'grey'
    },
    onReservationEdit() {
      if (this.$route.name === 'multi-calendar') {
        this.$store.commit('listingCalendar/calendarUpdate')
      }
    },
    showCatalogInfo() {
      this.showModal({
        name: 'CatalogItemInfoModal',
        props: {
          pickerMode: true,
        },
      })
    },
    showGrantItemButton(storeItem) {
      return (
        (this.isAdmin || this.isCommunicationManager) &&
        !['purchased', 'not_available'].includes(storeItem.status) &&
        this.reservation.status === 'confirmed' &&
        !storeItem.item.is_manual &&
        ['PoolHeater', 'EarlyCheckin', 'LateCheckout'].includes(
          storeItem.item.type
        )
      )
    },
    getRiskColor(risk) {
      if (risk < 0.2) {
        return 'green lighten-2'
      }
      if (risk < 0.5) {
        return 'yellow accent-4'
      }
      if (risk < 0.8) {
        return 'red lighten-2'
      }
      return 'red darken-4'
    },
    calcReservationPrice(res) {
      return res.price !== 0
        ? res.price
        : res.fare_accommodation +
            res.cleaning_fee +
            res.tourist_tax +
            res.sales_tax
    },
    reservationChanged(val) {
      const reservation = this.conversation.related_reservations.find(
        r => r.reservation_guesty_id === val
      )
      this.$store.commit('updateChatReservation', reservation)
    },
    textClass({ status, metadata, amount }) {
      if (
        ['succeeded', 'requires_capture', 'canceled'].includes(status) ||
        this.paidOnGuesty(amount / 100)
      ) {
        return 'green--text'
      }
      if (
        !this.reservation.should_pay_deposit &&
        metadata['type'] === 'deposit' &&
        (status === 'requires_source' || status === 'requires_payment_method')
      ) {
        return 'secondary--text'
      } else {
        return 'red--text'
      }
    },
    markAspaid(reservationID) {
      const userConfirm = confirm('Are you sure?')
      if (userConfirm) {
        this.$store.dispatch('markAsPaid', reservationID).then(({ data }) => {
          if (data && data.success) {
            this.$store.dispatch(
              'getConversationBG',
              this.conversation.guesty_id
            )
          }
        })
      }
    },
    paidOnGuesty(amount) {
      return this.reservation.payment_items.find(
        p => p.confirmation_code && p.amount === amount
      )
    },
    paymentStatus({ status, metadata, amount }) {
      if (metadata['type'] === 'deposit') {
        if (
          !this.reservation.should_pay_deposit &&
          (status === 'requires_source' || status === 'requires_payment_method')
        ) {
          return "Shouldn't be paid yet"
        }
        if (status === 'succeeded') {
          return 'Paid and captured'
        }
        if (status === 'canceled') {
          return 'Paid and expired'
        }
      }
      if (status === 'succeeded' || status === 'requires_capture') {
        return 'Paid'
      }

      if (this.paidOnGuesty(amount / 100)) {
        return 'Paid on PMS'
      }
      if (
        status === 'requires_source' ||
        status === 'requires_payment_method'
      ) {
        return 'not paid'
      }

      return status
    },
    issuesAreDone(issues) {
      let res = true
      issues.forEach(issue => {
        if (issue.status !== 'Done') {
          res = false
        }
      })
      return res
    },
    sendInfo() {
      this.$store.commit(
        'updatePushedMessage',
        this.reservation.generated_code_msg
      )
    },
    afterTaskCreate() {
      this.$store.dispatch('conversationUpdate', { id: this.conversation.id })
    },
    hourDesc(issue) {
      let hour = this.$moment.utc(issue.scheduled_at).hours()
      if (hour !== 0) {
        return this.parseDateJustTime(issue.scheduled_at)
      } else {
        return 'No hour scheduled'
      }
    },
    async conversationRefresh() {
      await this.$store.dispatch(
        'getChatConversation',
        this.conversation.guesty_id
      )
    },
    approveRes() {
      this.$store.dispatch('conversationUpdate', {
        id: this.conversation.id,
        reservation_id: this.reservation.id,
        approve_reservation: true,
      })
    },
    declineRes() {
      this.$store.dispatch('conversationUpdate', {
        id: this.conversation.id,
        reservation_id: this.reservation.id,
        decline_reservation: true,
      })
    },
    openInfo() {
      this.$store.commit('updateShowListingInfoPop', true)
    },
    markPreApproved() {
      this.$store.dispatch('preApproveReservation', this.reservation.id)
      this.reservation.pre_approved = true
    },
    sourceColor(channel) {
      if (channel === 'Airbnb') {
        return 'airbnb-color'
      } else if (channel === 'Booking.com') {
        return 'booking-color'
      } else if (channel === 'HomeAway') {
        return 'homeaway-color'
      } else if (channel === 'Designedvr.com') {
        return 'designedvr-color'
      } else if (channel === 'Website') {
        return 'designedvr-color'
      } else if (channel === 'Expedia') {
        return 'expedia-color'
      }
    },
    async updateCheckInOutTimes({
      arrival,
      departure,
      luggage_drop,
      dvr_notes,
    }) {
      await this.$store.dispatch('reservation/alterReservation', {
        id: this.reservation.id,
        planned_arrival: arrival,
        planned_departure: departure,
        luggage_drop,
        dvr_notes,
      })
      await this.conversationRefresh()
    },
  },
  data() {
    return {
      dialog: false,
      selectedRes: null,
    }
  },
  components: {
    UserAvatar,
    ReservationEditModal,
    CheckInOutForm,
    StoreItemDialog,
    ConversationInfoTitle,
    ReservationCalendar,
    Personal,
    ListingsSearch,
    ReservationsSelect,
    ListingTaskModule,
    CopyClipboard,
    PreCheckInCard,
  },
}
</script>
