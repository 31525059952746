<template>
  <v-stepper-content :step="3" class="pa-0">
    <v-container fluid class="pa-0">
      <div class="text-h6 font-weight-medium">Contact Info</div>
      <v-form ref="form" class="flex d-flex flex-column">
        <v-row no-gutters class="my-4">
          <v-col cols="12" sm="6" class="pr-sm-6">
            <v-text-field
              v-model="clientInfo.email"
              prepend-inner-icon="person"
              :rules="emailRules"
              outlined
              name="email"
              required
              label="Email"
              type="text"
            />
          </v-col>
          <v-col cols="12" sm="6" class="pr-sm-6">
            <v-text-field
              v-model="clientInfo.phone_number"
              prepend-inner-icon="phone"
              outlined
              name="Phone"
              label="Phone"
              :rules="phoneRules"
              type="text"
            />
          </v-col>
          <v-col cols="12" sm="6" class="pr-sm-6">
            <v-menu
              ref="menu"
              v-model="dateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              required
              min-width="290px"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="clientInfo.birthday"
                  persistent-hint
                  hint="You must be at least age of 18 years"
                  outlined
                  v-bind="attrs"
                  label="Date of birth"
                  :rules="[required]"
                  prepend-inner-icon="calendar_today"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                ref="picker"
                v-model="clientInfo.birthday"
                :max="maxDate"
                min="1940-01-01"
                @change="save"
              />
            </v-menu>
          </v-col>
          <v-col v-if="!isResort" cols="12">
            <v-checkbox
              v-model="requestRemember"
              label="I want to be a member"
              hint="DesignedVR membership gives you 5% discount on future
                reservations and it's free!"
              persistent-hint
            />
          </v-col>
        </v-row>
        <div>
          <div class="text-h6 font-weight-medium my-3">Upload Main ID</div>
          <div class="d-flex">
            <dvr-media-upload
              :multiple="false"
              :thumbnail="true"
              label="Main ID"
              folder="guests"
              :value.sync="documentId"
            />
            <v-img
              :src="documentId || preCheckIn.id_pic"
              class="mx-2"
              max-width="200"
              height="200"
            />
          </div>
          <div class="text-h6 font-weight-medium my-3">
            Additional Guests ID
          </div>
          <div class="d-flex">
            <dvr-media-upload
              :thumbnail="true"
              label="Additional ID"
              folder="guests"
              :value.sync="additionalDocs"
            />
            <v-img
              v-for="(src, i) in additionalDocs.length
                ? additionalDocs
                : preCheckIn.additional_id_images"
              :key="i"
              class="mx-2"
              max-width="200"
              height="200"
              :src="src"
            />
          </div>
        </div>
      </v-form>
    </v-container>
  </v-stepper-content>
</template>

<script>
import formRules from 'components/mixins/form-rules-mixin'
import DvrMediaUpload from 'components/common/dvr-media-upload'

export default {
  name: 'PersonalInfo',
  components: { DvrMediaUpload },
  mixins: [formRules],
  props: ['preCheckIn', 'validate', 'isResort'],
  data() {
    return {
      clientInfo: this.preCheckIn.client_info,
      dateMenu: false,
      additionalDocs: [],
      documentId: this.preCheckIn.id_pic,
      showUploadError: false,
      requestRemember: this.preCheckIn.request_to_memeber,
    }
  },
  computed: {
    maxDate() {
      return this.$moment().subtract(18, 'years').format('YYYY-MM-DD')
    },
    user() {
      return this.$store.getters.user
    },
  },
  watch: {
    dateMenu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    validate() {
      if (this.$refs.form.validate()) {
        const payload = {
          client_info: this.clientInfo,
          request_to_memeber: this.requestRemember,
          vr_licence_signed: true,
          id_pic: this.documentId,
          additional_id_images: this.additionalDocs,
        }
        this.$emit('step-forward', payload)
      }
    },
  },
  created() {
    this.clientInfo.email = this.preCheckIn.client_info.email
    this.clientInfo.birthday = this.preCheckIn.client_info.birthday
    this.clientInfo.phone_number = this.preCheckIn.client_info.phone_number
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date)
    },
  },
}
</script>

<style scoped>
.v-stepper__content >>> .v-stepper__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
