<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="py-0">
        <div class="text-h6">Notification Settings</div>
        <div class="text-caption font-weight-medium secondary--text">
          Select the kinds of notifications you would like to get about your
          activities
        </div>
      </v-col>
      <v-col cols="12" class="py-2"><v-divider /></v-col>

      <v-col cols="12" md="4">
        <div class="text-subtitle-1 font-weight-medium">
          Email Notifications
        </div>
        <div class="text-caption font-weight-medium secondary--text">
          Get emails to find out what's going on when you're not online. You can
          turn this off
        </div>
      </v-col>
      <v-col cols="12" md="6" offset-md="2">
        <div class="d-flex justify-space-between align-center">
          <div class="text-subtitle-2 font-weight-medium">New Reservation</div>
          <v-switch
            v-model="notifications.new_reservation"
            color="info"
            @change="updateNotifications({ new_reservation: $event })"
          />
        </div>
        <div class="d-flex justify-space-between align-center">
          <div class="text-subtitle-2 font-weight-medium">
            Canceled Reservation
          </div>
          <v-switch
            v-model="notifications.canceled_reservation"
            color="info"
            @change="updateNotifications({ canceled_reservation: $event })"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserNotifications',
  props: ['user'],
  data() {
    return {
      notifications: {},
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  mounted() {
    this.notifications = this.user
      ? this.user.notifications
      : this.currentUser.notifications
  },
  methods: {
    updateNotifications(options) {
      this.$store.dispatch('users/updateProfile', {
        id: this.user ? this.user.id : this.currentUser.id,
        notifications: { ...this.notifications, ...options },
      })
    },
  },
}
</script>

<style scoped></style>
