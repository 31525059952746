<template>
  <v-sheet
    :width="menuExpanded ? 500 : isFull ? '100%' : 'auto'"
    class="flex"
    :class="{ 'scrollable-dvr': !absolute }"
  >
    <v-progress-linear :active="fetching" indeterminate color="info" />

    <v-overlay v-if="fetching" absolute :opacity="0.2" />
    <v-tabs v-if="isFull" v-model="tab" centered show-arrows>
      <v-tab href="#reservation">
        <v-icon class="mr-2">$home</v-icon>
        <span class="d-none d-sm-inline">{{ $t('Res') }}</span>
      </v-tab>
      <v-tab href="#guest-info">
        <v-icon class="mr-2">$people</v-icon>
        <span class="d-none d-sm-inline">{{ $t('Guest') }}</span>
      </v-tab>
      <v-tab
        v-if="
          currentReservation.store_items_options &&
          currentReservation.store_items_options.length
        "
        href="#store"
      >
        <v-icon class="mr-2">$store</v-icon>
        <span class="d-none d-sm-inline">{{ $t('Store') }}</span>
      </v-tab>
      <v-tab href="#payment-info">
        <v-icon class="mr-2">$money</v-icon>
        <span class="d-none d-sm-inline">{{ $t('Payment') }}</span>
      </v-tab>
      <v-tab
        v-if="
          conversation.issues &&
          conversation.issues.length > 0 &&
          currentReservation.status === 'confirmed'
        "
        href="#issues"
      >
        <v-badge
          :content="conversation.issues.length"
          overlap
          top
          color="warning"
          class="text-center"
        >
          <v-icon class="mr-2">$maintenance</v-icon>
        </v-badge>

        <span class="d-none d-sm-inline">{{ $t('Service calls') }}</span>
      </v-tab>
      <v-tab
        v-if="
          !isEmpty(currentReservation.chat_relevant_listings) &&
          currentReservation.status !== 'confirmed'
        "
        href="#comparables"
      >
        <v-icon class="mr-2">tune</v-icon>
        <span class="d-none d-sm-inline">{{ $t('Comps') }}</span>
      </v-tab>
      <v-tab
        v-if="!isEmpty(conversation.extra_data.sales_status)"
        href="#timeline"
      >
        <v-icon class="mr-2">timeline</v-icon>
        <span class="d-none d-sm-inline">{{ $t('Timeline') }}</span>
      </v-tab>
      <!--      <v-tab-->
      <!--        v-if="-->
      <!--          currentReservation.status !== 'confirmed' &&-->
      <!--            this.$store.state.currentPricingMonthly-->
      <!--        "-->
      <!--        href="#pricing"-->
      <!--      >-->
      <!--        <v-icon class="mr-2">local_offer</v-icon>-->
      <!--        <span class="d-none d-sm-inline">Pricing</span>-->
      <!--      </v-tab>-->
      <!--      <v-tab v-if="currentReservation.status === 'confirmed'" href="#iot-tab">-->
      <!--        <v-icon class="mr-2">$wifi_alt</v-icon>-->
      <!--        <span class="d-none d-sm-inline">IOT</span>-->
      <!--      </v-tab>-->
    </v-tabs>
    <div class="conversation-info d-flex">
      <v-slide-x-reverse-transition>
        <v-tabs-items
          v-if="menuExpanded || isFull"
          v-model="tab"
          class="flex-grow-1"
          touchless
        >
          <v-tab-item key="1" value="reservation">
            <conversation-info-reservation
              v-if="conversation"
              :single-reservation="singleReservation"
              :conversation="conversation"
            />
          </v-tab-item>

          <v-tab-item value="guest-info">
            <conversation-info-guest
              :reservation="currentReservation"
              :conversation="conversation"
            />
          </v-tab-item>

          <v-tab-item value="payment-info">
            <conversation-info-payment
              :conversation-id="conversation.guesty_id"
              :guest="conversation.guest"
              :reservation="currentReservation"
            />
          </v-tab-item>
          <v-tab-item value="store">
            <purchase-table
              v-if="!listingLoading"
              :reservation-id="currentReservation.id"
              :guest-id="currentReservation.guest_id"
            />
            <quick-charge
              :reservation="currentReservation"
              :tourist-email="touristEmail"
              @get-purchases="getPurchases"
            />
            <v-row>
              <store-dialog
                :disabled="!touristEmail"
                :confirmation-code="currentReservation.confirmation_code"
              />
              <v-dialog v-model="signupDialog" persistent>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-if="hasAbility('create-store-purchase')"
                    depressed
                    outlined
                    :disabled="!!touristEmail"
                    v-bind="attrs"
                    color="info"
                    class="ma-5 white--text"
                    v-on="on"
                  >
                    <v-icon small class="mr-1">mdi-account</v-icon>
                    Guest Signup
                  </v-btn>
                </template>
                <v-card>
                  <div class="d-flex flex-column align-end">
                    <v-btn class="ma-3 p-fixed" icon @click="closeSignup">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <iframe
                      v-if="signupDialog"
                      title="Guest Signup"
                      class="store-iframe"
                      :src="signupPortalPath"
                      frameborder="0"
                      allowFullScreen="true"
                      Content-Security-Policy="frame-ancestors 'self'"
                    ></iframe>
                  </div>
                </v-card>
              </v-dialog>
            </v-row>
          </v-tab-item>
          <v-tab-item v-if="hasOutstandingIssues" value="outstanding_issues">
            <conversation-info-issues
              issues-field="outstanding_issues"
              :conversation="conversation"
              :reservation="currentReservation"
            />
          </v-tab-item>
          <v-tab-item value="issues">
            <conversation-info-issues
              :conversation="conversation"
              :reservation="currentReservation"
            />
          </v-tab-item>
          <v-tab-item value="comparables">
            <conversation-info-optional-listings
              :reservation="currentReservation"
              :conversation="conversation"
            />
          </v-tab-item>
          <v-tab-item value="pricing">
            <conversation-pricing :conversation="conversation" />
          </v-tab-item>
          <v-tab-item value="marketing">
            <marketing-links
              :integrations="currentReservation.listing.integrations"
            />
          </v-tab-item>
          <!--          <v-tab-item value="iot-tab">-->
          <!--            <conversation-info-iot-->
          <!--              :listing-id="currentReservation.listing_id"-->
          <!--            />-->
          <!--          </v-tab-item>-->
        </v-tabs-items>
      </v-slide-x-reverse-transition>
      <aside v-if="!isFull" class="conversation-info-menu text-center pa-3">
        <v-btn
          v-if="!keepExpanded"
          class="mb-2 py-6"
          text
          @click="menuExpanded = !menuExpanded"
        >
          <v-icon :class="{ open: menuExpanded }">$expand_menu</v-icon>
        </v-btn>
        <v-tabs
          v-model="tab"
          vertical
          hide-slider
          active-class="secondary lighten-3 black--text"
        >
          <v-tab
            class="secondary lighten-3 mb-2"
            href="#reservation"
            @click="menuExpanded = true"
          >
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">$home</v-icon>
              </template>
              <span>Reservations</span>
            </v-tooltip>
          </v-tab>
          <v-tab
            class="secondary lighten-3 mb-2"
            href="#guest-info"
            @click="menuExpanded = true"
          >
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">$people</v-icon>
              </template>
              <span>Guest</span>
            </v-tooltip>
          </v-tab>
          <v-tab
            v-if="!isEmpty(currentReservation.store_items_options)"
            class="secondary lighten-3 mb-2"
            href="#store"
            @click="storeSelectHandler"
          >
            <v-badge
              v-if="storeCount"
              overlap
              :content="storeCount"
              top
              color="primary"
              class="text-center"
            >
              <v-icon>$store</v-icon>
            </v-badge>
            <v-icon v-else>$store</v-icon>
          </v-tab>
          <v-tab
            class="secondary lighten-3 mb-2"
            href="#payment-info"
            @click="menuExpanded = true"
          >
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">$money</v-icon>
              </template>
              <span>Payment</span>
            </v-tooltip>
          </v-tab>
          <v-tab
            v-if="
              currentReservation &&
              currentReservation.status === 'confirmed' &&
              showModule('ops')
            "
            class="secondary lighten-3 mb-2"
            href="#issues"
            @click="menuExpanded = true"
          >
            <v-badge
              v-if="conversation.issues && conversation.issues.length"
              :content="conversation.issues.length"
              top
              color="primary"
              class="text-center"
            >
              <v-icon>$maintenance</v-icon>
            </v-badge>
            <v-icon v-else>$maintenance</v-icon>
          </v-tab>
          <v-tab
            v-if="
              !isEmpty(currentReservation.chat_relevant_listings) &&
              currentReservation.status !== 'confirmed'
            "
            class="secondary lighten-3 mb-2"
            href="#comparables"
            @click="menuExpanded = true"
          >
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">tune</v-icon>
              </template>
              <span>Comps</span>
            </v-tooltip>
          </v-tab>
          <v-tab v-if="hasOutstandingIssues" href="#outstanding_issues">
            <v-badge
              :content="conversation.outstanding_issues.length"
              overlap
              top
              color="warning"
              class="text-center"
            >
              <v-icon class="mr-2">error</v-icon>
            </v-badge>
          </v-tab>
          <v-tab
            href="#marketing"
            class="secondary lighten-3 mb-2"
            @click="menuExpanded = true"
          >
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on"
                  >mdi-share-variant-outline</v-icon
                >
              </template>
              <span>Marketing</span>
            </v-tooltip>
          </v-tab>
          <!--          <v-tab-->
          <!--            v-if="-->
          <!--              currentReservation.status !== 'confirmed' &&-->
          <!--                $store.state.currentPricingMonthly-->
          <!--            "-->
          <!--            class="secondary lighten-3 mb-2"-->
          <!--            href="#pricing"-->
          <!--            @click="menuExpanded = true"-->
          <!--          >-->
          <!--            <v-tooltip left>-->
          <!--              <template v-slot:activator="{ on, attrs }">-->
          <!--                <v-icon v-bind="attrs" v-on="on">local_offer</v-icon>-->
          <!--              </template>-->
          <!--              <span>Pricing</span>-->
          <!--            </v-tooltip>-->
          <!--          </v-tab>-->
          <!--          <v-tab-->
          <!--            v-if="currentReservation.status === 'confirmed'"-->
          <!--            class="secondary lighten-3"-->
          <!--            href="#iot-tab"-->
          <!--            @click="menuExpanded = true"-->
          <!--          >-->
          <!--            <v-tooltip left>-->
          <!--              <template v-slot:activator="{ on, attrs }">-->
          <!--                <v-icon v-bind="attrs" v-on="on">$wifi_alt</v-icon>-->
          <!--              </template>-->
          <!--              <span>IOT</span>-->
          <!--            </v-tooltip>-->
          <!--          </v-tab>-->
        </v-tabs>
      </aside>
    </div>
  </v-sheet>
</template>
<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ConversationInfoPayment from 'components/conversation-info-payment'
import ConversationInfoOptionalListings from 'components/conversation-info-optional-listings'
import ConversationPricing from 'components/conversation-pricing'
import ConversationInfoReservation from 'components/conversation-info-reservation'
import ConversationInfoGuest from 'components/conversation-info-guest'
import ConversationInfoIssues from 'components/conversations/conversation-info-issues'
import MarketingLinks from 'components/conversations/marketing-links.vue'
import PurchaseTable from 'components/store/purchase-table'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import QuickCharge from 'components/store/quick-charge'
import StoreDialog from 'components/store/store-dialog'

export default {
  mixins: [CommonFunctions, PermissionsMixin],
  props: [
    'conversation',
    'isFull',
    'keepExpanded',
    'singleReservation',
    'absolute',
    'fetching',
  ],
  data() {
    return {
      storeDialog: null,
      signupDialog: null,
      tab: null,
      panel: true,
      panel2: false,
      message: null,
      messageModule: 'airbnb',
      menuExpanded: this.keepExpanded || false,
      currentConversation: this.conversation,
      touristEmail: null,
    }
  },
  computed: {
    ...mapGetters({
      currentReservation: 'chosenChatReservation',
    }),
    ...mapState(['serviceCallModalOpened']),
    storeCount() {
      return this.currentConversation.store_items_count
    },
    storePortalPath() {
      const { confirmation_code } = this.currentReservation
      return `${process.env.VUE_APP_TOURIST_PORTAL_URL}/tourist/store/reservation/${confirmation_code}?externalCheckout=true`
    },
    signupPortalPath() {
      const { guest_full_name, logo, guest } = this.conversation
      return `${process.env.VUE_APP_TOURIST_PORTAL_URL}/tourist/signup?g=${guest.guesty_id}&fullName=${guest_full_name}&logo=${logo}&externalCheckout=true`
    },
    hasOutstandingIssues() {
      return !this.isEmpty(this.conversation.outstanding_issues)
    },
  },
  methods: {
    ...mapMutations(['updatePushedMessage']),
    ...mapActions(['getTouristInfo', 'getStorePurchases']),
    getPurchases() {
      this.getStorePurchases({
        guest_id: this.currentReservation.guest_id,
        page: 1,
      })
    },
    storeSelectHandler() {
      this.menuExpanded = true
      this.getTourist(this.conversation.guest_id)
    },
    openStoreModal() {
      const { confirmation_code } = this.currentReservation
      window.open(
        `${process.env.VUE_APP_TOURIST_PORTAL_URL}/tourist/store/reservation/${confirmation_code}?externalCheckout=true`,
        '_blank'
      )
    },
    async getTourist(id) {
      const { data } = await this.getTouristInfo({ id })
      this.touristEmail = data && data.email
    },
    closeStore() {
      this.getPurchases()
      this.storeDialog = null
    },
    closeSignup() {
      this.getTourist(this.conversation.guest_id)
      this.signupDialog = null
    },
  },
  mounted() {
    this.updatePushedMessage(null)
  },
  watch: {
    serviceCallModalOpened(val) {
      if (val) {
        this.menuExpanded = true
        this.tab = 'issues'
      }
    },
  },
  components: {
    PurchaseTable,
    ConversationInfoGuest,
    ConversationInfoReservation,
    ConversationPricing,
    ConversationInfoOptionalListings,
    ConversationInfoPayment,
    ConversationInfoIssues,
    QuickCharge,
    StoreDialog,
    MarketingLinks,
  },
}
</script>
<style lang="scss" scoped>
.conversation-info {
  border: 1px solid var(--v-secondary-lighten1);

  &-menu {
    border-left: 1px solid var(--v-secondary-lighten1);

    .v-tab {
      min-width: unset;
      padding: 0 12px;
      border-radius: 5px;

      &--active {
        border: 1px solid currentColor !important;
      }
    }

    .v-icon.open {
      transform: rotateY(180deg);
    }
  }
}

.toggle-btn {
  color: #7ebac0;
}

.btn--active {
  background-color: #7ebac0 !important;
  color: #ffffff !important;
}

.store-iframe {
  width: 100%;
  height: 100vh;
}
</style>
