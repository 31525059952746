<template>
  <v-card class="pa-4">
    <div class="d-flex pa-4">
      <div
        class="flex text-center justify-center font-weight-medium text-subtitle-1"
      >
        Pre-Made Message {{ newMessage.id ? 'Edit' : 'Create' }}
      </div>
    </div>
    <v-form ref="form" @submit.prevent="save">
      <v-text-field
        v-model="newMessage.title"
        label="Title"
        dense
        outlined
        :rules="[required]"
      />
      <v-select
        v-model="newMessage.field"
        :items="['Sales', 'Customer-Support']"
        label="Department"
        :rules="[required]"
        outlined
        dense
        required
      />
      <conversation-tag-multi-select
        v-if="isDesignedVr"
        :pre-selected="newMessage.sales_status"
        :value.sync="newMessage.sales_status"
        :rules="[required]"
      />
      <v-combobox
        v-model="newMessage.keywords"
        chips
        multiple
        outlined
        label="Keywords"
      />
      <v-textarea
        v-model="newMessage.body"
        placeholder="Text..."
        label="Message"
        outlined
        rows="3"
      >
      </v-textarea>
      <v-card-actions class="justify-end">
        <v-btn color="primary" outlined @click="close">Cancel</v-btn>
        <v-btn color="primary" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import ConversationTagMultiSelect from 'components/conversations/coversation-tag-multi-select'
import formRules from 'components/mixins/form-rules-mixin'
import { mapActions } from 'vuex'
export default {
  name: 'PreMadeMsgModal',
  components: { ConversationTagMultiSelect },
  mixins: [formRules],
  props: ['msg'],
  data() {
    return {
      newMessage: this.msg || {},
    }
  },
  methods: {
    ...mapActions(['createPreMadeMessage']),
    close() {
      this.$store.commit('hideModal')
    },
    save() {
      if (this.$refs.form.validate()) {
        this.createPreMadeMessage(this.newMessage)
        this.$store.commit('hideModal')
      }
    },
  },
}
</script>

<style scoped></style>
