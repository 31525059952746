<template>
  <v-row class="pa-3 ma-0">
    <v-col cols="12">
      <div v-if="isBoom">
        <div class="d-flex justify-space-between align-center my-3">
          <div class="text-h6">{{ $t('Property images') }}</div>
          <v-btn
            small
            color="primary"
            :disabled="!saveEnabled"
            @click="updateListing"
            >Save
          </v-btn>
        </div>
        <div class="text-body-2 font-weight-medium">
          * The first image will be used as the Main image
        </div>
        <div class="text-body-2 font-weight-medium warning--text">
          ** All pictures must be in high quality and in minimum resolution of
          1024x768
        </div>
      </div>
      <gallery
        :folder="`listings/${listing.nickname}/pictures`"
        :cols="isMobile ? 4 : 2"
        :limitation="isOptima ? null : checkImageResolution"
        :uploadable="isBoom"
        :thumbnails="true"
        :images="pictures"
        :on-change="addPictures"
        :remove-img="removePicture"
        :compress="false"
        image-value="original"
        :draggable="isBoom"
        :readonly="!isBoom"
        @on-dragged="onDragged"
      />
    </v-col>
  </v-row>
</template>

<script>
import Common_functions from 'components/mixins/common_functions'
import Gallery from 'components/common/gallery.vue'
import DeviceMixin from 'components/mixins/device-mixin'

export default {
  name: 'ListingGallery',
  components: { Gallery },
  props: ['listing'],
  mixins: [Common_functions, DeviceMixin],
  data() {
    return {
      pictures: [],
      saveEnabled: false,
    }
  },
  mounted() {
    this.pictures = this.sortPictures()
  },
  computed: {
    isBoom() {
      return (
        !this.listing.app_integration_id ||
        this.listing.app_integration_name === 'boom' ||
        this.isOptima
      )
    },
  },
  methods: {
    sortPictures() {
      if (this.listing && this.listing.pictures) {
        return this.listing.pictures
          .filter(p => p.thumbnail || p.original)
          .sort((a, b) => a.sort - b.sort)
      }
      return []
    },
    addPictures(pics) {
      const newPics = pics.map((img, i) => ({
        original: img,
        sort: this.pictures.length + i + 1,
      }))
      this.pictures = [...this.pictures, ...newPics]
      this.saveEnabled = true
    },
    removePicture(pic) {
      this.pictures = this.pictures.filter(p => pic !== p.original)
      this.saveEnabled = true
    },
    onDragged(e) {
      const pictures = [...this.pictures]
      const pic = pictures.splice(e.oldIndex, 1)
      pictures.splice(e.newIndex, 0, pic[0])

      this.pictures = pictures.map((p, i) => ({ ...p, sort: i + 1 }))
      this.saveEnabled = true
    },
    async updateListing() {
      this.$store.commit('updateLoading', true)
      this.saveEnabled = false
      await this.$store
        .dispatch('listings/updateListing', {
          id: this.$route.params.id,
          payload: {
            pictures: this.pictures,
          },
        })
        .finally(this.$store.commit('updateLoading', false))
    },
  },
}
</script>

<style scoped>
.ghost {
  opacity: 0.1;
}
</style>
