<template>
  <div class="stripe-form">
    <v-row class="d-flex mb-4">
      <v-col class="d-flex pt-0 flex-column stripe-field-wrapper" cols="12">
        <div ref="card" class="field flex">
          <!-- A Stripe Element will be inserted here. -->
        </div>
        <div v-if="errors.cardNumber" class="text-caption error--text ml-2">
          {{ errors.cardNumber }}
        </div>
      </v-col>
      <v-col cols="6" class="d-flex flex-column stripe-field-wrapper">
        <div ref="exp" class="field flex">
          <!-- A Stripe Element will be inserted here. -->
        </div>
        <div v-if="errors.cardExpiry" class="text-caption error--text ml-2">
          {{ errors.cardExpiry }}
        </div>
      </v-col>
      <v-col cols="6" class="d-flex flex-column stripe-field-wrapper">
        <div ref="cvc" class="field flex">
          <!-- A Stripe Element will be inserted here. -->
        </div>
        <div v-if="errors.cardCvc" class="text-caption error--text ml-2">
          {{ errors.cardCvc }}
        </div>
      </v-col>
    </v-row>
    <v-divider
      v-if="cardErrors"
      :class="[
        'form-divider',
        'mt-2',
        { 'card-field-style': cardErrors, 'card-focus': cardFocus },
      ]"
    />
    <div class="text-caption error--text">
      {{ cardErrors }}
    </div>
    <slot name="disclaimer" />
    <div class="text-right">
      <v-btn
        :loading="paymentProcessing"
        color="primary"
        elevation="0"
        :block="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
        large
        @click.prevent="purchase"
      >
        {{ buttonText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import formRules from 'components/mixins/form-rules-mixin'
import { loadStripe } from '@stripe/stripe-js/pure'

const elementStyles = {
  base: {
    color: '#000000',
    fontWeight: 300,
    fontFamily: 'Montserrat',
    fontSize: '16px',

    ':focus': {
      color: '#000000',
    },

    '::placeholder': {
      color: 'rgba(0, 0, 0, .6)',
    },

    ':focus::placeholder': {
      color: '#CFD7DF',
    },
  },
  invalid: {
    color: '#A01622',
    '::placeholder': {
      color: '#FFCCA5',
    },
  },
}

const elementClasses = {
  focus: 'focus',
  empty: 'empty',
  invalid: 'invalid',
}
const elementsOptions = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700',
    },
  ],
}

export default {
  name: 'StripeElement',
  mixins: [formRules],
  props: ['clientSecret', 'buttonText', 'publicKey', 'email', 'intentId'],
  data() {
    return {
      stripe: undefined,
      card: undefined,
      cvc: undefined,
      exp: undefined,
      errors: {
        cardNumber: '',
        cardExpiry: '',
        cardCvc: '',
      },
      cardErrors: '',
      valid: false,
      cardFocus: false,
      paymentProcessing: false,
    }
  },
  mounted: async function () {
    this.stripe = await loadStripe(this.publicKey)
    const stripeElements = this.stripe.elements(elementsOptions)
    this.card = stripeElements.create('cardNumber', {
      showIcon: true,
      hidePostalCode: true,
      style: elementStyles,
      classes: elementClasses,
      placeholder: 'Card Number',
    })
    this.cvc = stripeElements.create('cardCvc', {
      style: elementStyles,
      classes: elementClasses,
    })
    this.exp = stripeElements.create('cardExpiry', {
      style: elementStyles,
      classes: elementClasses,
    })
    this.card.mount(this.$refs.card)
    this.exp.mount(this.$refs.exp)
    this.cvc.mount(this.$refs.cvc)
    this.card.on('change', e => {
      if (e.error) {
        this.errors.cardNumber = e.error.message
      } else {
        this.errors.cardNumber = ''
      }
    })
    this.exp.on('change', e => {
      if (e.error) {
        this.errors.cardExpiry = e.error.message
      } else {
        this.errors.cardExpiry = ''
      }
    })
    this.cvc.on('change', e => {
      if (e.error) {
        this.errors.cardCvc = e.error.message
      } else {
        this.errors.cardCvc = ''
      }
    })
    this.card.on('focus', () => {
      this.cardFocus = true
    })
  },
  methods: {
    async purchase() {
      this.$emit('payment-processing')
      this.stripe
        .confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.card,
          },
          receipt_email: this.email,
        })
        .then(({ paymentIntent, error }) => {
          if (error) {
            this.cardErrors = error.message
            this.$emit('payment-failed')
            return
          }
          this.cardErrors = ''
          this.$emit('payment-success', {
            intent: paymentIntent,
            user: { email: this.email },
          })
        })
        .catch(() => {
          this.$emit('payment-failed')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
label {
  position: relative;
  color: #6a7c94;
  font-weight: 400;
  height: 32px;
  line-height: 32px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

label > span {
  width: 115px;
}

.form-divider {
  background: rgb(118, 118, 118);
}
.card-focus:not(.card-field-style) {
  background: #7ebac0;
  height: 2px;
}

.stripe-link {
  text-decoration: none;
}
.stripe-badge {
  line-height: 0;
}

.stripe-form .field {
  padding: 10px 12px 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  width: 100%;
  margin-top: 6px;
  + div {
    position: absolute;
    left: 15px;
    bottom: -10px;
  }
}

.stripe-form .field.focus,
.stripe-form .field:focus {
  border-color: var(--v-primary-base);
  border-width: 2px;
}

.stripe-form .field.invalid {
  border-color: var(--v-error-base);
  color: var(--v-error-base);
  border-width: 2px;
}

.stripe-form input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
}

.stripe-field-wrapper {
  position: relative;
}
</style>
