<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="12" sm="auto" align="center">
        <v-select
          v-model="filters.type"
          label="Type"
          :items="['All', 'Contractor Payment', 'Listings Payments']"
          outlined
          hide-details
          @change="fetchData(1)"
        />
      </v-col>

      <v-col cols="12" sm="5">
        <v-text-field
          v-model="filters.search"
          label="Search..."
          prepend-inner-icon="search"
          outlined
          clearable
          hide-details
          @input="fetchDataDebounce(1)"
        />
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-data-table
        :headers="tableHeaders"
        :items="items"
        :options="pagination"
        :server-items-length="pagination.itemsLength"
        :loading="fetchingData"
        class="elevation-1 w-100 mx-3"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100],
        }"
        @pagination="paginationChanged($event)"
      >
        <template #item.amount="{ item }">
          {{ dollarFormatter(item.amount) }}
        </template>
        <template #item.info="{ item }">
          <div v-if="item.type === 'Contractor Payment'" class="text-no-wrap">
            Contractor:<br />{{ nameById(item.contractor_id) }}
          </div>
          <div v-else>
            <div>Listings:</div>
            <div
              v-for="listingPayment in item.listing_payments"
              :key="listingPayment.id"
              class="text-no-wrap"
            >
              {{ listingNicknameById(listingPayment.id) }}
            </div>
          </div>
        </template>
        <template #item.created_at="{ item }">
          <div class="text-no-wrap">
            {{ parseDate(item.created_at) }}
          </div>
        </template>
        <template #item.transaction_type="{ item }">
          <div class="text-no-wrap text text-capitalize">
            {{ item.transaction_type }}
          </div>
        </template>
        <template #item.period="{ item }">
          <div class="text-no-wrap">
            {{ parseDate(item.start_period) }}<br />
            {{ parseDate(item.end_period) }}
          </div>
        </template>
        <template #item.created_by_id="{ item }">
          {{ nameById(item.created_by_id) }}
        </template>
        <template #item.confirmed_by_id="{ item }">
          {{ nameById(item.confirmed_by_id) }}
        </template>
        <template #item.notes="{ item }">
          <inline-text-field
            class="flex-1"
            :multiline="true"
            placeholder="notes..."
            :value="item.notes"
            @change="actionTransaction('update', item.id, { notes: $event })"
          />
        </template>
        <template #item.actions="{ item }">
          <div v-if="item.status === 'InProcess'" class="flex-center">
            <ConfirmationModal
              title="Approve Confirmation"
              :text="`Are you sure ?`"
              @action="actionTransaction('approve', item.id)"
            >
              <template #activator="{ on }">
                <v-btn
                  small
                  color="success"
                  :disabled="idsInAction.has(item.id)"
                  v-on="on"
                  >Approve
                </v-btn>
              </template>
            </ConfirmationModal>
            <ConfirmationModal
              title="Cancel Confirmation"
              :text="`Are you sure ?`"
              @action="actionTransaction('cancel', item.id)"
            >
              <template #activator="{ on }">
                <v-btn
                  class="ml-3"
                  small
                  color="error"
                  :disabled="idsInAction.has(item.id)"
                  v-on="on"
                  >Cancel
                </v-btn>
              </template>
            </ConfirmationModal>
          </div>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import axios from '@/axios/config'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { debounce } from 'lodash'
import ConfirmationModal from 'components/modals/confirmation-modal'
import InlineTextField from '../inline-inputs/inline-text-field'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'AchTransactions',
  components: { InlineTextField, ConfirmationModal },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  data: function () {
    return {
      filters: {
        type: 'All',
        search: '',
      },
      tableHeaders: [
        { text: 'Id', value: 'id', sortable: false },
        {
          text: 'Confirmation Code',
          value: 'confirmation_code',
          sortable: false,
        },
        { text: 'Amount', value: 'amount', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Info', value: 'info', sortable: false },
        {
          text: 'Transaction Type',
          value: 'transaction_type',
          sortable: false,
        },
        { text: 'Created At', value: 'created_at', sortable: false },
        { text: 'Period', value: 'period', sortable: false },
        { text: 'Created By', value: 'created_by_id', sortable: false },
        { text: 'Confirmed By', value: 'confirmed_by_id', sortable: false },
        { text: 'Notes', value: 'notes', sortable: false },
      ].concat(
        this.hasAbility('ach-transactions-actions')
          ? [
              {
                text: 'Actions',
                value: 'actions',
                sortable: false,
              },
            ]
          : []
      ),
      fetchingData: true,
      pagination: {
        page: 1,
        itemsPerPage: 25,
        itemsLength: null,
      },
      items: [],
      fetchDataDebounce: debounce(this.fetchData, 300),
      idsInAction: new Set(),
    }
  },
  computed: {},
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData(page) {
      this.fetchingData = true
      if (page) this.pagination.page = page
      axios
        .get('/api/ach-transactions', {
          params: {
            page: this.pagination.page,
            per_page: this.pagination.itemsPerPage,
            search: this.filters.search,
            type: this.filters.type,
          },
        })
        .then(res => {
          this.items = res.data.transactions
          this.pagination = {
            page: parseInt(res.data.pagi_info.page),
            itemsPerPage: parseInt(res.data.pagi_info.per_page),
            itemsLength: parseInt(res.data.pagi_info.count),
          }
        })
        .finally(() => {
          this.fetchingData = false
        })
    },
    paginationChanged(pagination) {
      const page = this.pagination.page
      const itemsPerPage = this.pagination.itemsPerPage
      this.pagination = pagination
      if (
        page !== pagination.page ||
        itemsPerPage !== pagination.itemsPerPage
      ) {
        this.fetchData()
      }
    },
    actionTransaction(action, transactionId, updatePayload = {}) {
      this.idsInAction.add(transactionId)
      this.$forceUpdate()
      axios
        .post(`/api/ach-transactions/${transactionId}/${action}`, updatePayload)
        .then(_res => this.fetchData())
        .finally(() => {
          this.idsInAction.delete(transactionId)
          this.$forceUpdate()
        })
    },
  },
}
</script>

<style scoped></style>
