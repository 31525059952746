<template>
  <div>
    <div class="grey lighten-4">
      <v-container class="pa-0">
        <v-tabs
          v-model="paramsTab"
          slider-color="white"
          background-color="#f6f6f7"
          active-class="white"
        >
          <v-tab href="#live">
            <v-icon left>$pulse</v-icon> {{ $t('Live') }}</v-tab
          >
          <v-tab href="#config">
            <v-icon left>$settings</v-icon> {{ $t('Config') }}</v-tab
          >
        </v-tabs>
      </v-container>
    </div>
    <v-container>
      <v-tabs-items v-model="paramsTab">
        <v-tab-item id="live" class="ma-4">
          <ai-live />
        </v-tab-item>
        <v-tab-item v-if="settingsGetter.id" id="config" class="ma-4">
          <ai-config />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import AiLive from 'components/ai/ai-live'
import AiConfig from 'components/ai/ai-config'
export default {
  components: {
    AiConfig,
    AiLive,
  },
  mounted() {
    this.$store.dispatch('getAiConfigs')
  },
  computed: {
    settingsGetter() {
      return this.$store.state.AiConfigs
    },
  },
}
</script>
<style></style>
