<template>
  <v-card class="overflow-auto full-screen">
    <v-card-title class="text-center">
      <span class="text-h5"
        >{{ !editMode ? 'Create new' : 'Edit' }} SC rule
        {{ editMode ? ' #' + listingTaskRule.id : '' }}</span
      >
    </v-card-title>
    <v-form ref="form" @submit.prevent="save">
      <v-card-text>
        <v-switch
          v-if="!editMode"
          v-model="listingTaskRule.is_master_rule"
          mt-2
          hide-details
          dense
          class="d-inline-block pa-0 ma-0"
          :label="$t('Create a master-rule')"
        />
        <v-switch
          v-if="!editMode"
          v-model="listingTaskRule.office_task_rule"
          class="mb-4 mt-2"
          hide-details
          dense
          :label="$t('Create a office task rule')"
        />

        <div v-if="listingTaskRule.is_master_rule" class="mb-4 comp-wrapper">
          <h4 cols="12" class="text-subtitle-1">Apply on</h4>
          <v-checkbox
            v-model="listingTaskRule.rules.work_on_setups"
            hide-details
            :label="$t('Apply on setups')"
          />
          <v-checkbox
            v-model="listingTaskRule.rules.work_on_units"
            hide-details
            :label="$t('Apply also on units')"
          />
          <v-checkbox
            v-model="listingTaskRule.rules.work_on_pools"
            hide-details
            :label="$t('Apply only pools/Hotub')"
          />
          <!--          <v-checkbox
            v-model="listingTaskRule.rules.work_on_gardens"
            hide-details
            :label="$t('Apply only if garden')"
          />-->
        </div>
        <v-text-field
          v-model="listingTaskRule.description"
          dense
          outlined
          :rules="[required]"
          :label="$t('Description')"
        />
        <listing-priority :value.sync="listingTaskRule.priority" class="mb-5" />
        <catalog-quick-select
          :rules="[required]"
          :value="listingTaskRule.listing_task_catalog_item_id"
          :filter-change="quickPickChange"
        />
        <v-autocomplete
          v-if="!listingTaskRule.office_task_rule"
          v-model="listingTaskRule.auto_expense_type"
          outlined
          dense
          :items="expensesTypes"
          :label="$t('Expense type')"
        />
        <div
          v-if="
            !editMode &&
            !listingTaskRule.is_master_rule &&
            !listingTaskRule.office_task_rule
          "
          class="mt-3"
        >
          <multi-property-picker
            v-if="!listingLoading"
            v-model="listingTaskRule.listings_ids"
          />
        </div>
        <v-text-field
          v-model="listingTaskRule.planned_duration"
          outlined
          dense
          class="mt-2"
          :label="$t('Planned duration (hours)')"
        />

        <v-text-field
          v-if="!listingTaskRule.office_task_rule"
          v-model="listingTaskRule.amount"
          type="number"
          outlined
          dense
          :label="$t('Price per visit')"
        />
        <v-text-field
          v-if="!listingTaskRule.office_task_rule"
          v-model="listingTaskRule.management_fee"
          type="number"
          outlined
          dense
          :label="$t('Management fee visit')"
        />
        <div class="comp-wrapper">
          <div class="text-subtitle-1">Assigned person:</div>
          <v-radio-group v-model="byContractor" row>
            <v-radio label="Contractor" value="contractor">{{
              $t('By Contractor')
            }}</v-radio>
            <v-radio
              v-if="!listingTaskRule.office_task_rule"
              label="Personnel"
              value="personnel"
              >{{ $t('By personnel') }}</v-radio
            >
          </v-radio-group>
          <v-select
            v-if="byContractor === 'personnel'"
            v-model="listingTaskRule.by_personnel"
            dense
            clearable
            :hint="personnelNames"
            persistent-hint
            hide-details
            :label="$t('Choose Personnel')"
            outlined
            :items="[
              { text: 'Cleaning Supervisor', value: 'cleaning_supervisor' },
              { text: 'Pool', value: 'pool' },
              { text: 'Pest', value: 'pest' },
              { text: 'Landscape', value: 'landscape' },
              { text: 'Host', value: 'host' },
              { text: 'Region Manager', value: 'region_manager' },
            ]"
            @change="personnelChange"
          />
          <div v-if="byContractor === 'personnel'">
            <span
              v-for="(person, ind) in personnelNames"
              :key="ind"
              class="secondary--text d-inline text-caption"
            >
              <span class="font-weight-medium">{{ person.listing }} -> </span>
              <span
                :class="person.name === 'Missing personnel' && 'warning--text'"
                >{{ person.name }}</span
              >
              <span v-if="ind + 1 !== personnelNames.length" class="mx-2">
                |</span
              >
            </span>
          </div>
          <div v-if="byContractor === 'contractor'">
            <contractor-select
              :value.sync="listingTaskRule.assigned_contractor_id"
            />
            <contractor-select
              v-if="!listingTaskRule.office_task_rule"
              icon="$account_alt"
              :label="$t('Additional assignee')"
              hint="Up to 4 assignees"
              :persistent-hint="true"
              :allow-multiple="true"
              :input="limited"
              :enable-sug="true"
              :value.sync="additionalContractors"
            />
          </div>
        </div>
        <div class="comp-wrapper">
          <v-row>
            <v-col cols="12" class="text-subtitle-1">{{
              $t('Time settings')
            }}</v-col>
            <v-col cols="6">
              <v-select
                v-model="everyPriod"
                outlined
                dense
                hide-details
                :items="everyPriodOptions"
                :label="$t('Period')"
                @change="everyPriodChange($event)"
              />
            </v-col>
            <v-col
              v-if="
                everyPriod &&
                !everyPriod.includes('check') &&
                !everyPriod.includes('while') &&
                !everyPriod.includes('garbage-days')
              "
              cols="6"
            >
              <v-select
                v-model="listingTaskRule.rules.every_number"
                :items="everyRuleNumbers"
                :label="$t('Every')"
                outlined
                hide-details
                dense
              ></v-select>
            </v-col>
            <v-col
              v-if="
                everyPriod && (listingsPicked || listingTaskRule.is_master_rule)
              "
              cols="6"
            >
              <v-select
                v-model="listingTaskRule.rules.exclude_list"
                :items="[
                  'check-in',
                  'checkout',
                  'checkout&in',
                  'while-staying',
                ]"
                multiple
                mt-2
                hide-details
                dense
                outlined
                :label="$t('Exclude on')"
              />
            </v-col>
            <v-col v-if="everyPriod" cols="6">
              <v-select
                v-model="listingTaskRule.rules.days_without_cleaning"
                outlined
                dense
                hide-details
                type="number"
                :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]"
                :label="$t('Days without cleaning task')"
              />
            </v-col>
            <v-col v-if="reservationRelated" cols="6">
              <v-select
                v-model="listingTaskRule.rules.reservation_min_days"
                outlined
                dense
                hide-details
                type="number"
                :items="[
                  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                  19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33,
                  34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
                  49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
                  64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
                  79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93,
                  94, 95, 96, 97, 98, 99, 100,
                ]"
                :label="$t('Reservation min length')"
              />
            </v-col>
            <v-col v-if="reservationRelated" cols="6">
              <v-select
                v-model="listingTaskRule.rules.reservation_source"
                outlined
                dense
                hide-details
                multiple
                :items="channels"
                :label="$t('Whitelist channels')"
              />
            </v-col>
            <v-col v-if="reservationRelated" cols="6">
              <v-select
                v-model="listingTaskRule.rules.reservation_source_black"
                outlined
                dense
                hide-details
                multiple
                :items="channels"
                :label="$t('Blacklist channels')"
              />
            </v-col>
            <v-col v-if="everyPriod && everyPriod.includes('check')" cols="6">
              <v-select
                v-model="listingTaskRule.rules.min_days_between"
                outlined
                dense
                hide-details
                type="number"
                :items="[
                  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                  19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33,
                  34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
                  49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
                  64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
                  79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93,
                  94, 95, 96, 97, 98, 99, 100,
                ]"
                :label="$t('Min Days between visits')"
              />
            </v-col>
            <v-col v-if="everyPriod && everyPriod.includes('check')" cols="6">
              <v-select
                v-model="listingTaskRule.rules.max_days_between"
                outlined
                dense
                hide-details
                type="number"
                :items="[
                  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                  19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33,
                  34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
                  49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
                  64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
                  79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93,
                  94, 95, 96, 97, 98, 99, 100,
                ]"
                :label="$t('Max Days between visits')"
              />
            </v-col>
            <v-col
              v-if="everyPriod && !everyPriod.includes('garbage-days')"
              cols="12"
            >
              <v-select
                v-model="listingTaskRule.rules.allowed_days"
                outlined
                dense
                hide-details
                multiple
                :items="[
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                  'Sunday',
                ]"
                :label="$t('Allowed visit days')"
              />
            </v-col>
            <v-col v-if="everyPriod">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                required
                min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="listingTaskRule.rules.start_date"
                    dense
                    outlined
                    hide-details
                    :label="$t('Starting From')"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="listingTaskRule.rules.start_date"
                  :max="
                    new Date(new Date().setMonth(new Date().getMonth() + 10))
                      .toISOString()
                      .substr(0, 10)
                  "
                  :min="new Date().toISOString().substr(0, 10)"
                  @change="saveDate"
                />
              </v-menu>
            </v-col>
            <v-col cols="12">
              <vue-timepicker
                v-model="listingTaskRule.rules.hour"
                format="hh:mm a"
                :minute-interval="15"
                :hour-range="[
                  '7a',
                  '8a',
                  '9a',
                  '10a',
                  '11a',
                  '12p',
                  '1p',
                  '2p',
                  '3p',
                  '4p',
                  '5p',
                  '6p',
                  '7p',
                  '8p',
                  '9p',
                  '10p',
                ]"
              >
              </vue-timepicker>
            </v-col>
          </v-row>
        </div>
        <div v-if="!isEmpty(ruleTasksList)" class="comp-wrapper">
          <v-checkbox
            v-model="overrideChecklist"
            :disabled="listingTaskRule.is_master_rule"
            :label="$t('Override Checklist')"
            dense
            hide-details
          />
          <lt-sub-task-list-item
            :disabled="!overrideChecklist"
            :after-change="checkListChange"
            :existing-items="ruleTasksList"
          />
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="blue darken-1" text @click.native="close">Close</v-btn>
        <v-btn color="blue darken-1" :disabled="loading" text type="submit"
          >{{ $t('Save') }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import ContractorSelect from './contractor-select.vue'
import MultiPropertyPicker from './multi-property-picker.vue'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import CatalogQuickSelect from 'components/catalog-quick-select'
import LtSubTaskListItem from 'components/listing-tasks/lt-sub-task-list-item'
import { pick } from 'lodash'
import { compact } from 'lodash/fp'
import { mapGetters } from 'vuex'
import ListingPriority from 'components/listing-priority.vue'

export default {
  components: {
    ListingPriority,
    LtSubTaskListItem,
    CatalogQuickSelect,
    ContractorSelect,
    MultiPropertyPicker,
    VueTimepicker,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormRulesMixin],
  props: ['afterSaveFunc', 'editMode', 'currentRule'],
  data() {
    return {
      byContractor:
        this.currentRule && this.currentRule.by_personnel
          ? 'personnel'
          : 'contractor',
      personnelNames: [],
      overrideChecklist:
        this.currentRule && !this.isEmpty(this.currentRule.checklist),
      additionalContractors: this.currentRule
        ? compact([
            this.currentRule.assigned_contractor1_id,
            this.currentRule.assigned_contractor2_id,
            this.currentRule.assigned_contractor3_id,
            this.currentRule.assigned_contractor4_id,
          ])
        : [],
      currentEveryPriod: null,
      ruleTasksList:
        this.currentRule && this.currentRule.check_list_items
          ? this.currentRule.check_list_items
          : [],
      listingTaskRule: this.currentRule
        ? { ...this.currentRule }
        : {
            is_master_rule: false,
            rules: {
              every_priod: null,
              start_date: new Date().toISOString().substr(0, 10),
              simple_mode: true,
              exclude_list: null,
            },
            listings_ids: [],
          },
      menu: false,
    }
  },
  mounted() {
    if (this.currentRule && this.listingTaskRule.by_personnel) {
      const listing = this.listingsPicker.find(
        l => l.id === this.listingTaskRule.listing_id
      )
      const name = this.getPersonnelName(
        this.listingTaskRule.by_personnel,
        listing
      )
      this.personnelNames.push({ name, listing: listing.nickname })
    }
  },
  computed: {
    ...mapGetters(['listingsPicker']),
    channels() {
      return [
        'Airbnb',
        'Booking.com',
        'Website',
        'Expedia',
        'Google',
        'HomeAway',
        'HomeToGo',
        'Marriott',
        'PlumGuide',
        'TripAdvisor',
        'Vacation',
      ]
    },
    reservationRelated() {
      return (
        this.everyPriod === 'check-in' ||
        this.everyPriod === 'checkout' ||
        this.everyPriod === 'while-staying'
      )
    },
    listingsPicked() {
      return (
        (this.currentRule && this.currentRule.listing_id) ||
        this.everyPriod === 'check-in' ||
        this.everyPriod === 'checkout' ||
        this.everyPriod === 'while-staying' ||
        !this.isEmpty(this.listingTaskRule.listings_ids)
      )
    },
    everyPriodOptions() {
      if (
        this.listingsPicked ||
        this.listingTaskRule.is_master_rule ||
        (this.currentRule && this.currentRule.listing_id)
      ) {
        return [
          'check-in',
          'checkout',
          'while-staying',
          'garbage-days',
          'days',
          'weeks',
          'months',
          'years',
        ]
      } else {
        return ['days', 'weeks', 'months', 'years']
      }
    },
    everyPriod: {
      get() {
        return this.currentEveryPriod || this.listingTaskRule.rules.every_priod
      },
      set(val) {
        this.listingTaskRule.rules.every_priod = val
      },
    },
    everyRuleNumbers() {
      if (this.everyPriod === 'days') {
        return [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
          38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
          55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71,
          72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88,
          89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
        ]
      } else if (this.everyPriod === 'weeks') {
        return [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25,
        ]
      } else {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      }
    },
    description() {
      return this.listingTaskRule.description
    },
    taskType() {
      return this.listingTaskRule.task_type
    },
    subTopic() {
      return this.listingTaskRule.sub_topic
    },
    contractorId() {
      return this.listingTaskRule.assigned_contractor_id
    },
  },
  methods: {
    limited(e) {
      if (e.length > 4) {
        e.pop()
      }
    },
    getPersonnelName(role, listing) {
      const personnel = listing && listing.personal[role]
      if (personnel) {
        return this.nameById(personnel)
      }
      return ''
    },
    personnelChange(personnel) {
      this.personnelNames = []
      if (this.listingTaskRule.is_master_rule || !personnel) {
        return
      }
      const listingIds = this.currentRule
        ? [this.currentRule.listing_id]
        : this.listingTaskRule.listings_ids
      listingIds.forEach(l_id => {
        const listing = this.listingsPicker.find(l => l.id === l_id)
        const name = this.getPersonnelName(personnel, listing)
        this.personnelNames.push({
          name: name || 'Missing personnel',
          listing: listing.nickname,
        })
      })
    },
    checkListChange(items) {
      this.ruleTasksList = items
    },
    quickPickChange(change) {
      this.listingTaskRule.task_type = change.department
      this.listingTaskRule.sub_topic = change.sub_topic
      this.listingTaskRule.planned_duration = change.avg_duration
      this.ruleTasksList = change.checklist
      if (change.auto_expense_type) {
        this.listingTaskRule.auto_expense_type = change.auto_expense_type
      }
      if (!this.listingTaskRule.assigned_contractor_id) {
        this.listingTaskRule.assigned_contractor_id = change.user_id
      }
      this.listingTaskRule.assigned_contractor_id = change.user_id
      if (!this.listingTaskRule.amount) {
        this.listingTaskRule.amount = change.avg_price_total
      }
      this.listingTaskRule.listing_task_catalog_item_id = change.id
      this.listingTaskRule.listing_task_catalog_item_name = change.description
    },
    everyPriodChange(val) {
      const picks = ['every_priod', 'simple_mode']
      if (this.listingTaskRule.is_master_rule) {
        picks.push(
          ...[
            'work_on_setups',
            'work_on_units',
            'work_on_pools',
            'work_on_gardens',
          ]
        )
      }
      this.listingTaskRule.rules = pick(this.listingTaskRule.rules, picks)
      this.currentEveryPriod = val
      this.everyPriod = val
    },
    saveDate(date) {
      this.$refs.menu.save(date)
    },
    close() {
      this.currentEveryPriod = null
      this.$store.commit('hideModal')
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.listingTaskRule.is_master_rule) {
          if (this.editMode) {
            this.listingTaskRule.update_all = confirm(
              'This update will apply on all rules created from this master rule.'
            )
          }
        }
        if (
          this.byContractor === 'personnel' &&
          this.listingTaskRule.by_personnel
        ) {
          this.listingTaskRule.assigned_contractor_id = null
        } else if (
          this.byContractor !== 'personnel' &&
          this.listingTaskRule.assigned_contractor_id
        ) {
          this.listingTaskRule.by_personnel = null
        }
        if (this.listingTaskRule.rules.hour) {
          this.listingTaskRule.rules.hour = this.transformHourToString(
            this.listingTaskRule.rules.hour
          )
        }
        if (this.ruleTasksList) {
          this.listingTaskRule.checklist = this.ruleTasksList
        }
        this.listingTaskRule.override_catalog = this.overrideChecklist
        this.listingTaskRule.additional_contractors = this.additionalContractors
        this.$store.commit('updateListingLoading', true)
        this.listingTaskRule.created_tasks = []
        if (this.editMode) {
          axios
            .post(
              `/api/auto-listing-tasks/${this.listingTaskRule.id}`,
              this.listingTaskRule
            )
            .then(() => {
              this.$store.commit('updateListingLoading', false)
              if (this.afterSaveFunc) {
                this.afterSaveFunc()
              } else {
                this.$store.dispatch('getAutoListingTaskRules', {
                  id: this.listingManageContext
                    ? this.$router.currentRoute.path.split('/')[3]
                    : 'All',
                })
              }
              this.close()
            })
            .catch(error => {
              alert(error)
              this.$store.commit('updateListingLoading', false)
              this.close()
            })
        } else {
          axios
            .post('/api/auto-listing-tasks', this.listingTaskRule)
            .then(() => {
              this.close()
              if (this.afterSaveFunc) {
                this.afterSaveFunc()
              }
            })
            .catch(error => {
              alert(error)
              this.close()
              this.$store.commit('updateListingLoading', false)
            })
        }
      }
    },
  },
}
</script>
