<template>
  <div class="ma-4">
    <div class="text-center font-weight-bold my-5">Marketing Links</div>
    <div
      v-for="link in links"
      :key="link.label"
      :class="[!link.href && 'opacity-30']"
    >
      <div class="my-2 d-flex align-center">
        <v-icon class="mr-5">{{ link.icon }}</v-icon>
        <a class="black--text darken-2" target="_blank" :href="link.href"
          >{{ link.label }}
        </a>
        <copy-clipboard
          v-if="!!link.href"
          class="ml-2"
          description="Copy payment link"
          :text="link.href"
          >Copy Link</copy-clipboard
        >
      </div>
      <v-divider />
    </div>
  </div>
</template>

<script>
import CopyClipboard from 'components/copy-clipboard'

const ICONS = {
  Airbnb: '$airbnb',
  'Booking.com': '$bookingcom',
  'Designedvr.com': '$designedvrcom',
  Expedia: '$expedia',
  HomeAway: '$homeaway',
  HomeToGo: '$hometogo',
  Marriott: '$marriott',
  PlumGuide: '$plumguide',
  TripAdvisor: '$tripadvisor',
}

export default {
  components: { CopyClipboard },
  props: ['integrations'],
  computed: {
    links() {
      return Object.keys(this.integrations).map(k => ({
        href: this.integrations[k].links[0],
        icon: ICONS[k],
        label: k,
      }))
    },
  },
}
</script>

<style></style>
