import ActionCable from 'actioncable'

class Websocket {
  static initStore(store) {
    Websocket.store = store
    Websocket.consumer = ActionCable.createConsumer()
  }

  static unsubscribe(subscription) {
    if (subscription) {
      return Websocket.consumer.subscriptions.remove(subscription)
    }
  }

  static subscribe(channel, handler, params = {}) {
    return Websocket.consumer.subscriptions.create(
      { channel, ...params },
      { received: res => handler(res.event, res.data, Websocket.store) }
    )
  }

  static subscribeToApplicationChannel(handler) {
    return Websocket.subscribe('ApplicationChannel', handler)
  }

  static subscribeToNotificationsChannel(handler, userId) {
    return Websocket.subscribe('NotificationsChannel', handler, {
      user_id: userId,
    })
  }

  static subscribeToInquiryChannel(handler) {
    return Websocket.subscribe('InquiryChannel', handler)
  }
}

export default Websocket
