<template>
  <v-dialog v-model="pop" class="white" persistent max-width="1000px">
    <v-btn fab dark x-small text class="float-right primary" @click="close">
      <v-icon dark>close</v-icon>
    </v-btn>
    <v-flex v-if="listingId" xs12 class="container white">
      <div class="pa-4">
        <info-items-panel v-if="!loading" :listing-id="listingId" />
      </div>
    </v-flex>
  </v-dialog>
</template>
<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

import InfoItemsPanel from './info-items-panel.vue'
export default {
  components: {
    InfoItemsPanel,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['afterMessageChosen', 'module'],
  data: () => ({
    chosenTag: null,
    searchSub: null,
    pop: true,
  }),
  computed: {
    listingId() {
      return this.currentReservation.listing_id
    },
    currentReservation() {
      return this.$store.getters.chosenChatReservation || {}
    },
    showListingInfoPop: {
      get() {
        return this.$store.state.showListingInfoPop
      },
      set(val) {
        this.$store.commit('updateShowListingInfoPop', val)
      },
    },
  },
  methods: {
    close() {
      this.$store.commit('updateShowListingInfoPop', null)
    },
  },
}
</script>
