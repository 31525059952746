<template>
  <menu-page
    v-if="currentListing && currentListing.id !== 0"
    :menu-items="menuItems"
  >
    <v-container fluid class="py-0 grey lighten-4">
      <v-btn class="mb-2 px-0" text :to="{ name: 'Listings' }" :ripple="false">
        <v-icon>mdi-chevron-left</v-icon>
        <span class="font-weight-light text-capitalize">
          {{ $t('Return to listings') }}
        </span>
      </v-btn>
      <listing-info-panel
        class="mb-6"
        :listing="currentListing"
        :image="currentListing.picture"
        :title="!isDemoUser ? currentListing.address : currentListing.city_name"
        :nickname="currentListing.nickname"
        :link="addressEditFor(currentListing.id)"
        :pricing-link="addressPricing(currentListing.pricing_id)"
        :ops-link="addressOps"
        :link-show="isAdmin || isAccountant"
        :guesty-id="currentListing.guesty_id"
        :duplicates="currentListing.duplicates"
        :listed="currentListing.listed"
        :master-guesty-id="currentListing.master_guesty_id"
        link-title="Manage"
      />
      <v-progress-linear
        v-show="accountingDataFetching"
        color="primary"
        class="ma-0"
        :indeterminate="true"
      />
    </v-container>
    <v-tabs-items v-model="paramsTab" touchless>
      <v-tab-item
        v-if="
          currentListing.purchase_price != null &&
          currentListing.stage !== 'setup' &&
          !loading
        "
        id="investment"
      >
        <investment-info :listing="currentListing" :year-name="dates.year" />
      </v-tab-item>
      <v-tab-item id="information">
        <v-container>
          <v-progress-linear
            v-show="listingLoading"
            color="secondary"
            style="margin: 0"
            :indeterminate="true"
          />
          <services v-if="!isInvestor" obj-name="currentListing" />
          <info-items-panel
            v-else
            :homeowner-only="true"
            :listing-id="currentListing.id"
          />
        </v-container>
      </v-tab-item>
      <v-tab-item value="stats">
        <v-container class="py-6">
          <v-row
            v-if="!isMobile"
            class="justify-space-between align-center py-3"
            no-gutters
          >
            <v-col cols="auto">
              <month-year-toggle
                :is-yearly="isYearlyStats"
                @toggle="setYearly($event)"
              />
            </v-col>
            <v-col cols="auto">
              <month-year-picker
                v-model="dates"
                :is-yearly="isYearlyStats"
                @change="financeUpdate"
              />
            </v-col>
          </v-row>
          <stats
            :fetching="accountingDataFetching"
            :stats="accountingData"
            :period="isYearlyStats ? 'year' : 'month'"
          />
          <v-row
            v-if="isMobile"
            class="justify-space-between align-center"
            no-gutters
          >
            <v-col cols="auto">
              <month-year-toggle
                :is-yearly="isYearlyStats"
                @toggle="setYearly($event)"
              />
            </v-col>
            <v-col cols="auto">
              <month-year-picker
                v-model="dates"
                :is-yearly="isYearlyStats"
                @change="financeUpdate"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="!isMobile" cols="12" sm="4">
              <div>
                <sources-graph
                  v-if="accountingData.sources_data"
                  class="mb-4"
                  :stats-object="accountingData.sources_data"
                  :title="$t('Reservation Sources')"
                  :graph-id="isYearlyStats ? 'sources' : 'sourcesMonthly'"
                />
              </div>
              <div
                v-if="
                  accountingData.inspection_service_calls &&
                  accountingData.inspection_service_calls.length !== 0
                "
                class="elevation-1"
              >
                <v-card-title class="text-caption secondary--text"
                  >Recent inspections</v-card-title
                >
                <v-data-table
                  mobile-breakpoint="0"
                  :headers="headers"
                  :items="accountingData.inspection_service_calls"
                  disable-pagination
                  fixed-header
                  hide-default-footer
                >
                  <template #item.scheduled_at="{ item }">
                    {{ parseDate(item.scheduled_at) }}
                  </template>
                  <template #item.description="{ item }">
                    {{ item.description }}
                  </template>
                  <template #item.link="{ item }">
                    <router-link
                      :to="'/task-report/' + item.id"
                      target="_blank"
                    >
                      View
                    </router-link>
                  </template>
                </v-data-table>
              </div>
            </v-col>
            <v-col cols="12" sm="8">
              <div :class="isYearlyStats ? 'overlay-strong' : ''">
                <reservation-calendar
                  v-if="
                    currentListing.id &&
                    currentListing.days_rates &&
                    !loading &&
                    currentListing.listing_type !== 'hotel'
                  "
                  :add-owner-vacation="showOwnerVacation"
                  :class="currentListing.listing_type"
                  class="mb-4"
                  :monthly="!isYearlyStats"
                  :tab="paramsTab"
                  :hide-header="true"
                  :calendar.sync="calApi"
                />
                <hotel-table
                  v-if="currentListing.listing_type === 'hotel'"
                  :units="accountingData.unit_stats"
                />
                <pricing-monthly
                  v-if="
                    $store.state.currentPricingMonthly &&
                    isDesignedVr &&
                    !isHotel
                  "
                />
              </div>
            </v-col>
            <v-col v-if="isMobile" cols="12">
              <sources-graph
                v-if="accountingData.sources_data"
                class="mb-4"
                :stats-object="accountingData.sources_data"
                :title="$t('Reservation Sources')"
                :graph-id="isYearlyStats ? 'sources' : 'sourcesMonthly'"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item v-if="!loading" value="finance">
        <v-container class="py-6">
          <v-row class="justify-space-between align-center py-3" no-gutters>
            <v-col cols="auto">
              <month-year-toggle
                :is-yearly="isYearlyStats"
                @toggle="setYearly($event)"
              />
            </v-col>
            <month-year-picker
              v-model="dates"
              :is-yearly="isYearlyStats"
              :max-year="new Date().getFullYear()"
              :max-month="new Date().getMonth()"
              @change="financeUpdate"
            />
          </v-row>
          <payment-card />
          <accounting-card
            :fetching="accountingDataFetching"
            :listing="currentListing"
            :is-monthly="!isYearlyStats"
            :year="dates.year"
            :month="dates.month"
          />
        </v-container>
      </v-tab-item>
      <v-tab-item id="maintenance">
        <maintenance
          v-if="currentListing.id && !loading"
          :listing-id="currentListing.id"
        />
      </v-tab-item>
      <v-tab-item value="marketing">
        <listing-marketing />
      </v-tab-item>
      <v-tab-item value="iot">
        <v-container>
          <v-row justify="center">
            <v-col
              v-for="(device, index) in listingDevices()"
              :key="index"
              cols="8"
              md="6"
              lg="4"
              xl="3"
            >
              <minut-device-card
                v-if="device.device_type === 'minut'"
                :device="device"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </menu-page>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DeviceMixin from 'components/mixins/device-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import PricingMonthly from 'components/pricing-monthly'
import SourcesGraph from 'components/sources-graph'
import AccountingCard from 'components/accounting-card'
import hotelTable from 'components/hotel-table'
import ReservationCalendar from 'components/calendar/reservation-calendar'
import PaymentCard from 'components/payment-card'
import ListingMarketing from 'components/listing-marketing'
import InvestmentInfo from 'components/investment-info/investment-info-tab'
import Maintenance from 'components/maintenance'
import MinutDeviceCard from 'components/iot/minut-device-card'
import ListingInfoPanel from 'components/listing/listing-info-panel'
import Stats from 'components/stats'
import MonthYearToggle from 'components/month-year-toggle'
import MonthYearPicker from 'components/common/month-year-picker'
import { MONTHS } from '@/consts'
import Services from './services'
import InfoItemsPanel from 'components/info-items-panel.vue'
import MenuPage from 'components/common/menu-page.vue'

export default {
  mixins: [CommonFunctions, DeviceMixin, PermissionsMixin],
  data() {
    return {
      isYearlyStats: false,
      calApi: null,
      headers: [
        {
          text: 'Date',
          align: 'center',
          value: 'scheduled_at',
          sortable: false,
        },
        {
          text: 'Desc',
          align: 'center',
          value: 'description',
          sortable: false,
        },
        {
          text: 'Link',
          align: 'center',
          value: 'link',
          sortable: false,
        },
      ],
      dates: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },
      lastYearAccountingFetch: new Date().getFullYear(),
    }
  },
  watch: {
    '$route.params.id'() {
      this.isYearlyStats = false
      this.loadListing()
    },
    '$route.params.paramsTab'(newVal, oldVal) {
      if (
        (newVal === 'finance' || oldVal === 'finance') &&
        this.isCurrentMonthOrYear
      ) {
        this.financeUpdate(this.dates, true)
      }
    },
  },
  async mounted() {
    await this.loadListing()
    if (!this.paramsTab) {
      this.paramsTab = 'stats'
    }
    this.setYearly(false)
    this.financeUpdate(this.dates, true)
  },
  computed: {
    ...mapState([
      'currentListing',
      'listings',
      'listingLoading',
      'accountingData',
      'accountingDataFetching',
    ]),
    ...mapGetters(['listingDevices', 'isAdmin', 'isInvestor']),
    menuItems() {
      return [
        {
          title: this.$t('Investment'),
          disabled: !(
            this.currentListing.purchase_price != null &&
            this.currentListing.stage !== 'setup'
          ),
          show: true,
          value: 'investment',
          icon: '$sales',
        },
        {
          title: this.$t('Calendar'),
          show: this.currentListing.stage !== 'setup',
          icon: '$dashboard_tiles',
          value: 'stats',
        },
        {
          title: this.$t('P&L'),
          show:
            !(
              this.currentListing.extra_info &&
              this.currentListing.extra_info.hide_finance
            ) || this.isAdmin,
          icon: '$finance',
          value: 'finance',
        },
        {
          title: this.$t('Marketing'),
          show: this.currentListing.stage !== 'setup',
          icon: '$promotion',
          value: 'marketing',
        },
        {
          title: this.$t('Information'),
          show: true,
          icon: '$info_circle_alt',
          value: 'information',
        },
        {
          title: this.$t('Maintenance'),
          show:
            this.showModule('ops') &&
            (this.isAdmin ||
              this.hasAbility('investor_see_tasks') ||
              this.hasAbility('investor_operator')),
          icon: '$maintenance',
          value: 'maintenance',
        },
      ]
    },

    listingId() {
      return this.$route.params.id
    },
    showOwnerVacation() {
      return true
    },
    addressOps() {
      return this.currentListing.id
        ? {
            path: `/dashboard/service-calls/area-report/listing/${this.currentListing.id}?filter=high-open`,
          }
        : null
    },
    isPrivate() {
      return (
        this.currentListing && this.currentListing.listing_type === 'private'
      )
    },
    yearly_stats() {
      return this.currentListing.yearly_stats || {}
    },
    years() {
      const year = new Date().getFullYear()
      return [year - 2, year - 1, year, year + 1]
    },
    isSameYear() {
      return this.dates.year === new Date().getFullYear()
    },
    isSameMonth() {
      return this.dates.month === new Date().getMonth()
    },
    isCurrentMonthOrYear() {
      const isSameMonth = this.dates.month === new Date().getMonth()
      const isSameYear = this.dates.year === new Date().getFullYear()
      if (!this.isYearlyStats) {
        return isSameMonth && isSameYear
      }
      return isSameYear
    },
  },
  methods: {
    ...mapActions(['changeEditListing']),
    financeUpdate({ month, year }, runYearly = false) {
      this.$store.dispatch('getPricingMonthlyStats', {
        date: `${MONTHS[month]} ${year}`,
      })
      this.$store.dispatch('getInitialStats', {
        year,
        month: MONTHS[month],
        is_ytd:
          this.isSameYear && this.isSameMonth && this.paramsTab === 'finance',
        id: this.listingId,
      })
      if (
        this.isYearlyStats ||
        year !== this.lastYearAccountingFetch ||
        !this.accountingData ||
        runYearly
      ) {
        this.$store.dispatch('getInitialStats', {
          id: this.listingId,
          is_ytd: this.isSameYear && this.paramsTab === 'finance',
          year,
        })
      }
      this.lastYearAccountingFetch = year
      if (this.calApi) {
        const date = new Date(year, month)
        this.calApi.gotoDate(date)
      }
    },
    back() {
      this.$router.push('/dashboard')
    },
    addressEditFor(id) {
      return { path: `/dashboard/edit/${id}` }
    },
    addressPricing(id) {
      return id ? { path: `/dashboard/pricing/${id}` } : null
    },
    setYearly(isYearly) {
      this.isYearlyStats = isYearly
      this.$store.commit('updateAccountingData', !isYearly)
    },
    loadListing() {
      this.$store.dispatch('changeListing', this.listingId)
    },
  },
  components: {
    MenuPage,
    InfoItemsPanel,
    Services,
    MonthYearToggle,
    Maintenance,
    ReservationCalendar,
    InvestmentInfo,
    SourcesGraph,
    AccountingCard,
    hotelTable,
    PaymentCard,
    PricingMonthly,
    ListingMarketing,
    MinutDeviceCard,
    ListingInfoPanel,
    Stats,
    MonthYearPicker,
  },
}
</script>
