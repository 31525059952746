import { render, staticRenderFns } from "./personal-info.vue?vue&type=template&id=328f6444&scoped=true&"
import script from "./personal-info.vue?vue&type=script&lang=js&"
export * from "./personal-info.vue?vue&type=script&lang=js&"
import style0 from "./personal-info.vue?vue&type=style&index=0&id=328f6444&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "328f6444",
  null
  
)

export default component.exports