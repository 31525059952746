<template>
  <div v-if="expense" class="full h-100 d-flex flex-column">
    <expense-card
      :expense="expense"
      :task="listingTask"
      :sub-tasks="subTasks"
      :delete-enabled="true"
      :edit-enabled="true"
      :on-delete="expenseDeleted"
      :on-update="expenseUpdated"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ExpenseCard from 'components/expenses/expense-card'
import { get } from 'lodash'

export default {
  name: 'Expense',
  components: { ExpenseCard },
  mixins: [],
  data() {
    return {
      expense: null,
    }
  },
  mounted() {
    this.fetchAndSet()
  },
  computed: {
    currentExpenseId() {
      return this.$route.params.expenseId
    },
    listingTask() {
      return get(this.expense, 'listing_task')
    },
    subTasks() {
      return get(this.expense, 'listing_task.sub_tasks')
    },
  },
  methods: {
    ...mapActions('expenses', ['getExpense']),
    async fetchAndSet() {
      const res = await this.getExpense(this.currentExpenseId)
      this.expense = res.data
    },
    expenseUpdated() {
      setTimeout(() => {
        this.fetchAndSet()
      }, 500)
    },
    expenseDeleted() {
      window.location.replace('/')
    },
  },
  watch: {
    currentExpenseId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchAndSet()
      }
    },
  },
}
</script>

<style scoped></style>
