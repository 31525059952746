<template>
  <div>
    <div
      v-if="showModule('guest-exp')"
      class="d-flex justify-space-between align-center"
    >
      <span class="font-weight-bold">Pre-check-in info</span>
      <trip-pre-check-in
        :pre-check-in-data="preCheckInEdit"
        :code="confirmationCode"
      />
    </div>
    <v-sheet
      v-if="preCheckIn"
      :class="flatView ? '' : 'comp-wrapper mt-4 mb-3'"
      :style="flatView ? '' : 'min-width: 400px'"
    >
      <v-row class="my-1" no-gutters>
        <v-col cols="6" class="cyan--text">Email:</v-col>
        <v-col cols="6" class="grey--text">
          {{ preCheckIn.client_info.email || '&mdash;' }}
        </v-col>
      </v-row>
      <v-divider />
      <v-row class="my-1" no-gutters>
        <v-col cols="6" class="cyan--text">Phone:</v-col>
        <v-col cols="6" class="grey--text">
          {{ preCheckIn.client_info.phone_number || '&mdash;' }}
        </v-col>
      </v-row>
      <v-divider />
      <v-row class="my-1" no-gutters>
        <v-col cols="6" class="cyan--text">Check-in:</v-col>
        <v-col cols="6" class="grey--text">
          {{ preCheckIn.times_info.requested_check_in_time }}
        </v-col>
      </v-row>
      <v-divider />
      <v-row class="my-1" no-gutters>
        <v-col cols="6" class="cyan--text">Check-out:</v-col>
        <v-col cols="6" class="grey--text">
          {{ preCheckIn.times_info.requested_check_out_time }}
        </v-col>
      </v-row>
      <v-divider />
      <v-row class="my-1" no-gutters>
        <v-col cols="6" class="cyan--text">Guest amount:</v-col>
        <v-col cols="6" class="grey--text">
          {{ guestAmount }}
        </v-col>
      </v-row>
      <v-divider />
      <h4 class="cyan--text mt-2">Guests</h4>
      <div v-if="preCheckIn.guests_details.length">
        <div v-for="item in filteredGuests" :key="item.full_name" class="mb-2">
          <v-row
            v-for="field in parsedGuest(item)"
            :key="field.key"
            class="my-1"
            no-gutters
          >
            <v-col cols="6" class="cyan--text">{{ field.key }}:</v-col>
            <v-col cols="6" class="grey--text">
              {{ field.val }}
            </v-col>
          </v-row>
          <v-divider />
        </div>
      </div>
      <div
        v-for="(item, index) in preCheckIn.guest_count_info['guests']"
        v-else
        :key="index"
        class="grey--text"
      >
        {{ item }}
      </div>
      <v-divider />
      <div
        v-if="
          preCheckIn.has_assigned_parking || preCheckIn.client_info.car_amount
        "
      >
        <h4 class="cyan--text mt-2">Car info</h4>
        <span class="mt-1 grey--text">
          Needs parking: {{ preCheckIn.client_info.need_parking }}
        </span>
        <span v-if="preCheckIn.client_info.car_amount" class="mt-1 grey-text">
          Car amount: {{ preCheckIn.client_info.car_amount }}
        </span>
        <span v-if="preCheckIn.client_info.car_model" class="mt-1 grey--text">
          Car model: {{ preCheckIn.client_info.car_model }}
        </span>
        <span
          v-if="preCheckIn.client_info.licence_plate"
          class="mt-1 grey--text"
        >
          Licence plate: {{ preCheckIn.client_info.licence_plate }}
        </span>
      </div>
      <v-divider />
      <h4 class="cyan--text mt-2">Special Requests</h4>
      <div
        v-for="(val, key) in preCheckIn.special_requests"
        v-show="isAllowedReq(key)"
        :key="key"
        class="grey--text"
      >
        {{ key }}
      </div>
      <div v-if="preCheckIn.id_pic" class="mt-2">
        <h4 class="cyan--text">ID image:</h4>
        <img
          id="id_pic"
          :src="preCheckIn.id_pic"
          class="comp-wrapper"
          style="max-width: 100%; max-height: 400px"
        />
      </div>
      <div v-if="preCheckIn.additional_id_images.length" class="mt-2">
        <h4 class="cyan--text">Other guests ID images:</h4>
        <img
          v-for="(image, index) in preCheckIn.additional_id_images"
          id="additional_id_pics"
          :key="`additional-image-${index}`"
          :src="image"
          class="comp-wrapper"
          style="max-width: 100%; max-height: 400px"
        />
      </div>
    </v-sheet>
    <p v-else>
      Pre-check-in: <v-icon color="warning">warning</v-icon> Not filled
    </p>
  </div>
</template>

<script>
import { isNumber, pickBy, valuesIn, sum } from 'lodash'
import CommonFunctions from 'components/mixins/common_functions'
import TripPreCheckIn from 'components/pre-check-in/trip-pre-check-in'
import { isEmpty } from 'lodash'

const FIELDS = {
  full_name: 'Full name',
  email: 'Email',
  address: 'Address',
  phone_number: 'Phone',
  passport: 'Passport',
}

export default {
  components: { TripPreCheckIn },
  mixins: [CommonFunctions],
  props: ['preCheckIn', 'flatView', 'confirmationCode', 'editable'],
  data() {
    return {
      preCheckInEdit: {},
    }
  },
  computed: {
    guestsTypes() {
      return pickBy(this.preCheckIn.guest_count_info, isNumber)
    },
    guestAmount() {
      return sum(valuesIn(this.guestsTypes))
    },
    filteredGuests() {
      return this.preCheckIn.guests_details.filter(g => !isEmpty(g))
    },
  },
  async mounted() {
    if (this.editable) {
      this.preCheckInEdit = await this.$store.dispatch(
        'getPreCheckIn',
        this.confirmationCode
      )
    }
  },
  methods: {
    isAllowedReq(reqName) {
      return (
        !reqName.includes('Long stay') &&
        this.preCheckIn.special_requests[reqName]
      )
    },
    parsedGuest(guest) {
      const fields = Object.entries(guest).map(([key, val]) => ({
        key: FIELDS[key] || key,
        val,
      }))
      const nameValue = fields.splice(
        fields.findIndex(f => f.key === FIELDS.full_name),
        1
      )
      fields.unshift(nameValue[0])
      return fields
    },
  },
}
</script>
