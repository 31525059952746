<template>
  <v-data-table
    :headers="headers"
    :expanded.sync="expanded"
    show-expand
    item-key="id"
    :items="items"
    dense
  >
    <template #item.type="{ item }">
      <div class="text-capitalize">
        {{ item.type.substr(0, 7) }}
      </div>
    </template>
    <template #item.amount="{ item }">
      <div>
        {{ dollarFormatter(item.amount) }}
      </div>
    </template>
    <template #item.paid_at="{ item }">
      <div>
        {{ item.paid_at }}
      </div>
    </template>
    <template #item.status="{ item }">
      <div
        :class="[
          'text-capitalize',
          'caption',
          'mr-2',
          item.status === 'succeeded' || item.status === 'requires_capture'
            ? 'green--text'
            : 'red--text',
        ]"
      >
        {{ formatStatus(item.status) }}
      </div>
    </template>
    <template #item.actions="{ item }">
      <div>
        <refund-dialog
          v-if="
            (isAdmin || hasAbility('refund')) &&
            item.type === 'accommodation' &&
            item.status === 'succeeded' &&
            item.isRefundable
          "
          type="refund"
          :intent-id="item.payment_intent_id"
          :total="item.amount"
          @success="getReservation"
        />
        <span
          v-if="
            item.type === 'accommodation' &&
            item.status === 'succeeded' &&
            !item.isRefundable
          "
          class="text-caption green--text"
        >
          Fully refunded
        </span>
        <refund-dialog
          v-if="
            (isAdmin || hasAbility('refund') || isCommunicationManager) &&
            item.type === 'deposit' &&
            item.status === 'requires_capture'
          "
          type="capture"
          :intent-id="item.payment_intent_id"
          :total="item.amount"
        />
        <charge-modal
          v-if="
            ((isCommunicationAgent || managementRoles) &&
              item.type === 'accommodation' &&
              item.status === 'requires_source') ||
            item.status === 'requires_payment_method'
          "
          :can-charge-third="isHotelReception || isAdmin"
          :guest-email="reservation.guest.emails[0]"
          :balance="item.amount"
          :intent="item"
          @charge-success="getReservation"
          @payment-canceled="getReservation({ refresh: true })"
        />
        <span
          v-if="item.type === 'deposit' && item.status === 'succeeded'"
          class="text-caption green--text"
        >
          Captured({{ dollarFormatter(item.amount_received) }}$)
        </span>
      </div>
    </template>
    <template #expanded-item="{ item }">
      <td></td>
      <td>
        <div v-for="charge in item.charges" :key="charge.id" class="py-1">
          <span v-if="charge.reason">
            Refund
            <info-tooltip>{{ charge.reason }}</info-tooltip>
          </span>
          <span v-else>Charged</span>
          <span
            v-if="
              charge.payment_method_details &&
              charge.payment_method_details.card
            "
          >
            (xxxx-{{ charge.payment_method_details.card.last4 }})
          </span>
        </div>
      </td>
      <td>
        <div v-for="charge in item.charges" :key="charge.id" class="py-1">
          {{ dollarFormatter(charge.amount / 100) }}
        </div>
      </td>
      <td>
        <div
          v-for="charge in item.charges"
          :key="charge.id"
          class="py-1"
          :class="charge.status === 'succeeded' ? 'green--text' : 'red--text'"
        >
          {{ charge.status }}
        </div>
      </td>
      <td>
        <div v-for="charge in item.charges" :key="charge.id" class="py-1">
          {{
            parseTimestamp(charge.created, 'ddd, MMM Do HH:mm', {
              local: true,
            })
          }}
        </div>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import InfoTooltip from 'components/common/info-tooltip'
import FormattersMixin from 'components/mixins/formatters-mixin'
import ChargeModal from 'components/common/charge-modal'
import RefundDialog from 'components/stripe/refund-dialog'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import Common_functions from 'components/mixins/common_functions'
import { paymentIntents } from 'components/reservation/config'
export default {
  name: 'ReservationIntentsTable',
  components: { RefundDialog, ChargeModal, InfoTooltip },
  mixins: [FormattersMixin, PermissionsMixin, Common_functions],
  props: ['reservation', 'getReservation'],
  data() {
    return {
      expanded: [],
      headers: [
        { text: 'Payment Intents', value: 'type' },
        { text: 'Amount', value: 'amount' },
        { text: 'Status', value: 'status' },
        { text: 'Paid at', value: 'paid_at' },
        { text: '', value: 'actions' },
      ],
    }
  },
  computed: {
    items() {
      return paymentIntents(this.reservation)
    },
  },
}
</script>

<style scoped></style>
