<template>
  <tr>
    <td class="text-center">
      <v-switch v-model="isActive" @change="itemChange" />
    </td>
    <td class="text-center">
      <a class="text-decoration-underline" @click="updateEditRule(item)">{{
        item.name
      }}</a>
    </td>
    <td class="text-center">
      <div v-if="item.allowed_channels.length === 0">
        <v-chip class="primary" small>All Channels</v-chip>
      </div>
      <div v-else>
        <tag
          v-for="channel in item.allowed_channels"
          :key="channel"
          color="primary"
          :inverted="true"
          size="sm"
          rounded
          >{{ channel }}</tag
        >
      </div>
    </td>
    <td class="text-center">
      <div>
        <v-chip class="secondary" small>{{ item.send_in_channel }}</v-chip>
      </div>
    </td>
    <td class="text-center justify-center">
      <v-col>
        <div v-if="item.whitelist.length === 0">
          <tag color="primary" :inverted="true" size="sm">All Listings</tag>
        </div>
        <div v-else>
          <tag color="primary" :inverted="true" size="sm"
            >{{ item.whitelist.length }} listings</tag
          >
        </div>
      </v-col>
      <v-col v-if="item.blacklist.length !== 0">
        <div>
          <tag color="secondary" :inverted="true" size="sm"
            >{{ item.blacklist.length }} Exluded</tag
          >
        </div></v-col
      >
    </td>
    <td class="text-center">
      <tag
        v-if="item.sent_at_number"
        bg-color="grey lighten-2"
        color="black"
        rounded
      >
        {{ item.sent_at_number }}
      </tag>
      <v-icon v-if="item.sent_at_number" color="grey lighten-1" class="mx-1"
        >mdi-chevron-right</v-icon
      >
      <tag
        v-if="item.sent_at_period"
        bg-color="grey lighten-2"
        color="black"
        rounded
      >
        {{ item.sent_at_period }}
      </tag>
      <v-icon v-if="item.sent_at_period" color="grey lighten-1" class="mx-1"
        >mdi-chevron-right</v-icon
      >
      <tag bg-color="grey lighten-2" color="black" rounded>
        {{ item.sent_at_timing }}
      </tag>
      <v-icon color="grey lighten-1" class="mx-1">mdi-chevron-right</v-icon>
      <tag bg-color="grey lighten-2" color="black" rounded>{{
        item.sent_at_event
      }}</tag>
    </td>
  </tr>
</template>

<script>
import axios from 'axios'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import Tag from 'components/common/tag.vue'

export default {
  components: { Tag },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['item', 'afterChange', 'whatsappTemplates'],
  data() {
    return {
      singleSelect: false,
      menu: false,
      menu2: false,
      isActive: this.item.active,
    }
  },
  methods: {
    updateEditRule(item) {
      this.$store.commit('showModal', {
        name: 'AutoMessageRuleModal',
        props: {
          editMode: true,
          afterSaveFunc: this.afterChange,
          currentRule: Object.assign({}, item),
          templates: this.whatsappTemplates,
        },
        isPersistent: false,
      })
    },
    deleteRule() {
      const userConfirm = confirm('Are you sure you want to delete?')
      if (userConfirm) {
        axios
          .delete(`/api/auto-listing-tasks/${this.item.id}`)
          .then(() => {
            this.$store.commit('updateListingLoading', false)
            if (this.afterChange) {
              this.afterChange()
            } else {
              this.$store.dispatch('getAutoMessageRules', {
                id: this.listingManageContext
                  ? this.$router.currentRoute.path.split('/')[3]
                  : 'All',
              })
            }
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    itemChange() {
      if (!this.isActive) {
        const userConfirm = confirm('Are you sure you want to disable?')
        if (!userConfirm) {
          return
        }
      }

      this.$store.commit('updateListingLoading', true)
      axios
        .patch(`/api/auto-message-rules/${this.item.id}`, {
          active: this.isActive,
        })
        .then(() => {
          this.$store.commit('updateListingLoading', false)
        })
        .catch(error => {
          console.error(error)
        })
    },
  },
}
</script>
