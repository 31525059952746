<template>
  <v-row class="d-flex full ma-0 pa-0 pa-md-1 pb-0">
    <v-col v-if="!boardId" cols="12" md="2">
      <taskim-side-bar />
    </v-col>
    <v-col cols="12" :md="!boardId ? 10 : 12">
      <div
        v-if="!boardsFetched || sectionsFetching"
        class="flex-fill flex-center"
      >
        <loader-animation-palms />
      </div>
      <div v-else-if="errorMessage" class="flex-fill flex-center">
        <div>{{ errorMessage }}</div>
      </div>
      <div v-else class="flex-fill v-window d-flex flex-column">
        <listing-picker
          v-if="currentBoard.project && currentBoard.project.listing_id"
          :value="currentBoard.project.listing_id"
          :disabled="true"
          @input="listingTaskFieldChange(item, 'listing_id', $event)"
        />
        <div class="d-flex pt-3 align-end">
          <div class="text-h5">{{ currentBoardName }}</div>
          <v-spacer />
          <form-dialog
            v-if="!isQueryBoard"
            title="Create New Taskim Section"
            @submit="onCreateNewSection"
            @open="newSection = {}"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                :small="isMobile"
                v-bind="attrs"
                text
                color="secondary"
                v-on="on"
              >
                <v-icon>mdi-plus</v-icon>
                New Section
              </v-btn>
            </template>
            <v-text-field
              v-model="newSection.name"
              label="Name *"
              outlined
              dense
              :rules="[required]"
            />
          </form-dialog>
          <v-btn :small="isMobile" text color="primary" @click="refreshAll">
            <v-icon>mdi-refresh</v-icon>
            Refresh
          </v-btn>
        </div>
        <v-divider class="mt-2 mb-5" />
        <task-calendar-filters
          :open-on-change="false"
          @change="onFiltersChanged"
        />
        <v-divider class="mt-2 mb-5" />

        <div class="flex-fill">
          <div
            v-for="(section, index) in sections"
            :key="`${section.id} ${index}`"
            class="mb-10"
          >
            <taskim-section
              :board-id="currentBoardId"
              :project-id="projectId"
              :section="section"
              :order-up-disabled="index === 0"
              :order-down-disabled="index === sections.length - 1"
              :collapsed="section.id !== openedSectionId"
              @update:collapsed="onSectionCollapsed(section.id, $event)"
              @order-up="onSectionOrder(index, 'UP')"
              @order-down="onSectionOrder(index, 'DOWN')"
              @pagination-changed="onPaginationChanged(section, $event)"
              @server-sort-changed="onServerSortChanged($event)"
            />
          </div>
        </div>
      </div>
      <v-sheet :key="feedOpenId" class="feed-modal">
        <div v-if="focusRight && feedOpenId" class="feed-overlay" />
        <v-sheet
          v-if="feedOpenId && !isMobileOrIPad"
          :key="feedOpenId"
          v-click-outside="closeFeed"
          elevation="2"
          class="feed-container"
          @mouseover="focusOn"
          @mouseleave="focusOff"
        >
          <v-slide-x-transition>
            <lt-feed
              class="d-flex flex-column"
              :logs="logs"
              :users="activeUsers"
              :unseen="unseenComments"
              :comments-list="commentsList"
              :internal-comments="internalComments"
              @add-message="addComment"
              @update-comment="updateInternalComments"
            />
          </v-slide-x-transition>
        </v-sheet>
        <v-dialog v-if="feedOpenId && isMobileOrIPad" value="true" fullscreen>
          <v-slide-x-transition>
            <lt-feed
              class="d-flex flex-column"
              :logs="logs"
              :users="activeUsers"
              :unseen="unseenComments"
              :comments-list="commentsList"
              :internal-comments="internalComments"
              @add-message="addComment"
              @update-comment="updateInternalComments"
            />
          </v-slide-x-transition>
        </v-dialog>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import TaskimSideBar from 'components/taskim/taskim-side-bar'
import TaskimSection from 'components/taskim/taskim-section.vue'
import FormDialog from 'components/common/form-dialog'
import CommonFunctions from 'components/mixins/common_functions'
import formRules from 'components/mixins/form-rules-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import LoaderAnimationPalms from 'components/loaders/loader-animation-palms'
import LtFeed from 'components/listing-tasks/lt-feed'
import ListingTaskViewMixin from 'components/listing-tasks/mixins/listing-task-view-mixin'
import MentionMixin from 'components/mixins/mention-mixin'
import { get, toNumber } from 'lodash'
import TaskCalendarFilters from 'components/calendar/task-calendar-filters'
import LocalStorage from '@/utils/localStorage'
import ListingPicker from 'components/listing/listing-picker'
import CalendarMixin from 'components/mixins/calendar-mixin'

export default {
  name: 'Taskim',
  components: {
    ListingPicker,
    TaskCalendarFilters,
    LtFeed,
    LoaderAnimationPalms,
    FormDialog,
    TaskimSection,
    TaskimSideBar,
  },
  mixins: [
    CommonFunctions,
    formRules,
    ListingTaskViewMixin,
    MentionMixin,
    DeviceMixin,
    CalendarMixin,
  ],
  props: ['boardId', 'projectId'],
  data() {
    return {
      newSection: {},
      focusRight: false,
      openedSectionId: null,
    }
  },
  beforeMount() {
    this.initDefaultUserCalendarFilters()
  },
  async mounted() {
    this.updateExcludedFilters(this.excludedFilters)
    this.setForcedFilters()
    if (
      this.currentBoardId &&
      this.$route.query.feed_id &&
      !this.currentListingTask
    ) {
      this.getListingTaskBg(this.$route.query.feed_id)
    }
    await this.reloadSections()
    await this.setDefaultOpenedSection()
  },
  beforeDestroy() {
    this.updateExcludedFilters([])
    this.updateForcedFilters({})
    this.resetIncludeIdsFilter()
  },
  computed: {
    ...mapGetters(['currentListingTask', 'currentUser']),
    ...mapState('taskim', [
      'boardsFetched',
      'boards',
      'sectionsFetching',
      'sections',
    ]),
    ...mapGetters('taskim', [
      'boardsMap',
      'querySectionPagination',
      'excludedFilters',
    ]),
    currentBoardId() {
      return this.boardId || this.$route.params.boardId
    },
    isQueryBoard() {
      return this.currentBoardId === 'query'
    },
    currentBoard() {
      return this.boardsMap[this.currentBoardId]
    },
    currentBoardName() {
      return this.currentBoard ? this.currentBoard.name : ''
    },
    errorMessage() {
      if (this.boardsFetched) {
        if (!this.boards.length) {
          return 'No Boards! please create your first board.'
        } else if (!this.currentBoardId) {
          return 'Select Board'
        } else if (!this.boardsMap[this.currentBoardId]) {
          return 'Invalid Board Id'
        }
      }
      return ''
    },
    feedOpenId() {
      return this.$route.query.feed_id && this.currentListingTask
        ? toNumber(this.$route.query.feed_id)
        : null
    },
  },
  methods: {
    ...mapActions(['getListingTaskBg']),
    ...mapActions('taskim', [
      'getBoards',
      'getBoardSections',
      'createSection',
      'updateBoardSection',
      'stateTaskimUpdateTask',
      'getSectionTasks',
    ]),
    ...mapMutations('taskim', ['setQuerySectionSort']),
    ...mapMutations('taskCalendar', [
      'updateExcludedFilters',
      'updateForcedFilters',
    ]),
    ...mapActions('taskCalendar', ['resetIncludeIdsFilter']),
    focusOn() {
      this.focusRight = true
    },
    focusOff() {
      this.focusRight = false
    },
    async reloadSections(bg = false, pagi = {}) {
      if (this.currentBoardId) {
        await this.getBoardSections({ id: this.currentBoardId, bg, pagi })
      }
    },
    async setDefaultOpenedSection() {
      this.openedSectionId = get(this.sections, '0.id')
      if (this.openedSectionId) {
        await this.getSectionTasks(this.openedSectionId)
      }
    },
    setForcedFilters() {
      if (this.isQueryBoard) {
        this.updateForcedFilters({ contractor_id: [this.currentUser.id] })
      } else {
        this.updateForcedFilters({})
      }
    },
    async refreshAll() {
      await this.resetIncludeIdsFilter()
      !this.boardId && (await this.getBoards())
      await this.reloadSections(true)
      await this.getSectionTasks(this.openedSectionId)
    },
    async onCreateNewSection() {
      await this.createSection({
        ...this.newSection,
        board_id: this.currentBoardId,
      })
      await this.reloadSections(true)
      await this.setDefaultOpenedSection()
    },
    async onSectionCollapsed(sectionId, collapsed) {
      if (!collapsed) {
        await this.resetIncludeIdsFilter()
      }
      this.openedSectionId = collapsed ? null : sectionId
    },
    async onSectionOrder(currentIndex, direction) {
      const section = this.sections[currentIndex]
      const afterSectionIndex = direction === 'DOWN' ? 1 : -2
      const afterSection = this.sections[currentIndex + afterSectionIndex]
      await this.updateBoardSection({
        boardId: this.currentBoardId,
        sectionId: section.id,
        payload: {
          after_section_id: afterSection ? afterSection.id : 0,
        },
      })
      await this.reloadSections(true)
    },
    async onPaginationChanged(section, pagination) {
      if (!section.pagi_info) return
      let page = null
      if (section.pagi_info.page !== pagination.page) page = pagination.page
      else if (section.pagi_info.per_page !== pagination.itemsPerPage) page = 1
      if (page) {
        await this.reloadSections(true, {
          page,
          per_page: pagination.itemsPerPage,
        })
        await this.getSectionTasks(this.openedSectionId)
      }
    },
    async onServerSortChanged(sort) {
      await this.setQuerySectionSort(sort)
      await this.reloadSections(true, { page: 1 })
      await this.getSectionTasks(this.openedSectionId)
    },
    closeFeed(event) {
      if (event.target && document.body.contains(event.target)) {
        this.focusOff()
        this.$router.push({ query: {} })
      }
    },
    async onFiltersChanged() {
      await this.resetIncludeIdsFilter()
      await this.reloadSections(true, { page: 1 })
      await this.getSectionTasks(this.openedSectionId)
    },
    async updateInternalComments(value) {
      await this.commentInternal(value)
      this.stateTaskimUpdateTask({
        id: this.currentListingTask.id,
        internal_comments: value,
      })
    },
  },
  watch: {
    async currentBoardId() {
      this.openedSectionId = null
      this.initDefaultUserCalendarFilters()
      await this.resetIncludeIdsFilter()
      this.setForcedFilters()
      await this.reloadSections()
      await this.setDefaultOpenedSection()
    },
  },
}
</script>

<style scoped>
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
  margin: 10px;
  border: 1px solid #ededed;
  display: block;
}
.feed-modal {
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 200;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  background-color: transparent;
  pointer-events: none;
}

.feed-overlay {
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 200;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  background-color: var(--v-secondary-base);
  opacity: 0.8;
}

.feed-container {
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 201;
  width: 40%;
  height: 100%;
  overflow-y: hidden;
  pointer-events: auto;
}
</style>
