<template>
  <v-dialog v-model="showServiceCall" persistent max-width="900px">
    <template v-if="!hideActivator" #activator="props">
      <slot name="customBtn" v-bind="props" />
      <v-btn
        v-if="!$scopedSlots.customBtn"
        :disabled="disabled"
        depressed
        color="primary"
        v-on="props.on"
      >
        <v-icon left>add</v-icon>
        {{ buttonText ? $t(buttonText) : $t('New SC') }}
      </v-btn>
    </template>
    <v-card v-if="showServiceCall" class="service-call-create">
      <v-card-title class="py-6">
        <div class="p-relative flex text-center">
          <h2 v-if="quickTask" class="text-h6">
            {{ $t('Create New Field Task') }}
          </h2>
          <h2 v-else class="text-h6">{{ $t('Create service call') }}</h2>
          <v-icon class="close-button" @click="close">close</v-icon>
        </div>
      </v-card-title>
      <v-card-text class="pt-5">
        <multi-property-picker
          v-model="newServiceTask.listings_ids"
          :hide-btns="typeof hideButton !== 'undefined' ? hideButton : true"
          :filter-change="listingChange"
          :class="{ 'mb-4': !$v.listingId.$error }"
          :close-on-content-click="true"
        />
        <p v-if="$v.listingId.$error" class="warning--text">
          {{ $t('Required field') }}
        </p>
        <catalog-quick-select
          :filter-change="quickPickChange"
          :outer-click="showCatalogInfo"
          :reject-mode="rejectMode"
          :outer-icon="extraCatalogInfo ? 'fas fa-wrench' : ''"
          :class="{ 'mb-4': !$v.taskType.$error }"
          :value="newServiceTask.listing_task_catalog_item_id"
        />
        <p v-if="$v.taskType.$error" class="warning--text">
          {{ $t('Required field') }}
        </p>
        <div
          v-if="activeWarranties.length"
          class="d-flex align-center warning--text mb-4"
        >
          {{ $t('This listing already has active warranties') }}.
          <v-btn
            color="info"
            class="text-capitalize ml-2"
            text
            small
            @click="warrantyDialog = true"
            >{{ $t('See warranties') }}
          </v-btn>
        </div>
        <div
          v-if="
            newServiceTask.listing_task_catalog_item_id &&
            flowsWithSingleTemplateRoot(
              newServiceTask.listing_task_catalog_item_id
            ).length
          "
        >
          <em class="text--secondary">{{
            $t(
              'This catalog item starts a flow, you can choose it to activate it'
            )
          }}</em>
          <v-select
            v-model="newServiceTask.listing_task_template_id"
            dense
            outlined
            :items="
              flowsWithSingleTemplateRoot(
                newServiceTask.listing_task_catalog_item_id
              )
            "
            item-value="listing_task_templates_roots.0.id"
            clearable
            label="Flow to trigger"
            class="listing-select"
          >
            <template #item="{ item, on }">
              <v-list-item v-on="on">
                <span class="dark-grey-text bolder"
                  >{{ item.division }}<span class="mx-3">➔</span></span
                >
                <span class="text--secondary">{{ item.name }}</span>
              </v-list-item>
            </template>
            <template #selection="{ item, on }">
              <div v-on="on">
                <span class="dark-grey-text bolder"
                  >{{ item.division }}<span class="mx-3">➔</span></span
                >
                <span class="text--secondary">{{ item.name }}</span>
              </div>
            </template>
          </v-select>
        </div>
        <v-expansion-panels
          v-if="
            listingTaskCreationInfo &&
            listingTaskCreationInfo.past_lt &&
            listingTaskCreationInfo.past_lt.length > 0
          "
          class="mb-6"
        >
          <v-expansion-panel>
            <v-expansion-panel-header class="text-subtitle-1">
              {{ $t('Similar service calls') }} ({{
                listingTaskCreationInfo.past_lt.length
              }})
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <LtSimilarExpand :tasks="listingTaskCreationInfo.past_lt" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-textarea
          v-model="newServiceTask.description"
          :label="$t('Description')"
          :class="{ 'mb-4': !$v.description.$error }"
          :rows="4"
          required
          outlined
          hide-details
        />
        <p v-if="$v.description.$error" class="warning--text">
          {{ $t('Required field') }}
        </p>
        <v-row>
          <v-col cols="12" sm="6">
            <contractor-select
              hide-details="true"
              icon="$account_alt"
              :enable-sug="true"
              :value.sync="newServiceTask.assigned_contractor_id"
            />
            <span
              v-if="personnelMsg && newServiceTask.assigned_contractor_id"
              class="mt--1"
              v-html="personnelMsg"
            />
          </v-col>
          <v-col cols="12" sm="3">
            <listing-priority
              :value.sync="newServiceTask.priority"
              :class="{ 'mb-4': !$v.priority.$error }"
            />
            <p v-if="$v.priority.$error" class="warning--text">
              {{ $t('Required field') }}
            </p>
          </v-col>
          <v-col cols="12" :sm="!isCommunicationPerson ? 3 : 6">
            <v-menu
              v-if="!showMaxPerDay"
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              required
              min-width="290px"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-model="newServiceTask.scheduled_at"
                  :label="$t('Scheduled at')"
                  prepend-inner-icon="event"
                  readonly
                  outlined
                  dense
                  hide-details
                  v-on="on"
                />
              </template>
              <v-date-picker
                ref="picker"
                v-model="newServiceTask.scheduled_at"
                :events="allowedDates"
                :event-color="eventColor"
                :max="
                  new Date(new Date().setMonth(new Date().getMonth() + 8))
                    .toISOString()
                    .substr(0, 10)
                "
                min="2019-01-01"
                @change="saveDate"
              />
            </v-menu>
          </v-col>
          <v-col cols="12">
            <contractor-select
              icon="$account_alt"
              label="Additional assignee"
              hint="Up to 4 assignees"
              :persistent-hint="true"
              :allow-multiple="true"
              :input="limited"
              :enable-sug="true"
              :value.sync="newServiceTask.additional_contractors"
            />
          </v-col>
        </v-row>
        <gallery
          folder="service-calls"
          :cols="4"
          :images="newServiceTask.desc_images"
          :button-text="$t('Choose photos')"
          :thumbnails="true"
          :on-change="addImages"
          :uploadable="true"
          :remove-img="removeImage"
        />
        <h4
          class="primary--text text--darken-3"
          @click="advancedExpand = !advancedExpand"
        >
          <v-icon
            >{{ advancedExpand ? 'mdi-chevron-down' : ' mdi-chevron-up' }}
          </v-icon>
          More
        </h4>
        <v-expand-transition>
          <div v-show="advancedExpand">
            <v-row>
              <v-col cols="12" sm="4">
                <v-select
                  v-model="newServiceTask.requested_approver_id"
                  :items="approvers"
                  :label="$t('Approver')"
                  item-text="name"
                  item-value="id"
                  dense
                  outlined
                />
              </v-col>
              <v-col
                v-if="!isCommunicationPerson"
                cols="12"
                :sm="!isCommunicationPerson ? 3 : 6"
              >
                <DvrTimePicker
                  v-if="!showMaxPerDay"
                  :value.sync="newServiceTask.hour"
                />
              </v-col>
              <v-col v-if="!isCommunicationPerson" cols="12" sm="2">
                <v-text-field
                  v-model="newServiceTask.planned_duration"
                  :label="$t('Planned duration (hours)')"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" :sm="!isCommunicationPerson ? 3 : 6">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  required
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-model="newServiceTask.follow_up_at"
                      outlined
                      dense
                      :label="$t('Follow-up at')"
                      prepend-inner-icon="event"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="newServiceTask.follow_up_at"
                    @input="menu1 = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                v-if="newServiceTask.follow_up_at"
                cols="12"
                :sm="!isCommunicationPerson ? 4 : 6"
              >
                <contractor-select
                  :label="$t('Follow-up on')"
                  icon="$account_alt"
                  :enable-sug="true"
                  :allow-multiple="false"
                  :value.sync="newServiceTask.follow_up_by_id"
                />
                <p v-if="$v.followUp.$error" class="warning--text">
                  {{ $t('Required field') }}
                </p>
              </v-col>
            </v-row>
            <v-textarea
              v-model="newServiceTask.internal_comments"
              :label="$t('Internal company comments')"
              rows="4"
              outlined
              dense
              hide-details
            >
            </v-textarea>
            <v-col v-if="isClaim" cols="12" class="mt-2">
              <claim-items
                class="comp-wrapper"
                :after-change="claimItemsChange"
              ></claim-items>
            </v-col>
            <v-row>
              <v-col
                v-if="isCommunicationPerson"
                :cols="
                  ['During his stay', 'ASAP!'].includes(
                    newServiceTask.when_service_needed
                  )
                    ? 6
                    : 12
                "
              >
                <v-select
                  v-model="newServiceTask.when_service_needed"
                  :items="[
                    'ASAP!',
                    'During his stay',
                    'After checkout',
                    'Guest didn\'t reply',
                  ]"
                  :label="$t('When guest request to come')"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col
                v-if="
                  isCommunicationPerson &&
                  ['During his stay', 'ASAP!'].includes(
                    newServiceTask.when_service_needed
                  )
                "
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="newServiceTask.req_hour_slot"
                  :items="['9:00 - 12:00', '12:00 - 15:00', '15:00 - 18:00']"
                  :label="$t('Preferred time')"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col
                v-if="
                  ['During his stay', 'ASAP!'].includes(
                    newServiceTask.when_service_needed
                  )
                "
                cols="12"
              >
                <v-checkbox
                  v-model="newServiceTask.can_enter_on_absence"
                  class="mt-0"
                  dense
                  :label="$t('Approve to serivce on absence')"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
            <div v-if="!isCommunicationPerson" class="mt-4">
              <div class="mb-4">
                <lt-sub-task-list-item
                  :after-change="checkListChange"
                  :existing-items="newServiceTask.check_list"
                />
              </div>
              <div class="mb-4">
                <v-btn
                  v-if="!assignProject"
                  color="darkpurple"
                  text
                  @click="assignProject = true"
                >
                  <v-icon>mdi-plus</v-icon>
                  {{ $t('Assign to project') }}
                </v-btn>
                <div v-else>
                  <div class="d-flex align-center mb-4">
                    <h3 class="text-h6 font-weight-regular">
                      {{ $t('Assign to project') }}
                    </h3>
                    <v-btn class="ml-2" icon @click="clearAssignProject">
                      <v-icon color="warning" small>$trash</v-icon>
                    </v-btn>
                  </div>
                  <v-select
                    v-model="newServiceTask.project_id"
                    :items="activeProjects"
                    item-value="id"
                    label="Select a Project"
                    outlined
                    dense
                    hide-details
                  >
                    <template #selection="{ item }">
                      <span class="bolder mr-1">{{ item.name }} </span>
                      <span v-if="item.description" class="font-weight-regular">
                        - {{ item.description }}</span
                      >
                    </template>
                    <template #item="{ item }">
                      <span class="bolder mr-1">{{ item.name }} </span>
                      <span v-if="item.description" class="font-weight-regular">
                        - {{ item.description }}</span
                      >
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="mb-4">
                <v-btn
                  v-if="!showMaxPerDay"
                  color="darkpurple"
                  text
                  @click="openMaxPerDay"
                >
                  <v-icon>mdi-plus</v-icon>
                  Spread the tasks when open
                </v-btn>
                <div v-else>
                  <div class="d-flex align-center">
                    <h3 class="text-h6 font-weight-regular">
                      Spread the tasks when open
                    </h3>
                    <v-btn class="ml-2" icon @click="clearMaxPerDay">
                      <v-icon color="warning" small>$trash</v-icon>
                    </v-btn>
                  </div>
                  <v-row align="center">
                    <v-col cols="12" sm="8" lg="9">
                      <v-text-field
                        v-model="maxPerDay"
                        label="Max tasks per day"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" lg="3">
                      <v-checkbox
                        v-model="newServiceTask.cant_be_moved"
                        class="mt-0"
                        hide-details
                        label="Can't be moved"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
            <h3 class="text-h6 font-weight-regular my-4">Attachments</h3>
            <div v-if="isCommunicationPerson && listingTaskCreationInfo">
              {{ listingTaskCreationInfo.current_guest_name || 'The guest' }} is
              currently staying, would like to send tracking code?
              <v-checkbox
                v-model="notifyGuest"
                class="mt-0"
                label="Send tracking link to guest"
                dense
                hide-details
              />
            </div>
          </div>
        </v-expand-transition>
      </v-card-text>
      <v-divider />
      <v-card-actions class="py-4">
        <v-spacer />
        <v-btn
          :disabled="isUploadingImages"
          color="secondary"
          class="text-capitalize"
          text
          @click="close"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          :disabled="isUploadingImages"
          color="primary"
          class="text-capitalize"
          elevation="0"
          :loading="listingLoading"
          @click.native="save"
        >
          {{ $t('Save') }}
        </v-btn>
      </v-card-actions>
      <v-dialog v-model="warrantyDialog" max-width="800">
        <v-card>
          <v-card-title class="text-h6"
            >{{ $t('Active warranties') }}
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-list>
              <v-list-item
                v-for="item in activeWarranties"
                :key="item.id"
                class="px-0"
              >
                <v-list-item-avatar width="120" height="120" tile>
                  <v-img :src="item.images[0]" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-body-1 font-weight-medium mb-2"
                  >
                    Warranty for task
                    <router-link
                      target="_blank"
                      :to="`/dashboard/listing-task/${item.listing_task_id}`"
                      text
                    >
                      <span class="font-weight-bold">
                        #{{ item.listing_task_id }}
                      </span>
                    </router-link>
                  </v-list-item-title>
                  <div>
                    <span class="secondary--text">{{ $t('Expires at') }}:</span>
                    <span>{{ parseDate(item.expired_at, 'DD MMM YYYY') }}</span>
                  </div>
                  <div>
                    <span class="secondary--text">{{ $t('Contractor') }}:</span>
                    <span>
                      {{ nameById(item.listing_task_contractor_id) }}
                    </span>
                  </div>
                  <div>
                    <span class="secondary--text"
                      >{{ $t('Description') }}:</span
                    >
                    <span>{{ item.description }}</span>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import axios from 'axios'

import ContractorSelect from './contractor-select.vue'

import { required } from 'vuelidate/lib/validators'

import MultiPropertyPicker from 'components/multi-property-picker.vue'
import CatalogQuickSelect from 'components/catalog-quick-select'
import LtSimilarExpand from 'components/listing-tasks/lt-similar-expand'
import ListingPriority from './listing-priority'
import DvrTimePicker from 'components/form-fields/dvr-time-picker'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ClaimItems from 'components/claim-items'
import { cloneDeep, get } from 'lodash'
import LtSubTaskListItem from 'components/listing-tasks/lt-sub-task-list-item'
import Toaster from '@/utils/toaster'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import Gallery from 'components/common/gallery.vue'

export default {
  components: {
    Gallery,
    LtSubTaskListItem,
    ClaimItems,
    DvrTimePicker,
    ListingPriority,
    LtSimilarExpand,
    ContractorSelect,
    MultiPropertyPicker,
    CatalogQuickSelect,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormRulesMixin],
  props: [
    'request',
    'afterSaveFunc',
    'disabled',
    'hideButton',
    'preFilledListingId',
    'preFields',
    'reservation',
    'reviewId',
    'buttonText',
    'quickTask',
    'triggerOpen',
    'hideActivator',
  ],
  validations: {
    priority: {
      required,
    },
    description: {
      required,
    },
    taskType: {
      required,
    },
    followUp: {
      required,
    },
    subTopic: {
      required,
    },
    listingId: {
      required,
    },
  },
  mounted() {
    this.getInitialNewServiceTask()
    if (this.serviceCallModalOpened) {
      this.showServiceCall = true
    }
  },
  watch: {
    serviceCallModalOpened(value) {
      if (value) {
        this.getInitialNewServiceTask()
        this.showServiceCall = true
      }
    },
    showServiceCall(value) {
      if (value) {
        this.$store.commit('updateListingTaskCreationInfo', null)
        this.$store.dispatch('getListingTaskFlows', { with_roots: true })
        this.$store.dispatch('projects/getProjects', {
          without_inactive: true,
          without_estimate: true,
        })
        this.getInitialNewServiceTask(true)
      }
    },
    triggerOpen(value) {
      if (!this.showServiceCall) {
        this.showServiceCall = value
        this.rejectMode = true
      }
    },
  },
  computed: {
    ...mapState([
      'listingLoading',
      'serviceCallModalOpened',
      'serviceCallModalPayload',
    ]),
    ...mapState('warranty', ['activeWarranties']),
    ...mapState('projects', ['activeProjects']),
    ...mapGetters(['listingTaskFlows']),
    ...mapGetters('users', ['approvers']),
    listingTaskCreationInfo() {
      return this.$store.state.listingTaskCreationInfo
    },
    isClaim() {
      return (
        this.newServiceTask.listing_task_catalog_item_name &&
        this.newServiceTask.listing_task_catalog_item_name
          .toLowerCase()
          .includes('claim')
      )
    },
    description() {
      return this.newServiceTask.description
    },
    subTopic() {
      return this.newServiceTask.sub_topic || this.isCommunicationPerson
    },
    listingId() {
      if (this.request) {
        return this.request.listing_id
      } else {
        return this.newServiceTask.listings_ids.length > 0
          ? this.newServiceTask.listings_ids
          : null
      }
    },
    taskType() {
      return this.newServiceTask.task_type
    },
    followUp() {
      if (this.newServiceTask.follow_up_at) {
        return this.newServiceTask.follow_up_by_id
      } else {
        return true
      }
    },
    priority() {
      return this.newServiceTask.priority
    },
    priorities() {
      return [
        { text: 'Critical', color: 'warning' },
        { text: 'High', color: 'orange' },
        { text: 'Med', color: 'indigo' },
        { text: 'Low', color: 'info' },
      ]
    },
  },
  methods: {
    ...mapMutations('warranty', ['setActiveWarranties']),
    ...mapActions('warranty', ['checkWarranty']),
    ...mapActions(['setServiceCallModalOpened', 'setServiceCallAISuggestion']),
    limited(e) {
      if (e.length > 4) {
        e.pop()
      }
    },
    addImages(images) {
      this.newServiceTask.desc_images =
        this.newServiceTask.desc_images.concat(images)
    },
    removeImage(link) {
      this.newServiceTask.desc_images = this.newServiceTask.desc_images.filter(
        img => img !== link
      )
    },
    flowsWithSingleTemplateRoot(catalogItemId) {
      return this.listingTaskFlows.filter(
        flow =>
          flow.listing_task_templates_roots.length === 1 &&
          flow.listing_task_templates_roots[0].listing_task_catalog_item_id ===
            catalogItemId
      )
    },
    claimItemsChange(items) {
      this.newServiceTask.claim_items = items
    },
    close() {
      this.showServiceCall = false
      this.personnelMsg = null
      this.$emit('close')
      this.setActiveWarranties([])
      this.newServiceTask.listing_task_catalog_item_id = null
      this.newServiceTask.listings_ids = this.request
        ? [this.request.listing_id]
        : []
    },
    showCatalogInfo() {
      this.$store.commit('showModal', {
        name: 'CatalogItemInfoModal',
        props: {
          catalogItemId: this.newServiceTask.listing_task_catalog_item_id,
        },
      })
    },
    quickPickChange(value) {
      const change = value || {}
      this.newServiceTask.listing_task_template_id = undefined
      this.newServiceTask.running_flow_id = undefined
      this.newServiceTask.task_type = change.department
      this.newServiceTask.sub_topic = change.sub_topic
      this.newServiceTask.planned_duration = change.avg_duration || 1
      this.newServiceTask.check_list = change.checklist || []
      if (change.user_id) {
        this.newServiceTask.assigned_contractor_id = change.user_id
      }
      if (change.approver_id) {
        this.newServiceTask.requested_approver_id = change.approver_id
      }
      this.newServiceTask.listing_task_catalog_item_id = change.id
      this.newServiceTask.listing_task_catalog_item_name = change.description
      if (!this.newServiceTask.description) {
        this.newServiceTask.description = change.description
      }
      this.extraCatalogInfo =
        change.quick_fixes.length ||
        change.possible_causes.length ||
        change.needed_actions.length
      if (change.by_personnel) {
        this.personnelMsg = `Will be auto assigned to <b>${change.by_personnel}</b> personnel`
      } else {
        this.personnelMsg = null
      }
      this.$store.dispatch('getCreationInfo', {
        listingsIds: this.newServiceTask.listings_ids,
        listingTaskCatalogItemId:
          this.newServiceTask.listing_task_catalog_item_id,
      })
      this.checkForWarranties()
    },
    checkListChange(val) {
      this.newServiceTask.check_list = val
    },
    clearChecklist() {
      this.newServiceTask.check_list = []
    },
    allowedDates(val) {
      if (
        this.listingTaskCreationInfo &&
        this.listingTaskCreationInfo.house_vacant_dates &&
        this.listingTaskCreationInfo.house_check_in_dates &&
        this.listingTaskCreationInfo.house_check_out_dates
      ) {
        const parsedDate = this.$moment(val).utc().add(1, 'days')
        const timeNow = this.$moment().utc()
        if (
          timeNow <= parsedDate &&
          (!this.listingTaskCreationInfo.house_vacant_dates[val] ||
            this.listingTaskCreationInfo.house_check_in_dates[val] ||
            this.listingTaskCreationInfo.house_check_out_dates[val])
        ) {
          return true
        } else {
          return false
        }
      }
    },
    eventColor(val) {
      if (
        this.listingTaskCreationInfo &&
        this.listingTaskCreationInfo.house_vacant_dates &&
        this.listingTaskCreationInfo.house_check_in_dates &&
        this.listingTaskCreationInfo.house_check_out_dates
      ) {
        const parsedDate = this.$moment(val).utc().add(1, 'days')
        const timeNow = this.$moment().utc()
        if (
          timeNow <= parsedDate &&
          this.listingTaskCreationInfo.house_check_out_dates[val]
        ) {
          return 'yellow lighten-1'
        } else if (
          timeNow <= parsedDate &&
          this.listingTaskCreationInfo.house_check_in_dates[val]
        ) {
          return 'purple lighten-1'
        } else if (
          timeNow <= parsedDate &&
          this.listingTaskCreationInfo.future_blocks[val]
        ) {
          return 'grey lighten-1'
        } else if (
          timeNow <= parsedDate &&
          !this.listingTaskCreationInfo.house_vacant_dates[val]
        ) {
          return 'green lighten-1'
        } else {
          return ''
        }
      }
    },
    listingChange(value) {
      this.$store.dispatch('getCreationInfo', {
        listingsIds: value,
        listingTaskCatalogItemId:
          this.newServiceTask.listing_task_catalog_item_id,
      })
      this.checkForWarranties()
    },
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        let data = {}
        data.listing_task = Object.assign({}, this.newServiceTask)
        if (this.maxPerDay) {
          data.max_per_day = this.maxPerDay
        }
        if (
          this.isCommunicationPerson &&
          this.listingTaskCreationInfo &&
          this.listingTaskCreationInfo.current_guest_name &&
          this.notifyGuest
        ) {
          data.notify_guest = true
        }
        if (this.reservation) {
          data.listing_task.created_on_reservation_id = this.reservation.id
        }
        const hour = (data.listing_task.hour || '00:00') + 'Z'
        data.listing_task.scheduled_at = this.$moment(
          data.listing_task.scheduled_at + ' ' + hour
        )
          .utc()
          .format()
        if (this.reviewId) {
          data.listing_task.review_id = this.reviewId
        }
        this.$store.commit('updateListingLoading', true)
        this.setServiceCallAISuggestion(false)
        axios
          .post('/api/listing-tasks', data)
          .then(res => {
            this.maxPerDay = null
            this.showMaxPerDay = false
            this.$store.commit('updateListingLoading', false)
            if (this.$route.fullPath.includes('service-calls')) {
              if (this.afterSaveFunc) {
                this.afterSaveFunc()
              } else {
                let startDate = this.$moment().startOf('month')._d
                this.$store.dispatch('getListingTasks', {
                  filters: {
                    from: startDate.toISOString().substr(0, 10),
                    to: new Date(startDate.setMonth(new Date().getMonth() + 1))
                      .toISOString()
                      .substr(0, 10),
                    status: 'All',
                    type: 'All',
                    saveToCal: true,
                    getPropertyTasks: true,
                  },
                })
                this.$store.dispatch('getListingTasks', {
                  router: this.$router,
                  filters: {
                    from: new Date(
                      new Date().getFullYear(),
                      new Date().getMonth() - 1,
                      1
                    )
                      .toISOString()
                      .substr(0, 10),
                    to: new Date(
                      new Date().getFullYear(),
                      new Date().getMonth() + 2,
                      1
                    )
                      .toISOString()
                      .substr(0, 10),
                    status: 'All',
                    type: 'All',
                  },
                })
              }
            } else {
              let id = ''
              if (this.$store.state.currentEditListing) {
                if (this.$store.state.currentEditListing.id !== 0) {
                  id = this.$store.state.currentEditListing.id
                } else {
                  id = this.$store.state.currentListing.id
                }
                this.$store.dispatch('changeEditListing', id)
              } else {
                this.$store.dispatch('getRequestsByFilter')
              }
              if (this.afterSaveFunc) {
                this.afterSaveFunc()
              }
              this.assignProject = false
            }
            this.$v.$reset()
            this.close()
            this.$store.commit('updateListingTaskCreationInfo', null)
            this.maxPerDay = null
            this.showMaxPerDay = false
            this.getInitialNewServiceTask()
            if (this.quickTask) {
              const ltId = get(res, 'data.payload.data.0.id')
              const link = ltId ? `/dashboard/listing-task/${ltId}` : undefined
              Toaster.show([
                { type: 'success', text: 'Task Created successfully', link },
              ])
            }
            this.$emit('after-create')
          })
          .catch(error => {
            alert(error)
            this.$store.commit('updateListingTaskCreationInfo', null)
            this.$store.commit('updateListingLoading', false)
          })
      }
    },
    saveDate(date) {
      this.$refs.menu.save(date)
    },
    clearAssignProject() {
      this.assignProject = false
      this.newServiceTask.project_id = null
    },
    openMaxPerDay() {
      this.maxPerDay = 5
      this.showMaxPerDay = true
    },
    clearMaxPerDay() {
      this.maxPerDay = null
      this.showMaxPerDay = false
    },
    checkForWarranties() {
      if (
        this.newServiceTask.listings_ids.length > 0 &&
        this.newServiceTask.listing_task_catalog_item_id
      ) {
        this.checkWarranty({
          listing_id: this.newServiceTask.listings_ids,
          catalog_id: this.newServiceTask.listing_task_catalog_item_id,
        })
      }
    },
    getInitialNewServiceTask(overridByState) {
      let priority = 'Med'
      if (this.isCommunicationPerson) {
        priority = 'High'
      }
      const preFields = this.preFields || {}
      this.newServiceTask = cloneDeep({
        assigned_contractor_id: null,
        additional_contractors: [],
        requested_approver_id: null,
        request_id: this.request ? this.request.id : null,
        description: this.request ? this.request.description : '',
        status: 'New',
        desc_images: [],
        check_list: [],
        task_type: null,
        sub_topic: null,
        listing_task_catalog_item_id: null,
        listing_task_catalog_item_name: null,
        simple_mode: true,
        listings_ids: this.request
          ? [this.request.listing_id]
          : this.preFilledListingId || [],
        scheduled_at: this.$moment().utc().format('YYYY-MM-DD'),
        hour: null,
        planned_duration: 1,
        cant_be_moved: false,
        priority: priority,
        project_id: null,
        ...preFields,
      })
      if (
        overridByState &&
        this.serviceCallModalOpened &&
        this.serviceCallModalPayload
      ) {
        if (this.serviceCallModalPayload.triggered_by_ai) {
          this.newServiceTask.triggered_by_ai = true
        }
        if (this.serviceCallModalPayload.description) {
          this.newServiceTask.description =
            this.serviceCallModalPayload.description
        }
        if (this.serviceCallModalPayload.priority) {
          this.newServiceTask.priority = this.serviceCallModalPayload.priority
        }
        if (this.serviceCallModalPayload.listing_task_catalog_item_id) {
          this.newServiceTask.listing_task_catalog_item_id =
            this.serviceCallModalPayload.listing_task_catalog_item_id
        }
        this.setServiceCallModalOpened({ opened: false, payload: null })
      }
      if (this.newServiceTask.listing_task_catalog_item_id) {
        const items = this.$store.state.tasksCatalog.catalogItemSelect
        const item = items.find(
          item => item.id === this.newServiceTask.listing_task_catalog_item_id
        )
        this.quickPickChange(item)
      }
    },
  },
  data() {
    return {
      menu: false,
      menu1: false,
      showServiceCall: false,
      personnelMsg: null,
      notifyGuest: true,
      previewSrc: null,
      isUploadingImages: false,
      maxPerDay: null,
      extraCatalogInfo: null,
      time: '',
      rejectMode: false,
      showMaxPerDay: false,
      images: null,
      myId: Math.floor(Math.random() * 100000) + 1,
      assignProject: false,
      newServiceTask: null,
      advancedExpand: false,
      hours: [
        '7a',
        '8a',
        '9a',
        '10a',
        '11a',
        '12p',
        '1p',
        '2p',
        '3p',
        '4p',
        '5p',
        '6p',
        '7p',
        '8p',
        '9p',
        '10p',
      ],
      warrantyDialog: false,
    }
  },
}
</script>
<style scoped lang="scss">
.fa-wrench:before {
  color: orange !important;
}

.service-call-create {
  .vue__time-picker {
    display: block;
    position: relative;
    font-size: 1em;
    width: 100%;
    font-family: sans-serif;
    vertical-align: middle;
    height: 40px;

    input.display-time {
      width: 100%;
      height: 100%;
      border-color: rgba(0, 0, 0, 0.87);
      border-radius: 4px;
      transition: all 0.3s;

      &:focus {
        border-color: #7ed9d9;
        border-width: 2px;
        outline: 0;
      }
    }

    .dropdown {
      width: 100%;

      .select-list {
        width: 100%;
      }
    }
  }
}
</style>
