<template>
  <v-dialog v-model="dialog" width="fit-content" :disabled="disabled">
    <template #activator="slotData">
      <slot name="activator" v-bind="slotData" />
    </template>
    <v-card>
      <v-card-title class="text-h5 align-center bb-1">
        <v-icon class="pr-2" color="secondary"
          >fas fa-exclamation-triangle
        </v-icon>
        {{ title || 'Confirmation' }}
      </v-card-title>

      <v-card-text class="pt-3 text-caption text-md-body-1" v-html="text" />

      <v-card-actions v-if="actions" class="justify-center flex-wrap px-10">
        <div v-for="(action, index) in actions" :key="index">
          <v-btn
            v-if="action.text"
            :color="action.color"
            depressed
            @click="
              () => {
                onClose()
                action.onClick && action.onClick()
              }
            "
          >
            {{ action.text }}
          </v-btn>
          <v-spacer v-else />
        </div>
      </v-card-actions>
      <v-card-actions v-else class="justify-space-around">
        <v-btn color="error" :small="isMobile" depressed @click="onClose"
          >NO</v-btn
        >
        <v-btn color="success" :small="isMobile" @click="onApprove">YES</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DeviceMixin from 'components/mixins/device-mixin'

export default {
  name: 'ConfirmationModal',
  props: ['text', 'actions', 'title', 'disabled'],
  mixins: [DeviceMixin],
  data() {
    return {
      dialog: false,
      approved: false,
    }
  },
  watch: {
    dialog(value) {
      if (!value && !this.approved) {
        this.$emit('cancel')
      }
    },
  },
  methods: {
    onClose() {
      this.dialog = false
    },
    onApprove() {
      this.approved = true
      this.dialog = false
      this.$emit('action')
      this.$nextTick(() => {
        this.approved = false
      })
    },
  },
}
</script>

<style scoped></style>
