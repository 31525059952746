<template>
  <div>
    <v-progress-linear v-show="isLoading" fixed indeterminate />
    <div class="grey lighten-4">
      <v-container class="max-w-1600 pb-0">
        <v-row class="mt-4 align-center">
          <v-col v-if="!isInvestor || isDemoUser" cols="6" sm="4" lg="2">
            <v-autocomplete
              v-model="regionFilter"
              class="white"
              :label="$t('Regions')"
              dense
              outlined
              hide-details
              clearable
              :items="listingRegions"
              @change="filterChanged"
            />
          </v-col>
          <v-col v-if="!isInvestor || isDemoUser" cols="6" sm="4" lg="2">
            <filters-select
              class="white"
              :label="$t('Beds')"
              :items="[1, 2, 3, 4, 5, 6, 7, 8, 9]"
              :value.sync="bedsFilter"
              @change="filterChanged"
            />
          </v-col>
          <v-col v-if="!isInvestor || isDemoUser" cols="12" sm="4" lg="2">
            <filters-select
              :label="$t('Cities')"
              class="white"
              :value.sync="cityFilter"
              :items="listingCities"
              @change="filterChanged"
            />
          </v-col>
          <v-spacer />
          <v-col cols="auto" class="ml-auto">
            <month-year-picker
              v-model="dates"
              :min-year="new Date().getFullYear() - 2"
              class="ml-auto"
              :is-yearly="true"
            />
          </v-col>
        </v-row>
        <v-tabs
          v-model="paramsTab"
          slider-color="white"
          background-color="#f6f6f7"
          active-class="white"
          :grow="isMobile"
        >
          <v-tab :ripple="false" href="#overview">
            <v-icon left> $growth </v-icon>

            <span class="hidden-sm-and-down">{{ $t('Overview') }}</span>
          </v-tab>
          <v-tab :ripple="false" href="#reviews">
            <v-icon left> $promotion </v-icon>

            <span class="hidden-sm-and-down">{{ $t('Reviews') }}</span>
          </v-tab>
        </v-tabs>
      </v-container>
    </div>
    <div>
      <v-container class="max-w-1600">
        <v-tabs-items v-model="paramsTab" class="pa-2">
          <v-tab-item value="overview">
            <overview-portfolio
              v-if="portfolio"
              :data="portfolio"
              :year="dates.year"
              @listing-changed="listingChanged"
            />
          </v-tab-item>
          <v-tab-item value="reviews">
            <reviews-portfolio :listing-ids="listingIds" :year="dates.year" />
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import formattersMixin from 'components/mixins/formatters-mixin'
import deviceMixin from 'components/mixins/device-mixin'
import FiltersSelect from 'components/form-fields/filters-select'
import permissionsMixin from 'components/mixins/permissions-mixin'
import hotjar from '@/utils/hotjar'
import navigationMixin from 'components/mixins/navigation-mixin'
import InfoRow from './info-row.vue'
import ReviewsPortfolio from 'components/portfolio/reviews-portfolio'
import MonthYearPicker from 'components/common/month-year-picker'
import OverviewPortfolio from 'components/portfolio/overview-portfolio'

export default {
  components: {
    OverviewPortfolio,
    MonthYearPicker,
    ReviewsPortfolio,
    FiltersSelect,
  },
  mixins: [
    formattersMixin,
    InfoRow,
    deviceMixin,
    permissionsMixin,
    navigationMixin,
  ],
  data() {
    return {
      portfolio: null,
      cityFilter: null,
      bedsFilter: null,
      isLoading: false,
      regionFilter: null,
      listingIds: null,
      dates: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },
    }
  },
  async mounted() {
    hotjar.event('PORTFOLIO-PAGE-LOAD')
    this.regionFilter = this.currentUser.region
      ? +this.currentUser.region
      : null
    if (this.currentUser) {
      this.getPortfolioData()
    }
  },
  methods: {
    ...mapActions('listingReport', ['getPortfolio']),
    async listingChanged(listings) {
      this.isLoading = true
      this.portfolio = await this.getPortfolio({
        listing_ids: listings,
        year: this.dates.year,
      })
      if (!this.regionFilter && !this.bedsFilter && !this.cityFilter) {
        this.listingIds = null
      } else {
        this.listingIds = this.portfolio.listings.map(l => l.id)
      }
      this.isLoading = false
    },
    filterChanged() {
      this.selectedRow = null
      this.getPortfolioData()
    },
    async getPortfolioData(params = {}) {
      this.isLoading = true
      this.portfolio = await this.getPortfolio({
        region: this.regionFilter,
        beds: this.bedsFilter,
        city: this.cityFilter,
        year: this.dates.year,
        ...params,
      })
      if (!this.regionFilter && !this.bedsFilter && !this.cityFilter) {
        this.listingIds = null
      } else {
        this.listingIds = this.portfolio.listings.map(l => l.id)
      }
      this.isLoading = false
    },
  },
  watch: {
    year() {
      this.getPortfolioData()
    },
  },
  computed: {
    ...mapState('regions', ['regions']),
    ...mapGetters(['currentUser', 'listingCities']),
    year() {
      return this.dates.year
    },
    listingRegions() {
      return this.regions.map(r => ({
        text: r.name,
        value: r.id,
      }))
    },
  },
}
</script>

<style scoped></style>
