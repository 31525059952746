<template>
  <div v-if="isMobile" class="mt-3">
    <v-select
      dense
      outlined
      hide-details
      label="Select board"
      clearable
      :value="currentBoard && currentBoard.id"
      item-value="id"
      item-text="name"
      :items="filteredBoards"
      @change="selectBoardById($event)"
    />
  </div>
  <div v-else class="taskim-side-bar">
    <div class="d-flex align-center pa-3">
      <v-tooltip v-if="isAdmin" :key="showAllBoards" bottom open-delay="300">
        <template #activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <v-btn icon class="mr-2" @click="toggleShowAll">
              <v-icon v-if="showAllBoards">mdi-clipboard-multiple</v-icon>
              <v-icon v-else>mdi-clipboard-multiple-outline</v-icon>
            </v-btn>
          </span>
        </template>
        <span>Show All Boards</span>
      </v-tooltip>

      <div class="flex-fill text-h6">Boards</div>
      <form-dialog
        title="Create New Taskim Board"
        @submit="onCreateNewBoard"
        @open="editedBoard = {}"
      >
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" icon v-on="on">
            <v-icon>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-text-field
          v-model="editedBoard.name"
          label="Name *"
          outlined
          dense
          :rules="[required]"
        />
      </form-dialog>
    </div>
    <v-divider />
    <v-list v-if="filteredBoards[0]" class="transparent pt-0">
      <v-hover
        v-for="board in filteredBoards"
        v-slot="{ hover }"
        :key="board.id"
      >
        <v-list-item class="pr-1" @click="selectBoard(board)">
          <v-list-item-content>
            <v-list-item-title
              :class="{
                'primary--text darken-2 font-weight-bold':
                  board.id === selectedBoardId,
              }"
            >
              {{ board.name }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action v-show="hover" class="flex-center flex-row ma-0">
            <form-dialog
              title="Update Taskim Board"
              @submit="onUpdateBoard"
              @open="onUpdateBoardOpen(board)"
            >
              <template #activator="{ on, attrs }">
                <v-btn small v-bind="attrs" icon v-on="on">
                  <v-icon small color="grey lighten-1">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <v-text-field
                v-model="editedBoard.name"
                label="Name *"
                outlined
                dense
                :rules="[required]"
              />
              <contractor-select
                label="Allowed Users"
                :value.sync="editedBoard.users_ids"
                :allow-multiple="true"
                :hide-details="true"
              />
              <v-text-field
                v-model="editedBoard.slack_channel"
                class="mt-5"
                label="Slack Channel"
                outlined
                dense
              />
            </form-dialog>
            <ConfirmationModal
              :text="`Are you sure you want to delete this board ?`"
              @action="onDeleteBoard(board.id)"
            >
              <template #activator="{ on }">
                <v-btn small icon v-on="on">
                  <v-icon small color="grey lighten-1">
                    mdi-delete-forever
                  </v-icon>
                </v-btn>
              </template>
            </ConfirmationModal>
          </v-list-item-action>
        </v-list-item>
      </v-hover>
    </v-list>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { find, keyBy, map } from 'lodash'
import CommonFunctions from 'components/mixins/common_functions'
import formRules from 'components/mixins/form-rules-mixin'
import FormDialog from 'components/common/form-dialog'
import ConfirmationModal from 'components/modals/confirmation-modal'
import ContractorSelect from '../contractor-select'
import LocalStorage from '../../utils/localStorage'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'

export default {
  name: 'TaskimSideBar',
  components: { ContractorSelect, ConfirmationModal, FormDialog },
  mixins: [CommonFunctions, formRules, PermissionsMixin, DeviceMixin],
  data() {
    return {
      selectedBoardId: null,
      editedBoard: {},
      showAllBoards: LocalStorage.get('TaskimShowAllBoards'),
    }
  },
  async mounted() {
    await this.getBoards()
    if (this.currentBoard) this.selectedBoardId = this.currentBoard.id
  },
  computed: {
    ...mapState('taskim', ['boards']),
    ...mapGetters('taskim', ['boardsMap']),
    currentBoardId() {
      return this.$route.params.boardId
    },
    currentBoard() {
      return this.boardsMap[this.currentBoardId]
    },
    filteredBoards() {
      if (this.showAllBoards) return this.boards
      return this.boards.filter(
        (board, index) =>
          index === 0 ||
          find(board.users_relations, { user_id: this.getMyUserId })
      )
    },
    filteredBoardsMap() {
      return keyBy(this.filteredBoards, 'id')
    },
  },
  methods: {
    ...mapActions('taskim', [
      'getBoards',
      'createBoard',
      'updateBoard',
      'deleteBoard',
      'stateTaskimRemoveBoard',
    ]),
    toggleShowAll() {
      this.showAllBoards = !this.showAllBoards
      LocalStorage.set('TaskimShowAllBoards', this.showAllBoards)
      const board = this.filteredBoardsMap[this.selectedBoardId]
      if (!board) this.selectBoardFallback()
    },
    async selectBoardFallback() {
      if (this.filteredBoards[0]) {
        this.selectedBoardId = this.filteredBoards[0].id
        await this.$router.replace({
          params: { ...this.$route.params, boardId: this.filteredBoards[0].id },
        })
      }
    },
    async onCreateNewBoard() {
      const newBoard = await this.createBoard(this.editedBoard)
      await this.getBoards()
      await this.selectBoard(newBoard)
    },
    onUpdateBoardOpen(board) {
      this.editedBoard = {
        id: board.id,
        name: board.name,
        slack_channel: board.slack_channel,
        users_ids: map(board.users_relations, 'user_id'),
      }
    },
    async onUpdateBoard() {
      await this.updateBoard(this.editedBoard)
    },
    async onDeleteBoard(id) {
      this.stateTaskimRemoveBoard(id)
      await this.deleteBoard(id)
      const board = this.filteredBoardsMap[this.selectedBoardId]
      if (!board) await this.selectBoardFallback()
    },
    async selectBoard(board) {
      if (this.currentBoardId !== board.id) {
        await this.$router.replace({
          params: { ...this.$route.params, boardId: board.id.toString() },
        })
      }
      this.selectedBoardId = board.id
    },
    async selectBoardById(boardId) {
      if (this.currentBoardId !== boardId) {
        await this.$router.replace({
          params: { ...this.$route.params, boardId: boardId },
        })
      }
      this.selectedBoardId = boardId
    },
  },
}
</script>
<style scoped lang="scss">
.taskim-side-bar {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--v-secondary-lighten);
}
</style>
