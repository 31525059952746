<template>
  <div v-show="!isInvestor">
    <div class="grey lighten-4">
      <v-container v-show="!loading" class="pb-0 max-w-1600">
        <v-tabs
          v-model="paramsTab"
          slider-color="white"
          center-active
          background-color="#f6f6f7"
          active-class="white"
          :grow="isMobile"
        >
          <v-tab
            v-if="
              isAdmin ||
              isOperationsPersonal ||
              isCommunicationPerson ||
              isSalesAgent ||
              isSalesManager
            "
            href="#area-report"
          >
            <v-icon left>$overall_status</v-icon>

            {{ $t('Area Report') }}
          </v-tab>
          <v-tab href="#today-tasks">
            <v-icon left>$tasks</v-icon>
            {{ $t("Today's tasks") }}
          </v-tab>
          <v-tab v-if="!isCleaner" href="#tasks-calendar">
            <v-icon left>$calendar_check</v-icon>

            {{ $t('Calendar') }}
          </v-tab>
          <v-tab
            v-if="isAdmin || isPropertyManager || isCoordinator"
            href="#pm-summary"
          >
            <v-icon left>$maintenance</v-icon>

            {{ $t('PM Summary') }}
          </v-tab>
          <v-tab
            v-if="
              isDesignedVr &&
              (isAdmin || hasAbility(['super-pm', 'ge-summary']))
            "
            href="#ge-summary"
          >
            <v-icon left>$activity</v-icon>

            {{ $t('GE Summary') }}
          </v-tab>

          <v-tab v-if="!isCleaner" href="#accepted">
            <v-icon left>$search_task</v-icon> {{ $t('Search') }}
          </v-tab>
          <v-tab
            v-if="
              !user.on_payrole &&
              (isContractor || isCleaningSupervisor || isCleaningManager)
            "
            href="#payments"
            @click="
              getDetails({
                id: currentUserId,
                from: contractorPaymentsDateRange.from,
                to: contractorPaymentsDateRange.to,
              })
            "
          >
            <v-icon left>$money</v-icon>

            {{ $t('Payments') }}
          </v-tab>
          <v-tab
            v-if="isAdmin || isPropertyManager || isCoordinator"
            href="#housekeeping"
          >
            <v-icon left>$hotel_reception</v-icon>

            {{ $t('House Keeping') }}
          </v-tab>
          <v-tab
            v-if="isAdmin || isPropertyManager || isCoordinator"
            href="#listing-info"
          >
            <v-icon left>$info_circle_alt</v-icon>

            {{ $t('Info def') }}
          </v-tab>
          <v-tab
            v-if="isAdmin || isPropertyManager || isCoordinator"
            href="#projects"
          >
            <v-icon left>$complete</v-icon>

            {{ $t('Projects') }}
          </v-tab>
          <v-tab
            v-if="isAdmin || isPropertyManager || hasAbility('catalog-manager')"
            href="#catalog"
          >
            <v-icon left>$files</v-icon>

            {{ $t('Catalog') }}
          </v-tab>
          <v-tab
            v-if="
              isAdmin || isPropertyManager || hasAbility('auto-rules-manager')
            "
            href="#auto-tasks"
          >
            <v-icon left>$rules</v-icon>

            {{ $t('Automations') }}
          </v-tab>
        </v-tabs>
      </v-container>
    </div>
    <div>
      <v-tabs-items v-model="paramsTab" touchless>
        <v-progress-linear
          v-show="listingLoading"
          color="secondary"
          style="margin: 0"
          absolute
          :indeterminate="true"
        />
        <v-tab-item
          v-if="paramsTab === 'accepted'"
          value="accepted"
          class="main-background"
        >
          <listing-tasks-table />
        </v-tab-item>
        <v-tab-item
          v-if="paramsTab === 'housekeeping'"
          value="housekeeping"
          class="pl-4 pb-2 pr-4 main-background"
        >
          <housekeeping-tab />
        </v-tab-item>
        <v-tab-item
          v-if="isAdmin || isPropertyManager || hasAbility('catalog-manager')"
          value="catalog"
          class="pl-4 pr-4 main-background"
        >
          <listing-task-catalog-items-table />
        </v-tab-item>
        <v-tab-item
          v-if="paramsTab === 'tasks-calendar'"
          value="tasks-calendar"
          class="main-background"
        >
          <v-layout wrap justify-center>
            <v-flex xs12 sm11>
              <tasks-calendar
                :initial-view="isInvestor ? 'listWeek' : 'timeGridDay'"
              />
            </v-flex>
          </v-layout>
        </v-tab-item>
        <v-tab-item
          v-if="isAdmin || isPropertyManager"
          value="projects"
          class="pl-4 pr-4 main-background"
        >
          <projects v-if="paramsTab === 'projects'" />
        </v-tab-item>
        <v-tab-item
          v-if="isAdmin || isPropertyManager || isCoordinator"
          value="pm-summary"
          class="pl-4 pr-4 main-background"
          eager
        >
          <daily-summary v-if="paramsTab === 'pm-summary'" />
        </v-tab-item>
        <v-tab-item value="area-report" class="main-background" eager>
          <router-view />
        </v-tab-item>
        <v-tab-item
          v-if="isAdmin || hasAbility(['super-pm', 'ge-summary'])"
          value="ge-summary"
          class="pl-4 pr-4 main-background"
          eager
        >
          <daily-summary v-if="paramsTab === 'ge-summary'" />
        </v-tab-item>
        <v-tab-item
          v-if="isAdmin || isPropertyManager"
          value="schedule-helper"
          class="pl-4 pr-4 main-background"
        >
          <v-layout wrap justify-center>
            <v-flex xs12 sm10>
              <LtDailyList />
            </v-flex>
          </v-layout>
        </v-tab-item>
        <v-tab-item
          v-if="isAdmin || isPropertyManager || isGarbageUser"
          value="garbage"
          class="pl-4 pr-4 main-background"
        >
          <v-layout wrap justify-center>
            <v-flex xs12 sm10>
              <lt-checklist
                v-if="paramsTab === 'garbage'"
                :filter-func="isContractor ? filterGarbage : null"
                :list-type="[
                  'garbage patrol',
                  'waste management',
                  'day patrol',
                ]"
              />
            </v-flex>
          </v-layout>
        </v-tab-item>
        <v-tab-item
          v-if="isAdmin || isPropertyManager"
          value="listing-info"
          class="pl-4 pr-4 main-background"
        >
          <v-layout wrap justify-center>
            <v-flex xs12 sm10>
              <listing-info-definitions-table class="mt-4" />
            </v-flex>
          </v-layout>
        </v-tab-item>
        <v-tab-item v-if="paramsTab === 'today-tasks'" value="today-tasks">
          <todays-tasks-tab />
        </v-tab-item>
        <v-tab-item
          v-if="
            isAdmin ||
            isPropertyManager ||
            isSecurityUser ||
            isCommunicationManager ||
            isCommunicationAgent
          "
          value="security"
          class="pl-4 pr-4 main-background"
        >
          <v-layout wrap justify-center>
            <v-flex xs12 sm10>
              <lt-checklist
                v-if="paramsTab === 'security'"
                list-type="security patrol"
              />
            </v-flex>
          </v-layout>
        </v-tab-item>
        <v-tab-item value="payments" class="px-4">
          <v-row justify="center">
            <v-col cols="12" sm="10">
              <contractor-listing-task-details />
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item
          v-if="isAdmin || isPropertyManager"
          value="tasks-stats"
          class="pl-4 pr-4 main-background"
        >
          <v-layout wrap justify-center>
            <v-flex sm10>
              <listing-tasks-stats />
            </v-flex>
          </v-layout>
        </v-tab-item>
        <v-tab-item
          v-if="
            isAdmin || isPropertyManager || hasAbility('auto-rules-manager')
          "
          class="pl-2 pr-2"
          value="auto-tasks"
        >
          <v-layout wrap justify-center>
            <v-flex sm10 class="pb-2">
              <v-switch
                v-if="!loading && false"
                v-model="showRuleSummary"
                mt-2
                mb-2
                :label="$t('Show rules per house')"
              >
              </v-switch>
              <auto-listing-task-rules-table
                v-show="!showRuleSummary"
                :general-mode="true"
              />
              <auto-listing-task-rules-warnings v-show="showRuleSummary" />
            </v-flex>
          </v-layout>
        </v-tab-item>
        <v-tab-item
          v-if="isAdmin || hasAbility('super-pm')"
          value="bi"
          class="pl-4 pr-4 main-background"
        >
          <iframe
            title="Pms"
            width="100%"
            height="1500"
            src="https://app.powerbi.com/reportEmbed?reportId=9d57c9d3-2b53-4227-9a46-1962259b41ec&appId=49f02176-238c-41a1-847c-97192c95335b&autoAuth=true&ctid=cfaa53b4-8414-410c-ad73-470f4cecfeab&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLWVhc3QtYS1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9"
            frameborder="0"
            allowFullScreen="true"
          ></iframe>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import TasksCalendar from 'components/calendar/tasks-calendar'
import ListingTasksTable from 'components/listing-tasks-table'
import ListingTasksStats from 'components/listing-tasks-stats'
import LtDailyList from 'components/listing-tasks/lt-daily-list'
import LtChecklist from 'components/lt-checklist'
import ListingTaskCatalogItemsTable from 'components/listing-tasks-catalog/listing-task-catalog-items-table'
import ListingInfoDefinitionsTable from 'components/info-item-definition/listing-info-definitions-table'
import ContractorListingTaskDetails from 'components/contractor-listing-task-details'
import DailySummary from 'components/summaries/daily-summary'
import Projects from 'components/projects/projects'
import TodaysTasksTab from 'components/todays-tasks/todays-tasks-tab'
import AutoListingTaskRulesTable from 'components/auto-listing-task-rules-table.vue'
import AutoListingTaskRulesWarnings from 'components/auto-listing-task-rules-warnings.vue'
import HousekeepingTab from 'components/hotel-dashboard/housekeeping-tab.vue'
// import ListingReport from 'components/listing-report/listing-report-layout.vue'

export default {
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  data() {
    return {
      showRuleSummary: false,
      forceShow: false,
    }
  },
  created() {
    if (!this.paramsTab) {
      this.paramsTab = 'area-report'
    }
  },
  computed: {
    ...mapState(['listingTasksLoaded', 'contractorPaymentsDateRange', 'user']),
    ...mapGetters(['currentUserId']),
    ...mapGetters('users', ['activeUsers']),
    usersHeaders() {
      return [
        { text: 'Role', align: 'center', value: 'role' },
        { text: 'Name', align: 'center', value: 'name' },
        {
          text: 'Department',
          value: 'department',
        },
        {
          text: 'Sub Role',
          value: 'sub_role',
        },
        {
          text: 'Additional desc',
          align: 'center',
          value: 'field_of_expertise',
        },
        { text: 'Team', align: 'center', value: 'team_name' },

        { text: 'On Payroll', align: 'center', value: 'on_payrole' },
        {
          text: 'Auto approve',
          align: 'center',
          value: 'can_auto_approve',
        },
        {
          text: 'Preferred',
          value: 'preferred',
        },
        { text: 'Owners hourly pay', align: 'center', value: 'hourly_pay' },
        { text: 'DVR hourly pay', align: 'center', value: 'dvr_hourly_chrage' },
        { text: 'Actions', align: 'center', value: 'action' },
      ]
    },
  },
  methods: {
    ...mapActions({
      getDetails: 'getContractorPaymentsDetails',
    }),
    filterGarbage(item) {
      return !item.extra_data.cleaning_on_site
    },
  },
  components: {
    HousekeepingTab,
    AutoListingTaskRulesWarnings,
    AutoListingTaskRulesTable,
    DailySummary,
    Projects,
    // ListingReport,
    ContractorListingTaskDetails,
    ListingInfoDefinitionsTable,
    ListingTaskCatalogItemsTable,
    LtChecklist,
    TasksCalendar,
    ListingTasksTable,
    ListingTasksStats,
    LtDailyList,
    TodaysTasksTab,
  },
}
</script>
