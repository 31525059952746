<template>
  <form-dialog title="Quick Charge" max-width="600px" @submit="submitForm">
    <template #activator="{ on }">
      <v-btn outlined color="success" class="ma-2" v-on="on"
        ><v-icon>mdi-lightning-bolt</v-icon>Quick Charge
      </v-btn>
    </template>
    <v-text-field
      v-if="!touristEmail"
      v-model="email"
      label="User Email"
      outlined
      dense
      prepend-inner-icon="mdi-account"
    />
    <v-select
      v-model="category"
      :items="items"
      label="Category"
      outlined
      dense
      prepend-inner-icon="mdi-shape"
      :rules="[required]"
    />
    <v-text-field
      v-model="description"
      outlined
      dense
      label="Description"
      prepend-inner-icon="mdi-card-text-outline"
      :rules="[required]"
    />
    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field
          v-model.number="totalPrice"
          label="Total Price"
          type="number"
          outlined
          dense
          prepend-inner-icon="mdi-currency-usd"
          :rules="[required]"
        />
      </v-col>
      <v-col cols="6" class="pr-2">
        <v-text-field
          label="Net"
          type="number"
          disabled
          :value="netPrice.toFixed(2)"
          outlined
          dense
        />
      </v-col>
      <v-col cols="6" class="pl-2">
        <v-text-field
          disabled
          label="Tax"
          type="number"
          outlined
          dense
          :value="tax.toFixed(2)"
        />
      </v-col>
    </v-row>
    <div class="d-flex">
      <date-picker v-model="date" class="mx-1" reactive />
      <dvr-time-picker class="mx-1" :value.sync="time" />
    </div>
    <v-checkbox
      v-model="includeServiceCall"
      hide-details
      label="Create Service Call"
    />
    <v-checkbox v-model="manual" hide-details label="Authorize&Capture" />
    <v-checkbox v-model="autoCharge" label="Auto charge" />
    <v-select
      v-if="autoCharge"
      v-model="methodId"
      dense
      :rules="[required]"
      outlined
      :clearable="false"
      label="Payment Method"
      :items="paymentMethods"
    />
    <div v-if="includeServiceCall" class="d-flex mt-2">
      <contractor-select
        class="mx-1"
        :text-selection="true"
        :value.sync="contractorId"
        :rules="[required]"
      />
      <v-text-field
        v-model.number="contractorPrice"
        class="mx-1"
        type="number"
        outlined
        dense
        label="Contractor Amount"
        prepend-inner-icon="mdi-account-hard-hat"
        :rules="[required]"
      />
    </div>
    <template #actions>
      <v-btn class="mt-4" block type="submit" depressed color="success">
        <v-icon>mdi-lightning-bolt</v-icon> Create Charge Request
      </v-btn>
    </template>
  </form-dialog>
</template>

<script>
import DvrTimePicker from 'components/form-fields/dvr-time-picker'
import DatePicker from 'components/form-fields/date-picker'
import CommonFunctions from 'components/mixins/common_functions'
import formRules from 'components/mixins/form-rules-mixin'
import ContractorSelect from 'components/contractor-select.vue'
import FormDialog from 'components/common/form-dialog'
import { first, get } from 'lodash/fp'
import { round } from 'lodash'
import axios from '@/axios/config'

export default {
  name: 'QuickCharge',
  components: {
    FormDialog,
    DvrTimePicker,
    DatePicker,
    ContractorSelect,
  },
  mixins: [CommonFunctions, formRules],
  props: ['reservation', 'touristEmail'],
  data() {
    return {
      items: ['Spa', 'Food & Drinks', 'General', 'Parking', 'Yacht'],
      category: null,
      description: null,
      contractorPrice: null,
      manual: null,
      totalPrice: null,
      date: this.parseDate(new Date()),
      time: null,
      email: null,
      methodId: null,
      autoCharge: null,
      includeServiceCall: false,
      contractorId: null,
      paymentMethods: [],
    }
  },
  computed: {
    taxRate() {
      return 1 + this.$store.state.app.configuration.store_tax
    },
    netPrice() {
      if (this.totalPrice) {
        return round(this.totalPrice / this.taxRate, 2)
      }
      return 0
    },
    tax() {
      if (this.totalPrice && this.netPrice) {
        return this.totalPrice - this.netPrice
      }
      return 0
    },
  },
  async mounted() {
    const { data: payment_methods } = await axios.get(
      `/api/reservations/${this.reservation.id}/payment-methods`
    )
    this.paymentMethods = payment_methods.map(method => ({
      value: method.payment_method_id,
      text: `xxxx-${method.last4} (${method.exp_month}/${method.exp_year})`,
    }))
    if (!this.isEmpty(this.paymentMethods)) {
      this.methodId = get('value', first(this.paymentMethods))
      this.autoCharge = true
    }
  },
  methods: {
    contractorChange(value) {
      this.contractorId = value
    },
    async submitForm() {
      const timeString = this.time || ''
      const payload = {
        category: this.category,
        description: this.description,
        contractor_price: this.contractorPrice,
        total_price: this.netPrice,
        email: this.email,
        manual: this.manual,
        scheduled_date: this.$moment(`${this.date} ${timeString}`).format(),
        contractor_id: this.contractorId,
        reservationId: this.reservation.id,
        payment_method_id: this.autoCharge ? this.methodId : null,
      }
      await this.$store.dispatch('createQuickStorePurchase', payload)
      this.$emit('get-purchases')
    },
  },
}
</script>

<style></style>
