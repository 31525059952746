<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="12" sm="3">
        <v-select
          v-model="filters.item_type"
          label="Type"
          :items="[
            'ListingIncome',
            'Payment',
            'Expense',
            'AutoListingTaskRule',
            'AppSetting',
          ]"
          clearable
          outlined
          hide-details
          @change="fetchDataDebounce(1)"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          v-model="filters.event"
          label="Event"
          :items="['create', 'update', 'destroy']"
          clearable
          outlined
          hide-details
          @change="fetchDataDebounce(1)"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="filters.item_id"
          label="Item Id"
          outlined
          clearable
          hide-details
          @input="fetchDataDebounce(1)"
        />
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12" sm="4">
        <v-text-field
          v-if="
            filters.item_type === 'All' || !modelsColumnNames[filters.item_type]
          "
          v-model="filters.changed_field"
          label="Changed Field"
          outlined
          clearable
          hide-details
          @input="fetchDataDebounce(1)"
        />
        <v-select
          v-else
          v-model="filters.changed_field"
          label="Changed Field"
          :items="modelsColumnNames[filters.item_type]"
          clearable
          outlined
          hide-details
          @change="fetchDataDebounce(1)"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="filters.changed_value_old"
          label="Changed Value - Old"
          outlined
          clearable
          hide-details
          :disabled="!filters.changed_field"
          @input="fetchDataDebounce(1)"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="filters.changed_value_new"
          label="Changed Value - New"
          outlined
          clearable
          hide-details
          :disabled="!filters.changed_field"
          @input="fetchDataDebounce(1)"
        />
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-data-table
        :headers="tableHeaders"
        :items="items"
        :options="pagination"
        :server-items-length="pagination.itemsLength"
        :loading="fetchingData"
        class="elevation-1 w-100 mx-3"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100],
        }"
        @pagination="paginationChanged($event)"
      >
        <template #item.created_at="{ item }">
          <div class="text-no-wrap">
            {{ parseDateLocalTime(item.created_at) }}
          </div>
        </template>
        <template #item.whodunnit="{ item }">
          {{ item.whodunnit ? nameById(item.whodunnit) : 'System' }}
        </template>
        <template #item.object_changes="{ item }">
          <div class="text-pre-wrap">{{ item.object_changes }}</div>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import axios from '@/axios/config'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { debounce } from 'lodash'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'PaperTrail',
  components: {},
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  data: function () {
    return {
      modelsColumnNames: {},
      filters: {
        item_type: '',
        event: '',
        item_id: '',
        changed_field: '',
        changed_value_old: '',
        changed_value_new: '',
      },
      tableHeaders: [
        { text: 'Id', value: 'id', sortable: false },
        { text: 'Item Id', value: 'item_id', sortable: false },
        { text: 'Type', value: 'item_type', sortable: false },
        { text: 'Event', value: 'event', sortable: false },
        { text: 'Created At', value: 'created_at', sortable: false },
        { text: 'Created By', value: 'whodunnit', sortable: false },
        { text: 'Changes', value: 'object_changes', sortable: false },
      ],
      fetchingData: true,
      pagination: {
        page: 1,
        itemsPerPage: 25,
        itemsLength: null,
      },
      items: [],
      fetchDataDebounce: debounce(this.fetchData, 300),
      idsInAction: new Set(),
    }
  },
  beforeMount() {
    this.fetchModelsColumnNames()
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchModelsColumnNames() {
      axios.get('/api/dev-tools/models-column-names').then(res => {
        this.modelsColumnNames = res.data
      })
    },
    fetchData(page) {
      this.fetchingData = true
      if (page) this.pagination.page = page
      axios
        .get('/api/dev-tools/paper-trail', {
          params: {
            page: this.pagination.page,
            per_page: this.pagination.itemsPerPage,
            item_type: this.filters.item_type || '',
            event: this.filters.event || '',
            item_id: this.filters.item_id || '',
            changed_field: this.filters.changed_field || '',
            changed_value_old: this.filters.changed_value_old || '',
            changed_value_new: this.filters.changed_value_new || '',
          },
        })
        .then(res => {
          this.items = res.data.items
          this.pagination = {
            page: parseInt(res.data.pagi_info.page),
            itemsPerPage: parseInt(res.data.pagi_info.per_page),
            itemsLength: parseInt(res.data.pagi_info.count),
          }
        })
        .finally(() => {
          this.fetchingData = false
        })
    },
    paginationChanged(pagination) {
      const page = this.pagination.page
      const itemsPerPage = this.pagination.itemsPerPage
      this.pagination = pagination
      if (
        page !== pagination.page ||
        itemsPerPage !== pagination.itemsPerPage
      ) {
        this.fetchData()
      }
    },
  },
  watch: {
    'filters.changed_field': {
      handler(newValue) {
        if (!newValue) {
          this.filters.changed_field = ''
          this.filters.changed_value_old = ''
          this.filters.changed_value_new = ''
        }
      },
    },
    'filters.item_type': {
      handler() {
        this.filters.item_id = ''
        this.filters.changed_field = ''
      },
    },
  },
}
</script>

<style scoped></style>
