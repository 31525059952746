<template>
  <v-sheet>
    <v-row class="align-center">
      <v-col cols="9" class="text-h5 font-weight-bold">Billing</v-col>
      <v-col class="d-flex justify-end" cols="3">
        <month-year-picker
          :is-yearly="true"
          :min-year="2024"
          :max-year="new Date().getFullYear()"
          @change="onDateChange"
        />
      </v-col>
    </v-row>
    <v-progress-linear
      v-if="loading"
      color="info"
      indeterminate
      rounded
    ></v-progress-linear>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel v-for="(log, index) in logs" :key="index">
            <v-expansion-panel-header>
              <v-sheet max-width="100px" class="pa-0 font-weight-semibold">
                {{ $moment(log.stats_date).format('MMMM') }}
              </v-sheet>
              <v-sheet max-width="40px">|</v-sheet>
              <v-sheet class="pa-0 font-weight-bold"
                >{{ toMoney(monthlyCharge(log)) }}
                <span
                  class="pa-0 ms-2 font-weight-semibold text-caption text--secondary"
                  >({{ log.chargable_listings_amount }}
                  Charged listings x
                  {{ toMoney(log.per_door) }}
                  Per door)
                </span>
              </v-sheet>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              <div class="font-weight-bold my-2">Active Listings:</div>
              <div class="d-flex flex-wrap" style="line-height: 2rem">
                <div v-for="(nickname, key) in log.active_listings" :key="key">
                  <a
                    class="grey--text text--darken-3 me-3"
                    :href="`/dashboard/edit/${key}`"
                    target="_blank"
                  >
                    {{ nickname }}</a
                  >
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import MonthYearPicker from 'components/common/month-year-picker'
import { mapActions } from 'vuex'
import formattersMixin from 'components/mixins/formatters-mixin'

export default {
  components: {
    MonthYearPicker,
  },
  mixins: [formattersMixin],
  data() {
    return {
      loading: false,
      logs: [],
    }
  },
  async mounted() {
    this.getBilling()
  },
  methods: {
    ...mapActions('billing', ['fetchBilling']),
    monthlyCharge(log) {
      return log.per_door * log.chargable_listings_amount
    },
    async getBilling(year) {
      this.loading = true
      const { data } = await this.fetchBilling({ year })
      this.loading = false
      this.logs = data.logs
    },
    onDateChange({ year }) {
      this.getBilling(year)
    },
  },
  computed: {},
}
</script>
