<template>
  <v-dialog v-model="dialog" fullscreen>
    <template #activator="{ on }">
      <v-btn icon small v-on="on">
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <div class="py-3 px-sm-4 white pre-check-in-wrapper fill-height">
      <v-stepper
        v-if="preCheckIn.id"
        v-model="step"
        class="fill-height d-flex flex-column overflow-y-auto"
      >
        <div class="d-flex pa-3 justify-space-between">
          <div class="text-h6">Pre Check-In</div>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-row
          v-if="step !== 3"
          class="justify-center flex-grow-0 flex-shrink-0"
          no-gutters
        >
          <v-col cols="10" sm="8" md="4">
            <v-stepper-header>
              <v-stepper-step
                complete-icon="check"
                :complete="step > 1"
                :step="1"
              />
              <v-divider />
              <v-stepper-step :complete="step > 2" :step="2" />
              <v-divider />
              <v-stepper-step :complete="step > 3" :step="3" />
            </v-stepper-header>
          </v-col>
        </v-row>
        <v-container class="flex-grow-1 d-flex flex-column pt-0">
          <v-row class="justify-center">
            <v-col cols="12" class="d-flex" elevation="0">
              <v-stepper-items v-if="preCheckIn" class="flex">
                <GuestsInfo
                  :validate="validations.stepOne"
                  :pre-check-in="preCheckIn"
                  @step-forward="stepForward"
                />
                <SpecialRequests
                  :validate="validations.stepTwo"
                  :pre-check-in="preCheckIn"
                  @step-back="stepBack"
                  @step-forward="stepForward"
                />
                <PersonalInfo
                  :hide-save="true"
                  :validate="validations.stepThree"
                  :pre-check-in="preCheckIn"
                  @step-back="stepBack"
                  @step-forward="stepForward"
                />
              </v-stepper-items>
            </v-col>
          </v-row>
          <v-divider />
          <div class="py-6 d-flex">
            <v-btn
              v-if="step > 1 && step < 4"
              elevation="0"
              large
              color="secondary.lighten1"
              @click="stepBack"
            >
              <v-icon class="pr-3"> $left_arrow </v-icon>Back
            </v-btn>
            <v-spacer />
            <v-btn elevation="0" large color="primary" @click="nextStep">
              {{ isLastStep ? 'submit' : 'next' }}
            </v-btn>
          </div>
        </v-container>
      </v-stepper>
    </div>
  </v-dialog>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import GuestsInfo from './guests-info'
import SpecialRequests from './special-requests'
import PersonalInfo from './personal-info'

export default {
  name: 'TripPreCheckIn',
  components: {
    PersonalInfo,
    SpecialRequests,
    GuestsInfo,
  },
  mixins: [CommonFunctions],
  props: {
    preCheckInData: {
      type: Object,
    },
    code: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      step: 1,
      isSubmitting: false,
      dialog: false,
      validations: {
        stepOne: 0,
        stepTwo: 0,
        stepThree: 0,
      },
      preCheckIn: this.preCheckInData,
    }
  },
  computed: {
    isLastStep() {
      return this.step === 3
    },
  },
  watch: {
    preCheckInData(val) {
      this.preCheckIn = val
    },
  },
  methods: {
    nextStep() {
      if (this.step === 1) {
        this.validations.stepOne++
      }
      if (this.step === 2) {
        this.validations.stepTwo++
      }
      if (this.step === 3) {
        this.validations.stepThree++
      }
    },
    async stepForward(payload = {}) {
      const currentStep = this.step
      const isLastStep = currentStep === 3

      this.preCheckIn = await this.$store.dispatch('updatePreCheckIn', {
        ...this.preCheckIn,
        step: currentStep,
        ...payload,
      })
      this.$emit('updated-precheckin')
      if (!isLastStep) {
        this.step++
      } else {
        this.dialog = false
        await this.$store.dispatch(
          'getChatConversation',
          this.$store.state.chosenChatConversation.guesty_id
        )
      }
    },
    stepBack() {
      this.step--
    },
  },
}
</script>

<style scoped>
.pre-check-in-wrapper {
  position: relative;
}
@media (min-width: 1904px) {
  .store-container {
    max-width: 1400px;
  }
}
</style>
