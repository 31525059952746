export default {
  computed: {
    isDemoUser() {
      const user = this.$store.state.user
      return user && user.email === 'demo6@designedvr.com'
    },
    isSmartBnb() {
      const app = this.$store.state.app
      return (
        app &&
        app.configuration &&
        app.configuration.company_name === 'Smartbnb'
      )
    },
    managementRoles() {
      return (
        this.isAdmin ||
        this.isOperationsPersonal ||
        this.isCommunicationManager ||
        this.isSalesManager ||
        this.isAccountant ||
        this.isHotelReception
      )
    },
    salesDepartment() {
      const user = this.$store.state.user
      return user && user.department === 'Sales'
    },
    userRelatedToComm() {
      const user = this.$store.state.user
      return (
        this.isCommunicationManager ||
        this.isCommunicationAgent ||
        (user &&
          (user.department === 'Sales' ||
            user.department === 'Customer Support'))
      )
    },
    cleaningRoles() {
      return (
        this.isCleaner || this.isCleaningManager || this.isCleaningSupervisor
      )
    },
    isCommunicationPerson() {
      return this.isCommunicationManager || this.isCommunicationAgent
    },
    canDoListingTasks() {
      const { user } = this.$store.state
      if (user && user.id) {
        return (
          user.abilities.indexOf('submit_sc') != -1 ||
          user.role == 'property-manager' ||
          user.role == 'admin'
        )
      } else {
        return false
      }
    },

    isAdmin: function () {
      return this.$store.getters.isAdmin
    },
    isSuperAdmin() {
      const { user } = this.$store.state
      return this.isAdmin && user.abilities.includes('super-admin')
    },
    isSystemAdmin() {
      const { user } = this.$store.state
      return user.super_admin
    },
    isExperienceManager: function () {
      return this.$store.getters.isExperienceManager
    },
    isCleaner: function () {
      return this.$store.getters.isCleaner
    },
    isAccountant: function () {
      return this.$store.getters.isAccountant
    },
    isHotelReception: function () {
      return this.$store.getters.isHotelReception
    },
    onPayroleContractor() {
      const user = this.$store.state.user
      if (user) {
        return user.on_payrole && user.role == 'contractor'
      } else {
        return false
      }
    },
    onPayrole() {
      const user = this.$store.state.user
      if (user) {
        return user.on_payrole
      } else {
        return false
      }
    },
    externalContractor() {
      const user = this.$store.state.user
      if (user) {
        return (
          !user.on_payrole &&
          !user.dvr_hourly_chrage &&
          user.role == 'contractor'
        )
      } else {
        return false
      }
    },
    isPropertyManager: function () {
      return this.$store.getters.isPropertyManager
    },
    isOperationsPersonal: function () {
      return this.$store.getters.isOperationsPersonal
    },
    isCoordinator: function () {
      return this.$store.getters.isCoordinator
    },
    isFieldPm: function () {
      return this.$store.getters.isFieldPm
    },
    isSalesManager: function () {
      return this.$store.getters.isSalesManager
    },
    isCommunicationAgent: function () {
      return this.$store.getters.isCommunicationAgent
    },
    isSalesAgent: function () {
      return this.$store.getters.isSalesAgent
    },
    isCleaningManager: function () {
      return this.$store.getters.isCleaningManager
    },
    isInvestor: function () {
      return this.$store.getters.isInvestor
    },
    isGarbageUser: function () {
      return this.$store.getters.isGarbageUser
    },
    isSecurityUser: function () {
      return this.$store.getters.isSecurityUser
    },

    isContractor: function () {
      return this.$store.getters.isContractor
    },
    isCleaningSupervisor: function () {
      return this.$store.getters.isCleaningSupervisor
    },
    isCommunicationManager: function () {
      return (
        this.$store.getters.isCommunicationManager ||
        this.$store.getters.isSalesManager
      )
    },
    isInvestmentManager: function () {
      return this.$store.getters.isInvestmentManager
    },
    isServiceGiver: function () {
      if (this.$store.state.user) {
        let role = this.$store.state.user.role
        return (
          role === 'pools-manager' ||
          role === 'repairs-manager' ||
          role === 'landscaping-manager'
        )
      } else {
        return false
      }
    },
  },
  methods: {
    isManagementRolesUser(user) {
      return [
        'admin',
        'property-manager',
        'coordinator',
        'field-pm',
        'communication-manager',
        'sales-manager',
        'accountant',
      ].includes(user.role)
    },
  },
}
