<template>
  <div class="locale-changer">
    <v-menu v-model="menu" bottom offset-y>
      <template #activator="{ on }">
        <div class="pointer" v-on="on">
          <country-flag
            :value="$i18n.locale"
            :country="currentCountry"
            :rounded="true"
            size="normal"
          />
        </div>
      </template>
      <v-list>
        <v-list-item-group v-model="$i18n.locale">
          <v-list-item v-for="(item, i) in langs" :key="i" :value="item.lang">
            <country-flag
              :value="item.lang"
              :rounded="true"
              :country="item.country"
              size="normal"
            />
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import DeviceMixin from 'components/mixins/device-mixin'
import { LANGUAGES } from '@/consts'

export default {
  name: 'LocaleChanger',
  mixins: [DeviceMixin],
  components: { CountryFlag },
  data() {
    return {
      menu: false,
      langs: LANGUAGES,
    }
  },
  computed: {
    currentLocale() {
      if (this.$i18n) {
        return this.$i18n.locale
      }
      return null
    },
    currentCountry() {
      return this.langs.find(l => l.lang === this.currentLocale).country
    },
  },
  watch: {
    currentLocale: {
      handler(newValue) {
        if (newValue === 'he') {
          document.dir = 'rtl'
          this.$vuetify.rtl = true
        } else {
          document.dir = 'ltr'
          this.$vuetify.rtl = false
        }

        this.$store.dispatch('users/updateProfile', {
          id: this.$store.getters.CurrentUserId,
          locale: newValue,
        })
      },
    },
  },
  mounted() {
    document.dir = this.currentLocale === 'he' ? 'rtl' : 'ltr'
  },
}
</script>
