<template>
  <div>
    <v-row>
      <v-col cols="12" lg="3" md="6">
        <v-card
          class="py-3 px-6 d-flex flex-column justify-space-around"
          height="100%"
        >
          <div class="p-relative">
            <v-icon color="grey lighten-3" large class="rate-icon"
              >$speedometer</v-icon
            >
            <div class="text-h5 font-weight-medium">
              {{ currentStats.avg_overall }}
            </div>
            <div
              class="text-overline font-weight-medium secondary--text text-uppercase"
            >
              {{ $t('Overall score') }}
            </div>
          </div>
          <v-divider class="my-2" />
          <div class="p-relative">
            <v-icon color="grey lighten-3" class="rate-icon" large
              >$rating</v-icon
            >
            <div class="text-h5 font-weight-medium">
              {{ currentStats.reviews_count }}
            </div>
            <div
              class="text-overline font-weight-medium secondary--text text-uppercase"
            >
              {{ $t('Reviews count') }}
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="9">
        <v-row>
          <v-col cols="12" lg="4" sm="6">
            <review-stats-box
              :is-yearly="true"
              min-width="240"
              :year="year"
              :value="currentStats.avg_communication"
              :delta="deltaValues.cleaning"
              :title="$t('Communication')"
              color="#245ECB"
            />
          </v-col>
          <v-col cols="12" lg="4" sm="6">
            <review-stats-box
              :is-yearly="true"
              min-width="240"
              :year="year"
              :value="currentStats.avg_check_in"
              :delta="deltaValues.check_in"
              :title="$t('Check in')"
              color="#B14EC8"
            />
          </v-col>
          <v-col cols="12" lg="4" sm="6">
            <review-stats-box
              :is-yearly="true"
              min-width="240"
              :year="year"
              :value="currentStats.avg_cleaning"
              :delta="deltaValues.cleaning"
              :title="$t('Cleaning')"
              color="#C84E9E"
            />
          </v-col>
          <v-col cols="12" lg="4" sm="6">
            <review-stats-box
              :is-yearly="true"
              min-width="240"
              :year="year"
              :value="currentStats.avg_accuracy"
              :delta="deltaValues.accuracy"
              :title="$t('Accuracy')"
              color="#26A561"
            />
          </v-col>
          <v-col cols="12" lg="4" sm="6">
            <review-stats-box
              :is-yearly="true"
              min-width="240"
              :year="year"
              :value="currentStats.avg_location"
              :delta="deltaValues.location"
              :title="$t('Location')"
              color="#CB9E24"
            />
          </v-col>
          <v-col cols="12" lg="4" sm="6">
            <review-stats-box
              :is-yearly="true"
              min-width="240"
              :year="year"
              :value="currentStats.avg_value"
              :delta="deltaValues.value"
              :title="$t('Value')"
              color="#C84E4E"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card class="pa-3">
          <line-chart
            graph-id="review_trend"
            label="Rate"
            :title="$t('Review Trend')"
            :config="chartsConfig"
            :stats-object="monthlyChartValues"
            color="#02BC77"
          />
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="d-flex">
        <reviews-data-table
          :items="reviews"
          :fetching="fetchingReviews"
          :pagination="pagination"
          :filtered-headers="headers"
          @pagination="paginationChanged"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ReviewsDataTable from 'components/reviews/reviews-data-table'
import LineChart from 'components/line-chart'
import ReviewStatsBox from 'components/reviews/review-stats-box'
import round from 'lodash/round'
import { MONTHS } from '@/consts'
export default {
  name: 'ReviewsPortfolio',
  components: { ReviewStatsBox, LineChart, ReviewsDataTable },
  props: ['year', 'listingIds'],
  data() {
    return {
      reviews: [],
      pagination: {},
      yearlyStats: {},
      fetchingReviews: false,
    }
  },
  computed: {
    headers() {
      if (this.listingIds && this.listingIds.length == 1) {
        return ['created', 'guest', 'channel', 'score']
      } else {
        return ['created', 'listing', 'guest', 'channel', 'score']
      }
    },
    currentStats() {
      return this.yearlyStats[this.year] || {}
    },
    monthlyChartValues() {
      return this.currentStats.reviews_monthly_values
        ? this.currentStats.reviews_monthly_values.map(data => data.avg_overall)
        : []
    },
    lastYearMonthlyChartValues() {
      const prevStats = this.yearlyStats[this.year - 1]
      return prevStats && prevStats.reviews_monthly_values
        ? prevStats.reviews_monthly_values.map(data => data.avg_overall)
        : []
    },
    deltaValues() {
      if (!this.yearlyStats[this.year]) {
        return {}
      }
      const prevStats = this.yearlyStats[this.year - 1]
      if (!prevStats || !this.currentStats.reviews_count) {
        return {}
      }
      return {
        cleaning:
          (round(this.currentStats.avg_cleaning / prevStats.avg_cleaning, 2) -
            1) *
          100,
        check_in:
          (round(this.currentStats.avg_check_in / prevStats.avg_check_in, 2) -
            1) *
          100,
        communication:
          (round(
            this.currentStats.avg_communication / prevStats.avg_communication,
            2
          ) -
            1) *
          100,
        accuracy:
          (round(this.currentStats.avg_accuracy / prevStats.avg_accuracy, 2) -
            1) *
          100,
        location:
          (round(this.currentStats.avg_location / prevStats.avg_location, 2) -
            1) *
          100,
        value:
          (round(this.currentStats.avg_value / prevStats.avg_value, 2) - 1) *
          100,
      }
    },
    months() {
      return MONTHS
    },
    chartsConfig() {
      return {
        data: {
          labels: this.months,
          datasets: [
            {
              data: this.monthlyChartValues,
              borderColor: '#02BC77',
              backgroundColor: '#02BC77',
              fill: false,
              label: this.year,
            },
            {
              data: this.lastYearMonthlyChartValues,
              borderColor: '#ff4757',
              backgroundColor: '#ff4757',
              fill: false,
              label: this.year - 1,
            },
          ],
        },
        options: {
          tooltips: {
            backgroundColor: 'white',
            bodyFontColor: '#707070',
            titleFontColor: '#000000',
            displayColors: false,
          },
          maintainAspectRatio: true,
          legend: {
            display: true,
            position: 'bottom',
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  drawTicks: false,
                },
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: 5,
                  stepSize: 0.5,
                },
              },
            ],
          },
        },
      }
    },
  },
  methods: {
    paginationChanged({ page }) {
      if (!page) {
        return
      }
      this.fetchReviews({ page })
    },
    async fetchStats() {
      if (this.listingIds && this.listingIds.length === 0) {
        this.yearlyStats = {}
      } else {
        this.yearlyStats = await this.$store.dispatch(
          'listings/getListingStatistics',
          this.listingIds
        )
      }
    },
    async fetchReviews({ page = 1 } = {}) {
      if (this.listingIds && this.listingIds.length === 0) {
        return
      }
      this.fetchingReviews = true
      const yearDate = this.$moment.utc(Date.UTC(this.year, 1, 1))
      const startYear = this.$moment(yearDate).startOf('year')
      const endYear = this.$moment(yearDate).endOf('year')
      const { reviews = [], pagination = {} } = await this.$store.dispatch(
        'getReviews',
        {
          filters: {
            listing_ids: this.listingIds,
            investor_view: true,
            start: startYear,
            end: endYear,
            survey: false,
            page,
          },
        }
      )
      this.reviews = reviews
      this.pagination = pagination
      this.fetchingReviews = false
    },
  },
  async mounted() {
    this.fetchReviews()
    this.fetchStats()
  },
  watch: {
    year() {
      this.fetchReviews()
    },
    listingIds() {
      this.fetchReviews()
      this.fetchStats()
    },
  },
}
</script>

<style scoped>
.rate-icon {
  position: absolute !important;
  right: -5px;
  top: -5px;
}
</style>
