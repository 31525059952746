<template>
  <v-tab :href="href">
    <div
      v-if="isMobile"
      color="blue-grey lighten-5"
      style="width: 55px"
      class="text-center my-1 d-flex align-center flex-column"
    >
      <div class="text-body-1 font-weight-medium">{{ value }}</div>
      <div
        class="text-caption"
        style="text-transform: none; line-height: 1.2em"
      >
        {{ label }}
      </div>
    </div>
    <div
      v-else
      color="blue-grey lighten-5"
      class="d-flex flex-column justify-center my-1"
    >
      <div class="font-weight-black text-h6">{{ value }}</div>
      <div class="d-flex text-caption font-weight-medium justify-center">
        <span class="mr-1">{{ label }}</span>
        <info-tooltip v-if="tooltip">{{ tooltip }}</info-tooltip>
      </div>
    </div>
  </v-tab>
</template>

<script>
import InfoTooltip from 'components/common/info-tooltip.vue'
import DeviceMixin from 'components/mixins/device-mixin'

export default {
  props: ['label', 'value', 'href', 'tooltip'],
  mixins: [DeviceMixin],
  components: {
    InfoTooltip,
  },
}
</script>

<style></style>
