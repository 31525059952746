export const CELL_TYPES = {
  CHECKBOX: 'CHECKBOX',
  TEXT_FIELD: 'TEXT_FIELD',
  LINK: 'LINK',
}

export const FILTER_OPTIONS = [
  {
    value: `isCheckin`,
    text: 'Check Ins',
  },
  {
    value: `isCheckout`,
    text: 'Check Outs',
  },
  {
    value: `isStayOver`,
    text: 'Stay Overs',
  },
  {
    value: `cleaningIncomplete`,
    text: 'Cleaning Incomplete',
  },
]

export const HOUSEKEEPING_REPORT_HEADERS = [
  {
    text: 'Room',
    value: 'nickname',
    align: 'left',
  },
  {
    text: 'Check In Time',
    value: 'checkInTime',
  },
  {
    text: 'Check In',
    value: 'isCheckin',
    cellType: CELL_TYPES.CHECKBOX,
  },
  {
    text: 'Check Out Time',
    value: 'checkOutTime',
  },
  {
    text: 'Check Out',
    value: 'isCheckout',
    cellType: CELL_TYPES.CHECKBOX,
  },
  {
    text: 'Stay Over',
    value: 'isStayOver',
    cellType: CELL_TYPES.CHECKBOX,
  },
  {
    text: 'Cleaning Status',
    value: 'cleaningTaskId',
    cleaningTaskStatus: 'cleaningTaskStatus',
    cellType: CELL_TYPES.LINK,
  },
]

export const defaultReport = () => ({
  CELL_TYPES: CELL_TYPES,
  headers: HOUSEKEEPING_REPORT_HEADERS.map(h => {
    return { align: 'center', ...h }
  }),
  moves: '',
  wPatio: '',
  aPatio: '',
  vvPatio: '',
  dirty: false,
  todayCheckouts: '',
  todayCheckins: '',
  todayStayOvers: '',
  todayRoomsMade: '',
  tomorrowCheckins: '',
  tomorrowCheckouts: '',
  tomorrowStayOvers: '',
  tomorrowRoomsMade: '',
})
