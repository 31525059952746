<template>
  <v-row>
    <v-col cols="12" sm="9">
      <v-card elevation="2" class="ma-2" rounded>
        <v-card-title class="d-flex">
          <span class="text-subtitle-1 font-weight-medium">{{
            $t('Today Stats')
          }}</span>
          <span class="mx-3 text-body-2"
            >{{ stats.today_tasks_count }} {{ $t('Tasks') }}</span
          >
          <v-chip v-if="!isMobile" small outlined color="success" label
            >Done: {{ stats.today_tasks_done_count }}</v-chip
          >
          <v-chip
            v-if="!isMobile"
            small
            outlined
            color="tertiary"
            class="mx-3"
            label
            >{{ $t('+3 Days opened') }}:
            {{ stats.today_opened_3days_count }}</v-chip
          >
          <v-chip v-if="!isMobile" small outlined color="info" label>
            {{ $t('Opened today') }}:
            {{ stats.today_opened_tasks_count }}</v-chip
          >
          <v-chip
            v-if="
              !isMobile &&
              stats.home_owner_reservations_upcoming_2d &&
              stats.home_owner_reservations_upcoming_2d.length > 0
            "
            small
            outlined
            color="rgb(250, 12, 52)"
            label
            style="margin-left: 1%"
            @click="showOwnerVacationsDetails"
          >
            {{ $t('Owner vacations in 2 days') }}:
            {{ stats.home_owner_reservations_upcoming_2d.length }}
          </v-chip>
          <v-chip
            v-if="
              !isMobile &&
              (!stats.home_owner_reservations_upcoming_2d ||
                stats.home_owner_reservations_upcoming_2d.length === 0)
            "
            small
            outlined
            color="rgb(250, 12, 52)"
            label
            style="margin-left: 1%"
          >
            {{ $t('Owner vacations in 2 days') }}:
            {{ stats.home_owner_reservations_upcoming_2d.length }}
          </v-chip>
          <div>
            <v-menu
              left
              :value="
                stats.home_owner_reservations_upcoming_2d &&
                stats.home_owner_reservations_upcoming_2d.length > 0 &&
                ownerVacationDetailsShown
              "
              offset-y
              class="white"
              :content-class="`white owner-vacations-container`"
              @input="hideOwnerVacationsDetails"
            >
              <v-sheet class="white" rounded>
                <div class="pa-3">
                  <template>
                    <div
                      v-for="(
                        item, i
                      ) in stats.home_owner_reservations_upcoming_2d || []"
                      :key="i"
                    >
                      <simple-reservation-item
                        :date="$moment().format('YYYY-MM-DD')"
                        :reservation="item"
                        :pm-mode="true"
                        :pop-up="true"
                      />
                      <v-divider />
                    </div>
                  </template>
                </div>
              </v-sheet>
            </v-menu>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row>
            <v-col cols="6" sm="6">
              <today-stats-gauges :stats="stats" />
            </v-col>
            <v-divider vertical />

            <v-col
              cols="6"
              md="3"
              class="d-flex font-weight-medium black--text flex-column pt-0"
            >
              <div class="text-subtitle-1 font-weight-medium">
                {{ $t('Arrival & Departures') }}
              </div>
              <div
                class="d-flex list-checkin-btn justify-space-between align-center pa-2"
                @click="goToReportFilter('listings', 'checkinToday')"
              >
                <span>
                  <v-icon x-small left color="warning lighten-1"
                    >fas fa-square</v-icon
                  >
                  {{ $t('In') }}
                </span>
                <span class="pl-4">{{
                  vacancyCounts['Check-in today'] +
                  vacancyCounts['Check-in and Checkout today']
                }}</span>
              </div>
              <div
                class="d-flex list-checkin-btn justify-space-between align-center pa-2"
                @click="goToReportFilter('listings', 'checkoutToday')"
              >
                <span>
                  <v-icon x-small left color="warning lighten-1"
                    >fas fa-square</v-icon
                  >
                  {{ $t('Out') }}
                </span>
                <span>{{
                  vacancyCounts['Checkout today'] +
                  vacancyCounts['Check-in and Checkout today']
                }}</span>
              </div>
              <div
                class="d-flex list-checkin-btn justify-space-between align-center pa-2"
                @click="goToReportFilter('listings', 'checkinAnOutToday')"
              >
                <span>
                  <v-icon x-small left color="warning lighten-1"
                    >fas fa-square</v-icon
                  >
                  {{ $t('In&Out') }}
                </span>
                <span>{{ vacancyCounts['Check-in and Checkout today'] }}</span>
              </div>
              <div
                class="d-flex list-checkin-btn justify-space-between align-center pa-2"
                @click="goToReportFilter('listings', 'freeToday')"
              >
                <span>
                  <v-icon x-small left color="warning lighten-1"
                    >fas fa-square</v-icon
                  >
                  {{ $t('Free') }}
                </span>
                <span>{{ vacancyCounts['Free today'] }}</span>
              </div>
              <div
                class="d-flex list-checkin-btn justify-space-between align-center pa-2"
                @click="goToReportFilter('listings', 'occupiedToday')"
              >
                <span>
                  <v-icon x-small left color="warning lighten-1"
                    >fas fa-square</v-icon
                  >
                  {{ $t('Occupied') }}
                </span>
                <span class="pl-4">{{ vacancyCounts['Occupied'] }}</span>
              </div>
            </v-col>
            <v-col
              v-if="!isMobileOrIPad"
              cols="6"
              sm="3"
              class="font-weight-medium black--text flex-column pt-0"
            >
              <div class="text-subtitle-1 font-weight-medium d-flex">
                <div style="flex: 4">{{ $t('Pending Reviews') }}</div>
                <v-icon style="flex: 1" color="success" small
                  >mdi-thumb-up</v-icon
                >
                <v-icon style="flex: 1" color="error" small
                  >mdi-thumb-down</v-icon
                >
              </div>
              <today-report-row
                :label="$t('Checkups')"
                :positive="reviewCounts.checkup.pos"
                :negative="reviewCounts.checkup.neg"
                @click="goToReportFilter('pending-reviews', 'checkup')"
              />
              <today-report-row
                :label="$t('Survey')"
                :positive="reviewCounts.survey.pos"
                :negative="reviewCounts.survey.neg"
                @click="goToReportFilter('pending-reviews', 'survey')"
              />
              <today-report-row
                :label="$t('OTA')"
                :positive="reviewCounts.ota.pos"
                :negative="reviewCounts.ota.neg"
                @click="goToReportFilter('pending-reviews', 'ota')"
              />
              <div
                class="d-flex list-checkin-btn justify-space-between align-center py-2"
                @click="goToReportFilter('service-calls', 'review-tasks')"
              >
                <span style="flex: 4">
                  <v-icon x-small left color="warning lighten-1"
                    >fas fa-square</v-icon
                  >
                  {{ $t('Reviews Tasks') }}
                </span>
                <span style="flex: 2" class="text-center">{{
                  reviewCounts.reviewTasks
                }}</span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col v-if="!isMobile" cols="12" sm="3" class="d-flex">
      <v-card
        elevation="2"
        class="ma-2 d-flex flex-column"
        rounded
        width="100%"
      >
        <v-card-title class="d-flex align-center">
          <span class="text-subtitle-1 font-weight-medium">Health</span>
          <v-spacer />
          <health-badge v-if="!isMobile" :factors="factors" />
          <badge
            icon="$exclamation_triangle"
            :content="alertsSize"
            :hide-badge="alertsSize === 0"
            @change="openAlerts"
          />
        </v-card-title>
        <v-divider />
        <v-card-text class="d-flex align-center justify-center flex">
          <v-progress-circular
            :size="180"
            :rotate="-90"
            color="info lighten-1"
            width="25"
            :value="toPercent(stats.region_stats.health * 100, 0)"
          >
            <span class="text-h5 font-weight-bold black--text">{{
              toPercent(stats.region_stats.health * 100, 0)
            }}</span>
          </v-progress-circular>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import TodayStatsGauges from 'components/listing-report/today-stats-gauges'
import DeviceMixin from 'components/mixins/device-mixin'
import formattersMixin from 'components/mixins/formatters-mixin'
import HealthBadge from 'components/listing-report/health-badge'
import Badge from 'components/common/badge.vue'
import size from 'lodash/fp/size'
import get from 'lodash/fp/get'
import TodayReportRow from 'components/listing-report/today-report-row.vue'
import SimpleReservationItem from 'components/reservation/simple-reservation-item.vue'

export default {
  name: 'ReportTodayStats',
  mixins: [DeviceMixin, formattersMixin],
  components: {
    Badge,
    HealthBadge,
    TodayStatsGauges,
    TodayReportRow,
    SimpleReservationItem,
  },
  data() {
    return {
      alertsOn: false,
      rateIcons: {
        '10.0': 'mdi-emoticon',
        '8.0': 'mdi-emoticon-happy',
        '6.0': 'mdi-emoticon-neutral',
        '4.0': 'mdi-emoticon-sad',
        '2.0': 'mdi-emoticon-dead',
      },
      rateColor: {
        '10.0': '#57e32c',
        '8.0': '#b7dd29',
        '6.0': '#ffe234',
        '4.0': '#ffa534',
        '2.0': '#ff4545',
      },
      ownerVacationDetailsShown: false,
    }
  },
  props: {
    stats: {
      type: Object,
      required: true,
    },
    vacancyCounts: {
      type: Object,
      required: true,
    },
    reviewCounts: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goToReportFilter(section, filter) {
      this.addToQuery({ section, filter })
    },
    openAlerts(val) {
      if (val) {
        this.addToQuery({ alerts: '1' })
      } else {
        const { alerts: _a, ...query } = this.$route.query
        this.$router.push({ query }).catch(() => {})
      }
    },
    showOwnerVacationsDetails() {
      this.ownerVacationDetailsShown = true
    },
    hideOwnerVacationsDetails() {
      this.ownerVacationDetailsShown = false
    },
  },
  computed: {
    alertsSize() {
      return size(get('region_stats.alerts', this.stats))
    },
    factors() {
      return Object.entries(this.stats.region_stats.factors).map(
        ([key, data]) => {
          const formattedKey = key.replace(/_/g, ' ')
          return [formattedKey, (data.score * 100) / data.weight, data.text]
        }
      )
    },
  },
}
</script>

<style scoped>
.sliders {
  z-index: 1;
}
.list-checkin-btn {
  cursor: pointer;
}
.list-checkin-btn:hover {
  background-color: #f5f5f5;
}
.list-item-auto {
  min-height: auto;
}
.owner-vacations-container {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 8 !important;
  max-height: 55% !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  border-radius: 36px !important;
}
</style>
