<template>
  <div class="ma-0 pb-2">
    <v-list
      elevation="3"
      three-line
      max-width="600"
      class="mt-3 center-text"
      style="margin: auto"
    >
      <contractor-select
        v-if="managementRoles"
        class="ma-5 mb-0"
        :value.sync="contractorId"
        :filter-change="contractorChange"
      />
      <v-row no-gutters>
        <v-col cols="12" class="d-flex px-5 align-center py-1 fc-direction-ltr">
          <v-btn icon @click="onDateNavigation(-1)">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span
            class="font-weight-medium text-subtitle-1 text-center flex primary--text"
            >{{ parseDate(day, 'ddd, MMM Do', { local: true }) }}</span
          >
          <v-btn icon @click="onDateNavigation(1)">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
        <stat-card
          :x-small="true"
          label="Done"
          :value="`${doneTasks} / ${listingTasks.length}`"
        />
        <stat-card :x-small="true" label="Listing" :value="listingsCount" />
        <stat-card :x-small="true" label="Hours" :value="reportedHours" />
      </v-row>
      <div class="task-list">
        <today-task
          v-for="task in orderedTasks"
          :key="task.id"
          :task="task"
          :disabled.sync="tooltip"
        />
      </div>
      <v-col class="mt-4">
        <span
          v-if="isEmpty(orderedTasks)"
          class="center-text grey-text headline-font"
          >{{ $t('No tasks found') }}</span
        >
      </v-col>
    </v-list>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import TodayTask from 'components/todays-tasks/today-task'
import ContractorSelect from 'components/contractor-select'
import orderBy from 'lodash/orderBy'
import StatCard from 'components/common/stat-card.vue'
import uniq from 'lodash/fp/uniq'
import { round } from 'lodash'
import sumBy from 'lodash/fp/sumBy'

export default {
  components: {
    StatCard,
    TodayTask,
    ContractorSelect,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  data() {
    return {
      contractorId: null,
      day: this.$moment(),
      tooltip: false,
    }
  },
  computed: {
    ...mapState(['listingTasks', 'listingLoading']),
    orderedTasks() {
      return this.mapTasks(this.listingTasks)
    },
    listingsCount() {
      return uniq(this.listingTasks.map(t => t.listing_id)).length
    },
    reportedHours() {
      return round(sumBy('total_hours', this.listingTasks), 1)
    },
    doneTasks() {
      return this.listingTasks.filter(
        t => t.status === 'Done' || t.status === "Can't do"
      ).length
    },
  },
  mounted() {
    this.contractorId = this.getMyUserId
    this.getTasks()
  },
  methods: {
    ...mapActions(['getListingTasks']),
    onDateNavigation(change) {
      if (change > 0) {
        this.day.add(1, 'day')
      } else {
        this.day.subtract(1, 'day')
      }
      this.getTasks()
    },
    getTasks() {
      this.getListingTasks({
        filters: {
          date: this.day.format('YYYY-MM-DD'),
          contractor_id: this.contractorId,
          list_view: true,
          status: ['New', 'In Progress', 'Done', 'Rejected'],
        },
      })
    },
    contractorChange(value) {
      this.contractorId = value
      this.getTasks()
    },
    mapTasks(tasks) {
      const allTasks = tasks.map(t => {
        const hour = this.$moment.utc(t.scheduled_at).hours()
        return { ...t, allDay: hour < 4 || hour > 22 }
      })
      return orderBy(allTasks, ['allDay', 'scheduled_at'], 'asc')
    },
  },
}
</script>
<style scoped>
.task-list {
  max-height: calc(100vh - 340px);
  overflow-y: auto;
}
</style>
