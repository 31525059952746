<template>
  <v-container>
    <v-card class="overflow-y-auto">
      <v-card-title class="text-h6 text-uppercase d-flex justify-space-between">
        <div class="text-capitalize">
          <v-icon
            small
            class="mr-1"
            left
            :color="isActive ? 'success' : 'error'"
            >fas fa-circle</v-icon
          >{{ integrationName }}
        </div>
      </v-card-title>
      <v-form ref="form" @submit.prevent="submit">
        <v-card-text>
          <div v-for="(field, i) in fields" :key="i">
            <v-text-field
              v-model="field.value"
              :label="field.key"
              :disabled="!isActive"
              :type="field.key.includes('secret') ? 'password' : 'text'"
              dense
              outlined
            />
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <confirmation-modal
            v-if="isActive"
            :text="`Are you sure you want to disconnect ${integrationName} ?`"
            @action="disconnect"
          >
            <template #activator="{ on }">
              <v-btn :loading="creating" color="error" class="mx-2" v-on="on"
                >Disconnect</v-btn
              >
            </template>
          </confirmation-modal>
          <v-btn
            v-else
            :loading="creating"
            color="primary"
            @click="createIntegration"
            >Connect</v-btn
          >
          <v-btn v-if="isActive" type="submit" color="primary">Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import MarketplaceMixin from 'components/marketplace/marketplace-mixin'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'

export default {
  name: 'KeyBaseIntegration',
  components: { ConfirmationModal },
  mixins: [MarketplaceMixin],
}
</script>

<style scoped></style>
