<template>
  <div>
    <div class="w-full d-flex justify-space-between mr-6 ml-4 mb-6">
      <div class="d-flex">
        <v-btn
          small
          class="mr-2"
          color="success"
          :disabled="saveDisabled"
          depressed
          @click="save"
        >
          <v-icon small class="mr-2"> mdi-content-save </v-icon>Save
        </v-btn>
        <v-btn
          small
          color="secondary"
          outlined
          :disabled="saveDisabled"
          @click="cancel"
        >
          Cancel</v-btn
        >
      </div>
    </div>
    <v-progress-linear
      v-show="loading"
      color="primary"
      class="ma-0"
      :indeterminate="true"
    />
    <v-sheet>
      <v-row class="ml-4 mb-4">
        <v-col cols="12" class="text-h5 font-weight-bold"
          >Default Check In/Out Times</v-col
        >
        <v-col cols="2">
          <v-text-field
            ref="check_in_time"
            v-model="times.check_in_time"
            label="Check-in"
            type="time"
            :placeholder="'16:00'"
            outlined
            dense
            @focus="showPicker"
          />
        </v-col>
        <v-col cols="2">
          <v-text-field
            ref="check_out_time"
            v-model="times.check_out_time"
            label="Check Out"
            type="time"
            :placeholder="'11:00'"
            outlined
            dense
            @focus="showPicker"
          />
        </v-col>
      </v-row>
      <v-row class="ml-4 mb-4">
        <v-col cols="12" class="text-h5 font-weight-bold">Payments</v-col>
        <v-col cols="6">
          <v-checkbox
            v-model="info.accept_portal_payments"
            class="mt-0 pt-0 mr-10"
            :disabled="!hasStripeAccount"
            label="Accept payments via portal (Stripe)"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row class="ml-4">
        <v-col cols="12" class="text-h5 font-weight-bold">Checkin Info</v-col>
        <v-col cols="12">
          <v-checkbox
            v-model="info.hide_hotel_units"
            class="mt-0 pt-0 mr-10"
            label="Hide hotel room numbers"
            hide-details
          />
          <v-checkbox
            v-model="info.require_signature"
            class="mr-10"
            label="Require Signature"
            hide-details
          />
          <div class="d-flex">
            <v-checkbox
              v-model="info.require_all_guest_details"
              class="mr-10"
              hide-details
              @change="onChangeRequireAllGuestDetails"
            >
              <template #label>
                <div>
                  Require guest details
                  <info-tooltip
                    >Require Name, email & phone number for all guests
                  </info-tooltip>
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-if="info.require_all_guest_details"
              v-model="info.require_guest_passport"
              class="mr-10"
              hide-details
            >
              <template #label>
                <div>
                  Require guests passport numbers
                  <info-tooltip
                    >Additionally require passport numbers for all guests
                  </info-tooltip>
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-if="info.require_all_guest_details"
              v-model="info.require_guest_address"
              class="mr-10"
              hide-details
            >
              <template #label>
                <div>
                  Require guests addresses
                  <info-tooltip
                    >Additionally require addresses for all guests
                  </info-tooltip>
                </div>
              </template>
            </v-checkbox>
          </div>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="info.offer_parking"
            class="mt-0 pt-0 mr-10"
            label="Offer Parking"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="ms-8 mt-4">
          <v-text-field
            v-model="info.travel_guide_url"
            dense
            hide-details
            outlined
            rows="4"
            label="Travel Guide URL"
          />
        </v-col>
      </v-row>
      <v-row class="ml-4">
        <v-col cols="12" class="text-h5 font-weight-bold">VIP membership</v-col>
        <v-col cols="6">
          <v-checkbox
            v-model="info.show_membership"
            class="mt-0 pt-0 mb-8 mr-10"
            label="Show membership option"
            hide-details
          />
          <v-text-field
            v-if="info.show_membership"
            v-model="info.membership_title"
            dense
            outlined
            rows="4"
            label="Custom membership title"
          />
          <v-textarea
            v-if="info.show_membership"
            v-model="info.membership_description"
            dense
            outlined
            rows="4"
            label="Custom membership description"
          />
        </v-col>
      </v-row>
      <v-row class="ml-4">
        <v-col cols="12" class="text-h5 font-weight-bold">Terms of Use</v-col>
        <v-col cols="6">
          <text-editor
            v-model="info.terms_of_use"
            autofocus
            :show-save-button="false"
          />
        </v-col>
      </v-row>
      <v-row class="ml-4 mt-8">
        <v-col
          cols="12"
          class="text-h5 font-weight-bold d-flex justify-space-between"
        >
          <div>Additional Docs</div>
          <div>
            <v-btn class="white--text" color="blue" depressed @click="addDoc">
              <v-icon class="mr-2"> mdi-plus </v-icon>Add Document
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12">
          <v-row class="font-weight-bold">
            <v-col cols="2" class="align-self-end">Type</v-col>
            <v-col cols="2" class="align-self-end">Title</v-col>
            <v-col cols="5" class="align-self-end">Description</v-col>
            <v-col class="align-self-end text-center">Foreign Only</v-col>
            <v-col cols="2" class="align-self-end">Regions</v-col>
          </v-row>
          <div v-for="(doc, i) in docs" :key="doc.id">
            <v-divider />
            <v-row class="d-flex align-center">
              <v-col cols="2" class="p-relative">
                <v-btn icon class="mr-2 absolute-btn" @click="deleteDoc(i)">
                  <v-icon small color="secondary">mdi-delete</v-icon>
                </v-btn>
                <v-select
                  v-model="doc.type"
                  hide-details
                  outlined
                  label="Document Type"
                  dense
                  :items="getItems(doc.type)"
                  @change="updateField(i, 'type', $event)"
                />
              </v-col>
              <v-col cols="2">
                <editable-text-field
                  placeholder="Title"
                  :value="doc.title"
                  @onupdate="updateField(i, 'title', $event)"
              /></v-col>
              <v-col cols="5">
                <editable-text-field
                  placeholder="Description"
                  :value="doc.description"
                  type="textarea"
                  @onupdate="updateField(i, 'description', $event)"
              /></v-col>
              <v-col class="d-flex justify-center">
                <v-checkbox
                  v-model="doc.foreign_only"
                  class="pt-0 mt-0"
                  hide-details
                  @change="updateField(i, 'foreign_only', $event)"
              /></v-col>
              <v-col cols="2">
                <filters-select
                  :label="$t('Regions')"
                  :items="listingRegions"
                  :value="doc.regions"
                  :selection-func="item => item.text"
                  @change="updateField(i, 'regions', $event)"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/fp/isEqual'
import EditableTextField from '@/components/form-fields/editable-text-field.vue'
import FiltersSelect from 'components/form-fields/filters-select'
import TextEditor from 'components/common/text-editor'
import InfoTooltip from 'components/common/info-tooltip.vue'
import { mapState } from 'vuex'

export default {
  components: {
    EditableTextField,
    FiltersSelect,
    TextEditor,
    InfoTooltip,
  },
  props: ['checkinInfo', 'loading'],
  data() {
    return {
      saveDisabled: true,
      info: cloneDeep(this.checkinInfo) || {},
      docs: cloneDeep(this.checkinInfo.additional_docs || []),
      times: cloneDeep(
        this.checkinInfo.times || {
          check_in_time: '16:00',
          check_out_time: '11:00',
        }
      ),
    }
  },
  watch: {
    info: {
      handler() {
        this.saveDisabled =
          isEqual(this.info, this.checkinInfo) &&
          isEqual(this.times, this.checkinInfo.times)
      },
      deep: true,
    },
  },
  mounted() {
    if (this.info.show_membership === undefined) {
      this.info.show_membership = true
    }
  },
  computed: {
    ...mapState('regions', ['regions']),
    ...mapState('stripe', ['accounts']),
    hasStripeAccount() {
      return this.accounts.length > 0
    },
    listingRegions() {
      return this.regions.map(r => ({ text: r.name, value: r.id }))
    },
  },
  methods: {
    getItems(myType) {
      const items = ['B2 slip', 'Generic']
      const existingTypes = this.docs.map(d => d.type)
      return items.filter(
        i => [myType, 'Generic'].includes(i) || !existingTypes.includes(i)
      )
    },
    updateField(i, key, val) {
      this.docs[i][key] = val
      this.saveDisabled = false
    },
    addDoc() {
      const newDoc = {
        id: Math.floor(Math.random() * 100000) + 1,
        type: 'Generic',
        description: null,
        title: '',
        foreign_only: false,
        regions: [],
      }
      this.docs = [...this.docs, newDoc]
    },
    deleteDoc(i) {
      this.docs.splice(i, 1)
      this.saveDisabled = false
    },
    onChangeRequireAllGuestDetails(val) {
      if (!val) {
        this.info.require_guest_passport = false
        this.info.require_guest_address = false
      }
    },
    async save() {
      this.info.additional_docs = this.docs
      this.info.times = this.times
      this.$emit('save', { checkin_info: this.info })
    },
    cancel() {
      this.info = cloneDeep(this.checkinInfo)
      this.docs = cloneDeep(this.checkinInfo.additional_docs || [])
    },
    showPicker(event) {
      this.saveDisabled = false
      event.target.showPicker()
    },
  },
}
</script>

<style>
.absolute-btn {
  position: absolute;
  left: -25px;
}
</style>
