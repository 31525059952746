<template>
  <v-card>
    <conversation-info-title title="Issues" />
    <v-divider />
    <v-card-text>
      <v-col cols="12">
        <listing-task-module
          v-if="!loading && !listingLoading"
          :pre-filled-listing-id="[reservation.listing_id]"
          class="mt-2 mb-2"
          :reservation="reservation"
          :after-save-func="afterTaskCreate"
        />
      </v-col>
      <v-col xs12 class="bolder bb-1 center-text" mt-1 mb-1>
        {{
          isOutStanding ? 'Outstanding issues' : 'Service calls during the stay'
        }}
      </v-col>
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th class="text-left"></th>
              <th class="text-left">Scheduled</th>
              <th class="text-left">Priority</th>
              <th class="text-left">Status</th>
              <th class="text-left">Desc</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="issue in issues || []"
              :key="issue.id"
              class="small-text bb-1"
            >
              <td>
                <CopyClipboard
                  description="Link"
                  :text="`https://guest.easyair.io/tourist-lt/${issue.public_id}`"
                  >Copy Link</CopyClipboard
                >
                <router-link
                  target="_blank"
                  :to="`/dashboard/listing-task/${issue.id}`"
                >
                  Sc
                </router-link>
                <v-tooltip
                  v-if="
                    issue.extra_data &&
                    issue.extra_data.created_on_guest &&
                    issue.extra_data.created_on_guest.id ===
                      conversation.guest.id
                  "
                  top
                >
                  <template #activator="{ on }">
                    <v-icon small class="red-warn" v-on="on">warning </v-icon>
                  </template>
                  <v-row class="white--text mt-2" wrap style="min-width: 200px">
                    <v-col cols="12">** Reported by guest **</v-col>
                    <v-col cols="12">{{ issue.reported_by_name }}</v-col>
                  </v-row>
                </v-tooltip>
              </td>
              <td class="center-text br-1">
                {{ parseDateWithTimeIfPresent(issue.scheduled_at) }} <br />
                <span
                  v-if="timeNotSet(issue.scheduled_at)"
                  class="small-text red-warn"
                  >Time not set</span
                >
              </td>
              <td class="br-1">
                {{ issue.priority }}
              </td>
              <td class="br-1" :class="statusColor(issue.status)">
                {{ issue.status }}
              </td>
              <td>
                <v-row class="center-text justify-center">
                  {{ issue.task_type }} - {{ issue.sub_topic }}
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-icon small class="icon ml-1 alt help-icon" v-on="on"
                        >fas fa-question-circle</v-icon
                      ></template
                    >
                    <v-row wrap style="min-width: 100px">
                      <v-col cols="12">
                        {{ issue.task_type }} -> {{ issue.sub_topic }} ->
                        {{ issue.listing_task_catalog_item_name }}
                      </v-col>

                      <v-col cols="12">
                        {{ issue.description }}
                      </v-col>
                      <v-col v-if="issue.internal_comments" cols="12">
                        Internal notes:
                      </v-col>
                      <v-col v-if="issue.internal_comments" cols="12">
                        {{ issue.internal_comments }}
                      </v-col>

                      <br />

                      {{ issue.description }}
                    </v-row>
                  </v-tooltip>
                </v-row>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import CopyClipboard from 'components/copy-clipboard'
import ConversationInfoTitle from 'components/chat/conversation-info-title'
import ListingTaskModule from '../listing-task-module'

export default {
  components: {
    ListingTaskModule,
    ConversationInfoTitle,
    CopyClipboard,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['conversation', 'reservation', 'issuesField'],
  data: () => ({
    reportedByComm: false,
    issuesPri: ['Critical', 'High'],
  }),
  computed: {
    issues() {
      return this.conversation[this.issuesField || 'issues']
    },
    isOutStanding() {
      return this.issuesField == 'outstanding_issues'
    },
  },
  methods: {
    afterTaskCreate() {
      setTimeout(() => {
        this.$store.dispatch('conversationUpdate', { id: this.conversation.id })
      }, 500)
    },
    statusColor(status) {
      if (status === 'New') {
        return 'red-health'
      } else if (status === 'In Progress') {
        return 'yellow-health'
      } else {
        return 'green-health'
      }
    },
  },
}
</script>
