<template>
  <div class="pa-3 pt-0 d-flex flex-grow-1 flex-column p-relative">
    <v-icon
      v-if="drawerMode"
      class="p-absolute pointer"
      :style="{ top: '5px', right: '12px', position: 'absolute !important' }"
      @click="closeDrawer"
      >fas fa-times</v-icon
    >
    <chat-agents
      @view-active="
        $emit('view-assignee-conversations', {
          assignee: $event.id,
          is_done: $event.is_done,
        })
      "
    />
    <v-sheet
      color="grey lighten-4"
      class="d-flex justify-space-between align-center pa-4"
    >
      <div class="d-flex align-center">
        <conversation-assignee
          :after-change="refetchConversations"
          :pre-selected="userById(conversation.assigned_user_id)"
          :conversation="conversation"
        />
        <copy-clipboard color="secondary" :text="conversationLink" />
        <v-spacer></v-spacer>
        <span class="small-text ml-4"> Fun level: </span>
        <v-rating
          length="5"
          size="18"
          :disabled="conversation.fun_level"
          :value="conversation.fun_level"
          @input="setFunLevel($event, conversation.id)"
        >
        </v-rating>
        <v-tooltip v-if="conversation.allow_courtesy_early_check_in" bottom>
          <template #activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <Tag bg-color="yellow" color="black" class="pa-1">
                Courtesy CI
              </Tag>
            </div>
          </template>
          <span
            >Guest is allowed to get in at 15:00 (even a bit earlier if he is
            already there) for free, please use saved message</span
          >
        </v-tooltip>
        <v-tooltip v-if="highRisk" bottom>
          <template #activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <Tag bg-color="red" color="white" class="pa-1">
                Screening on
              </Tag>
            </div>
          </template>
          <span>High risk score please do some screaning</span>
        </v-tooltip>
      </div>
      <div class="d-flex align-center">
        <v-icon
          v-if="conversation.escalated"
          style="margin-right: 15px; color: var(--v-warning-base) !important"
        >
          mdi-bell
        </v-icon>
        <confirmation-modal
          v-if="isCommunicationAgent && conversation.assigned_user_id"
          :text="`Please confirm manager assistance is needed?`"
          @action="moveToManager"
        >
          <template #activator="{ on }">
            <v-btn
              height="32"
              class="mr-2 super-light-red"
              elevation="0"
              x-small
              v-on="on"
            >
              <v-icon small> supervisor_account</v-icon>
            </v-btn>
          </template>
        </confirmation-modal>
        <v-chip v-if="integrationLabel" class="mr-2" label>
          {{ integrationLabel }}
        </v-chip>

        <v-btn
          v-if="conversation.direct_chat_link"
          height="32"
          class="mr-2"
          elevation="0"
          x-small
          color="white"
          :href="conversation.direct_chat_link"
          target="_blank"
        >
          <v-icon small>public</v-icon>
        </v-btn>
        <sale-btn
          :conversation-id="conversation.id"
          :reservation-id="activeReservation.id"
          :reservation-sellers="activeReservation.reservation_sellers"
        />
        <v-btn
          color="white"
          class="pa-0"
          height="32"
          elevation="0"
          x-small
          @click="showTags"
        >
          <v-icon :color="showTagPop ? 'info' : 'secondary'" small>
            mdi-tag-outline
          </v-icon>
        </v-btn>
        <follow-up-modal
          v-if="managementRoles || isCommunicationPerson"
          :conversation="conversation"
          :mini-button="true"
          @on-action="onFollowUpModalAction"
        />
        <v-btn-toggle v-model="toggleBtn" class="ml-2" borderless>
          <v-btn
            color="white"
            value="notes"
            height="32"
            elevation="0"
            x-small
            :disabled="notes.length === 0"
          >
            <v-badge :content="notes.length" :value="notes.length">
              <v-icon color="secondary" small> $file</v-icon>
            </v-badge>
          </v-btn>
        </v-btn-toggle>
      </div>
    </v-sheet>
    <div
      v-if="conversation.thread_plus_dvr_log"
      ref="msgscroll"
      class="scrollable-dvr-partial flex"
    >
      <div
        v-for="(msg, index) in messages"
        :key="`msg-${index}`"
        class="d-flex"
      >
        <div
          v-if="msg.generated_by_ai"
          style="display: flex; margin-left: auto"
        >
          <div
            v-if="!conversationLoading"
            class="float-right ai-component align-baseline"
            style="margin-top: 20px"
          >
            <div class="center-text pb-3 bb-1 full-width">
              <v-icon class="mr-2">$guest_crm</v-icon> Ai-Agent
            </div>
            <div class="ai-component-content">
              <div
                v-if="
                  !aiSuggestionMessage(msg) &&
                  (!aiSuggestionActions(msg) ||
                    aiSuggestionActions(msg).length === 0)
                "
                class="ai-component-message mt-3"
              >
                <div class="small-text">
                  <strong>No AI Suggestion Found</strong>
                </div>
              </div>
              <div
                v-if="aiSuggestionMessage(msg)"
                class="ai-component-message mt-3"
              >
                <span>
                  <span class="cyan--text">
                    <v-icon class="mr-2 cyan--text">$chat</v-icon>
                  </span>
                  <strong>Message</strong>
                </span>
                <p>
                  {{ aiSuggestionMessage(msg) }}
                </p>
              </div>
              <div
                v-for="co_pilot_action in aiSuggestionActions(msg)"
                :key="co_pilot_action.action_name"
                class="ai-component-action"
              >
                <div>
                  <span class="cyan--text">
                    <v-icon class="mr-2">{{
                      co_pilot_action.description == 'Service Call'
                        ? '$maintenance'
                        : '$alarm'
                    }}</v-icon>
                  </span>
                  <strong>
                    Create {{ co_pilot_action.description }}
                    <span v-if="co_pilot_action.payload.human_time" class="ml-1"
                      >({{ co_pilot_action.payload.human_time }})</span
                    >
                  </strong>
                </div>
                <div>
                  {{
                    co_pilot_action.payload &&
                    co_pilot_action.payload.description
                  }}
                </div>
              </div>
            </div>
            <div
              v-if="
                aiSuggestionMessage(msg) ||
                (aiSuggestionActions(msg) && aiSuggestionActions(msg).length)
              "
              class="ai-component-buttons"
            >
              <v-btn
                :disabled="msg.ai_status === 'approved'"
                class="ai-component-reject-btn"
                @click="rejectAiSuggestion(msg)"
              >
                {{ msg.ai_status === 'rejected' ? 'Rejected' : 'Reject' }}
              </v-btn>
              <v-btn
                :disabled="msg.ai_status === 'rejected'"
                class="ai-component-approve-btn"
                @click="approveAiSuggestion(msg)"
              >
                {{ msg.ai_status === 'approved' ? 'Approved' : 'Approve' }}
              </v-btn>
            </div>
          </div>
        </div>
        <img v-else-if="msg.msg_type === 'img'" :src="msg.body" class="img" />
        <video
          v-else-if="msg.msg_type === 'video'"
          :max-width="isMobile ? '85%' : '60%'"
          autoplay
          controls
          height="500"
          :src="msg.body"
        />
        <msg-box
          v-else-if="msg.msg_type !== 'log'"
          :msg="msg"
          :conversation="conversation"
          :collapsable="
            msg.by === 'host' &&
            !msg.rep_name &&
            msg.body &&
            msg.body.length > 50
          "
        />
        <log-line v-else :msg="msg" />
      </div>
    </div>
    <conversation-tag-pop v-if="showTagPop" />
  </div>
</template>
<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import get from 'lodash/fp/get'
import axios from 'axios'
import CopyClipboard from 'components/copy-clipboard'
import MsgBox from 'components/msg-box'
import FollowUpModal from 'components/conversations/follow-up-modal'
import ConversationAssignee from './chat/conversation-assignee'
import LogLine from 'components/chat/log-line'
import Tag from 'components/common/tag'
import ConversationTagPop from 'components/conversation-tag-pop'
import { mapGetters, mapMutations, mapState } from 'vuex'
import ChatAgents from 'components/chat-agents'
import orderBy from 'lodash/fp/orderBy'
import filter from 'lodash/fp/filter'
import compose from 'lodash/fp/compose'
import SaleBtn from 'components/crm/sale-btn'
import ConfirmationModal from 'components/modals/confirmation-modal'
import deviceMixin from 'components/mixins/device-mixin'

const extractNotes = compose(
  orderBy(['sent_at'], ['desc']),
  filter(i => i.module === 'note')
)
export default {
  components: {
    ConfirmationModal,
    SaleBtn,
    ChatAgents,
    ConversationTagPop,
    Tag,
    ConversationAssignee,
    FollowUpModal,
    MsgBox,
    CopyClipboard,
    LogLine,
  },
  mixins: [CommonFunctions, PermissionsMixin, deviceMixin],
  props: ['conversation', 'getConversations', 'drawerMode'],
  computed: {
    ...mapState(['showTagPop']),
    ...mapGetters(['chosenChatReservation']),
    activeReservation() {
      return get('active_reservation', this.conversation) || {}
    },
    conversationLink() {
      return `${location.origin}/dashboard/sales-chat/${this.conversation.guesty_id}`
    },
    integrationLabel() {
      return this.chosenChatReservation.integration &&
        this.chosenChatReservation.integration.platform === 'airbnb2'
        ? this.chosenChatReservation.integration.nickname
        : ''
    },
    highRisk() {
      return (
        this.chosenChatReservation.status === 'inquiry' &&
        this.chosenChatReservation.risk_analysis &&
        this.chosenChatReservation.risk_analysis.risk_score > 0.8
      )
    },
    conversationId() {
      return get('id', this.conversation)
    },
    linkRegex() {
      return /((https?|ftps?):\/\/[^"'<(,)>\s]+)(?![^<>]*>|[^"]*?<\/a)/gim
    },
    notes() {
      return extractNotes(this.conversation.thread_plus_dvr_log)
    },
    notesOn() {
      return this.toggleBtn === 'notes'
    },
    messages() {
      if (this.notesOn) {
        return this.notes
      }
      return this.conversation.thread_plus_dvr_log
    },
    threadLength() {
      if (this.conversation.thread_plus_dvr_log) {
        return this.conversation.thread_plus_dvr_log.length
      }
      return 0
    },
    isSellerAssigned() {
      return this.reservationSellers.length
    },
  },
  mounted() {
    this.reservationSellers = this.activeReservation.reservation_sellers || []
    this.scrollToEnd()
    setTimeout(() => {
      this.scrollToEnd()
    }, 250)
  },
  data() {
    return {
      toggleBtn: null,
      isTagPop: false,
      reservationSellers: [],
    }
  },
  methods: {
    ...mapMutations(['updateShowTagPop']),
    closeDrawer() {
      this.$router.push({ query: {} })
    },
    refetchConversations() {
      this.getConversations({ isRefresh: true })
    },
    async setFunLevel(value, id) {
      await this.$store.dispatch('conversationUpdate', {
        fun_level: value,
        id: id,
      })
      this.$emit('update-conversations')
    },
    switchNewLine(message) {
      if (message) {
        return message
          .replace(/(?:\r\n|\r|\n)/g, '<br>')
          .replace(
            this.linkRegex,
            '<a style="word-break: break-all" href="$1" target="_blank">$1</a>'
          )
      }
    },
    messageClass(msg) {
      if (msg.msg_type === 'log') {
        return 'full-width'
      } else if (msg.by === 'guest') {
        return 'guest-msg'
      } else if (msg.by !== 'guest' && msg.module === 'note') {
        return 'note-msg'
      } else if (!msg.rep_name && msg.by !== 'guest') {
        return 'staff-msg'
      } else {
        return 'staff-msg'
      }
    },
    scrollToEnd() {
      var content = this.$refs.msgscroll
      if (content) {
        content.scrollTop = content.scrollHeight
      }
    },
    showTags() {
      this.updateShowTagPop(true)
    },
    moveToManager() {
      this.$store.dispatch('conversationUpdate', {
        id: this.conversation.id,
        move_to_manager: true,
      })
    },
    onFollowUpModalAction() {
      this.$store.commit('updateConversationsPagination', null)
      this.$emit('update-conversations')
      if (this.getConversations) {
        this.getConversations()
      }
    },
    async rejectAiSuggestion(msg) {
      if (msg.ai_status) {
        return
      }
      await axios.post(`/api/conversations/${msg.id}/reject-ai-suggestion`)
      await this.$store.dispatch('conversationUpdate', {
        id: this.conversation.id,
      })
    },
    async approveAiSuggestion(msg) {
      if (msg.ai_status) {
        return
      }

      await axios.post(`/api/conversations/${msg.id}/approve-ai-suggestion`)
      await this.$store.dispatch('conversationUpdate', {
        id: this.conversation.id,
      })
      this.$emit('approve-ai-suggestion', this.aiSuggestionMessage(msg))
      if (this.aiSuggestionActions(msg)) {
        const serviceCall = this.aiSuggestionActions(msg).find(
          e => e.action_name === 'create_service_call'
        )
        if (serviceCall) {
          this.$emit('create-service-call', serviceCall.payload)
        }
      }
    },
    aiSuggestionMessage(msg) {
      return JSON.parse(msg.body).message
    },
    aiSuggestionActions(msg) {
      return JSON.parse(msg.body).actions.filter(
        e =>
          e.action_name === 'create_service_call' ||
          e.action_name === 'create_follow_up_reminder'
      )
    },
  },
  watch: {
    conversationId() {
      this.toggleBtn = null
      this.reservationSellers = this.activeReservation.reservation_sellers || []
    },
    threadLength() {
      setTimeout(() => {
        this.scrollToEnd()
      }, 100)
    },
    notesOn(val) {
      if (!val) {
        setTimeout(() => {
          this.scrollToEnd()
        }, 100)
      }
    },
  },
}
</script>
<style scoped>
.img {
  width: 50%;
  border-radius: 5%;
  margin-top: 5px;
}
.ai-component {
  width: 500px;
  margin-left: auto;
  margin-right: 24px;
  border: 1px solid lightgrey;
  border-radius: 11px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ai-component-content {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ai-component-reject-btn {
  margin: 5px;
  background-color: var(--v-warning-base) !important;
  color: white;
}
.ai-component-approve-btn {
  margin: 5px;
  background-color: var(--v-success-base) !important;
  color: white;
}
.ai-component-message {
  flex-direction: column;
  align-items: center;
}
.ai-component-action {
  flex-direction: column;
  margin-top: 10px;
}
.ai-component-buttons {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 30px;
}
</style>
