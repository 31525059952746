<template>
  <div>
    <v-text-field
      v-show="!showFull"
      label="Add comment.."
      outlined
      dense
      @click="addComment"
    />
    <quill-editor
      v-show="showFull"
      ref="myQuillEditor"
      :key="key"
      :content="content"
      :options="editorOption"
      @change="onEditorChange($event)"
    />
    <div v-if="showSaveButton" class="py-2 text-end">
      <v-btn :disabled="!content" small color="info" @click="sendMsg"
        >Send
      </v-btn>
    </div>
  </div>
</template>

<script>
import { getQuillDefaultOptions } from '@/utils/editor-options'

export default {
  name: 'TextEditor',
  props: {
    mentions: {
      type: Array,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    showToolbar: {
      type: Boolean,
      default: true,
    },
    showSaveButton: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      content: this.value,
      key: 'initial',
      showFull: false,
      editorOption: getQuillDefaultOptions(this.mentions, '', {
        showToolbar: this.showToolbar,
      }),
    }
  },
  watch: {
    mentions(value) {
      this.key = `initial${Math.random()}`
      this.editorOption = getQuillDefaultOptions(value, '', {
        showToolbar: this.showToolbar,
      })
    },
  },
  mounted() {
    if (this.autofocus) {
      this.showFull = true
      this.editorFocus()
    }
  },
  methods: {
    addComment() {
      this.showFull = true
    },
    onEditorChange({ html }) {
      this.content = html
      this.$emit('input', html)
    },
    sendMsg() {
      this.$emit('text-submit', this.content)
      this.content = ''
    },
    editorFocus() {
      setTimeout(() => this.$refs.myQuillEditor.quill.focus(), 0)
    },
  },
}
</script>

<style scoped>
>>> .ql-mention-list-container {
  z-index: 9999;
  top: 30px !important;
  background-color: white;
  box-shadow: 0 3px 1px -2px rgba(0 0 0 0.2), 0 2px 2px 0 rgba(0 0 0 0.14),
    0 1px 5px 0 rgba(0 0 0 0.12);
}

>>> .v-list-item.v-list-item--link.selected {
  background-color: var(--v-info-lighten4) !important;
}

>>> .ql-container {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: auto;
}

>>> .ql-editor {
  min-height: 150px;
  max-height: 300px;
  height: auto;
  overflow: auto;
}

>>> .ql-toolbar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

>>> .mention {
  color: var(--v-info-base) !important;
}
</style>
