<template>
  <v-navigation-drawer
    temporary
    stateless
    right
    app
    :width="1400"
    style="top: 64px"
    :value="Boolean(conversationId)"
  >
    <div v-if="conversationId" v-click-outside="onClickOutside">
      <v-progress-linear v-show="conversationLoading" indeterminate />
      <div class="d-flex flex">
        <v-sheet
          v-if="conversation"
          min-width="400"
          class="flex-grow-1 d-flex flex-column"
        >
          <div v-for="issue in conversation.outstanding_issues" :key="issue.id">
            <div
              class="w-100 amber lighten-4 font-weight-medium py-2 px-4 font-weight-medium text-caption"
              style="color: #a34904"
            >
              <span class="font-weight-medium"
                >{{ issue.task_type }} <v-icon small>mdi-chevron-right</v-icon>
                {{ issue.sub_topic }}:</span
              ><a
                class="ml-2 text-decoration-underline"
                style="color: #a34904"
                target="_blank"
                :href="`dashboard/listing-task/${issue.id}`"
                >{{ issue.description }}
              </a>
            </div>
            <v-divider class="grey lighten-2" />
          </div>
          <conversation-messages
            v-if="conversation && !conversationLoading"
            :conversation="conversation"
            :drawer-mode="true"
            @update-conversations="updateConversations"
            @create-service-call="openServiceCallModal"
            @approve-ai-suggestion="approveAiSuggestion"
          />
          <v-divider />
          <conversation-chat-box
            v-if="conversation"
            ref="conversationChatBox"
            :mode="mode"
            @create-service-call="openServiceCallModal"
            @update-conversation-fun-level="updateConversationFunLevel"
          />
        </v-sheet>
        <v-sheet class="d-flex flex-column">
          <conversation-info
            v-if="conversation"
            :key="conversation.id"
            :fetching="conversationLoading"
            :conversation="conversation"
          />
        </v-sheet>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ConversationChatBox from 'components/conversation-chat-box.vue'
import ConversationMessages from 'components/conversation-messages'
import ConversationInfo from 'components/conversation-info.vue'

export default {
  props: ['mode'],
  components: { ConversationChatBox, ConversationMessages, ConversationInfo },
  mounted() {
    if (this.conversationId) {
      this.getConversation(this.conversationId)
    }
  },
  computed: {
    ...mapState(['conversationLoading']),
    conversationId() {
      return this.$route.query.conversation
    },
    messages() {
      if (this.notesOn) {
        return this.notes
      }
      return this.conversation && this.conversation.thread_plus_dvr_log
    },
    conversation() {
      return this.$store.getters.currentConversation
    },
  },
  methods: {
    ...mapActions(['getChatConversation', 'setServiceCallModalOpened']),
    updateConversations() {
      this.$emit('update-conversations')
    },
    getConversation(conversationId) {
      this.getChatConversation(conversationId)
    },
    onClickOutside(e) {
      if (e.target.className === 'v-overlay__scrim') {
        this.$router.push({ query: {} })
      }
    },
    scrollToEnd() {
      var content = this.$refs.msgscroll
      if (content) {
        content.scrollTop = content.scrollHeight
      }
    },
    openServiceCallModal(payload) {
      this.setServiceCallModalOpened({ opened: true, payload: payload })
    },
    updateConversationFunLevel() {
      this.$store.dispatch('conversationUpdate', { id: this.conversation.id })
    },
    approveAiSuggestion(message_body) {
      this.$refs.conversationChatBox.updateMessage(message_body)
    },
  },
  watch: {
    conversationId(value) {
      if (value) {
        this.getConversation(value)
      }
    },
    messages() {
      setTimeout(() => {
        this.scrollToEnd()
      }, 100)
    },
  },
}
</script>
