<template>
  <v-autocomplete
    :label="$t(label || 'Current listing')"
    :value.sync="value"
    :items="listings"
    :placeholder="placeHolder"
    autocomplete="off"
    :search-input.sync="search"
    item-text="nickname"
    item-value="id"
    rtl
    :filter="filterFunction"
    :hide-details="hideDetails !== false"
    :outlined="outlined !== false"
    :dense="dense !== false"
    :clearable="clearable === true"
    :multiple="allowMultiple"
    @change="onListingChange"
  >
    <template #selection="{ item }">
      <v-chip class="pl-2" small>
        <v-avatar left>
          <v-img :src="item.image || item.picture"></v-img>
        </v-avatar>
        {{ item.stage === 'setup' ? 'Setup - ' : ''
        }}{{ truncateBy(item.nickname, isMobile ? '15' : '30') }}
      </v-chip>
      <v-chip
        v-if="item.is_multi_unit"
        label
        class="mx-2"
        x-small
        color="cyan lighten-4"
        >{{ $t('Multi') }}
      </v-chip>
      <v-chip
        v-if="item.listing_type === 'hotel'"
        label
        class="mx-2"
        x-small
        color="purple lighten-4"
        >{{ $t('Complex') }}
      </v-chip>
    </template>
    <template #item="{ item }" class="listing-picker">
      <div class="d-flex align-start mb-3">
        <v-avatar tile rounded>
          <img
            class="rounded"
            :src="item.image || item.picture || placeholderImg"
          />
        </v-avatar>
        <div class="pl-2">
          <div class="d-flex align-center">
            <div class="font-weight-medium">
              {{ item.nickname }}
            </div>
            <v-chip
              v-if="item.listed"
              label
              class="mx-2"
              x-small
              color="green lighten-3"
              >{{ $t('Listed') }}
            </v-chip>
            <v-chip
              v-if="!item.active"
              label
              class="mx-2"
              x-small
              color="grey lighten-2"
              >{{ $t('Disabled') }}
            </v-chip>
            <v-chip
              v-if="item.second_guesty_id"
              label
              class="mx-2"
              x-small
              color="blue lighten-4"
              >{{ $t('Dup') }}
            </v-chip>
            <v-chip
              v-if="item.is_multi_unit"
              label
              class="mx-2"
              x-small
              color="cyan lighten-4"
              >{{ $t('Multi') }}
            </v-chip>
            <v-chip
              v-if="item.listing_type === 'hotel'"
              label
              class="mx-2"
              x-small
              color="purple lighten-4"
              >{{ $t('Complex') }}
            </v-chip>
            <v-chip
              v-if="item.stage === 'setup'"
              label
              class="mx-2"
              x-small
              color="orange lighten-4"
              >{{ $t('Setup') }}
            </v-chip>
          </div>
          <div class="text-caption">{{ item.title }}</div>
          <div class="text-caption">
            <span>{{ item.beds }}</span>
            <v-icon class="mx-1" small>$bedroom</v-icon>
            <span class="text-subtitle-1">·</span>
            <!--            <v-icon>mdi-circle-small</v-icon>-->
            <span class="mx-1">{{ item.baths }}</span>
            <v-icon small>$bath</v-icon>
          </div>
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from './mixins/device-mixin'
import placeholderImg from '@/assets/images/home.png'

export default {
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  props: [
    'afterChange',
    'notTopHeader',
    'value',
    'outlined',
    'hideDetails',
    'dense',
    'clearable',
    'label',
    'prependListings',
    'listingChangeAction',
    'allowMultiple',
    'showDups',
  ],
  data() {
    return {
      search: null,
      placeholderImg,
    }
  },
  computed: {
    placeHolder() {
      return this.$route.fullPath.includes('/property/') &&
        this.$store.state.currentListing
        ? this.$store.state.currentListing.nickname
        : 'Choose a listing'
    },
    listings() {
      let result = [
        ...(this.prependListings || []),
        ...this.$store.state.listingsIndex,
      ]
      if (!this.showDups) {
        result = result.filter(l => !l.second_guesty_id)
      }
      return result
    },
  },
  methods: {
    filterFunction(item, queryText, itemText) {
      return (
        item.nickname?.toLowerCase().indexOf(queryText.toLowerCase()) > -1 ||
        item.title?.toLowerCase().indexOf(queryText.toLowerCase()) > -1
      )
    },
    onListingChange(id) {
      if (isNaN(id) && !Array.isArray(id)) {
        return
      }
      if (this.listingChangeAction) return this.listingChangeAction(id)
      if (id && !this.afterChange) {
        this.$store.commit('updateLoading', true)
        if (this.isInvestor) {
          this.$router.push(`/dashboard/property/${id}`)
        } else {
          this.$router.push(`/dashboard/edit/${id}/expenses`)
        }
      } else if (id && this.afterChange) {
        this.afterChange(id)
      } else if (!id) {
        this.afterChange(null)
      }
    },
  },
}
</script>
