<template>
  <div class="p-relative">
    <v-sheet
      v-if="!isMobile && feedOpenId"
      elevation="2"
      style="width: 50%; height: 100%; overflow-y: hidden"
      class="setup-feed"
    >
      <v-scroll-x-reverse-transition>
        <LtFeed
          v-show="feedOpenId"
          class="d-flex flex-column"
          :users="userToMention"
          :logs="currentSetup ? currentSetup.log : []"
          :unseen="unseenComments"
          :title="currentSetup && currentSetup.listing.address"
          :comments-list="commentsList"
          @add-message="addFeedComment"
        />
      </v-scroll-x-reverse-transition>
    </v-sheet>
    <v-dialog :value="isMobile && feedOpenId" fullscreen>
      <v-slide-x-transition>
        <LtFeed
          class="d-flex flex-column"
          :logs="currentSetup ? currentSetup.log : []"
          :users="userToMention"
          :unseen="unseenComments"
          :comments-list="commentsList"
          :title="currentSetup && currentSetup.listing.address"
          @add-message="addFeedComment"
        />
      </v-slide-x-transition>
    </v-dialog>
    <v-tabs v-model="paramsTab" background-color="grey lighten-4">
      <v-tab href="#projects">
        <v-icon class="mr-md-2">$list</v-icon>
        <span class="d-none d-md-inline text-capitalize">{{
          $t('Projects')
        }}</span>
      </v-tab>
      <v-tab href="#templates">
        <v-icon class="mr-md-2">mdi-sitemap-outline</v-icon>
        {{ $t('Templates Flows') }}
      </v-tab>
      <v-tab v-if="isDesignedVr" href="#list">
        <v-icon class="mr-md-2">mdi-format-list-text</v-icon>
        {{ $t('ESTIMATES') }}
      </v-tab>
      <v-tab v-if="isDesignedVr" href="#builder">
        <v-icon class="mr-md-2">mdi-format-list-checks</v-icon>
        {{ $t('BUILDER') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="paramsTab" touchless>
      <v-tab-item id="templates" class="pl-2 pr-2" eager>
        <listing-task-templates />
      </v-tab-item>
      <v-tab-item id="projects">
        <v-card :loading="listingLoading">
          <v-card-title class="font-weight-regular">
            <v-row align="center">
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="searchTerm"
                  label="Search by name, listing, tag"
                  prepend-inner-icon="search"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" sm="6" lg="3">
                <v-select
                  v-model="status"
                  :label="$t('Status')"
                  :items="statuses"
                  outlined
                  dense
                  hide-details
                  @change="statusChange"
                />
              </v-col>
              <v-col cols="12" sm="6" lg="3">
                <v-select
                  v-model="progressStatus"
                  label="Progress status"
                  :items="progressStatuses"
                  outlined
                  clearable
                  multiple
                  dense
                  hide-details
                />
              </v-col>
              <!--              <v-col cols="12" sm="6" lg="3">-->
              <!--                <v-select-->
              <!--                  v-model="activeScope"-->
              <!--                  clearable-->
              <!--                  label="Active scope"-->
              <!--                  :items="scopeList"-->
              <!--                  outlined-->
              <!--                  dense-->
              <!--                  hide-details-->
              <!--                />-->
              <!--              </v-col>-->
              <v-col cols="12" sm="4" md="2">
                <setup-listing-modal v-if="!loading" />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :options.sync="pagination"
            :headers="headers"
            :loading="listingLoading"
            :items="items"
          >
            <template #item="{ item }">
              <listing-setup-row
                :key="item.id"
                :after-save="change"
                :item="item"
                @open-units-edit-dialog="openUnitsEditDialog"
              />
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item value="list" class="full">
        <estimates-table v-if="paramsTab === 'list'" />
      </v-tab-item>
      <v-tab-item value="builder" class="full">
        <estimates-builder v-if="paramsTab === 'builder'" />
      </v-tab-item>
    </v-tabs-items>
    <v-dialog
      v-if="setupToEdit.listing_info"
      v-model="setupUnitsEditDialog"
      max-width="800"
      @click:outside="closeUnitsEditDialog"
    >
      <v-card>
        <v-card-title> Edit setup units</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="saveEditedSetup()">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="setupToEdit.listing_info.beds"
                  type="number"
                  label="Beds"
                  prepend-inner-icon="$bed_doubled"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="setupToEdit.listing_info.baths"
                  type="number"
                  label="Baths"
                  prepend-inner-icon="$shower"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox
                  v-model="setupToEdit.listing_info.has_pool"
                  class="mt-0"
                  label="Pool"
                  prepend-inner-icon="mdi-swim"
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox
                  v-model="setupToEdit.listing_info.has_garden"
                  class="mt-0"
                  label="Garden"
                  prepend-inner-icon="mdi-swim"
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="6" sm="4">
                <v-checkbox
                  v-model="setupToEdit.listing_info.has_jacuzzi"
                  class="mt-0"
                  label="Jacuzzi"
                  prepend-inner-icon="mdi-swim"
                  dense
                  hide-details
                />
              </v-col>
            </v-row>
            <div class="d-flex">
              <v-spacer />
              <v-btn
                color="secondary"
                class="text-capitalize"
                elevation="0"
                text
                @click="closeUnitsEditDialog"
              >
                Close
              </v-btn>
              <v-btn
                type="submit"
                color="primary"
                class="text-capitalize"
                elevation="0"
              >
                Save
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import ListingSetupRow from 'components/listing-setup-row.vue'
import SetupListingModal from 'components/setup-listing-modal.vue'
import { orderBy, toNumber } from 'lodash/fp'
import { cloneDeep, filter, uniq } from 'lodash'
import LtFeed from 'components/listing-tasks/lt-feed'
import EstimatesTable from './estimates/estimates-table'
import EstimatesBuilder from './estimates/estimates-builder'
import ListingTaskTemplates from 'components/listing-tasks/listing-task-templates.vue'

export default {
  components: {
    ListingTaskTemplates,
    EstimatesBuilder,
    EstimatesTable,
    LtFeed,
    ListingSetupRow,
    SetupListingModal,
  },
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  beforeRouteUpdate(to, from, next) {
    if (to.query.feed_id) {
      this.$store.dispatch('seenSetupComments', to.query.feed_id)
    }
    next()
  },
  data: function () {
    return {
      statuses: ['All', 'Not done', 'Done'],
      progressStatuses: [
        { text: 'Paused', value: true },
        { text: 'Unpaused', value: false },
      ],
      investorManageStatus: [true],
      searchTerm: '',
      expense: null,
      status: 'Not done',
      progressStatus: [false],
      removePaused: true,
      menu: false,
      pagination: {
        itemsPerPage: 100,
      },
      menu2: false,
      activePm: null,
      activeDesigner: null,
      activeCsm: null,
      activeScope: null,
      setupUnitsEditDialog: false,
      setupToEdit: {},
    }
  },
  methods: {
    ...mapActions(['getListingsSetups', 'updateListingSetup']),
    addFeedComment({ html, parent_id }) {
      this.$store.dispatch('addSetupComment', {
        id: this.feedOpenId,
        html,
        parent_id,
      })
    },
    getPersonalByType(type) {
      let personl = []
      this.items.forEach(setup => {
        if (setup.listing && setup.listing.personal[type]) {
          const user = this.userById(setup.listing.personal[type])
          if (user) {
            personl.push(user)
          }
        }
      })
      return uniq(personl)
    },
    passFilters(setup) {
      if (
        this.activePm &&
        setup.listing.personal.project_manager !== this.activePm.id
      ) {
        return false
      }
      if (
        this.activeCsm &&
        setup.listing.personal.customer_success !== this.activeCsm.id
      ) {
        return false
      }
      if (
        this.activeDesigner &&
        setup.listing.personal.designer !== this.activeDesigner.id
      ) {
        return false
      }
      if (this.activeScope) {
        if (!setup.scope) {
          return false
        }
        const scope = setup.scope.find(u => u.value === this.activeScope)
        if (!scope || !this.isActiveScope(scope)) {
          return false
        }
      }
      if (this.progressStatus.length > 0) {
        if (!this.progressStatus.includes(setup.pause)) {
          return false
        }
      }
      if (this.investorManageStatus.length > 0) {
        if (!this.investorManageStatus.includes(setup.mananged_by_dvr)) {
          return false
        }
      }
      if (
        this.searchTerm &&
        !setup.listing_address
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase())
      ) {
        return false
      }
      return true
    },
    change() {
      this.getListingsSetups({ status: this.status })
    },
    assigneeChange(user, type) {
      this[type] = user
    },
    statusChange(newStatus) {
      this.status = newStatus
      this.getListingsSetups({ status: newStatus })
    },
    openUnitsEditDialog(setupId) {
      this.setupToEdit = cloneDeep(
        this.listingSetups.find(setup => setup.id === setupId)
      )
      this.setupUnitsEditDialog = true
    },
    closeUnitsEditDialog() {
      this.setupUnitsEditDialog = false
    },
    saveEditedSetup() {
      this.updateListingSetup(this.setupToEdit)
      this.closeUnitsEditDialog()
    },
  },
  computed: {
    ...mapState(['listingSetups']),
    ...mapGetters(['currentUserId']),
    ...mapGetters('users', ['activeUsers']),
    userToMention() {
      return this.activeUsers.filter(this.isManagementRolesUser)
    },
    scopeList() {
      return this.$store.state.scopeItems
    },
    feedOpenId() {
      return this.$route.query.feed_id
        ? toNumber(this.$route.query.feed_id)
        : null
    },
    currentSetup() {
      return this.listingSetups.find(s => s.id === this.feedOpenId)
    },
    commentsList() {
      if (this.currentSetup) {
        return orderBy(
          ['created_at'],
          ['desc'],
          this.currentSetup.listing_setup_comments.filter(c => !c.parent_id)
        )
      }
      return []
    },
    unseenComments() {
      return this.commentsList
        .filter(c => !c.seen.includes(this.currentUserId))
        .map(c => c.id)
    },
    headers() {
      return this.isMobileOrIPad
        ? [
            { text: 'Details', sortable: false },
            { text: 'Launch Date', sortable: false },
            { text: 'Project Scope', sortable: false },
            { text: 'Start Date', sortable: false },
            { text: 'End Date', sortable: false },
            { text: '', value: 'actions', sortable: false },
          ]
        : [
            { text: 'Details', sortable: false },
            { text: 'Priority', sortable: false },
            { text: 'Launch Date', sortable: false },
            { text: 'Project Scope', sortable: false },
            { text: 'Start Date', sortable: false },
            { text: 'End Date', sortable: false },
            { text: 'Service Calls', sortable: false },
            { text: '', value: 'actions', sortable: false },
          ]
    },
    items() {
      return filter(this.listingSetups, item => this.passFilters(item))
    },
  },
  watch: {
    '$route.query'(_query, from) {
      if (from.feed_id) {
        this.$store.dispatch('seenSetupComments', from.feed_id)
      }
    },
  },
  mounted() {
    this.getListingsSetups({ status: this.status })
    if (this.$route.query.feed_id) {
      this.$store.dispatch('seenSetupComments', this.$route.query.feed_id)
    }
  },
}
</script>
<style>
.setup-feed {
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 200;
}
</style>
