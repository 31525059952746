<template>
  <menu-page v-if="currentListing && currentListing.id" :menu-items="menuItems">
    <v-container fluid class="py-0 grey lighten-4">
      <v-btn class="mb-2 px-0" text :to="{ name: 'Listings' }" :ripple="false">
        <v-icon>mdi-chevron-left</v-icon>
        <span class="font-weight-light text-capitalize">
          {{ $t('Return to listings') }}
        </span>
      </v-btn>
      <listing-info-panel
        class="mb-6"
        :image="currentListing.picture"
        :uploadable="
          !currentListing.picture && isEmpty(currentListing.pictures)
        "
        :pricing-link="addressPricing(currentListing.pricing_id)"
        :guesty-id="currentListing.guesty_id"
        :duplicates="currentListing.duplicates"
        :title="!isDemoUser ? currentListing.address : currentListing.city_name"
        :nickname="currentListing.nickname"
        :link="`/dashboard/property/${listingId}`"
        :link-show="isAdmin || isAccountant"
        :listed="currentListing.listed"
        :master-guesty-id="currentListing.master_guesty_id"
        :listing="currentListing"
        link-title="View"
        :is-mgmt="true"
      />
      <v-progress-linear
        v-show="listingLoading"
        color="secondary"
        style="margin: 0"
        :indeterminate="true"
      />
    </v-container>
    <v-tabs-items v-model="paramsTab" touchless>
      <v-tab-item
        v-if="
          isAdmin || isPropertyManager || isAccountant || isCommunicationManager
        "
        value="expenses"
      >
        <v-container>
          <expense-modal
            :related-to-setup="currentListing.stage === 'setup'"
            title="Create Property Expense"
            :fixed-listing-id="currentListing.id"
          />
          <expenses
            class="mt-4"
            update-method="updateListingLoading"
            :show-filters="true"
            :management-view="true"
            refetch-method="changeEditListing"
            :listing="currentListing"
          />
        </v-container>
      </v-tab-item>
      <v-tab-item
        v-if="isAdmin || isPropertyManager || isAccountant"
        value="payments"
      >
        <v-container>
          <payment-modal
            title="Create Client Payment"
            type="ClientPayment"
            class="mb-4"
          />
          <payments v-if="currentListing" :listing="currentListing" />
        </v-container>
      </v-tab-item>
      <v-tab-item
        v-if="isAdmin || isPropertyManager || isAccountant"
        value="incomes"
      >
        <v-container>
          <listing-income-modal title="Create Listing Income" />
          <listing-incomes :listing="currentListing" class="mt-4" />
        </v-container>
      </v-tab-item>
      <v-tab-item
        v-if="isAdmin || isPropertyManager || isAccountant"
        value="reservations"
      >
        <v-container>
          <reservation-manager :listing-id="listingId" />
        </v-container>
      </v-tab-item>

      <v-tab-item
        v-if="isAdmin || isAccountant"
        value="accounting"
        class="pa-4 main-background"
      >
        <v-container>
          <bank-account class="mb-4" />
          <bank-transactions :listing="currentListing" />
        </v-container>
      </v-tab-item>

      <v-tab-item
        v-if="managementRoles || isCommunicationAgent"
        value="configuration"
      >
        <v-container>
          <listing-edit class="my-3" />
        </v-container>
      </v-tab-item>
      <v-tab-item value="pnl">
        <v-container class="py-6">
          <pnl :listing="currentListing" />
        </v-container>
      </v-tab-item>
      <v-tab-item
        v-if="managementRoles || isCommunicationAgent"
        value="services"
      >
        <v-container>
          <services obj-name="currentEditListing" />
          <v-card class="my-3">
            <v-card-text>
              <v-container grid-list-md>
                <v-flex>
                  <h3 class="purple-icon">Garbage settings</h3>
                  <v-flex>
                    <v-layout wrap>
                      <v-flex xs4>
                        <v-select
                          v-model="newNotice.type"
                          :items="[
                            'General',
                            'Recycled',
                            'General and Recycled',
                            'Bulk',
                          ]"
                          label="Garbage Type"
                        ></v-select>
                      </v-flex>
                      <v-flex xs4>
                        <v-text-field
                          v-model="newNotice.location"
                          label="Garbage Location"
                          hint="Front/Back etc.."
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs4>
                        <v-select
                          v-model="newNotice.day"
                          :items="[
                            'Sunday',
                            'Monday',
                            'Tuesday',
                            'Wednesday',
                            'Thursday',
                            'Friday',
                            'Saturday',
                          ]"
                          label="Day of week"
                        ></v-select>
                      </v-flex>
                      <v-btn
                        v-if="
                          newNotice.day && newNotice.location && newNotice.type
                        "
                        class="cyan-icon"
                        outlined
                        style="max-width: 70px"
                        text
                        @click="saveNotice"
                      >
                        Send
                      </v-btn>
                    </v-layout>
                    <v-btn
                      v-if="!isEmpty(currentListing.garbage_config)"
                      class="red-icon"
                      outlined
                      text
                      @click="deleteNotices"
                    >
                      Clear all settings
                    </v-btn>
                  </v-flex>
                  <v-layout wrap class="comp-wrapper center-text">
                    <v-flex xs4>
                      <h4 class="purple-icon">Day</h4>
                      <br />
                    </v-flex>
                    <v-flex xs4>
                      <h4 class="purple-icon">Garbage Location</h4>
                      <br />
                    </v-flex>
                    <v-flex xs4>
                      <h4 class="purple-icon">Garbage Type</h4>
                      <br />
                    </v-flex>
                  </v-layout>
                  <v-layout column class="comp-wrapper center-text">
                    <v-layout
                      v-for="(val, key) in currentListing.garbage_config"
                      :key="key"
                      wrap
                      class="bb-1"
                    >
                      <v-flex xs4>
                        {{ key }}
                      </v-flex>
                      <v-flex xs4>
                        {{ val['location'] }}
                      </v-flex>
                      <v-flex xs4>
                        {{ val['type'] }}
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                  </v-layout>
                </v-flex>
              </v-container>
            </v-card-text>
          </v-card>
        </v-container>
      </v-tab-item>
      <v-tab-item
        v-if="
          isAdmin ||
          isPropertyManager ||
          isCommunicationManager ||
          isCleaningSupervisor ||
          isCommunicationAgent
        "
        value="calendar"
      >
        <v-container>
          <reservation-calendar
            v-if="$store.state.currentListing.days_rates"
            class="comp-wrapper"
            :add-owner-vacation="isCommunicationManager || isAdmin"
            listing-type="currentEditListing"
            :class="currentListing.listing_type"
          />
          <hotel-table
            v-if="currentListing.listing_type === 'hotel'"
            view-path="/dashboard/edit/"
          />
        </v-container>
      </v-tab-item>
      <v-tab-item value="tasks-cal">
        <v-container>
          <tasks-calendar :listing-id="listingId" :no-scroll="true" />
        </v-container>
      </v-tab-item>
      <v-tab-item value="personal">
        <v-container
          v-if="
            isAdmin ||
            isPropertyManager ||
            isFieldPm ||
            hasAbility('edit-personal')
          "
        >
          <Personal
            :on-change="updatePersonal"
            :personal="currentListing.personal"
            :override-personal-types="[
              'cleaning_supervisor',
              'pool',
              'pest',
              'landscape',
              'host',
              'region_manager',
            ]"
          />
        </v-container>
      </v-tab-item>
      <v-tab-item value="images">
        <v-container>
          <listing-gallery :listing="currentListing" />
        </v-container>
      </v-tab-item>
      <v-tab-item value="listing-channel-general">
        <v-container>
          <listing-channel-general :listing="currentListing" />
        </v-container>
      </v-tab-item>

      <v-tab-item value="warranties">
        <v-container>
          <warranties :warranties="listingWarranties" />
        </v-container>
      </v-tab-item>
      <v-tab-item value="amenities">
        <v-container>
          <amenities-edit
            v-model="currentListing.amenities"
            :is-channel-manager="currentListing.has_ru_channel_listing"
          />
        </v-container>
      </v-tab-item>
      <v-tab-item value="commissions">
        <v-container>
          <listing-commissions :listing="currentListing" />
        </v-container>
      </v-tab-item>
      <v-tab-item value="listing-pricing">
        <v-container>
          <v-row>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="currentListing.pricing_factors.weekly_discount"
                label="Weekly Discount"
                outlined
                append-icon="mdi-percent-outline"
                type="number"
                dense
                hide-details
                @change="savePricing"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="currentListing.pricing_factors.monthly_discount"
                label="Monthly Discount"
                outlined
                append-icon="mdi-percent-outline"
                dense
                type="number"
                hide-details
                @change="savePricing"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="currentListing.pricing_factors.advanced_notice"
                label="Advanced Notice (days)"
                outlined
                type="number"
                dense
                hide-details
                @change="savePricing"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="currentListing.pricing_factors.preparation_time"
                label="Preperation Time (days)"
                outlined
                dense
                type="number"
                hide-details
                @change="savePricing"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="currentListing.pricing_factors.booking_window"
                label="Booking Window (days)"
                outlined
                dense
                type="number"
                hide-details
                @change="savePricing"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="currentListing.pricing_factors.minNights"
                label="Min Nights"
                outlined
                dense
                type="number"
                hide-details
                @change="savePricing"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="currentListing.pricing_factors.maxNights"
                label="Max Nights"
                outlined
                dense
                type="number"
                hide-details
                @change="savePricing"
              />
            </v-col>
          </v-row>
          <listing-manage-calendar v-if="isUnit" />
          <multi-calendar v-if="!isUnit" :listing-id="listingId" />
        </v-container>
      </v-tab-item>
      <v-tab-item value="listing-channel-availability">
        <v-container>
          <listing-channel-availability :listing="currentListing" />
        </v-container>
      </v-tab-item>
      <v-tab-item value="listing-channel-finance">
        <v-container>
          <listing-channel-finance :listing="currentListing" />
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </menu-page>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Expenses from 'components/expenses/expenses.vue'
import ExpenseModal from 'components/expenses/expense-modal.vue'
import ListingEdit from 'components/listing-edit.vue'
import Payments from 'components/payments.vue'
import PaymentModal from 'components/payment-modal.vue'
import BankAccount from 'components/bank-account.vue'
import ListingManageCalendar from 'components/listing/listing-manage-calendar.vue'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import Services from 'components/services'
import hotelTable from 'components/hotel-table'
import BankTransactions from 'components/bank-transactions'
import axios from 'axios'
import ReservationManager from 'components/reservation-manager'
import ListingIncomeModal from 'components/listing-income-modal'
import ListingIncomes from 'components/listing-incomes'
import ListingInfoPanel from 'components/listing/listing-info-panel'
import ReservationCalendar from 'components/calendar/reservation-calendar'
import DeviceMixin from 'components/mixins/device-mixin'
import MenuPage from 'components/common/menu-page.vue'
import Pnl from 'components/pnl.vue'
import Warranties from 'components/warranties.vue'
import ListingGallery from 'components/listing-gallery.vue'
import TasksCalendar from 'components/calendar/tasks-calendar.vue'
import Personal from 'components/listing/personal.vue'
import AmenitiesEdit from 'components/listing/amenities-edit.vue'
import ListingCommissions from 'components/listing/listing-commissions.vue'
import ListingChannelGeneral from 'components/listing/listing-channel-general.vue'
import ListingChannelAvailability from 'components/listing/listing-channel-availability.vue'
import ListingChannelFinance from 'components/listing/listing-channel-finance.vue'
import MultiCalendar from 'components/calendar/multi-calendar'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin, FormattersMixin],
  data() {
    return {
      newNotice: {},
    }
  },
  methods: {
    ...mapActions({
      getWarranties: 'warranty/getWarranties',
      getStripeAccounts: 'stripe/getStripeAccounts',
    }),
    addressPricing(id) {
      return id ? { path: `/dashboard/pricing/${id}` } : null
    },
    updatePersonal(personal) {
      this.$store.dispatch('updateListingPartial', {
        id: this.currentEditListing.id,
        personal,
      })
    },
    saveNotice() {
      axios
        .post(`/api/listings/${this.listingId}/garbage-setting`, {
          day: this.newNotice.day,
          location: this.newNotice.location,
          type: this.newNotice.type,
        })
        .then(result => {
          this.currentListing.garbage_config = result.data.payload.data
          this.newNotice = {}
        })
        .catch(error => {
          alert(error)
        })
    },
    async savePricing() {
      this.$store.commit('updateLoading', true)
      const response = await this.$store.dispatch('listings/updateListing', {
        id: this.currentListing.id,
        payload: { pricing_factors: this.currentListing.pricing_factors },
      })
      this.$store.commit('updateLoading', false)
      this.$store.commit('updateCurrentEditListing', response.data)
    },
    deleteNotices() {
      axios
        .post(`/api/listings/${this.listingId}/garbage-setting?delete=true`, {
          delete: true,
        })
        .then(result => {
          this.currentListing.garbage_config = result.data.payload.data
          this.newNotice = {}
        })
        .catch(error => {
          alert(error)
        })
    },
    back() {
      this.$router.push('/dashboard')
    },
  },
  computed: {
    ...mapState('warranty', ['listingWarranties']),
    ...mapState(['loading', 'user']),
    menuItems() {
      return [
        {
          title: this.$t('Finance'),
          icon: '$accounting',
          show: true,
          children: [
            {
              title: this.$t('Expenses'),
              value: 'expenses',
              show: this.isAdmin || this.isCommunicationManager,
              icon: '$expenses',
            },
            {
              title: this.$t('Payments'),
              value: 'payments',
              show: this.isAdmin,
              icon: '$bill',
            },
            {
              title: this.$t('Income'),
              value: 'incomes',
              show: this.isAdmin,
              icon: '$profit',
            },
            {
              title: this.$t('Reservations'),
              value: 'reservations',
              show: this.isAdmin,
              icon: '$money',
            },
            {
              title: this.$t('P&L'),
              show:
                this.isAdmin ||
                this.isCommunicationManager ||
                this.isPropertyManager ||
                this.isCommunicationAgent ||
                this.isFieldPm,
              icon: '$finance',
              value: 'pnl',
            },
            {
              title: this.$t('Accounting'),
              show:
                this.showModule('accounting') &&
                (this.isAdmin || this.isAccountant),
              icon: '$calculator',
              value: 'accounting',
            },
          ],
        },
        {
          title: this.$t('Operation'),
          show: this.showModule('ops'),
          icon: '$maintenance',
          children: [
            {
              title: this.$t('Service Calls'),
              icon: '$calendar_check',
              show: true,
              value: 'tasks-cal',
            },
            {
              title: this.$t('Personnel'),
              icon: '$people',
              show: true,
              value: 'personal',
            },
            {
              title: this.$t('Information'),
              show: this.managementRoles || this.isCommunicationAgent,
              icon: '$info_circle_alt',
              value: 'services',
            },
            {
              title: this.$t('Warranties'),
              icon: '$warranty',
              show: true,
              value: 'warranties',
            },
          ],
        },
        {
          title: this.$t('Marketing'),
          icon: '$promotion',
          show:
            this.isAdmin ||
            this.isPropertyManager ||
            this.isCommunicationManager ||
            this.isCleaningSupervisor ||
            this.isCommunicationAgent,
          children: [
            {
              title: this.$t('Calendar'),
              icon: '$calendar',
              show: true,
              value: 'calendar',
            },
            {
              title: this.$t('Images'),
              icon: 'image',
              show: true,
              value: 'images',
            },
          ],
        },
        {
          title: this.$t('Configuration'),
          show: this.managementRoles || this.isCommunicationAgent,
          icon: '$settings',
          children: [
            {
              icon: '$home',
              title: this.$t('General'),
              show: true,
              value: 'configuration',
            },
            {
              icon: '$shower',
              title: this.$t('Amenities'),
              show: !(
                (this.managementRoles || this.isCommunicationAgent) &&
                (!this.currentListing.app_integration_id ||
                  this.currentListing.app_integration_name === 'boom') &&
                this.showModule('channel-manager')
              ),
              value: 'amenities',
            },
            {
              icon: '$profits',
              title: this.$t('Commissions'),
              show: true,
              value: 'commissions',
            },
          ],
        },
        {
          title: this.$t('Channel Management'),
          show:
            (this.managementRoles || this.isCommunicationAgent) &&
            (!this.currentListing.app_integration_id ||
              this.currentListing.app_integration_name === 'boom') &&
            this.showModule('channel-manager'),
          //need to change back
          icon: '$promotion',
          children: [
            {
              icon: '$home',
              title: this.$t('General'),
              show: true,
              value: 'listing-channel-general',
            },
            {
              icon: '$profits',
              title: this.$t('Finance'),
              show: true,
              value: 'listing-channel-finance',
            },
            {
              icon: '$shower',
              title: this.$t('Amenities'),
              show: true,
              value: 'amenities',
            },
            // {
            //   icon: '$calendar_check',
            //   title: this.$t('Availability'),
            //   show: true,
            //   value: 'listing-channel-availability',
            // },
            {
              icon: '$accounting',
              title: this.$t('Pricing'),
              show: true,
              value: 'listing-pricing',
            },
          ],
        },
      ]
    },
    currentListing() {
      return this.$store.state.currentEditListing || {}
    },
    listingId() {
      return Number(this.$route.params.id)
    },
    isUnit() {
      return (
        !this.currentListing.is_multi_unit &&
        this.currentListing.listing_type !== 'hotel'
      )
    },
  },
  mounted() {
    if (!this.paramsTab) {
      this.paramsTab = 'expenses'
    }
    const date = new Date()
    const listingId = Number(this.$route.params.id)
    this.getStripeAccounts()
    this.getWarranties(listingId)
    this.$store.dispatch('getAutoListingTaskRules', {
      id: listingId,
    })
    let filters = {
      catagories: [],
      from_price: 0,
      to_price: 1000000,
      listingsIds: listingId,
    }
    filters.from =
      filters.from ||
      new Date(date.getFullYear(), date.getMonth() - 1, 1)
        .toISOString()
        .substr(0, 10)
    filters.to =
      filters.to ||
      new Date(date.getFullYear(), date.getMonth(), 1)
        .toISOString()
        .substr(0, 10)
    this.$store.commit('updateLastExpensesFilter', filters)
    if (this.user && this.user.role === 'admin') {
      const date = new Date()
      this.$store.dispatch('getBankTransactionsByFilter', {
        from: new Date(new Date().setMonth(new Date().getMonth() - 1))
          .toISOString()
          .substr(0, 10),
        to: new Date(date.getFullYear(), date.getMonth(), 1)
          .toISOString()
          .substr(0, 10),
        transactionType: 'All',
        fromPrice: null,
        toPrice: null,
        listingId,
        attachedTo: 'All',
        status: false,
      })
      this.$store.dispatch(
        'getExpensesByFilter',
        this.$store.state.lastExpensesFilter
      )
    }
  },
  components: {
    ListingCommissions,
    AmenitiesEdit,
    Personal,
    TasksCalendar,
    ListingGallery,
    Warranties,
    Pnl,
    MenuPage,
    ReservationCalendar,
    Expenses,
    ExpenseModal,
    ListingIncomeModal,
    ListingEdit,
    PaymentModal,
    Payments,
    ListingManageCalendar,
    BankAccount,
    Services,
    ListingIncomes,
    hotelTable,
    BankTransactions,
    ReservationManager,
    ListingInfoPanel,
    ListingChannelGeneral,
    ListingChannelAvailability,
    ListingChannelFinance,
    MultiCalendar,
  },
}
</script>

<style scoped>
:deep() .v-input__icon > i {
  font-size: 20px;
}
.currency-sign {
  font-size: 24px;
  padding-right: 4px;
}
</style>
