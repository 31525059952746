var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingGuestExpInfo),expression:"loadingGuestExpInfo"}],attrs:{"indeterminate":""}}),(!_vm.isMobile)?_c('v-card',{staticClass:"d-flex justify-space-between py-4 px-8 rounded-lg pre-checkin-container",attrs:{"outlined":""}},[_c('pre-checkin-stat',{attrs:{"label":"Incomplete checkins","value":_vm.managedReservationsStats.missing_today,"color":"var(--v-dark500-base)"}}),_c('pre-checkin-stat',{attrs:{"label":"Incomplete checkins tomorrow","value":_vm.managedReservationsStats.missing_tomorrow,"color":"var(--v-info600-base)"}}),_c('pre-checkin-stat',{attrs:{"label":"Special requests","value":_vm.managedReservationsStats.special_today,"color":"var(--v-success600-base)"}}),_c('pre-checkin-stat',{attrs:{"label":"Special requests Tomorrow","value":_vm.managedReservationsStats.special_tomorrow,"color":"var(--v-success600-base)"}}),_c('pre-checkin-stat',{attrs:{"label":"Store purchases today","value":_vm.managedReservationsStats.store_today,"color":"var(--v-secondary600-base)"}}),_c('pre-checkin-stat',{attrs:{"label":"Store purchases tomorrow","value":_vm.managedReservationsStats.store_tomorrow,"color":"var(--v-primary600-base)"}}),_c('pre-checkin-stat',{attrs:{"label":"Store purchases last 24h","value":_vm.managedReservationsStats.store_purchase_last_24
          ? _vm.toMoney(_vm.managedReservationsStats.store_purchase_last_24)
          : '-',"color":"var(--v-warning600-base)"}})],1):_vm._e(),_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"6","sm":"4","lg":"2"}},[_c('date-picker',{attrs:{"label":"Check-in Date","max":_vm.parseISODateWithOffset(
            new Date(new Date().setMonth(new Date().getMonth() + 10))
          ).substr(0, 10),"min":_vm.parseISODateWithOffset(
            new Date(new Date().setMonth(new Date().getMonth() - 20))
          ).substr(0, 10)},on:{"change":_vm.fetchReservations},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4","lg":"2"}},[_c('v-autocomplete',{attrs:{"label":"Listing","clearable":"","hide-details":"","items":_vm.listingsPicker,"item-text":"nickname","item-value":"id","dense":"","outlined":""},on:{"change":function($event){return _vm.fetchReservations({})}},model:{value:(_vm.listingId),callback:function ($$v) {_vm.listingId=$$v},expression:"listingId"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4","lg":"2"}},[_c('filters-select',{attrs:{"value":_vm.channel,"label":"Channel","items":_vm.channelsItems,"selection-func":item => item.text},on:{"update:value":function($event){_vm.channel=$event},"change":function($event){return _vm.fetchReservations({})}}})],1),_c('v-col',[_c('v-checkbox',{staticClass:"mt-0",attrs:{"dense":"","hide-details":"","label":"Missing Checkins only"},on:{"change":function($event){return _vm.fetchReservations({})}},model:{value:(_vm.missingOnly),callback:function ($$v) {_vm.missingOnly=$$v},expression:"missingOnly"}})],1)],1),_c('reservations',{attrs:{"hide-reconcile":"true","headers":_vm.headers},on:{"pagination":_vm.paginationChanged,"refetch":_vm.fetchReservations}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }