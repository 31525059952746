<template>
  <badge icon="$pulse" :hide-badge="true">
    <v-sheet width="400" class="white" rounded>
      <div class="pa-3">
        <div v-for="(factor, i) in factors" :key="i" class="my-3">
          <div class="text-capitalize">
            {{ factor[0] }}
            <info-tooltip>
              <div class="text-caption" v-html="factor[2]" />
            </info-tooltip>
          </div>
          <div class="d-flex align-center">
            <v-progress-linear
              :value="factor[1]"
              rounded
              height="8"
              class="my-1"
              color="info lighten-1"
            />
            <v-sheet width="50" class="ml-3 font-weight-bold text-subtitle-1">
              {{ toPercent(factor[1], 0) }}
            </v-sheet>
          </div>
        </div>
      </div>
    </v-sheet>
  </badge>
</template>

<script>
import Badge from 'components/common/badge'
import InfoTooltip from 'components/common/info-tooltip.vue'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'HealthBadge',
  mixins: [FormattersMixin],
  props: ['factors'],
  components: { InfoTooltip, Badge },
}
</script>

<style scoped></style>
