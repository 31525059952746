export default {
  data() {
    return {
      subDepartments: {
        Maintenance: [
          { text: 'Office', value: 'office' },
          { text: 'Field', value: 'field' },
          { text: 'Repair', value: 'repair' },
          { text: 'Vendor relation', value: 'vendor-relation' },
          { text: 'Admin support', value: 'admin-support' },
        ],
      },
      roles: [
        { text: 'Admin', value: 'admin' },
        { text: 'Investor', value: 'investor' },
        { text: 'Property Manager', value: 'property-manager' },
        { text: 'Field PM', value: 'field-pm' },
        { text: 'Coordinator', value: 'coordinator' },
        { text: 'Contractor', value: 'contractor' },
        { text: 'Communication manager', value: 'communication-manager' },
        { text: 'Communication agent', value: 'communication-agent' },
        { text: 'Hotel Reception', value: 'hotel-reception' },
        { text: 'Sales Manager', value: 'sales-manager' },
        { text: 'Sales Agent', value: 'sales-agent' },
        { text: 'Cleaning manager', value: 'cleaning-manager' },
        { text: 'Cleaning supervisor', value: 'cleaning-supervisor' },
        { text: 'Cleaner', value: 'cleaner' },
      ],
      subRoles: {
        Maintenance: [
          { text: 'Head', value: 'head' },
          { text: 'Manager / supervisor', value: 'manager' },
          { text: 'Inspector', value: 'inspector' },
          { text: 'Tech', value: 'tech' },
          { text: 'Shift manager', value: 'shift-manager' },
          { text: 'Team leader', value: 'team-leader' },
          { text: 'Agent', value: 'agent' },
          { text: 'Specialist', value: 'specialist' },
          { text: 'Vendor', value: 'vendor' },
        ],
      },
      defaultSubRoles: [
        'manager',
        'field-manager',
        'handyman',
        'runner',
        'shift-manager',
        'agent',
        'backoffice',
      ],
      skills: {
        Maintenance: [
          { text: 'Pool', value: 'pool' },
          { text: 'Hvac', value: 'hvac' },
          { text: 'Waste management', value: 'waste_management' },
          { text: 'Landscape', value: 'landscape' },
          { text: 'Roof', value: 'roof' },
          { text: 'Appliance', value: 'appliance' },
          { text: 'Electric', value: 'electric' },
          { text: 'Plumbing', value: 'plumbing' },
          { text: 'Front line (communications)', value: 'front_line' },
          { text: 'Back office (data entry)', value: 'back_office' },
          { text: 'Paint', value: 'paint' },
          { text: 'Drywall', value: 'drywall' },
          { text: 'Welding', value: 'welding' },
          { text: 'Pressure wash', value: 'pressure_wash' },
          { text: 'Tiles', value: 'tiles' },
          { text: 'Flooring', value: 'flooring' },
        ],
      },
    }
  },
}
