import axios from '@/axios/config'
import { keyBy } from 'lodash/fp'
import { findIndex } from 'lodash'
import ltMixin from 'components/mixins/lt-mixin'

export default {
  namespaced: true,
  state: {
    allBoardsFetching: false,
    allBoardsFetched: false,
    allBoards: [],
    boardsFetching: false,
    boardsFetched: false,
    boards: [],
    sectionsFetching: false,
    sections: [],
    tasksFetching: false,
    tasks: [],
    querySectionSort: { by: 'created_at', dir: 'desc' },
  },
  mutations: {
    setAllBoardsFetching(state, value) {
      state.allBoardsFetching = value
    },
    setAllBoardsFetched(state, value) {
      state.allBoardsFetched = value
    },
    setAllBoards(state, payload) {
      state.allBoards = payload
    },
    setBoardsFetching(state, value) {
      state.boardsFetching = value
    },
    setBoardsFetched(state, value) {
      state.boardsFetched = value
    },
    setBoards(state, payload) {
      state.boards = [...payload]
    },
    setSectionsFetching(state, value) {
      state.sectionsFetching = value
    },
    setSections(state, payload) {
      state.sections = payload
    },
    setTasksFetching(state, value) {
      state.tasksFetching = value
    },
    setTasks(state, payload) {
      state.tasks = payload
    },
    setQuerySectionSort(state, value) {
      state.querySectionSort = value
    },
  },
  actions: {
    async getAllBoards({ commit }) {
      commit('updateHeaderProgressBar', true, { root: true })
      commit('setAllBoardsFetching', true)
      return axios
        .get(`/api/taskim/boards`, { params: { all: true } })
        .then(res => {
          commit('setAllBoards', res.data)
          commit('setAllBoardsFetched', true)
          return res.data
        })
        .finally(() => {
          commit('setAllBoardsFetching', false)
          commit('updateHeaderProgressBar', false, { root: true })
        })
    },
    async getBoards({ commit }) {
      commit('updateHeaderProgressBar', true, { root: true })
      commit('setBoardsFetching', true)
      return axios
        .get(`/api/taskim/boards`)
        .then(res => {
          commit('setBoards', res.data)
          commit('setBoardsFetched', true)
          return res.data
        })
        .finally(() => {
          commit('setBoardsFetching', false)
          commit('updateHeaderProgressBar', false, { root: true })
        })
    },
    async createBoard({ commit }, data) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios.post(`/api/taskim/boards`, data).then(res => res.data)
    },
    async updateBoard({ commit, dispatch }, board) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .post(`/api/taskim/boards/${board.id}`, board)
        .then(res => {
          dispatch('stateTaskimSetBoard', res.data)
          return res.data
        })
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async updateBoardSection(
      { commit, dispatch },
      { boardId, sectionId, payload }
    ) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .post(`/api/taskim/boards/${boardId}/section/${sectionId}`, payload)
        .then(res => {
          dispatch('stateTaskimSetBoard', res.data)
          return res.data
        })
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async deleteBoard({ commit, dispatch }, id) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .delete(`/api/taskim/boards/${id}`)
        .then(res => {
          dispatch('stateTaskimRemoveBoard', id)
          return res.data
        })
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async getBoardSections(
      { state, commit, getters },
      { id, bg = false, pagi = {} }
    ) {
      commit('updateHeaderProgressBar', true, { root: true })
      !bg && commit('setSectionsFetching', true)
      const params = {
        filters: getters.currentFilters,
      }
      if (id === 'query') {
        params.pagi = { ...getters.querySectionPagination, ...pagi }
        if (state.querySectionSort.by) {
          params.sort = state.querySectionSort
        }
      }
      return axios
        .get(`/api/taskim/boards/${id}/sections`, {
          params,
          cancelToken: axios.autoCancelToken('TaskimGetBoardSections'),
        })
        .then(res => {
          commit('setSections', res.data)
          return res.data
        })
        .finally(() => {
          !bg && commit('setSectionsFetching', false)
          commit('updateHeaderProgressBar', false, { root: true })
        })
    },
    async getProjectBoardSections({ commit, getters }, { id, bg = false }) {
      commit('updateHeaderProgressBar', true, { root: true })
      if (!bg) {
        commit('setBoardsFetching', true)
        commit('setSectionsFetching', true)
      }
      const params = {
        filters: getters.currentFilters,
      }
      return axios
        .get(`/api/taskim/project/${id}`, {
          params,
          cancelToken: axios.autoCancelToken('TaskimGetProjectBoardSections'),
        })
        .then(res => {
          commit('setBoards', [res.data.board])
          commit('setSections', res.data.sections)
          commit('setBoardsFetched', true)
          return res.data
        })
        .finally(() => {
          if (!bg) {
            commit('setBoardsFetching', false)
            commit('setSectionsFetching', false)
          }
          commit('updateHeaderProgressBar', false, { root: true })
        })
    },
    async createSection({ commit }, data) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios.post(`/api/taskim/sections`, data).then(res => res.data)
    },
    async updateSection({ state, commit, getters }, section) {
      commit('updateHeaderProgressBar', true, { root: true })
      const params = {
        filters: getters.currentFilters,
        section,
      }
      if (section.id === 'query') {
        params.pagi = getters.querySectionPagination
        if (state.querySectionSort.by) {
          params.sort = state.querySectionSort
        }
      }
      return axios
        .post(`/api/taskim/sections/${section.id}`, params)
        .then(res => res.data)
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async getSectionTasks({ state, commit, getters }, sectionId) {
      if (!sectionId) return
      commit('updateHeaderProgressBar', true, { root: true })
      commit('setTasksFetching', true)
      const params = {
        filters: getters.currentFilters,
      }
      if (sectionId === 'query') {
        params.pagi = getters.querySectionPagination
        if (state.querySectionSort.by) {
          params.sort = state.querySectionSort
        }
      }
      return axios
        .get(`/api/taskim/sections/${sectionId}/tasks`, {
          params,
          cancelToken: axios.autoCancelToken('TaskimGetSectionTasks'),
        })
        .then(res => {
          commit('setTasks', res.data)
          return res.data
        })
        .finally(() => {
          commit('updateHeaderProgressBar', false, { root: true })
          setTimeout(() => commit('setTasksFetching', false), 500)
        })
    },
    async updateSectionTasks(
      { state, commit, getters, dispatch },
      { sectionId, tasksIds, operation, section }
    ) {
      commit('updateHeaderProgressBar', true, { root: true })
      commit('updateHeaderProgressBar', true, { root: true })
      if (operation === 'add' || operation === 'remove') {
        const section = getters.sectionsMap[sectionId]
        const amount = tasksIds.length
        const count =
          section.tasks_count + (operation === 'add' ? amount : -amount)
        dispatch('stateTaskimUpdateSection', {
          id: sectionId,
          tasks_count: count,
        })
      }
      const params = {
        filters: getters.currentFilters,
        operation,
        section,
        tasks_ids: tasksIds,
      }
      if (sectionId === 'query') {
        params.pagi = getters.querySectionPagination
        if (state.querySectionSort.by) {
          params.sort = state.querySectionSort
        }
      }
      return axios
        .post(`/api/taskim/sections/${sectionId}/tasks`, params)
        .then(res => res.data)
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async updateSectionTask(
      { state, commit, getters, dispatch },
      { sectionId, taskId, operation, section, after_task_id }
    ) {
      commit('updateHeaderProgressBar', true, { root: true })
      if (operation === 'add' || operation === 'remove') {
        if (operation === 'remove') {
          dispatch('stateTaskimRemoveTask', taskId)
        }
        const section = getters.sectionsMap[sectionId]
        if (section) {
          const count = section.tasks_count + (operation === 'add' ? 1 : -1)
          dispatch('stateTaskimUpdateSection', {
            id: sectionId,
            tasks_count: count,
          })
        }
      }
      const params = {
        filters: getters.currentFilters,
        operation,
        section,
        after_task_id,
      }
      if (sectionId === 'query') {
        params.pagi = getters.querySectionPagination
        if (state.querySectionSort.by) {
          params.sort = state.querySectionSort
        }
      }
      return axios
        .post(`/api/taskim/sections/${sectionId}/task/${taskId}`, params)
        .then(res => res.data)
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async deleteSection({ commit, dispatch }, id) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .delete(`/api/taskim/sections/${id}`)
        .then(res => {
          dispatch('stateTaskimRemoveSection', id)
          return res.data
        })
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async createSectionTask(
      { state, commit, getters, dispatch },
      { sectionId, description }
    ) {
      commit('updateHeaderProgressBar', true, { root: true })
      const params = {
        filters: getters.currentFilters,
        description,
      }
      if (sectionId === 'query') {
        params.pagi = getters.querySectionPagination
        if (state.querySectionSort.by) {
          params.sort = state.querySectionSort
        }
      }
      return axios
        .post(`/api/taskim/sections/${sectionId}/task`, params)
        .then(res => {
          dispatch('taskCalendar/addToIncludeIdsFilter', res.data.id, {
            root: true,
          })
          dispatch('stateTaskimAddTask', res.data)
          const section = getters.sectionsMap[sectionId]
          if (section) {
            const count = section.tasks_count + 1
            dispatch('stateTaskimUpdateSection', {
              id: sectionId,
              tasks_count: count,
            })
          }
          return res.data
        })
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async getTaskHierarchy(store, taskId) {
      return axios
        .get(`/api/listing-tasks/${taskId}/taskim-hierarchy`)
        .then(res => res.data)
    },
    async setTaskFromCurrentListingTask({ dispatch, rootState }) {
      const task = rootState.currentlistingTask
      dispatch('stateTaskimSetTask', task)
    },
    stateTaskimRemoveBoard({ state, commit }, id) {
      const boards = [...state.boards.slice(1)]
      const boardIndex = findIndex(boards, { id })
      if (boardIndex !== -1) {
        boards.splice(boardIndex, 1)
        commit('setBoards', boards)
      }
    },
    stateTaskimSetBoard({ state, commit }, payload) {
      const boards = [...state.boards.slice(1)]
      const boardIndex = findIndex(boards, { id: payload.id })
      if (boardIndex !== -1) {
        boards[boardIndex] = payload
        commit('setBoards', boards)
      }
    },
    stateTaskimUpdateSection({ state, commit }, payload) {
      const sections = [...state.sections]
      const sectionIndex = findIndex(sections, { id: payload.id })
      if (sectionIndex !== -1) {
        sections[sectionIndex] = {
          ...sections[sectionIndex],
          ...payload,
        }
      }
      commit('setSections', sections)
    },
    stateTaskimRemoveSection({ state, commit }, id) {
      const sections = [...state.sections]
      const sectionIndex = findIndex(sections, { id })
      if (sectionIndex !== -1) {
        sections.splice(sectionIndex, 1)
        commit('setSections', sections)
      }
    },
    stateTaskimAddTask({ state, commit }, task) {
      commit('setTasks', [...state.tasks, task])
    },
    stateTaskimRemoveTask({ state, commit }, taskId) {
      const tasks = [...state.tasks]
      const taskIndex = findIndex(tasks, { id: taskId })
      if (taskIndex !== -1) {
        tasks.splice(taskIndex, 1)
      }
      commit('setTasks', tasks)
    },
    stateTaskimUpdateTask({ state, commit }, payload) {
      const tasks = [...state.tasks]
      const taskIndex = findIndex(tasks, { id: payload.id })
      if (taskIndex !== -1) {
        tasks[taskIndex] = {
          ...tasks[taskIndex],
          ...payload,
        }
      }
      commit('setTasks', tasks)
    },
    stateTaskimSetTask({ state, commit }, payload) {
      const tasks = [...state.tasks]
      const taskIndex = findIndex(tasks, {
        task_id: payload.id,
      })
      if (taskIndex !== -1) {
        tasks[taskIndex] = payload
      }
      commit('setTasks', tasks)
    },
  },
  getters: {
    boardsMap(state) {
      return keyBy('id', state.boards)
    },
    sectionsMap(state) {
      return keyBy('id', state.sections)
    },
    excludedFilters() {
      return [
        'division',
        'availabilities',
        'listings_id',
        'project_id',
        'project_name',
        'cities',
        'Show Only',
        'categories',
        'listingTaskCatalogItem',
        'listingCatalogItemId',
        'isDefault',
        'topics',
        'type',
      ]
    },
    currentFilters(state, getters, rootState, rootGetters) {
      return ltMixin.methods.prepareFiltersPayload(
        rootGetters['taskCalendar/filters'],
        {
          excludedFilters: rootState['taskCalendar/excludedFilters'],
          forcedFilters: rootState['taskCalendar/forcedFilters'],
        }
      )
    },
    querySectionPagination(state) {
      if (
        state.sections &&
        state.sections[0] &&
        state.sections[0].id === 'query' &&
        state.sections[0].pagi_info
      ) {
        return state.sections[0].pagi_info
      }
      return { page: 1, per_page: 50, count: null }
    },
  },
}
