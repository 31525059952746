import axios from '@/axios/config'

export default {
  namespaced: true,
  actions: {
    async fetchBilling(_, params) {
      return axios.get(`/api/billing/report`, { params })
    },
  },
}
