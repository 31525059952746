<template>
  <v-card class="overflow-auto">
    <v-card-title class="text-center">
      <span class="text-h5"
        >{{ !editMode ? $t('Create New') : $t('Edit') }}
        {{ $t('Message Automation') }}
        {{ editMode ? ' #' + messageRule.id : '' }}</span
      >
    </v-card-title>
    <v-form ref="form" @submit.prevent="save">
      <v-card-text>
        <v-text-field
          v-model="messageRule.name"
          dense
          outlined
          :rules="[required]"
          :label="$t('Name')"
        />
        <div>
          <v-row>
            <v-col cols="12" class="text-subtitle-1">{{
              $t('Time settings')
            }}</v-col>
            <v-col v-if="messageRule.sent_at_timing !== 'At'" cols="3">
              <v-select
                v-model="messageRule.sent_at_number"
                outlined
                dense
                hide-details
                type="number"
                :items="[
                  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                  19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33,
                  34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
                  49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
                  64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
                  79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93,
                  94, 95, 96, 97, 98, 99, 100,
                ]"
              />
            </v-col>
            <v-col v-if="messageRule.sent_at_timing !== 'At'" cols="3">
              <v-select
                v-model="messageRule.sent_at_period"
                :items="['Hours', 'Days', 'Weeks']"
                :placeholder="$t('Period')"
                outlined
                hide-details
                dense
              />
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="messageRule.sent_at_timing"
                :items="['After', 'Before', 'At']"
                :placeholder="$t('Timing')"
                outlined
                hide-details
                dense
              />
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="messageRule.sent_at_event"
                :items="['Check-in', 'Check-out', 'Confirmation']"
                :placeholder="$t('Event')"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" class="text-subtitle-1">{{ $t('Filters') }}</v-col>
            <v-col cols="12">
              <multi-property-picker
                v-model="messageRule.whitelist"
                :label="$t('All Listings')"
                multiple
                :hide-btns="true"
              />
              <multi-property-picker
                v-model="messageRule.blacklist"
                :disabled="messageRule.whitelist.length !== 0"
                :label="$t('Blacklisted listings')"
                :hide-btns="true"
              />
              <v-autocomplete
                v-model="messageRule.allowed_channels"
                :items="[
                  'Airbnb',
                  'PlumGuide',
                  'Marriott',
                  'HomeAway',
                  'TripAdvisor',
                  'Booking.com',
                  'Expedia',
                ]"
                label="All Channels"
                small-chips
                outlined
                dense
                multiple
                hide-details
              />
            </v-col>
          </v-row>
        </div>
        <v-row class="align-center">
          <v-col cols="12" class="text-subtitle-1 mb-3 mt-3">{{
            $t('Message')
          }}</v-col>
          <v-col cols="4">
            <v-select
              v-model="messageRule.send_in_channel"
              label="Type"
              :items="[
                { text: 'Email', value: 'email' },
                { text: 'SMS', value: 'sms' },
                { text: 'Whatsapp', value: 'whatsapp' },
                { text: 'Channel', value: 'channel' },
              ]"
              outlined
              dense
              hide-details
              @change="onChannelChange"
            />
          </v-col>
          <v-select
            v-if="messageRule.send_in_channel === 'whatsapp'"
            v-model="whatsappTemplate"
            label="Template"
            :items="templates"
            item-text="name"
            item-value="body"
            outlined
            dense
            hide-details
            return-object
            @change="updateBody"
          />
          <v-col
            v-if="messageRule.send_in_channel === 'channel'"
            class="pa-0 pt-1"
            cols="12"
          >
            <div class="text-caption">
              * In case channel isn't supported the message will be delivered by
              email.
            </div>
          </v-col>
          <v-col cols="12" class="correct-area">
            <v-row class="align-baseline">
              <v-col
                v-if="
                  messageRule.send_in_channel === 'email' ||
                  messageRule.send_in_channel === 'channel'
                "
                cols="4"
              >
                <div class="d-flex my-2">
                  <v-text-field
                    v-model="messageRule.email_subject"
                    outlined
                    dense
                    hide-details
                    :rules="[required]"
                    class="flex"
                    placeholder="Subject"
                  />
                </div>
              </v-col>
              <v-col>
                <div class="py-2 right-text">
                  <v-btn
                    class="text-capitalize"
                    color="info"
                    x-small
                    elevation="0"
                    @click="messageRule.message_body += '{{guest_name}}'"
                  >
                    Guest name
                  </v-btn>
                  <v-btn
                    class="text-capitalize ml-2"
                    color="info"
                    x-small
                    elevation="0"
                    @click="messageRule.message_body += '{{portal_link}}'"
                  >
                    Guest Portal Link
                  </v-btn>
                  <v-btn
                    class="text-capitalize ml-2"
                    color="info"
                    x-small
                    elevation="0"
                    @click="messageRule.message_body += '{{review_link}}'"
                  >
                    Internal Review Link
                  </v-btn>
                </div>
              </v-col>
            </v-row>

            <div class="d-flex">
              <v-textarea
                v-model="messageRule.message_body"
                label="Body"
                :readonly="messageRule.send_in_channel === 'whatsapp'"
                outlined
                dense
                hide-details
                class="flex"
                placeholder="Type your message..."
                @keyup="onMsgDown"
              />
            </div>
            <div v-if="isSMS" class="d-flex justify-space-between text-caption">
              <div class="warning--text">
                <span v-show="showError" class="ml-1">
                  SMS message can't contains more than
                  {{ smsCharLimit }} chars
                </span>
              </div>
              <div>{{ charCounter }} / {{ smsCharLimit }}</div>
            </div>
            <div
              v-if="isSMS && containsEmoji"
              class="d-flex justify-space-between text-caption"
            >
              <div class="warning--text">
                <span class="ml-1"> SMS message can't contains Emoji </span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="blue darken-1" text @click.native="close">Close</v-btn>
        <v-btn
          color="blue darken-1"
          :disabled="loading || showError"
          text
          type="submit"
          >{{ $t('Save') }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import MultiPropertyPicker from 'components/multi-property-picker.vue'

export default {
  components: {
    MultiPropertyPicker,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormRulesMixin],
  props: ['afterSaveFunc', 'editMode', 'currentRule', 'templates'],
  computed: {
    isSMS() {
      return this.messageRule.send_in_channel === 'sms'
    },
    containsEmoji() {
      const emojiRegex = /[\uD800-\uDFFF]./
      return emojiRegex.test(this.messageRule.message_body)
    },
  },
  data() {
    return {
      messageRule: this.currentRule
        ? { ...this.currentRule }
        : {
            listings_ids: [],
            whitelist: [],
            blacklist: [],
            sent_at_period: 'Minutes',
            sent_at_timing: 'At',
            sent_at_event: 'Confirmation',
            sent_at_number: 1,
            send_in_channel: 'email',
            allowed_channels: [],
          },
      menu: false,
      smsCharLimit: 150,
      showError: false,
      charCounter: 0,
      whatsappTemplate: null,
    }
  },
  mounted() {
    this.charCounter = this.messageRule.message_body?.length
    if (this.isSMS && this.charCounter > 150) {
      this.showError = true
    } else {
      this.showError = false
    }
    this.whatsappTemplate = this.templates?.find(
      t => t.body === this.messageRule.message_body
    )?.sid
  },
  methods: {
    close() {
      this.$store.commit('hideModal')
    },
    onChannelChange() {
      if (!this.isSMS) this.showError = false
    },
    onMsgDown(event) {
      if (this.isSMS) {
        if (event.target.value.length > this.smsCharLimit) {
          this.showError = true
        } else if (this.containsEmoji) {
          this.showError = true
        } else {
          this.showError = false
          if (
            event.ctrlKey &&
            (event.code === 'Enter' || event.keyCode === 13)
          ) {
            this.sendMessage()
            return
          }
        }
        this.charCounter = this.messageRule.message_body.length
      } else {
        this.showError = false
      }
    },
    updateBody({ body }) {
      this.messageRule.message_body = body
    },
    save() {
      if (this.$refs.form.validate() && !this.showError) {
        if (this.messageRule.sent_at_timing === 'At') {
          this.messageRule.sent_at_period = null
          this.messageRule.sent_at_number = null
        }
        this.$store.commit('updateListingLoading', true)
        if (this.editMode) {
          axios
            .patch(
              `/api/auto-message-rules/${this.messageRule.id}`,
              this.messageRule
            )
            .then(() => {
              this.$store.commit('updateListingLoading', false)
              if (this.afterSaveFunc) {
                this.afterSaveFunc()
              }
              this.close()
            })
            .catch(error => {
              console.error(error)
              this.$store.commit('updateListingLoading', false)
            })
        } else {
          axios
            .post('/api/auto-message-rules', this.messageRule)
            .then(() => {
              this.$store.commit('updateListingLoading', false)

              this.close()
              if (this.afterSaveFunc) {
                this.afterSaveFunc()
              }
            })
            .catch(error => {
              console.error(error)
              this.$store.commit('updateListingLoading', false)
            })
        }
      }
    },
  },
}
</script>
