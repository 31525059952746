<template>
  <v-sheet
    elevation="2"
    class="image-view d-flex p-relative rounded-t-lg"
    @mouseleave="outPic"
  >
    <div v-if="isPdf(src)" class="p-relative rounded-t-lg">
      <v-overlay absolute color="transparent" @click="clicked" />
      <iframe
        title="View PDF"
        width="100%"
        height="100%"
        :src="`${src}#toolbar=0`"
        frameborder="0"
      />
    </div>
    <img
      v-else-if="!isVideo(src)"
      :width="smallWidth"
      class="rounded-t-lg"
      style="object-fit: cover"
      :src="src"
      alt=""
      @mouseover="hoverPic"
      @click="clicked"
      @load="onLoad"
    />
    <video
      v-else
      :width="smallWidth"
      class="rounded-t-lg"
      :src="src"
      :autoplay="false"
      :height="smallWidth"
      @click="clicked"
      @mouseover="hoverPic"
      @canplaythrough="onLoad"
    />
    <v-sheet
      v-if="meta"
      height="20"
      class="p-absolute secondary--text date-mark white text-caption d-flex align-center px-1"
    >
      <span class="very-small-text bolder">{{
        parseDate(meta.created_at, isMobile ? 'MM/DD' : 'DD MMM YYYY, HH:mm', {
          local: true,
        })
      }}</span>
    </v-sheet>
    <v-icon
      v-if="isVideo(src)"
      x-small
      color="white"
      class="play-icon rounded-t-lg"
      @click="clicked"
      >fas fa-play
    </v-icon>
    <v-progress-circular
      v-if="!isPdf(src) && !loaded"
      indeterminate
      color="primary"
    />
  </v-sheet>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'

export default {
  name: 'ImageView',
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  props: ['src', 'smallWidth', 'largeWidth', 'onRemove', 'readonly', 'meta'],
  data() {
    return {
      hovered: false,
      pdfDialog: false,
      loaded: false,
    }
  },
  methods: {
    onLoad() {
      this.loaded = true
    },
    hoverPic() {
      this.hovered = true
    },
    outPic() {
      this.hovered = false
    },
    removeImg() {
      this.onRemove(this.src)
    },
    clicked() {
      this.$emit('click', this.src)
    },
    pdfClose() {
      this.pdfDialog = false
    },
  },
}
</script>

<style lang="scss" scoped>
.image-view {
  position: relative;

  > .action-buttons {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 100%;
    z-index: 100;
  }
}

.play-icon {
  position: absolute;
  padding: 6px;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  background-color: red;
}
.date-mark {
  width: 100%;
  bottom: 0;
  left: 0;
}
</style>
