<template>
  <v-menu
    left
    :value="tooltip"
    offset-y
    class="white"
    content-class="white"
    @input="onClick"
  >
    <template #activator="{}">
      <v-sheet
        width="26"
        height="26"
        rounded
        :elevation="tooltip ? 0 : 3"
        :data-badge="content"
        class="ba-black-1 mx-1 d-flex align-center justify-center p-relative pointer"
        :class="{
          'bg-black': tooltip,
          'bg-white': !tooltip,
          badge: !hideBadge,
        }"
        @click="onClick"
      >
        <v-icon small :color="tooltip ? ' white' : 'black'">{{ icon }}</v-icon>
      </v-sheet>
    </template>
    <slot />
  </v-menu>
</template>

<script>
export default {
  name: 'Badge',
  props: ['icon', 'hideBadge', 'content', 'value'],
  data() {
    return {
      tooltip: false,
    }
  },
  methods: {
    onClick() {
      this.tooltip = !this.tooltip
      this.$emit('input', this.tooltip)
      this.$emit('change', this.tooltip)
    },
  },
}
</script>

<style scoped>
.bg-black {
  background-color: #000000;
}
.badge::after {
  content: attr(data-badge);
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 0.7em;
  background: var(--v-warning-base);
  color: white;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  box-shadow: 0 0 1px #333;
}
.bg-white {
  background-color: #ffffff;
}
</style>
