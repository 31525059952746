import axios from '@/axios/config'

export default {
  namespaced: true,
  state: {
    calendarUpdates: 0,
    calendarLoading: false,
  },
  mutations: {
    calendarUpdate(state) {
      state.calendarUpdates++
    },
    toggleCalendarLoading(state) {
      state.calendarLoading = !state.calendarLoading
    },
  },
  actions: {
    async blockCalendar({ commit }, { listingId, ...body }) {
      commit('toggleCalendarLoading')
      try {
        const { data } = await axios.put(
          `/api/listing-calendar/${listingId}`,
          body
        )
        commit('toggleCalendarLoading')
        return data
      } catch (e) {
        commit('toggleCalendarLoading')
      }
    },
  },
}
