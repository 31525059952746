<template>
  <v-tabs v-model="paramsTab">
    <listing-info-pop v-if="showListingInfoPop" />
    <v-tab href="#sales" @click="updateChatType('sales')">
      {{ $t('Sales') }}</v-tab
    >
    <v-tab href="#cs" @click="updateChatType('customerSupport')">
      {{ $t('CS') }}</v-tab
    >
    <v-tab href="#auto"> {{ $t('Automations') }}</v-tab>
    <v-tab v-if="isDesignedVr" href="#summary">{{ $t('Today Summary') }}</v-tab>

    <v-tab-item v-if="paramsTab === 'sales'" id="sales" class="col-12">
      <sales-crm />
    </v-tab-item>
    <v-tab-item v-if="paramsTab === 'cs'" id="cs" class="col-12">
      <cs-crm />
    </v-tab-item>
    <v-tab-item v-if="paramsTab === 'auto'" id="auto" class="col-12">
      <auto-message-rules-table />
    </v-tab-item>
    <v-tab-item v-if="paramsTab === 'summary' && isDesignedVr" id="summary">
      <daily-summary />
    </v-tab-item>
  </v-tabs>
</template>

<script>
import SalesCrm from 'components/crm/sales-crm.vue'
import DailySummary from '../summaries/daily-summary'
import CsCrm from './cs-crm'
import { mapMutations } from 'vuex'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ListingInfoPop from 'components/listing-info-pop.vue'
import AutoMessageRulesTable from 'components/auto-message-rules/auto-message-rules-table.vue'
export default {
  components: {
    AutoMessageRulesTable,
    CsCrm,
    DailySummary,
    SalesCrm,
    ListingInfoPop,
  },
  mixins: [PermissionsMixin],
  methods: {
    ...mapMutations(['updateChatType']),
  },
  computed: {
    showListingInfoPop() {
      return this.$store.state.showListingInfoPop
    },
  },
  data() {
    return {
      view: 'pending',
    }
  },
  mounted() {
    this.$store.dispatch('getWhatsappTemplates')
  },
}
</script>
<style></style>
