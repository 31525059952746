<template>
  <div class="d-flex flex justify-space-between align-start">
    <div>
      <div>{{ label }}</div>
      <div class="text-caption secondary--text">
        {{ description }}
      </div>
    </div>
    <div class="d-flex justify-space-around add-item-wrapper align-center">
      <v-icon :disabled="isRemoveDisabled" small @click="remove">
        fas fa-minus-circle
      </v-icon>
      <span class="px-2 flex text-center">{{ value }}</span>
      <v-icon :disabled="isAddDisabled" small @click="add">
        fas fa-plus-circle
      </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddRemoveItem',
  props: {
    label: {
      type: String,
    },
    description: {
      type: String,
    },
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
  },
  computed: {
    isRemoveDisabled() {
      return (this.min && this.value === this.min) || this.value === 0
    },
    isAddDisabled() {
      return this.max && this.value === this.max
    },
  },
  mounted() {
    if (isNaN(this.value)) {
      this.$emit('update:value', this.min || 0)
    }
  },
  methods: {
    add() {
      this.$emit('update:value', this.value + 1)
    },
    remove() {
      this.$emit('update:value', this.value - 1)
    },
  },
}
</script>

<style scoped>
.add-item-wrapper {
  flex-basis: 80px;
}
.theme--light.v-icon {
  color: rgba(0, 0, 0, 1) !important;
}
.theme--light.v-icon:focus::after {
  opacity: 0;
}
</style>
