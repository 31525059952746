<template>
  <v-card>
    <v-toolbar elevation="0" dense hide-details>
      <v-toolbar-title>Reservation override</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pt-3">
      <div v-if="unsynced" class="font-weight-medium warning--text text-body-1">
        * This reservation isn't synced with the pms
      </div>
      <v-form ref="form">
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model.number="form.price"
              outlined
              :rules="[required]"
              dense
              hide-details
              label="Price"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.number="form.fare_accommodation"
              outlined
              :rules="[required]"
              dense
              hide-details
              label="Fare Accommodation"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.number="form.cleaning_fee"
              outlined
              :rules="[required]"
              dense
              hide-details
              label="Cleaning Fee"
          /></v-col>
          <v-col cols="6">
            <v-text-field
              v-model.number="form.processing_fee"
              outlined
              :rules="[required]"
              dense
              hide-details
              label="Processing Fee"
          /></v-col>
          <v-col cols="6">
            <v-text-field
              v-model.number="form.host_channel_fee"
              outlined
              :rules="[required]"
              dense
              hide-details
              label="Host Channel Fee"
          /></v-col>
          <v-col cols="6">
            <v-text-field
              v-model.number="form.management_commission"
              outlined
              :rules="[required]"
              dense
              hide-details
              label="Management Commission"
          /></v-col>
          <v-col cols="6">
            <v-text-field
              v-model.number="form.client_profit"
              outlined
              :rules="[required]"
              dense
              hide-details
              label="Owner Profit"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.number="form.net_income"
              outlined
              :rules="[required]"
              dense
              hide-details
              label="Net Income"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.number="form.sales_tax"
              outlined
              :rules="[required]"
              dense
              hide-details
              label="Sales Tax"
          /></v-col>
          <v-col cols="6">
            <v-text-field
              v-model.number="form.tourist_tax"
              outlined
              :rules="[required]"
              dense
              hide-details
              label="Tourist Tax"
          /></v-col>
        </v-row>
        <div
          v-if="showAlert && !unsynced"
          class="font-weight-medium warning--text text-body-1"
        >
          * Pay attention! By changing one of these values the reservation won't
          longer be synced with the pms
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="warning" outlined @click="close">Cancel</v-btn>
      <v-btn color="primary" @click="save">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import formRulesMixin from 'components/mixins/form-rules-mixin'

export default {
  name: 'ReservationOverrideModal',
  props: {
    reservation: Object,
    onSuccess: Function,
  },
  mixins: [formRulesMixin],
  data() {
    return {
      showAlert: false,
      unsynced: this.reservation.pms_unsync,
      form: {
        fare_accommodation: (this.reservation.fare_accommodation || 0).toFixed(
          2
        ),
        cleaning_fee: (this.reservation.cleaning_fee || 0).toFixed(2),
        processing_fee: (this.reservation.processing_fee || 0).toFixed(2),
        host_channel_fee: (this.reservation.host_channel_fee || 0).toFixed(2),
        client_profit: (this.reservation.client_profit || 0).toFixed(2),
        net_income: (this.reservation.net_income || 0).toFixed(2),
        price: (this.reservation.price || 0).toFixed(2),
        management_commission: (
          this.reservation.management_commission || 0
        ).toFixed(2),
        sales_tax: (this.reservation.sales_tax || 0).toFixed(2),
        tourist_tax: (this.reservation.tourist_tax || 0).toFixed(2),
      },
    }
  },
  watch: {
    form: {
      handler() {
        this.showAlert = true
      },
      deep: true,
    },
  },
  methods: {
    close() {
      this.$store.commit('hideModal')
    },
    async save() {
      if (this.$refs.form.validate()) {
        await this.$store.dispatch('reservation/updateReservation', {
          id: this.reservation.id,
          ...this.form,
        })
        this.onSuccess()
        this.$store.commit('hideModal')
      }
    },
  },
}
</script>
