<template>
  <v-card v-if="conversation.guest.id" elevation="0">
    <conversation-info-title title="Guest" />
    <v-divider />
    <v-card-text>
      <p class="font-weight-bold">PMS info</p>
      <div class="d-flex align-center mb-4">
        <user-avatar :name="conversation.guest.full_name" :size="50" />
        <div class="ml-4">
          <p class="text-body-1 black--text mb-0">
            {{ conversation.guest.full_name }}
          </p>
          <v-btn
            class="pa-0"
            :href="`tel:${conversation.guest.phones[0]}`"
            text
            x-small
          >
            <v-icon class="mr-1" x-small>mdi-phone</v-icon>
            {{ conversation.guest.phones[0] }}
          </v-btn>
        </div>
      </div>
      <a
        v-if="(isAdmin || hasAbility('login_as_tour')) && conversation.has_user"
        target="_blank"
        :href="`/api/loginas/?guest=${conversation.guest_id}`"
        >Login as guest to portal</a
      >
      <p v-if="conversation.registered_email">
        <v-icon>person</v-icon>
        <span class="cyan-icon">Registered email: </span>
        {{ conversation.registered_email }}
      </p>
      <v-btn
        v-if="conversation.guest.airbnb2"
        class="text-capitalize secondary--text mb-4"
        target="_blank"
        :href="conversation.guest.airbnb2['url']"
        block
        outlined
      >
        <v-icon class="mr-2" small>mdi-open-in-new</v-icon>
        <span>Guest Airbnb profile</span>
      </v-btn>
      <pre-check-in-card
        v-if="reservation.status === 'confirmed'"
        :flat-view="true"
        :editable="true"
        :pre-check-in="reservation.pre_check_in"
        :confirmation-code="reservation.confirmation_code"
        class="mb-2"
      />
      <reset-credentials-modal
        v-if="conversation.registered_email && !listingLoading"
        :current-email="conversation.registered_email"
        :conversation-id="conversation.guesty_id"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormMixin from 'components/mixins/form-rules-mixin'

import PreCheckInCard from 'components/pre-check-in/pre-check-in-card'
import ResetCredentialsModal from 'components/modals/reset-credentials-modal'
import ConversationInfoTitle from 'components/chat/conversation-info-title'
import UserAvatar from 'components/common/user-avatar'

export default {
  components: {
    UserAvatar,
    ConversationInfoTitle,
    ResetCredentialsModal,
    PreCheckInCard,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormMixin],
  props: ['conversation', 'reservation'],
}
</script>
