<template>
  <div class="d-flex grey lighten-4 pa-2">
    <profile-upload
      v-if="uploadable || editProfilePic"
      v-model="pic"
      @on-upload="uploadPicture"
    />
    <v-avatar v-else rounded :size="96">
      <v-img :src="image || placeholderImg" />
      <v-btn
        v-if="!editProfilePic"
        fab
        info
        x-small
        absolute
        center
        color="primary"
        class="mx-2"
        @click="editProfilePic = true"
      >
        <v-icon small center>$complete</v-icon>
      </v-btn>
    </v-avatar>
    <div class="d-flex flex flex-column px-2 justify-space-between">
      <div class="d-flex flex-column align-start px-2">
        <span class="text-caption text-capitalize">
          <span v-if="listing.tags && !isInvestor">
            <tag
              v-for="tag in listing.tags"
              :key="tag"
              color="primary"
              :inverted="true"
              size="xs"
              :class="['d-inline-block ml-1']"
            >
              {{ tag }}
            </tag>
          </span>
        </span>
        <h4 class="font-weight-light">
          <span>{{ title }}</span>
          <span v-if="nickname">/ {{ nickname }}</span>

          <v-btn
            v-if="isReport"
            x-small
            class="ml-2"
            fab
            color="yellow"
            depressed
            @click="directionsTo(listing)"
          >
            <v-icon>mdi-google-maps</v-icon>
          </v-btn>
        </h4>
        <div v-if="doorCode">
          <div class="font-weight-medium">
            <span class="text-caption">{{ $t('Door Code') }}:</span>
            {{ doorCode }}
          </div>
        </div>
        <div v-if="personal">
          <v-menu bottom offset-y>
            <template #activator="{ on }">
              <v-btn elevation="0" outlined x-small v-on="on"
                ><v-icon left x-small>fas fa-user</v-icon> Personnel
              </v-btn>
            </template>
            <div class="grey lighten-3 text-body-2 pa-2">
              <div
                v-for="[type, id] in Object.entries(filteredPersonal)"
                :key="type"
                class="pa-1"
              >
                <span
                  class="black--text font-weight-medium mr-1 text-capitalize"
                >
                  {{ type.replace(/[_]/g, ' ') }}:</span
                ><span>{{ nameById(id) }}</span>
              </div>
            </div>
          </v-menu>
        </div>
        <div class="d-flex align-center">
          <span v-if="guestyId && managementRoles" class="mr-4">
            <v-icon small :color="listed ? 'success' : 'secondary'">{{
              listed ? 'fas fa-check-circle' : 'fas fa-times-circle'
            }}</v-icon>
            <a
              class="small-text blue--text"
              :target="isMobile ? '_self' : '_blank'"
              :href="
                listing.pms_name === 'Guesty'
                  ? `https://app.guesty.com/properties/${guestyId}`
                  : ''
              "
            >
              {{ listing.pms_name }} link
            </a>
            <span v-if="masterGuestyId == guestyId" class="pl-2 small-text">
              --> Master</span
            >
          </span>
          <span v-if="duplicates && managementRoles">
            <span v-for="(dup, index) in duplicates" :key="index" class="mr-4">
              <v-icon small :color="listed ? 'success' : 'secondary'">{{
                listed ? 'fas fa-check-circle' : 'fas fa-times-circle'
              }}</v-icon>
              <a
                :key="dup.id"
                class="small-text blue--text"
                :href="`https://app.guesty.com/properties/${dup.guesty_id}`"
                target="_blank"
              >
                {{ dup.nickname }}
              </a>
              <span
                v-if="masterGuestyId == dup.guesty_id"
                class="pl-2 small-text"
              >
                --> Master</span
              >
            </span>
          </span>
        </div>
      </div>

      <div class="d-flex justify-space-between flex-wrap">
        <div class="d-flex align-center">
          <confirmation-modal
            v-if="hasAbility('super-admin') || hasAbility('disable-listing')"
            :text="`Are you sure you want to ${
              isActive ? 'deactivate' : 'activate'
            } this listing?`"
            title="Listing status"
            :disabled="!isMgmt"
            @action="toggleActive"
          >
            <template #activator="{ on }">
              <v-switch
                :disabled="!isMgmt"
                color="success"
                readonly
                :label="$t('Active')"
                :input-value="isActive"
                v-on="on"
              />
            </template>
          </confirmation-modal>
          <confirmation-modal
            v-if="hasAbility('super-admin') || hasAbility('disable-listing')"
            :text="`Are you sure you want to ${
              isListed ? 'unlist' : 'list'
            } this listing?`"
            title="Listing status"
            :disabled="!isMgmt"
            @action="toggleListed"
          >
            <template #activator="{ on }">
              <v-switch
                class="ml-5"
                color="info"
                :disabled="!isMgmt"
                :label="$t('Listed')"
                :input-value="isListed"
                v-on="on"
              />
            </template>
          </confirmation-modal>
        </div>
        <div class="d-flex align-center">
          <v-btn
            v-if="linkShow && pricingLink && showModule('pricing')"
            small
            class="ma-1"
            :to="pricingLink"
            outlined
          >
            <span class="text-capitalize">{{ $t('Pricing') }} </span>
          </v-btn>
          <v-btn
            v-if="linkShow && opsLink"
            small
            class="ma-1"
            :to="opsLink"
            outlined
          >
            <span class="text-capitalize">{{ $t('Ops') }} </span>
          </v-btn>
          <investment-details-btn
            v-if="
              !isDesignedVr &&
              (isInvestor || isAdmin) &&
              !listing.purchase_price &&
              !isReport
            "
            :listing="listing"
            mode="warn"
          />
          <v-btn v-if="linkShow" small class="ma-1" :to="link" outlined>
            <span class="text-capitalize">
              {{ $t(linkTitle) }}
            </span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeviceMixin from 'components/mixins/device-mixin'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import LtMixin from 'components/mixins/lt-mixin'
import pick from 'lodash/fp/pick'
import InvestmentDetailsBtn from 'components/listing/investment-details-btn.vue'
import ProfileUpload from 'components/common/profile-upload.vue'
import axios from 'axios'
import Tag from 'components/common/tag.vue'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'
import placeholderImg from '@/assets/images/home.png'

export default {
  name: 'ListingInfoPanel',
  components: { ConfirmationModal, ProfileUpload, InvestmentDetailsBtn, Tag },
  mixins: [DeviceMixin, PermissionsMixin, CommonFunctions, LtMixin],
  props: [
    'image',
    'duplicates',
    'guestyId',
    'personal',
    'title',
    'nickname',
    'pricingLink',
    'opsLink',
    'link',
    'linkShow',
    'linkTitle',
    'listed',
    'masterGuestyId',
    'isReport',
    'doorCode',
    'listing',
    'uploadable',
    'isMgmt',
  ],
  data() {
    return {
      pic: this.image,
      placeholderImg,
      editProfilePic: false,
      syncing: false,
    }
  },
  computed: {
    filteredPersonal() {
      return pick(
        ['pest', 'pool', 'cleaning_supervisor', 'landscape'],
        this.personal
      )
    },
    isListed() {
      return this.listing.listed
    },
    isActive() {
      return this.listing.active
    },
  },
  methods: {
    async uploadPicture(pic) {
      this.$store.commit('setListingProfileImage', pic)
      await axios.post(`/api/listings/${this.listing.id}/update`, {
        picture: pic,
      })
    },
    toggleListed() {
      this.listing.listed = !this.listing.listed
      this.$store.dispatch('listings/updateListing', {
        id: this.listing.id,
        payload: { listed: this.listing.listed },
      })
    },
    toggleActive() {
      this.listing.active = !this.isActive
      this.$store.dispatch('listings/activateListing', {
        id: this.listing.id,
        is_active: this.isActive,
      })
    },
    async syncToCm() {
      this.syncing = true
      await this.$store.dispatch('listings/publishListing', {
        id: this.listing.id,
      })
      this.syncing = false
    },
  },
  watch: {
    image(val) {
      this.pic = val
    },
  },
}
</script>

<style scoped></style>
