import { render, staticRenderFns } from "./stripe-account-modal.vue?vue&type=template&id=56fc9cf6&scoped=true&"
import script from "./stripe-account-modal.vue?vue&type=script&lang=js&"
export * from "./stripe-account-modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56fc9cf6",
  null
  
)

export default component.exports