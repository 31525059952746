<template>
  <div v-if="autoMessageRules" class="mt-3">
    <div class="d-flex justify-space-between">
      <h3>{{ $t('Automated Messages') }}</h3>
      <div class="mr-3">
        <v-btn color="primary" @click="addRule">
          <v-icon left>fas fa-plus</v-icon>
          {{ $t('New Auto Msg') }}</v-btn
        >
      </div>
    </div>
    <v-data-table
      :loading="listingLoading"
      :headers="headers"
      :items="filteredItems"
      class="py-2 mt-4"
    >
      <template #top>
        <div class="d-flex flex-row-reverse">
          <v-switch
            v-model="activeOnly"
            dense
            hide-details
            class="ml-4 px-3 mt-0"
            :label="$t('Active only')"
            @change="getAutoMessageRules"
          />
        </div>
      </template>
      <template #item="{ item }">
        <auto-message-rule-row
          :key="item.id"
          :after-change="afterSave"
          :item="item"
          :whatsapp-templates="whatsappTemplates"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import sortBy from 'lodash/sortBy'
import { mapState } from 'vuex'
import AutoMessageRuleRow from 'components/auto-message-rules/auto-message-rule-row.vue'
export default {
  components: {
    AutoMessageRuleRow,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  data() {
    return {
      activeOnly: true,
      whatsappTemplates: null,
    }
  },
  mounted() {
    this.getAutoMessageRules()
    this.getWhatsappTemplates()
  },
  methods: {
    addRule() {
      this.$store.commit('showModal', {
        name: 'AutoMessageRuleModal',
        props: {
          editMode: false,
          afterSaveFunc: this.afterSave,
          templates: this.whatsappTemplates,
        },
      })
    },
    afterSave() {
      this.getAutoMessageRules()
    },
    async getWhatsappTemplates() {
      const { data } = await this.$store.dispatch('getWhatsappTemplates')
      this.whatsappTemplates = data
    },
    getAutoMessageRules() {
      this.$store.dispatch('getAutoMessageRules', {
        id:
          this.listingManageContext &&
          this.$router.currentRoute.path.split('/')[3],
        active_only: this.activeOnly,
      })
    },
  },
  computed: {
    ...mapState(['autoMessageRules']),
    headers() {
      return [
        { text: 'Active', sortable: false },
        { text: 'Name', align: 'center', sortable: false },
        { text: 'Channels', align: 'center', sortable: false },
        { text: 'Via', align: 'center', sortable: false },
        { text: 'Listings', align: 'center', sortable: false },
        { text: 'Timing', align: 'center', sortable: false },
      ]
    },
    filteredItems() {
      return sortBy(this.autoMessageRules, ['listing_nickname'])
    },
  },
}
</script>
