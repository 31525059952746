<template>
  <div>
    <div v-if="!disabled" class="d-flex my-3 align-center">
      <v-col cols="auto" class="pa-0">
        <v-text-field
          v-model="newList"
          outlined
          color="primary"
          dense
          hide-details
          placeholder="New List"
        />
      </v-col>

      <v-btn
        color="primary"
        :disabled="createListDisabled"
        class="mx-2"
        @click="addToLists"
      >
        <v-icon left x-small>fas fa-plus</v-icon>
        Add list
      </v-btn>
    </div>
    <v-sheet
      v-for="listName in listSubjects"
      :key="listName"
      elevation="1"
      class="mt-3 ba-1"
    >
      <div class="text-subtitle-1 pa-2">
        <v-row class="align-center">
          <v-col cols="4">
            <v-text-field
              :value="listName"
              placeholder="List name"
              outlined
              :disabled="disabled"
              hide-details
              dense
              @change="updateListName($event, listName)"
            />
          </v-col>
          <v-col cols="auto">
            <v-select
              :value="listDupBy(listName)"
              :items="['Bedroom', 'Bathroom']"
              label="Dup by"
              clearable
              :disabled="disabled"
              hide-details
              dense
              outlined
              class="small-text max-w-150"
              @change="updateListDup($event, listName)"
            />
          </v-col>
          <v-col cols="auto">
            <v-btn
              v-if="!disabled"
              x-small
              color="primary"
              @click="addToArray(listName)"
            >
              <v-icon x-small left color="white">fas fa-plus</v-icon>
              task
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col cols="auto" class="px-4">
            <v-btn icon text @click="toggleSubTasks(listName)">
              <v-icon
                :class="listToggle[listName] && 'icon-flipped'"
                color="black"
                small
                >fas fa-angle-double-down</v-icon
              >
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-expand-transition>
        <div v-if="listToggle[listName]">
          <div v-for="(val, index) in buildArray" :key="index">
            <div v-if="val.list_subject === listName">
              <v-row no-gutters class="align-center px-3">
                <v-col cols="auto">
                  <v-icon
                    v-if="!disabled"
                    color="warning"
                    small
                    @click="removeCell(index)"
                    >fas fa-minus-circle</v-icon
                  >
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="val.description"
                    :disabled="disabled"
                    hide-details
                    outlined
                    dense
                    label="Description"
                    @change="updateArray($event, index, 'description')"
                  />
                </v-col>

                <v-col cols="auto" class="mx-2">
                  <v-select
                    v-model="val.dup_by"
                    :items="['Bedroom', 'Bathroom']"
                    label="Dup by"
                    clearable
                    hide-details
                    outlined
                    dense
                    class="max-w-150"
                    :disabled="disabled"
                    @change="updateArray($event, index, 'dup_by')"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">
                  <v-select
                    v-model="val.inspection_mode"
                    :items="[
                      { text: 'Yes/No', value: true },
                      { text: 'Multi', value: false },
                    ]"
                    label="Type"
                    hide-details
                    outlined
                    dense
                    class="max-w-150"
                    :disabled="disabled"
                    @change="updateArray($event, index, 'inspection_mode')"
                  />
                </v-col>
                <v-col class="px-1" cols="auto">
                  <v-autocomplete
                    v-model="val.amenity_based"
                    :disabled="disabled"
                    :items="amenities"
                    clearable
                    label="Only if Amenity"
                    hide-details
                    outlined
                    class="max-w-150"
                    dense
                    @change="updateArray($event, index, 'amenity_based')"
                  />
                </v-col>
                <v-col class="px-1" cols="auto">
                  <v-checkbox
                    v-model="val.must_upload_pic"
                    :disabled="disabled"
                    label="Pic*"
                    @change="updateArray($event, index, 'must_upload_pic')"
                  />
                </v-col>
                <v-col class="px-1" cols="auto">
                  <v-checkbox
                    v-model="val.ask_rating"
                    :disabled="disabled"
                    label="Rating*"
                    @change="updateArray($event, index, 'ask_rating')"
                  />
                </v-col>
              </v-row>
              <v-divider />
            </div>
          </div>
        </div>
      </v-expand-transition>
    </v-sheet>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { uniq } from 'lodash/fp'
import { BOOM_AMENITIES } from '@/consts'

export default {
  mixins: [CommonFunctions, PermissionsMixin],

  props: ['afterChange', 'existingItems', 'disabled'],
  data() {
    return {
      buildArray: [],
      newList: null,
      listToggle: {},
      amenities: BOOM_AMENITIES,
    }
  },
  watch: {
    existingItems(items) {
      if (items && items.length !== 0) {
        this.buildArray = cloneDeep(items)
      }
    },
  },
  computed: {
    listSubjects() {
      return uniq(this.buildArray.map(v => v.list_subject))
    },
    createListDisabled() {
      return !this.newList || this.listSubjects.includes(this.newList)
    },
  },
  mounted() {
    const items = this.existingItems
    if (items && items.length !== 0) {
      this.buildArray = cloneDeep(items)
    }
  },
  methods: {
    subTotal(item) {
      if (item.cost && item.amount) {
        return item.cost * item.amount
      }
    },
    toggleSubTasks(listName) {
      this.listToggle = {
        ...this.listToggle,
        [listName]: !this.listToggle[listName],
      }
    },
    removeCell(index) {
      this.buildArray.splice(index, 1)
      this.afterChange(cloneDeep(this.buildArray))
    },
    updateArray(val, index, itemType) {
      this.buildArray[index][itemType] = val
      this.afterChange(cloneDeep(this.buildArray))
    },
    addToArray(listname) {
      this.buildArray.push({ list_subject: listname })
      this.afterChange(cloneDeep(this.buildArray))
    },
    addToLists() {
      this.buildArray.push({
        list_subject: this.newList,
      })
      this.listToggle[this.newList] = true
      this.afterChange(cloneDeep(this.buildArray))
      this.newList = null
    },
    updateListName(newName, oldName) {
      this.buildArray.forEach(item => {
        if (item.list_subject === oldName) {
          item.list_subject = newName
        }
      })
      this.afterChange(cloneDeep(this.buildArray))
    },
    updateListDup(newName, listname) {
      this.buildArray.forEach(item => {
        if (item.list_subject === listname) {
          item.list_dup_by = newName
        }
      })
      this.afterChange(cloneDeep(this.buildArray))
    },
    listDupBy(listName) {
      const listItem = this.buildArray.find(
        item => item.list_subject === listName
      )
      return listItem && listItem.list_dup_by
    },
  },
}
</script>
<style scoped>
.icon-flipped {
  transform: scale(-1);
  -moz-transform: scale(-1);
  -webkit-transform: scale(-1);
  -ms-transform: scale(-1);
}
</style>
